<template>
  <el-dialog title="企业需求" v-model="show" :show-close="false" :close-on-press-escape="false" :width="showForm ?  '630px' : '900px'" :custom-class="'dialog-no-title'" :close-on-click-modal="false">
    <div class="wrap" v-if="!showForm" v-loading="loading">
      <div class="achieve-content hide-scroll-bar">
        <p class="title text-ol-ell">{{ detail.title }}</p>
        <p class="time text-ol-ell">发布时间：{{ detail.publishTime }}</p>
        <div class="form">
          <div class="line flex-cc">
            <p class="dark flex-cc">需求方</p>
            <div class="light">
              <p class="flex-cc text-ol-ell">{{ detail.position }}</p>
            </div>
            <p class="dark flex-cc">技术领域</p>
            <div class="light">
              <p class="flex-cc text-ol-ell">{{ detail.applicationIndustry }}</p>
            </div>
          </div>
          <div class="line flex-cc">
            <p class="dark flex-cc">应用产业</p>
            <div class="light">
              <p class="flex-cc text-ol-ell">{{ detail.domain }}</p>
            </div>
            <p class="dark flex-cc">所属地区</p>
            <div class="light">
              <p class="flex-cc text-ol-ell">{{ showAddress(detail) }}</p>
            </div>
          </div>
          <div class="line flex-cc">
            <p class="dark flex-cc">需求类型</p>
            <div class="light">
              <p class="flex-cc text-ol-ell">{{ detail?.typeList ? detail.typeList[0] : '-' }}</p>
            </div>
            <p class="dark flex-cc">合作方式</p>
            <div class="light">
              <p class="flex-cc text-ol-ell">{{ detail.cooperationModeList?.join('、') }}</p>
            </div>
          </div>
          <div class="line last flex-cc">
            <p class="dark flex-cc">期望解决时间</p>
            <div class="light">
              <p class="flex-cc text-ol-ell">{{ detail.startDate + '~' + detail.endDate }}</p>
            </div>
            <p class="dark flex-cc">项目投入预算</p>
            <div class="light">
              <p class="flex-cc text-ol-ell">{{ detail?.budget === '面议' ? detail?.budget : detail?.budget + '万元' }}</p>
            </div>
          </div>
        </div>
        <div class="options">
          <div class="option" :class="{'active': cur === 0}" @click="cur = 0">
            需求描述
          </div>
          <div class="option" :class="{'active': cur === 1}" @click="cur = 1" v-if="detail?.demandList?.length > 0">
            相似需求
          </div>
        </div>
        <div v-if="cur === 0" class="detail">
          <div class="d-title">
            <img src="@/assets/images/G60/pop/icon-3.png" class="icon-3" />
            <p class="p">技术需求：</p>
          </div>
          <div class="context">
            {{ detail.sketch }}
          </div>
          <!-- <div class="d-title">
            <img src="@/assets/images/G60/pop/icon-4.png" class="icon-4" />
            <p class="p">现有基础情况：</p>
          </div>
          <p class="num text-ol-ell">研发人数：8人</p>
          <p class="num text-ol-ell">研发投入成本：800万</p> -->
          <div class="d-title" v-if="detail.target">
            <img src="@/assets/images/G60/pop/icon-2.png" class="icon-2" />
            <p class="p">技术达到目标：</p>
          </div>
          <p class="num">{{ detail.target }}</p>
          <div class="button">
            <button @click="checkForm()">承接需求</button>
          </div>
        </div>
        <div v-else class="similar">
          <div class="d-title">
            <img src="@/assets/images/G60/pop/icon-3.png" class="icon-3" />
            <p class="p">技术需求：</p>
          </div>
          <div class="demand" v-for="item in detail.demandList" :key="item" :class="{'no-border': item === 2}" @click="changeDemand(item.id)">
            <img src="@/assets/images/G60/labSpace/demand-icon.png" class="icon" />
            <div class="info">
              <a class="text-otl-ell a-title">{{item.title }}</a>
              <div class="type">
                <p class="text-ol-ell">{{ item.applicationIndustry }}</p>
              </div>
              <div class="time">
                期望解决时间：{{ item.endDate }}
              </div>
            </div>
            <div class="budget">
              {{ item?.budget === '面议' ? item?.budget : item?.budget + '万元' }}<br/>
              <span>预算</span>
            </div>
          </div>
        </div>
      </div>
      <img src="@/assets/images/G60/pop/close-button.png" class="close-icon" @click="close" />
    </div>
    <div class="editing" v-else>
      <p class="stop" @click="showForm = false">X</p>
      <p class="title">承接需求</p>
      <div class="form-item">
        <p class="item"><span>*</span>联系人姓名：</p>
        <input v-model="form.name" class="input" maxlength="30" />
      </div>
      <div class="form-item">
        <p class="item"><span>*</span>联系人电话：</p>
        <input v-model="form.phone" class="input" maxlength="30" />
      </div>
      <div class="form-item">
        <p class="item"><span>*</span>技术成果案例：</p>
        <textarea v-model="form.case" class="textarea" maxlength="1000"></textarea>
      </div>
      <div class="form-item">
        <p class="item"><span>*</span>技术专长领域：</p>
        <textarea v-model="form.area" class="textarea" maxlength="1000"></textarea>
      </div>
      <div class="button">
        <button @click="submit">提交承接需求</button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { getG60DemandDetail,submitG60Window } from "@/api/G60";
import { ElMessage } from "element-plus";
import { getG60Id } from "@/utils/G60/login";


export default{
  data(){
    return{
      cur: 0,
      show: true,
      showForm: false,
      detail: {},
      cooperationList: [],
      filterStyle: 'width: 319px;height: 38px;',
      loading: false,
      form:{
        name: '',
        phone: '',
        case: '',
        area: '',
      },
    }
  },
  computed:{
    achieveId(){
      return this.demandStatus.id
    },
    ...mapGetters([
      "demandStatus",
    ]),
  },
  watch:{
    showForm(val){
      if(!val) this.clearForm()
    },
    achieveId(){
      this.getDetail()
    }
  },
  mounted(){
    this.getDetail()
  },
  methods:{
    checkForm(){
      if(this.detail.memberId === getG60Id()){
        ElMessage.warning('您不能承接自己的需求')
        return
      }
      this.showForm = true
    },
    submit(){
      if(!this.form.name){
        ElMessage.warning('请输入联系人姓名')
        return
      }
      else if(!this.form.phone){
        ElMessage.warning('请输入联系人电话')
        return
      }
      else if(!this.form.case){
        ElMessage.warning('请输入技术成果案例')
        return
      }
      else if(!this.form.area){
        ElMessage.warning('请输入技术专长领域')
        return
      }
      const param = {
        "customJson": JSON.stringify({
          "技术成果案例": this.form.case,
          "技术专长领域": this.form.area,
        }),
        "memberId": getG60Id(),
        "name": this.form.name,
        "phone": this.form.phone,
        "sourceId": this.achieveId,
        "type": 2
      }
      submitG60Window(param).then((res)=>{
        if(res.code === "Sx200"){
          this.$store.dispatch('G60/closeDemand')
          this.$store.dispatch('G60/openSuccess','您的承接已提交审核_产学研经理将在1-3个工作日内与您联系！')
        }
      })
    },
    changeDemand(id){
      if(!id) return
      this.$store.dispatch('G60/openDemand',id)
      this.cur = 0
    },
    showAddress(detail){
      let result = ''
      if(detail.province){
        result += detail.province
      }
      if(detail.city){
        result += '-' + detail.province
      }
      if(detail.area){
        result += '-' + detail.province
      }
      return result
    },
    getDetail(){
      if(!this.achieveId){
        this.$store.dispatch('G60/closeDemand')
        return
      }
      this.loading = true
      getG60DemandDetail({id: this.achieveId}).then((res)=>{
        this.detail = res.data
        console.log(this.detail,'heheh222');
      }).finally(()=>{
        this.loading = false
      })
    },
    clearForm(){
      this.form = {
        name: '',
        phone: '',
        case: '',
        area: '',
      }
    },
    close(){
      this.$store.dispatch('G60/closeDemand')
    },
  }
}
</script>


<style lang="scss" scoped>
.wrap{
  position: relative;
  .close-icon{
    width: 28px;
    height: 28px;
    position: absolute;
    right: 20px;
    top: 30px;
    cursor: pointer;
  }
}
.achieve-content{
  width: 100%;
  max-height: 70vh;
  overflow-x: auto;
  box-sizing: border-box;
  padding: 60px 50px;
  .title{
    font-size: 22px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: #111111;
    line-height: 26px;
    width: 100%;
    margin-bottom: 13px;
  }
  .time{
    font-size: 14px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 14px;
    width: 100%;
    margin-bottom: 34px;
  }
  .form{
    width: 100%;
    padding-bottom: 30px;
    .line{
      border-left: 1px solid #E2E8EF;
      align-items: stretch !important;
      p{
        width: 100%;
        display: block;
        min-height: 22px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #111111;
        line-height: 22px;
        border-bottom: none;
      }
      .light{
        display: flex;
        align-items: center;
        border: 1px solid #E2E8EF;
        border-left: none;
        border-bottom: none;
        width: 259px;
        box-sizing: border-box;
        padding: 15px 13px;
        .member{
          color: $--color-theme;
          cursor: pointer;
          .split{
            color: #111111 !important;
            cursor: text !important;
          }
        }
      }
      .country{
        width: 344px;
        box-sizing: border-box;
        padding: 15px;
        min-height: 43px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #111111;
        border: 1px solid #E2E8EF;
        border-left: none;
        line-height: 22px;
        border-bottom: none;
        display: flex;
        align-items: center;
        img{
          width: 32px;
          height: 18px;
          margin-right: 4px;
        }
      }
      .dark{
        border: 1px solid #E2E8EF;
        border-left: none;
        border-bottom: none;
        box-sizing: border-box;
        padding: 15px;
        width: 139px !important;
        background: #F3F9FD;
        color: #666666 !important;
      }
    }
    .last{
      border-bottom: 1px solid #E2E8EF;
    }
  }
  .options{
    width: 100%;
    border-bottom: 1px solid #ebebeb;
    margin-bottom: 20px;
    display: flex;
    .option{
      padding-bottom: 7px;
      font-size: 16px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #111111;
      line-height: 16px;
      margin-right: 40px;
      cursor: pointer;
    }
    .active{
      position: relative;
      color: $--G60-theme !important;
      &::after{
        content: '';
        position: absolute;
        bottom: -1px;
        left: 50%;
        margin-left: -32px;
        width: 64px;
        height: 2px;
        background: $--G60-theme;
      }
    }
  }
  .detail{
    width: 100%;
    padding-bottom: 50px;
    .d-title{
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      .icon-3{
        width: 15px;
        height: 16px;
        margin-right: 5px;
      }
      .icon-4{
        width: 18px;
        height: 15px;
        margin-right: 5px;
      }
      .icon-2{
        width: 16x;
        height: 16px;
        margin-right: 5px;
      }
      .p{
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: bold;
        color: #111111;
        line-height: 16px;
      }
    }
    .context{
      width: 100%;
      font-size: 14px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #111111;
      line-height: 28px;
      letter-spacing: 1px;
      text-align: justify;
      padding-bottom: 14px;
    }
    .num{
      font-size: 14px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #111111;
      line-height: 14px;
      margin-bottom: 14px;
      width: 100%;
    }
    .button{
      width: 100%;
      display: flex;
      padding-top: 40px;
      justify-content: center;
      button{
        width: 216px;
        height: 45px;
        background: $--G60-theme;
        cursor: pointer;
        font-size: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        border: none;
      }
    }
  }
  .similar{
    width: 100%;
    .d-title{
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      .icon-3{
        width: 15px;
        height: 16px;
        margin-right: 5px;
      }
      .icon-4{
        width: 18px;
        height: 15px;
        margin-right: 5px;
      }
      .icon-2{
        width: 16x;
        height: 16px;
        margin-right: 5px;
      }
      .p{
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: bold;
        color: #111111;
        line-height: 16px;
      }
    }
    .num{
      font-size: 14px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #111111;
      line-height: 14px;
      margin-bottom: 14px;
      width: 100%;
    }
    .no-border{
      border-bottom: none !important;
      margin-bottom: 20px;
    }
    .demand{
      width: 100%;
      display: flex;
      box-sizing: border-box;
      padding: 20px 0;
      border-bottom: 1px solid #EBEBEB;
      cursor: pointer;
      &:hover{
        .a-title{
          color: $--G60-theme !important;
        }
      }
      .icon{
        margin-top: 7px;
        width: 22px;
        height: 26px;
        margin-right: 13px;
      }
      .info{
        width: 620px;
        margin-right: 11px;
        .a-title{
          font-size: 18px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #111111;
          line-height: 24px;
          margin-bottom: 11px;
          cursor: pointer;
          &:hover{
            color: $--G60-theme !important;
          }
          &:visited{
            color: #111111;
          }
        }
        .type{
          width: 100%;
          margin-bottom: 12px;
          display: flex;
          p{
            padding: 4px 7px;
            box-sizing: border-box;
            background: #F0F0F0;
            border-radius: 4px;
            border: 1px solid #B3B3B3;
            font-size: 14px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #111111;
            line-height: 14px;
            max-width: 100%;
          }
        }
        .time{
          font-size: 14px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #2380FF;
          line-height: 14px;
          max-width: 100%;
          margin-bottom: 0 !important;
        }
      }
      .budget{
        width: 120px;
        font-size: 20px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #E51616;
        line-height: 28px;
        margin-top: 22px;
        text-align: center;
        span{
          color: #111111;
          font-size: 14px;
        }
      }
    }
  }
}
.editing{
  position: relative;
  width: 100%;
  padding-bottom: 40px;
  .stop{
    width: 13px;
    height: 13px;
    color: #000000;
    font-size: 13px;
    line-height: 13px;
    position: absolute;
    right: 20px;
    top: 24px;
    cursor: pointer;
  }
  .title{
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 18px;
    font-family: PingFangSC, PingFang SC;
    font-weight: bold;
    color: #111111;
    line-height: 22px;
    border-bottom: 1px solid #EbEBeb;
    text-align: center;
    margin-bottom: 30px;
  }
  .form-item{
    display: flex;
    width: 100%;
    padding-bottom: 18px;
    .item{
      width: 193px;
      text-align: right;
      font-size: 14px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #111111;
      line-height: 14px;
      padding-top: 12px;
      span{
        color: #F22222;
      }
    }
    .input{
      width: 319px;
      height: 38px;
      border-radius: 4px;
      border: 1px solid #E2E8EF;
      background-color: #ffffff;
      font-size: 14px;
      color: #111111;
      box-sizing: border-box;
      padding: 0 15px;
      line-height: 38px;
    }
    .textarea{
      width: 319px;
      height: 119px;
      border-radius: 4px;
      border: 1px solid #E2E8EF;
      background-color: #ffffff;
      font-size: 14px;
      color: #111111;
      box-sizing: border-box;
      padding: 15px;
      line-height: 20px;
      resize: none;
    }
  }
  .button{
    margin-top: 17px;
    width: 100%;
    display: flex;
    justify-content: center;
    button{
      border: none;
      cursor: pointer;
      width: 157px;
      height: 38px;
      background: $--G60-theme;
      border-radius: 4px;
      font-size: 14px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
    }
  }
}
</style>
