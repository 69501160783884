const state = {
    parameter: undefined,   // 参数
    type: -1,
    // 弹窗类型
    // 0:关联申请
    // 1:申请失败弹窗
    // 2:高校院所信息
    // 3:专家学者信息
    // 4:选择帐号类型
    // 5:发送邮件弹窗
    // 6:删除团队成员
    // 7:关联请求弹窗
    // 8:已申请关联弹窗
    // 9:已发送邮件弹窗
    // 10:关联成功弹窗
    // 11: 创建身份弹窗
    status: false,  // 仅用于状态监听
}

const mutations = {
    SET_POPUP: (state, inputObject) => {
        state.type = inputObject.type
        state.status = !state.status
        state.parameter = inputObject.parameter
    },
}
const actions = {
    setPopup({commit}, inputObject) {   // 传入type及参数parameter
        commit('SET_POPUP', inputObject)
    },
}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}