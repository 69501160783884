<template>
  <el-dialog v-model="showWindow" :show-close="false" :close-on-press-escape="false" :width="'959px'" :custom-class="'dialog-no-title dialog-transparent'" :top="'10vh'">
    <el-dialog v-model="showSuccessWindow" :width="'400px'" :custom-class="'dialog-no-title dialog-transparent'" :top="'15vh'" append-to-body>
      <div class="success-window" v-if="type === 0">
        <img src="@/assets/images/Tan/pop/vip/close.png" @click="closeSuccess" class="close" />
        <div class="success-top">
          <img src="@/assets/images/Tan/pop/vip/success.png" class="success" />
          <p class="success-title">购买成功</p>
        </div>
        <p class="success-word">可在订阅中心中选择任意细分研究领域进行订阅</p>
        <img src="@/assets/images/Tan/pop/vip/success-center.png" class="center" />
        <p class="success-tip">订单已生成，可前往<span @click="toOrder">订单管理</span>中查看</p>
        <button class="success-button" @click="toCenter">前往订阅中心</button>
      </div>
      <div class="success-window" v-else>
        <img src="@/assets/images/Tan/pop/vip/close.png" @click="closeSuccess" class="close" />
        <div class="success-top">
          <img src="@/assets/images/Tan/pop/vip/success.png" class="success" />
          <p class="success-title">购买成功</p>
        </div>
        <p class="success-tip">订单已生成，可前往<span @click="toProject">菜单-找项目</span>中查看</p>
        <button class="success-button" @click="toProject">立即前往</button>
      </div>
    </el-dialog>
    <div class="vip-window">
      <div class="logo-line">
        <div class="left">
          <img src="@/assets/images/Tan/identity/logo.png"/>
          <p>科企岛（上海）网络技术有限公司</p>
        </div>
        <p class="right">定制探角数字科创中心 让企业快速成长！</p>
      </div>
      <div class="intro">
        <div class="company-info">
          <div class="avatar">
            <MyAvatar :src="TanInfo.img" :id="TanInfo.tjMemberId" :word="TanInfo.name ? TanInfo.name.charAt(0) : ''" :small="true"></MyAvatar>
          </div>
          <p class="name text-ol-ell">{{ TanInfo.name || '-'}}</p>
          <p class="tip text-ol-ell">当前版本：{{ version }}</p>
        </div>
        <div class="options" v-if="step === 0">
          <div class="option" :class="{'active': type === 0}" @click="changeType(0)" style="background-position: left;">
            升级版本
          </div>
          <div class="option" :class="{'active': type === 1}" @click="changeType(1)" style="background-position: right;">
            创新赛道
          </div>
        </div>
        <div v-if="step === 0 && type === 0">
          <div class="intro-window" v-loading="loading">
            <div class="left-back">
              <div class="left">
                <img src="@/assets/images/Tan/pop/vip/diamond.png" class="diamond" v-if="level === 0"/>
                <img src="@/assets/images/Tan/pop/vip/diamond-1.png" class="diamond" v-else/>
                <p class="p-0" v-if="level === 0">精英版</p>
                <p class="p-0" v-else>至尊版</p>
                <p class="p-1">享受权益</p>
                <p class="p-2">
                  海量科创资源大数据，超<span>2万</span>研究领域<br/>
                  <span>40万</span>专家资源，链接<span>100万+</span>技术成果<br/>
                  覆盖全国<span>430+</span>高校、<span>700+</span>科研院所
                </p>
                <div class="divide">
                  ……………………………………………
                </div>
                <div class="lines" v-if="level === 0">
                  <div class="line">
                    <img src="@/assets/images/Tan/pop/vip/select.png" class="right" />
                    <p class="p-3">灵活选择最多20个细分研究领域订阅</p>
                  </div>
                  <div class="line">
                    <img src="@/assets/images/Tan/pop/vip/select.png" class="right" />
                    <p class="p-3">畅享所选领域所有科研专家资源</p>
                  </div>
                  <div class="line">
                    <img src="@/assets/images/Tan/pop/vip/select.png" class="right" />
                    <p class="p-3">最多 10名成员享有专家数据和管理权限</p>
                  </div>
                  <div class="line">
                    <img src="@/assets/images/Tan/pop/vip/select.png" class="right" />
                    <p class="p-3">专属客户管家，7*12小时一对一服务</p>
                  </div>
                </div>
                <div class="lines h-150" v-else>
                  <div class="line">
                    <img src="@/assets/images/Tan/pop/vip/select.png" class="right" />
                    <p class="p-3">解锁所有科创大数据，畅享全部专家资源</p>
                  </div>
                  <div class="line">
                    <img src="@/assets/images/Tan/pop/vip/select.png" class="right" />
                    <p class="p-3">最多可订阅200个研究领域，全面覆盖</p>
                  </div>
                  <div class="line">
                    <img src="@/assets/images/Tan/pop/vip/select.png" class="right" />
                    <p class="p-3">包含创新赛道所有硬科技项目权益</p>
                  </div>
                  <div class="line">
                    <img src="@/assets/images/Tan/pop/vip/select.png" class="right" />
                    <p class="p-3">最多40名成员享有专家数据和管理权限</p>
                  </div>
                  <div class="line">
                    <img src="@/assets/images/Tan/pop/vip/select.png" class="right" />
                    <p class="p-3">免费5次专家/项目对接</p>
                  </div>
                  <div class="line">
                    <img src="@/assets/images/Tan/pop/vip/select.png" class="right" />
                    <p class="p-3">专属客户管家，7*12小时一对一服务</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="right" :class="{'intro-back-0': level === 0,'intro-back-1': level === 1}">
              <div class="levels">
                <div class="level" :class="{'active': level === 0}" @click="changeLevel(0)">
                  <div class="icon-line">
                    <img src="@/assets/images/Tan/pop/vip/n.png" />
                    <p class="p-0">精英版</p>
                  </div>
                  <p class="p-1">¥<span class="price">{{ level0Price }}</span>/年 <span class="small">¥{{level0.marketPrice}}/年</span></p>
                  <p class="p-2">低至{{ Math.floor(level0Price / 365)}}元/天</p>
                </div>
                <div class="level" :class="{'active': level === 1}" @click="changeLevel(1)">
                  <div class="icon-line">
                    <img src="@/assets/images/Tan/pop/vip/t.png" />
                    <p class="p-0">至尊版</p>
                  </div>
                  <p class="p-1">¥<span class="price">{{ level1Price }}</span>/年 <span class="small">¥{{level1.marketPrice}}/年</span></p>
                  <p class="p-2">70%企业的选择</p>
                </div>
              </div>
              <div class="rights" v-if="level === 0">
                <p class="p-bonus">"限时回馈活动！开通精英版，额外获赠6个月创新赛道权益，畅享各类硬科技项目！</p>
                <p class="p-0">定制权益</p>
                <div class="right-line">
                  <div class="left-minus">
                    <img src="@/assets/images/Tan/pop/vip/minus.png" v-if="num0 > level0?.extendData?.vipMinCount" @click="minus(0)"/>
                    <img src="@/assets/images/Tan/pop/vip/minus-disable.png" v-else class="disabled"/>
                  </div>
                  <p class="num">{{ num0 }}</p>
                  <div class="right-plus">
                    <img src="@/assets/images/Tan/pop/vip/plus.png" v-if="num0 < level0?.extendData?.vipMaxCount" @click="add(0)"/>
                    <img src="@/assets/images/Tan/pop/vip/plus-disable.png" v-else class="disabled"/>
                  </div>
                  <div class="right-intro">
                    <p class="p-1">名成员享有专家数据和管理权限</p>
                    <p class="p-2">精英版最低{{level0?.extendData?.vipMinCount || 5}}位成员享有权益，最多可加购至{{level0?.extendData?.vipMaxCount || 10}}位，加购价¥{{level0?.extendData?.eachCountPrice || 258}}/位</p>
                  </div>
                </div>
                <div class="right-line p-t-30">
                  <div class="left-minus">
                    <img src="@/assets/images/Tan/pop/vip/minus.png" v-if="num1 > level0?.extendData?.subscribeMinCount" @click="minus(1)"/>
                    <img src="@/assets/images/Tan/pop/vip/minus-disable.png" v-else class="disabled"/>
                  </div>
                  <p class="num">{{ num1 }}</p>
                  <div class="right-plus">
                    <img src="@/assets/images/Tan/pop/vip/plus.png" v-if="num1 < level0?.extendData?.subscribeMaxCount" @click="add(1)"/>
                    <img src="@/assets/images/Tan/pop/vip/plus-disable.png" v-else class="disabled"/>
                  </div>
                  <div class="right-intro">
                    <p class="p-1">个细分研究领域订阅</p>
                    <p class="p-2">精英版最低享有{{level0?.extendData?.subscribeMinCount}}个细分研究领域订阅，最多可增加至{{level0?.extendData?.subscribeMaxCount}}个，加购价¥{{level0?.extendData?.eachSubscribePrice}}/个</p>
                  </div>
                </div>
              </div>
              <div class="rights" v-else>
                <p class="p-bonus">"限时回馈活动！开通至尊版，额外获赠12个月创新赛道权益，畅享各类硬科技项目！</p>
                <p class="p-0">定制权益</p>
                <div class="right-line">
                  <div class="left-minus">
                    <img src="@/assets/images/Tan/pop/vip/minus.png" v-if="num0 > level1?.extendData?.vipMinCount" @click="minus(0)"/>
                    <img src="@/assets/images/Tan/pop/vip/minus-disable.png" v-else class="disabled"/>
                  </div>
                  <p class="num">{{ num0 }}</p>
                  <div class="right-plus">
                    <img src="@/assets/images/Tan/pop/vip/plus.png" v-if="num0 < level1?.extendData?.vipMaxCount" @click="add(0)"/>
                    <img src="@/assets/images/Tan/pop/vip/plus-disable.png" v-else class="disabled"/>
                  </div>
                  <div class="right-intro">
                    <p class="p-1">名成员享有专家数据和管理权限</p>
                    <p class="p-2">至尊版最低{{level1?.extendData?.vipMinCount || 20}}位成员享有权益，最多可加购至{{level1?.extendData?.vipMaxCount || 40}}位，加购价¥{{level1?.extendData?.eachCountPrice || 258}}/位</p>
                  </div>
                </div>
                <p class="label-word">细分研究领域订阅：200个</p>
              </div>
              <div class="order-detail" :class="{'back-color-FFFCF8': level === 1}">
                <p class="p-0">订单详情</p>
                <div class="detail">
                  <div class="order-left">
                    <p class="p-1 text-ol-ell">订阅企业：<span>{{ TanInfo.tjCompanyName || '-' }}</span></p>
                    <p class="p-1 text-ol-ell">订阅版本：<span>{{ level === 0 ? '精英版' : '至尊版' }}</span></p>
                    <p class="p-1 text-ol-ell">订阅期限：<span>1年</span></p>
                    <p class="p-1 text-ol-ell">享有专家数据和管理权限的成员数量：<span>{{num0}}名</span></p>
                    <p class="p-1 text-ol-ell">享有细分研究领域订阅数量：<span v-if="level !== 1">{{num1}}个</span><span v-else>200个</span></p>
                  </div>
                  <div class="order-right">
                    <div class="price">
                      <p class="p-1">实付：<span class="big">{{ judgeNaN(realPrice) ? '-' : realPrice }}</span><span class="color">元</span></p>
                      <p class="p-2">原价¥{{oldPrice}}元</p>
                    </div>
                    <div class="confirm-button" @click="toStep(1)" v-loading="createOrderLoading">
                      确认订单
                    </div>
                    <div class="check-button">
                      <img v-if="checked" src="@/assets/images/Tan/pop/vip/checked.png" @click="checked = !checked"/>
                      <img v-else src="@/assets/images/Tan/pop/vip/check.png" @click="checked = !checked"/>
                      <p>已阅读并同意<span @click="toXy">《科企岛会员服务协议》</span></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="step === 0 && type === 1">
          <div class="race-window" v-loading="loading">
            <div class="left">
              <img src="@/assets/images/Tan/pop/vip/crown.png" class="crown" />
              <p class="p-0">创新赛道</p>
              <p class="p-1">享受权益</p>
              <div class="part">
                <div class="top">
                  <div class="icon">
                    <img src="@/assets/images/Tan/pop/vip/icon-0.png" style="width: 24px;height: 24px;" />
                  </div>
                  <p class="p-2">全面覆盖</p>
                </div>
                <p class="p-3">涵盖各大硬科技领域，数万项目应有尽有</p>
              </div>
              <div class="part">
                <div class="top">
                  <div class="icon">
                    <img src="@/assets/images/Tan/pop/vip/icon-1.png" style="width: 18px;height: 21px;" />
                  </div>
                  <p class="p-2">深度解析</p>
                </div>
                <p class="p-3">提供项目想尽介绍，全面洞悉项目价值</p>
              </div>
              <div class="part">
                <div class="top">
                  <div class="icon">
                    <img src="@/assets/images/Tan/pop/vip/icon-2.png" style="width: 20px;height: 19px;" />
                  </div>
                  <p class="p-2">智能推荐</p>
                </div>
                <p class="p-3">根据研究方向，精准推荐相关硬科技项目</p>
              </div>
              <div class="part">
                <div class="top">
                  <div class="icon">
                    <img src="@/assets/images/Tan/pop/vip/icon-3.png" style="width: 19px;height: 19px;" />
                  </div>
                  <p class="p-2">免费对接</p>
                </div>
                <p class="p-3">赠送3次优质项目对接机会，助力合作</p>
              </div>
              <div class="part">
                <div class="top">
                  <div class="icon">
                    <img src="@/assets/images/Tan/pop/vip/icon-4.png" style="width: 17px;height: 20px;" />
                  </div>
                  <p class="p-2">优先洽谈</p>
                </div>
                <p class="p-3">独家硬科技项目，享有优先合作洽谈权</p>
              </div>
              <div class="part">
                <div class="top">
                  <div class="icon">
                    <img src="@/assets/images/Tan/pop/vip/icon-5.png" style="width: 22px;height: 20px;" />
                  </div>
                  <p class="p-2">专属服务</p>
                </div>
                <p class="p-3">7*12小时专属客户管家，提供一对一服务</p>
              </div>
            </div>
            <div class="right">
              <div class="levels">
                <div class="level" :class="{'active': level === 1}" @click="changeLevel(1)">
                  <p class="p-0">12个月</p>
                  <p class="p-1">¥<span class="big">{{ level2.salePrice }}</span>/年 <span class="divide">¥{{level2.marketPrice}}/年</span></p>
                  <p class="p-2">低至{{ Math.floor(level2.salePrice / 365) }}元/天</p>
                </div>
                <div class="level" :class="{'active': level === 0}" @click="changeLevel(0)">
                  <p class="p-0">6个月</p>
                  <p class="p-1">¥<span class="big">{{ level3.salePrice }}</span>/年 <span class="divide">¥{{level3.marketPrice}}/年</span></p>
                  <p class="p-2">限时优惠</p>
                </div>
              </div>
              <div class="order-detail">
                <div class="details">
                  <p class="p-0">订单详情</p>
                  <p class="p-1 text-ol-ell">订阅企业：{{ TanInfo.tjCompanyName }}</p>
                  <p class="p-1 text-ol-ell">订单类型：创新赛道</p>
                  <p class="p-1 text-ol-ell">订阅期限：{{ level === 1 ? '12个月' : '6个月' }}</p>
                </div>
                <div class="divide">
                  …………………………………………………………………………………………………………………………………………
                </div>
                <div class="bottom">
                  <div class="pay-options">
                    <div class="pay-option-0" :class="{'active-0': payType === 0}" @click="changePayType(0)">
                      <img src="@/assets/images/Tan/pop/vip/wechat.png" class="wechat"/>
                      <p>微信支付</p>
                    </div>
                    <div class="pay-option-1" :class="{'active-1': payType === 1}" @click="changePayType(1)">
                      <img src="@/assets/images/Tan/pop/vip/ali-pay.png" class="ali-pay"/>
                      <p>支付宝支付</p>
                    </div>
                    <div class="pay-option-2" :class="{'active-2': payType === 2}" @click="changePayType(2)">
                      <img src="@/assets/images/Tan/pop/vip/transfer.png" class="transfer"/>
                      <p>对公转账</p>
                    </div>
                  </div>
                  <div class="pay-code-info">
                    <div class="code vague" v-if="!checked || !QRCodeValue" v-loading="codeLoading">
                      <img src="@/assets/images/manualService/wechat-service.jpg" alt="" />
                    </div>
                    <div class="code" v-else v-loading="codeLoading">
                      <qrcode-vue :value="QRCodeValue" :size="110"></qrcode-vue>
                    </div>
                    <div ref="alipay-block" v-show="showAlipayBlock"></div>
                    <div class="info">
                      <p class="p-0">实付：<span class="big">{{ judgeNaN(realPrice) ? '-' : realPrice }}</span><span class="price">元</span></p>
                      <p class="p-1">原价{{oldPrice}}元</p>
                      <div class="check-button">
                        <img v-if="checked" src="@/assets/images/Tan/pop/vip/checked.png" @click="checked = !checked"/>
                        <img v-else src="@/assets/images/Tan/pop/vip/check.png" @click="checked = !checked"/>
                        <p>已阅读并同意<span @click="toXy">《科企岛会员服务协议》</span></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="step === 1">
          <div class="order-window">
            <div class="top">
              <p class="p-0">确认订单</p>
              <button class="cancel-button" @click="cancelOrder">取消订单</button>
            </div>
            <div class="order-detail" v-if="type === 0">
              <div class="column-0">
                <div class="part">
                  <p class="p-0">订单类型：</p>
                  <p class="p-1 text-ol-ell">升级版本</p>
                </div>
                <div class="part">
                  <p class="p-0">收款方：</p>
                  <p class="p-1 text-ol-ell">科企岛（上海）网络技术有限公司</p>
                </div>
              </div>
              <div class="column-1">
                <div class="part">
                  <p class="p-0">订单编号：</p>
                  <p class="p-1 text-ol-ell">{{ orderInfo.orderNo || '-' }}</p>
                </div>
                <div class="part">
                  <p class="p-0">付款方：</p>
                  <p class="p-1 text-ol-ell">{{ TanInfo.tjCompanyName || '-' }}</p>
                </div>
              </div>
              <div class="column-2">
                <div class="part">
                  <p class="p-0">加购研究领域订阅数量：</p>
                  <p class="p-1 text-ol-ell">{{ level === 1 ? '200个' : num1 }}</p>
                </div>
                <div class="part">
                  <p class="p-0">订单创建时间：</p>
                  <p class="p-1 text-ol-ell">{{ orderInfo.time || '-'}}</p>
                </div>
              </div>
              <div class="column-3">
                <div class="part">
                  <p class="p-0">订阅时间：</p>
                  <p class="p-1 text-ol-ell">1年</p>
                </div>
                <div class="part">
                  <p class="p-0">订阅版本：</p>
                  <p class="p-1 text-ol-ell">{{ level === 1 ? '至尊版' : '精英版' }}</p>
                </div>
              </div>
            </div>
            <div class="pay-detail">
              <div class="pay-options">
                <div class="pay-option-0" :class="{'active-0': payType === 0}" @click="changePayType(0)">
                  <img src="@/assets/images/Tan/pop/vip/wechat.png" class="wechat"/>
                  <p>微信支付</p>
                </div>
                <div class="pay-option-1" :class="{'active-1': payType === 1}" @click="changePayType(1)">
                  <img src="@/assets/images/Tan/pop/vip/ali-pay.png" class="ali-pay"/>
                  <p>支付宝支付</p>
                </div>
                <div class="pay-option-2" :class="{'active-2': payType === 2}" @click="changePayType(2)">
                  <img src="@/assets/images/Tan/pop/vip/transfer.png" class="transfer"/>
                  <p>对公转账</p>
                </div>
                <p class="tip">
                  选择对公转账方式，将实付金额汇入汇款账户， 汇款成功后30分钟内服务自动生效
                </p>
              </div>
              <div class="pay-code-info">
                <div class="code" v-loading="codeLoading">
                  <qrcode-vue :value="QRCodeValue" :size="110"></qrcode-vue>
                </div>
                <div ref="alipay-block" v-show="showAlipayBlock"></div>
                <div class="info">
                  <p class="p-0">实付：<span class="big">{{ judgeNaN(realPrice) ? '-' : realPrice }}</span><span class="price">元</span></p>
                  <p class="time">剩余支付时间 <span>{{ orderCountdownWord }}</span>，否则订单将自动失效</p>
                  <p class="tip">支付成功后可在“订单管理”开具发票</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="step === 2">
          <div class="transfer-window">
            <p class="p-0">对公转账流程</p>
            <div class="wrap">
              <div class="left">
                <p class="p-1">第一步</p>
                <p class="p-2">对公汇款</p>
              </div>
              <div class="middle">
                <p class="p-3 text-ol-ell">开户名称: <span>科企岛（上海）网络技术有限公司</span></p>
                <p class="p-3 text-ol-ell">开户银行: <span>招商股份有限公司上海松江支行</span></p>
                <p class="p-3 text-ol-ell">汇款账号: <span>121939289710501</span></p>
                <p class="p-3 text-ol-ell">汇款备注：<span></span>订单号370939783151617</p>
              </div>
              <div class="price-line">
                <p class="p-4">汇款金额：<span class="big">{{ judgeNaN(realPrice) ? '-' : realPrice }}</span><span class="price">元</span></p>
                <button @click="copyTransfer" class="copy-button">复制汇款信息</button>
              </div>
            </div>
            <div class="wrap">
              <div class="left">
                <p class="p-1">第二步</p>
                <p class="p-2">填写公司信息及上传付款回执</p>
              </div>
              <div class="transfer-form">
                <div class="form-item">
                  <p class="label"><span>*</span>企业名称：</p>
                  <el-input v-model="form.name" style="width: 414px;" placeholder="请输入企业全称" maxlength="50"/>
                </div>
                <div class="form-item">
                  <p class="label"><span>*</span>联系电话：</p>
                  <el-input v-model="form.phone" style="width: 414px;" placeholder="请输入手机号" maxlength="11" @input="handleNumber()"/>
                </div>
                <div class="form-item upload-item">
                  <p class="label"><span>*</span>请上传付款回执：</p>
                  <div class="upload" v-if="!form.file.url" v-loading="uploading">
                    <el-upload
                      action="#"
                      :accept="'image/*'"
                      v-model:file-list="form.file.files"
                      :http-request="handleUpload"
                      :on-remove="handleRemove"
                      :on-exceed="handleExceed"
                      :before-upload="beforeUploadFile"
                      class="my-upload"
                      :limit="1">
                      <div class="upload-container">
                        <img src="@/assets/images/Tan/pop/vip/upload.png" />
                      </div>
                    </el-upload>
                  </div>
                  <div class="upload" v-else v-loading="uploading">
                    <img class="result" :src="form.file.url"/>
                    <img class="delete" @click="handleRemove" src="@/assets/images/Tan/pop/vip/delete.png"/>
                  </div>
                  <p class="tip">请上传付款回执图片或截图，支持jpg、png</p>
                </div>
              </div>
            </div>
            <div class="submit-button">
              <button @click="cancelSubmit" class="cancel" v-loading="cancelLoading">取消</button>
              <button @click="submit">提交</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import MyAvatar from "./MyAvatar.vue";
import { uploadTypeImage,cosByUploadType, } from "@/api/upload";
import { bucketMap } from "@/utils/consts";
import { ElMessage, ElMessageBox } from 'element-plus';
import { randomFilename } from "@/utils/time";
import { copyToClipboard } from "@/utils/tool.js"
import { getTanLocalInfo } from "@/utils/Tan/login";
import { getTanProducts } from "@/api/Tan/product";
import { createTanOrder,getTanPayment,closeTanOrder,getTanPaymentStatus, } from "@/api/Tan/order";
import QrcodeVue from 'qrcode.vue';
import { activityRegister } from "@/api/activity";

export default{
  components:{
    MyAvatar,
    QrcodeVue,
  },
  data(){
    return{
      showSuccessWindow: false,
      codeInterval: null,
      cancelLoading: false,
      vipInfo: [],
      countTime: 0,
      countInterval: '',
      orderCountdownWord: '-',
      showAlipayBlock: false,
      createOrderLoading: false,
      codeLoading: false,
      QRCodeValue: '',
      orderInfo: {},
      loading: false,
      products: [],
      TanInfo: {},
      showWindow: false,
      type: 0,
      step: 0,
      level: 0,
      num0: 0,
      num1: 0,
      checked: false,
      payType: 0,
      uploading: false,
      form: {
        name: '',
        phone: '',
        file: {
          url: '',
          files: [],
        },
      },
    }
  },
  computed:{
    version(){
      if(this.vipInfo?.length === 0) return '免费版'
      const flag1 = this.vipInfo.find(item=>item.vipType === 13 && item.useStatus)
      const flag2 = this.vipInfo.find(item=>item.vipType === 12 && item.useStatus)
      const flag3 = this.vipInfo.find(item=>item.vipType === 14 && item.useStatus)
      if(flag1){
        return '至尊版'
      }
      else if(flag2){
        return '精英版'
      }
      else if(flag3){
        return '创新赛道'
      }
      else return '免费版'
    },
    level0(){
      return this.products.find((item)=>item.summary.includes('探角vip企业版')) || {}
    },
    level0Price(){
      const level0 = this.level0
      return Math.floor((level0?.salePrice + level0?.extendData?.vipMinCount * level0?.extendData?.eachCountPrice + level0?.extendData?.subscribeMinCount * level0?.extendData?.eachSubscribePrice) * 100) / 100
    },
    level1(){
      return this.products.find((item)=>item.summary.includes('探角旗舰版vip')) || {}
    },
    level1Price(){
      const level1 = this.level1
      return Math.floor((level1?.salePrice + level1?.extendData?.vipMinCount * level1?.extendData?.eachCountPrice) * 100) / 100
    },
    level2(){
      return this.products.find((item)=>item.summary?.includes('探角创新赛道') && item.name?.includes('12个月')) || {}
    },
    level3(){
      return this.products.find((item)=>item.summary?.includes('探角创新赛道') && item.name?.includes('6个月')) || {}
    },
    ...mapGetters([
      "TanVipInfo",
      "TanVipPopOrderInfo",
      "TanVipPopShow",
    ]),
    realPrice(){
      if(this.type === 0){
        const level0 = this.level0
        const level1 = this.level1
        return this.level === 0 ? Math.floor((this.level0Price + (this.num0 - level0?.extendData?.vipMinCount) * level0?.extendData?.eachCountPrice + (this.num1 - level0?.extendData?.subscribeMinCount) * level0?.extendData?.eachSubscribePrice) * 100) / 100 : Math.floor((this.level1Price + (this.num0 - level1?.extendData?.vipMinCount) * level1?.extendData?.eachCountPrice) * 100) / 100
      }
      else{
        return this.level === 0 ? this.level3.salePrice : this.level2.salePrice
      }
    },
    oldPrice(){
      if(this.type === 0){
        const level0 = this.level0
        const level1 = this.level1
        return this.level === 0 ? Math.ceil((this.level0.marketPrice + (this.num0 - level0?.extendData?.vipMinCount) * level0?.extendData?.eachCountPrice + (this.num1 - level0?.extendData?.subscribeMinCount) * level0?.extendData?.eachSubscribePrice) * 100) / 100 : Math.ceil((this.level1.marketPrice + (this.num0 - level1?.extendData?.vipMinCount) * level1?.extendData?.eachCountPrice) * 100) / 100
      }
      else{
        return this.level === 0 ? this.level3.marketPrice : this.level2.marketPrice
      }
    },
  },
  watch:{
    TanVipPopShow(val){
      this.showWindow = val >= 0
      if(val === 2) this.changeType(1)
      else if(val === 3){
        // 升级探角精英版
        this.step = 1
        this.level = 0
        this.num0 = this.TanVipPopOrderInfo.vipCount
        this.num1 = this.TanVipPopOrderInfo.subscribeCount
        this.orderInfo = {
          orderNo: this.TanVipPopOrderInfo.no,
          orderId: this.TanVipPopOrderInfo.id,
          time: this.TanVipPopOrderInfo.time,
        }
        this.codeLoading = true
        this.setCountdown(this.TanVipPopOrderInfo.endTime)
        getTanPayment({
          orderNo: this.orderInfo.orderNo,
          type: 7,  // 7-微信native支付
        }).then(payRes=>{
          this.generateWechatQRCode(payRes.data.invokeParam.codeUrl)
        }).finally(()=>{
          this.codeLoading = false
        })
      }
      else if(val === 4){
        // 升级探角至尊版
        this.step = 1
        this.level = 1
        this.num0 = this.TanVipPopOrderInfo.vipCount
        this.orderInfo = {
          orderNo: this.TanVipPopOrderInfo.no,
          orderId: this.TanVipPopOrderInfo.id,
          time: this.TanVipPopOrderInfo.time,
        }
        this.codeLoading = true
        this.setCountdown(this.TanVipPopOrderInfo.endTime)
        getTanPayment({
          orderNo: this.orderInfo.orderNo,
          type: 7,  // 7-微信native支付
        }).then(payRes=>{
          this.generateWechatQRCode(payRes.data.invokeParam.codeUrl)
        }).finally(()=>{
          this.codeLoading = false
        })
      }
      else if(val === 5){
        // 创新赛道
        this.step = 0
        this.type = 1
        this.orderInfo = {
          orderNo: this.TanVipPopOrderInfo.no,
          orderId: this.TanVipPopOrderInfo.id,
          time: this.TanVipPopOrderInfo.time,
        }
        this.setCountdown(this.TanVipPopOrderInfo.endTime)
        this.codeLoading = true
        getTanPayment({
          orderNo: this.orderInfo.orderNo,
          type: 7,  // 7-微信native支付
        }).then(payRes=>{
          this.generateWechatQRCode(payRes.data.invokeParam.codeUrl)
        }).finally(()=>{
          this.codeLoading = false
        })
      }
      else if(val === 6) this.changeLevel(1)
    },
    step(val){
      if(val !== 1){
        if(this.countInterval){
          this.countTime = 0
          this.orderCountdownWord = '-'
          if(this.countInterval) clearInterval(this.countInterval)
        }
      }
      if(this.codeInterval){
        clearInterval(this.codeInterval)
      }
    },
    showWindow(val){
      if(!val){
        this.$store.dispatch('Tan/setVipShow',-1)
        this.type = 0
        this.step = 0
        this.level = 0
        this.num0 = this.level0?.extendData?.vipMinCount || 5
        this.num1 = this.level0?.extendData?.subscribeMinCount || 10
        this.checked = false
        this.payType = 0
        this.uploading = false
        this.clearForm()
        if(this.countInterval){
          clearInterval(this.countInterval)
        }
        if(this.codeInterval){
          clearInterval(this.codeInterval)
        }
      }
      else{
        this.getProducts()
        this.getVipInfo()
        this.TanInfo = getTanLocalInfo()?.tjInfo || {}
      }
    },
    type(){
      this.QRCodeValue = ''
      this.orderInfo = {}
      if(this.codeInterval){
        clearInterval(this.codeInterval)
      }
    },
    checked(val){
      if(val && this.type === 1 && !this.QRCodeValue){
        let param = {
          productSkuId: this.level === 1 ? this.level2.id : this.level3.id,
        }
        this.createOrder2(param)
      }
    },
  },
  methods:{
    judgeNaN(num){
      return isNaN(num)
    },
    closeSuccess(){
      this.showSuccessWindow = false
    },
    toOrder(){
      if(this.$route.path !== '/order'){
        this.$router.push('/order')
      }
      this.showSuccessWindow = false
      this.showWindow = false
    },
    toProject(){
      if(this.$route.path !== '/project-search'){
        this.$router.push('/project-search')
      }
      this.showSuccessWindow = false
      this.showWindow = false
    },
    toCenter(){
      if(this.$route.path !== '/resource-center'){
        this.$router.push('/resource-center')
      }
      this.showSuccessWindow = false
      this.showWindow = false
    },
    getVipInfo(){
      this.vipInfo = this.TanVipInfo
    },
    createOrder2(param){
      // 创新赛道 创建订单
      createTanOrder(param).then(res=>{
        this.orderInfo = res.data
        this.codeLoading = true
        getTanPayment({
          orderNo: this.orderInfo.orderNo,
          type: 7,  // 7-微信native支付
        }).then(payRes=>{
          this.setCountdown()
          this.generateWechatQRCode(payRes.data.invokeParam.codeUrl)
        }).finally(()=>{
          this.codeLoading = false
        })
      }).catch((err)=>{
        if(err.code === 'LxAPI-060007' && err.message){
          // 还有订单
          ElMessageBox.confirm('您已有一个相同类型的订单待支付，不支持重复提交。请先完成支付或取消原有订单后再进行新的支付','重复订单', {
            confirmButtonText: '查看待支付订单',
            cancelButtonText: '取消并创建新订单',
            closeOnClickModal: false,
            closeOnPressEscape: false,
            showClose: false,
            lockScroll: false,  // 防止页面振动
            type: 'error'
          }).then(()=>{
            this.$router.push('/order')
            this.showWindow = false
          }).catch(()=>{
            // 取消并创建
            closeTanOrder(err.message).then(()=>{
              this.createOrder2(param)
            })
          })
        }
      })
    },
    setCountdown(time=900) {
      const that = this
      this.countTime = time
      that.countInterval = setInterval(() => {
        if(that.countTime <= 0) {
          // 计时结束，清除缓存
          clearInterval(that.countInterval)
          that.orderCountdownWord = `00分00秒` // 00天00时
          this.step = 0
          ElMessageBox.confirm("订单已超时?", "提示", {
            confirmButtonText: "确定",
            lockScroll: false,  // 防止页面振动
            type: "info",
          })
        } else {
          that.countTime--
          // let day = parseInt(that.countTime / 60 / 60 / 24)
          // let hr = parseInt(that.countTime / 60 / 60 % 24)
          let min = parseInt(that.countTime / 60 % 60)
          let sec = parseInt(that.countTime % 60)
          // day = day > 9 ? day : '0' + day
          // hr = hr > 9 ? hr : '0' + hr
          min = min > 9 ? min : '0' + min
          sec = sec > 9 ? sec : '0' + sec
          that.orderCountdownWord = `${min}分${sec}秒` // ${day}天${hr}时
        }
      }, 1000);
    },
    getProducts(){
      if(this.products?.length === 0) this.loading = true
      getTanProducts().then(res=>{
        this.products = res.data.result
        if(this.TanVipPopShow < 3){
          this.num0 = this.level0?.extendData?.vipMinCount || 5
          this.num1 = this.level0?.extendData?.subscribeMinCount || 10
        }
      }).finally(()=>{
        this.loading = false
      })
    },
    copyTransfer(){
      copyToClipboard('开户名称:科企岛（上海）网络技术有限公司\n开户银行:招商股份有限公司上海松江支行\n汇款账号:121939289710501')
      ElMessage.success('复制成功!')
    },
    cancelSubmit(){
      this.cancelLoading = true
      closeTanOrder(this.orderInfo.orderId).then(()=>{
        if(this.type === 1){
          this.toStep(0)
          let param = {
            productSkuId: this.level === 1 ? this.level2.id : this.level3.id,
          }
          this.createOrder2(param)
        }
        else{
          this.toStep(1)
        }
        this.clearForm()
      }).finally(()=>{
        this.cancelLoading = false
      })
    },
    submit(){
      // 提交对公转账
      if(!this.form.name){
        ElMessage.warning('请填写企业名称')
        return
      }
      else if(!this.form.phone){
        ElMessage.warning('请填写联系电话')
        return
      }
      else if(!this.form.file.url){
        ElMessage.warning('请上传付款回执')
        return
      }
      const param = {
        activityName: '探角对公转账',
        activityType: '10001',
        code: '123',
        isCreateMember: false,
        contactWay: this.form.phone,
        companyName: this.form.name,
        repeat: true,
        name: this.TanInfo.name,
        jsonStr: JSON.stringify({
          '订单号': this.orderInfo.orderNo,
          '付款回执': this.form.file.url,
        }),
      }
      activityRegister(param).then(res=>{
        if(res.code === "Sx200"){
          ElMessage.success('提交成功！我们将尽快核实你的付款信息')
          this.showWindow = false
        }
      })
    },
    beforeUploadFile(file){
      // 上传文件
      if(file.size > 5 * 1024 * 1024){
        // 大小判断 5M
        ElMessage.warning('上传图片大小过大，不能超过5M!')
        return false
      }
    },
    handleExceed(){
      ElMessage.warning('最多只能上传1个图片')
    },
    handleRemove(){
      this.form.file.files = []
      this.form.file.url = ''
    },
    handleUpload(val){
      // 先登录
      this.directUploadToTen(val.file).then(res=>{
        this.form.file.url = 'https://' + res.Location
        this.form.file.files = [{
          name: val.file.name,
          url: 'https://' + res.Location,
        },]
      })
    },
    directUploadToTen(file){
      let self = this
      let cos = cosByUploadType(uploadTypeImage)
      let bucket = bucketMap.image
      return new Promise((resolve, reject) => {
        cos.putObject(
          {
            Bucket: bucket,
            Region: "ap-shanghai",
            Key: randomFilename(),
            StorageClass: "STANDARD",
            Body: file,
            onProgress: function (progressData) {
              const percentage = parseInt(progressData.percent * 100);
              if (percentage === 100){
                self.uploading = false;
                ElMessage.success('上传成功!')
              }
              else self.uploading = true
            },
          },
          function (err, data) {
            if (err) {
              reject(err);
            } else {
              resolve(data);
            }
          }
        );
      });
    },
    handleNumber(){  // 数字处理
      let pattern = new RegExp("^[0-9]|-$")
      this.form.phone = Array.from(this.form.phone).filter(v => pattern.test(v)).join("")
    },
    clearForm(){
      this.form = {
        name: '',
        phone: '',
        file: {
          url: '',
          files: [],
        },
      }
    },
    changePayType(index){
      if(index === 2){
        if(this.type === 1 && !this.checked){
          ElMessage.warning('请先阅读并同意《科企岛会员服务协议》')
          return
        }
        this.toStep(2)
      }
      else if(index === 1){
        // 支付宝
        if(this.type === 1 && !this.checked){
          ElMessage.warning('请先阅读并同意《科企岛会员服务协议》')
          return
        }
        this.codeLoading = true
        this.payType = 1
        getTanPayment({
          orderNo: this.orderInfo.orderNo,
          type: 6,  // 6-支付宝网页支付
        }).then(payRes=>{
          this.generateAliPayBlock(payRes.data.invokeParam.biz_content)
        })
      }
    },
    cancelOrder(){
      // 取消订单
      closeTanOrder(this.orderInfo.orderId).then(()=>{
        ElMessage.success('取消成功')
      })
      this.toStep(0)
    },
    toStep(index){
      let param = {}
      switch (index) {
        case 1:
          if(!this.checked){
            ElMessage.warning('请先阅读并同意《科企岛会员服务协议》')
            return
          }
          if(this.level === 0){
            // 精英版
            param = {
              productSkuId: this.level0.id,
              paramJson: JSON.stringify({
                vipCount: this.num0,
                subscribeCount: this.num1,
              })
            }
          }
          else{
            // 至尊版
            param = {
              productSkuId: this.level1.id,
              paramJson: JSON.stringify({
                vipCount: this.num0,
                subscribeCount: 200,
              })
            }
          }
          this.createOrderLoading = true
          createTanOrder(param).then(res=>{
            this.orderInfo = res.data
            this.orderInfo.time = res.timestamp[0] + '-' + (res.timestamp[1] >= 10 ? res.timestamp[1] : '0' + res.timestamp[1]) + '-' + (res.timestamp[2] >= 10 ? res.timestamp[2] : '0' + res.timestamp[2]) + ' ' + (res.timestamp[3] >= 10 ? res.timestamp[3] : '0' + res.timestamp[3]) + ':' + (res.timestamp[4] >= 10 ? res.timestamp[4] : '0' + res.timestamp[4])
            this.step = index
            this.createOrderLoading = false
            this.codeLoading = true
            this.setCountdown()
            getTanPayment({
              orderNo: this.orderInfo.orderNo,
              type: 7,  // 7-微信native支付
            }).then(payRes=>{
              this.generateWechatQRCode(payRes.data.invokeParam.codeUrl)
            }).finally(()=>{
              this.codeLoading = false
            })
          }).catch(err=>{
            this.createOrderLoading = false
            if(err.code === 'LxAPI-060007' && err.message){
              // 还有订单
              ElMessageBox.confirm('您已有一个相同类型的订单待支付，不支持重复提交。请先完成支付或取消原有订单后再进行新的支付','重复订单', {
                confirmButtonText: '查看待支付订单',
                cancelButtonText: '取消并创建新订单',
                closeOnClickModal: false,
                closeOnPressEscape: false,
                showClose: false,
                lockScroll: false,  // 防止页面振动
                type: 'error'
              }).then(()=>{
                this.$router.push('/order')
                this.showWindow = false
              }).catch(()=>{
                // 取消并创建
                closeTanOrder(err.message).then(()=>{
                  this.toStep(1)
                })
              })
            }
          })
          break;
        default:
          this.step = index
          break;
      }
    },
    generateWechatQRCode(val){
      this.QRCodeValue = val
      this.codeInterval = setInterval(()=>{
        getTanPaymentStatus(this.orderInfo.orderNo).then(res=>{
          if (res.data.isDone) {
            clearInterval(this.codeInterval)
            if(this.type === 0){
              this.showSuccessWindow = true
              this.toStep(0)
            }
            else{
              this.showSuccessWindow = true
              this.changeType(0)
            }
          }
        })
      },3000)
    },
    generateAliPayBlock(code){
      // 获取支付宝购买模块
      this.showAlipayBlock = true
      this.$nextTick(()=>{
        let alipayBLock = this.$refs["alipay-block"]
        alipayBLock.innerHTML = code
        document.forms.punchout_form.submit()
      })
    },
    toXy(){
      window.open('https://www.keqidao.com/yhxy')
    },
    changeType(index){
      this.type = index
      this.num0 = this.level0?.extendData?.vipMinCount || 5
      this.num1 = this.level0?.extendData?.subscribeMinCount || 10
      this.level = 0
      this.payType = 0
      if(index === 1) this.checked = false
    },
    changeLevel(index){
      this.level = index
      if(this.level === 0){
        this.num0 = this.level0?.extendData?.vipMinCount || 5
        this.num1 = this.level0?.extendData?.subscribeMinCount || 10
      }
      else{
        this.num0 = this.level1?.extendData?.vipMinCount || 20
        this.num1 = -1
      }
    },
    minus(index){
      switch (index) {
        case 0:
          this.num0 -=1
          break;
        case 1:
          this.num1 -=1
          break;
        default:
          break;
      }
    },
    add(index){
      switch (index) {
        case 0:
          this.num0 +=1
          break;
        case 1:
          this.num1 +=1
          break;
        default:
          break;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.vip-window{
  width: 100%;
  background: #FFFFFF;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 8px 28px 28px;
  .logo-line{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-bottom: 9px;
    .left{
      display: flex;
      align-items: flex-end;
      img{
        width: 62px;
        height: 34px;
        margin-right: 10px;
      }
      p{
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #999999;
        line-height: 14px;
        padding-bottom: 3px;
        position: relative;
        padding-left: 6px;
        &::before{
          position: absolute;
          content: '';
          width: 1px;
          height: 10px;
          background: #999999;
          left: 0;
          bottom: 4px;
        }
      }
    }
    .right{
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #333333;
      line-height: 20px;
    }
  }
  .intro{
    width: 100%;
    background: #F4F7FC;
    border-radius: 10px 10px 0px 0px;
    overflow: hidden;
    .company-info{
      height: 43px;
      width: 100%;
      background: #26252E;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      padding-left: 24px;
      .avatar{
        width: 26px;
        height: 26px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 8px;
      }
      .name{
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 14px;
        margin-right: 20px;
      }
      .tip{
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #FFFFFF;
        line-height: 12px;
      }
    }
    .options{
      width: 100%;
      display: flex;
      align-items: center;
      background: #26252E;
      position: relative;
      .active{
        background-image: url('../../../assets/images/Tan/pop/vip/active.png') !important;        background-size: 100% 100% !important;
      }
      .option{
        width: 50%;
        height: 39px;
        background-image: url('../../../assets/images/Tan/pop/vip/not-active.png');        background-size: 105% 100%;
        background-repeat: no-repeat;
        border-radius: 14px 14px 0px 0px;
        cursor: pointer;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: #FFFFFF;
        line-height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .active{
        color: #412201 !important;
      }
    }
    .race-window{
      width: 100%;
      box-sizing: border-box;
      padding: 20px 18px 18px;
      display: flex;
      justify-content: space-between;
      .left{
        width: 300px;
        height: 484px;
        box-sizing: border-box;
        padding-top: 17px;
        background: linear-gradient( 180deg, #FAE8BE 0%, #FFFFFF 100%);
        border-radius: 10px;
        display: flex;
        align-items: center;
        flex-direction: column;
        .crown{
          width: 62px;
          height: 56px;
          margin-bottom: 7px;
        }
        .p-0{
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 18px;
          color: #333333;
          line-height: 25px;
          padding-bottom: 25px;
        }
        .p-1{
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #E9BB6B;
          line-height: 22px;
          padding-bottom: 12px;
        }
        .part{
          width: 254px;
          padding: 3px 0;
          .top{
            display: flex;
            align-items: center;
            .icon{
              width: 24px;
              height: 24px;
              display: flex;
              justify-content: center;
              height: fit-content;
              margin-right: 1px;
            }
            .p-2{
              font-family: PingFangSC, PingFang SC;
              font-size: 12px;
              color: #333333;
              line-height: 22px;
              font-weight: bold;
            }
            .p-3{
              font-family: PingFangSC, PingFang SC;
              font-size: 12px;
              color: #333333;
              line-height: 22px;
            }
          }
        }
      }
      .right{
        width: calc(100% - 316px);
        .levels{
          display: flex;
          justify-content: space-between;
          width: 100%;
          padding-bottom: 20px;
          .level{
            width: calc(50% - 5px);
            height: 109px;
            background: #FFFFFF;
            border-radius: 10px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            flex-direction: column;
            padding-top: 13px;
            overflow: hidden;
            cursor: pointer;
            .p-0{
              font-family: PingFangSC, PingFang SC;
              font-weight: 500;
              font-size: 16px;
              color: #333333;
              line-height: 22px;
              padding-bottom: 10px;
            }
            .p-1{
              font-family: PingFangSC, PingFang SC;
              font-weight: 500;
              font-size: 14px;
              color: #FF6700;
              line-height: 20px;
              padding-bottom: 18px;
              .big{
                font-size: 22px;
              }
              .divide{
                font-weight: 400;
                text-decoration:line-through;
              }
            }
            .p-2{
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 12px;
              color: #B67E1E;
              line-height: 23px;
              text-align: center;
              width: 100%;
              height: 23px;
              background: #FFE9C0;
            }
          }
          .active{
            border: 2px solid #E9BB6A;
            background: #FEFCF6;
            .p-2{
              background: linear-gradient( 90deg, #FF6958 0%, #FF303A 100%) !important;
              color: #FFFFFF !important;
            }
          }
        }
        .order-detail{
          background: #FFFFFF;
          border-radius: 10px;
          width: 100%;
          box-sizing: border-box;
          padding-top: 18px;
          padding-left: 22px;
          padding-bottom: 50px;
          .details{
            width: 100%;
            .p-0{
              font-family: PingFangSC, PingFang SC;
              font-weight: 500;
              font-size: 14px;
              color: #333333;
              line-height: 20px;
              padding-bottom: 11px;
            }
            .p-1{
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 14px;
              color: #666666;
              line-height: 26px;
              span{
                color: #333333;
              }
            }
          }
          .divide{
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: #DFDFDF;
            line-height: 12px;
            padding: 19px 0 30px;
          }
          .bottom{
            width: 100%;
            display: flex;
            justify-content: space-between;
          }
          .pay-code-info{
            width: calc(100% - 160px);
            display: flex;
            justify-content: space-between;
            .code{
              width: 110px;
              height: 110px;
            }
            .info{
              width: 235px;
              .p-0{
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 14px;
                color: #333333;
                line-height: 20px;
                padding-bottom: 3px;
                .big{
                  font-size: 22px;
                  color: #FF6700;
                }
                .price{
                  color: #FF6700;
                }
              }
              .p-1{
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 12px;
                color: #FF6700;
                line-height: 17px;
                text-decoration: line-through;
              }
              .time{
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 14px;
                color: #333333;
                line-height: 14px;
                padding-bottom: 6px;
                span{
                  color: #FF6354;
                }
              }
              .tip{
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 12px;
                color: #999999;
                line-height: 14px;
              }
            }
          }
          .check-button{
            display: flex;
            align-items: center;
            padding-top: 30px;
            img{
              width: 12px;
              height: 12px;
              cursor: pointer;
              margin-right: 4px;
            }
            p{
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 12px;
              color: $--color-theme;
              line-height: 12px;
              span{
                cursor: pointer;
              }
            }
          }
          .pay-options{
            width: 160px;
            .pay-option-0{
              width: 122px;
              height: 28px;
              border-radius: 4px;
              border: 1px solid #00C800;
              box-sizing: border-box;
              padding-left: 9px;
              cursor: pointer;
              position: relative;
              display: flex;
              align-items: center;
              margin-bottom: 12px;
              .wechat{
                width: 20px;
                height: 18px;
                margin-right: 5px;
              }
              p{
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 14px;
                color: #00C800;
                line-height: 20px;
              }
            }
            .active-1{
              &::before{
                content: '';
                position: absolute;
                right: 0;
                bottom: 0;
                width: 12px;
                height: 12px;
                background-image: url('../../../assets/images/Tan/pop/vip/correct.png');
                background-size: 100% 100%;
                background-repeat: no-repeat;
                z-index: 20;
              }
              &::after{
                content: '';
                position: absolute;
                z-index: 10;
                right: 0;
                bottom: 0;
                width: 0;
                height: 0;
                border-top: 11px solid transparent;
                border-right: 11px solid #009FE8;
                border-left: 11px solid transparent;
                border-bottom: 11px solid #009FE8;
              }
            }
            .active-0{
              &::before{
                content: '';
                position: absolute;
                right: 0;
                bottom: 0;
                width: 12px;
                height: 12px;
                background-image: url('../../../assets/images/Tan/pop/vip/correct.png');
                background-size: 100% 100%;
                background-repeat: no-repeat;
                z-index: 20;
              }
              &::after{
                content: '';
                position: absolute;
                z-index: 10;
                right: 0;
                bottom: 0;
                width: 0;
                height: 0;
                border-top: 11px solid transparent;
                border-right: 11px solid #00C800;
                border-left: 11px solid transparent;
                border-bottom: 11px solid #00C800;
              }
            }
            .pay-option-1{
              width: 122px;
              height: 28px;
              border-radius: 4px;
              border: 1px solid #009FE8;
              box-sizing: border-box;
              padding-left: 9px;
              cursor: pointer;
              position: relative;
              display: flex;
              align-items: center;
              margin-bottom: 12px;
              .ali-pay{
                width: 18px;
                height: 18px;
                margin-right: 5px;
              }
              p{
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 14px;
                color: #009FE8;
                line-height: 20px;
              }
            }
            .pay-option-2{
              width: 122px;
              height: 28px;
              border-radius: 4px;
              border: 1px solid $--color-theme;
              box-sizing: border-box;
              padding-left: 9px;
              cursor: pointer;
              position: relative;
              display: flex;
              align-items: center;
              margin-bottom: 9px;
              .transfer{
                width: 18px;
                height: 14px;
                margin-right: 5px;
              }
              p{
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 14px;
                color: $--color-theme;
                line-height: 20px;
              }
            }
          }
        }
      }
    }
    .intro-window{
      width: 100%;
      box-sizing: border-box;
      padding: 20px 18px 18px;
      display: flex;
      justify-content: space-between;
      .left-back{
        width: 258px;
        height: 484px;
        background: #ffffff;
      }
      .left{
        width: 258px;
        height: 484px;
        background: linear-gradient( 180deg, #FAE8BE 0%, #FFFFFF 100%);
        background-repeat: no-repeat;
        background-size: 100% 160px;
        border-radius: 10px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding-top: 25px;
        .diamond{
          width: 63px;
          height: 52px;
          margin-bottom: 3px;
        }
        .p-0{
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 18px;
          color: #333333;
          line-height: 25px;
          margin-bottom: 22px;
        }
        .p-1{
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #E9BB6B;
          line-height: 22px;
          margin-bottom: 7px;
        }
        .p-2{
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #333333;
          line-height: 30px;
          margin-bottom: 11px;
          span{
            color: #E9BB6B;
          }
        }
        .divide{
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #DADADA;
          line-height: 17px;
          margin-bottom: 27px;
        }
        .h-150{
          height: 150px !important;
        }
        .lines{
          height: 137px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          .line{
            display: flex;
            align-items: center;
            .right{
              width: 14px;
              height: 10px;
              margin-right: 4px;
            }
            .p-3{
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 12px;
              color: #333333;
              line-height: 17px;
            }
          }
        }
      }
      .right{
        width: 590px;
        box-sizing: border-box;
        .levels{
          display: flex;
          width: 100%;
          justify-content: space-between;
          padding-bottom: 13px;
          .level{
            cursor: pointer;
            width: 287px;
            height: 109px;
            background: #FFFFFF;
            border-radius: 10px;
            overflow: hidden;
            display: flex;
            align-items: center;
            flex-direction: column;
            box-sizing: border-box;
            padding-top: 13px;
            .icon-line{
              display: flex;
              align-items: center;
              img{
                width: 22px;
                height: 24px;
                margin-right: 5px;
              }
              padding-bottom: 8px;
            }
            .p-0{
              font-family: PingFangSC, PingFang SC;
              font-weight: 500;
              font-size: 16px;
              color: #333333;
              line-height: 22px;
            }
            .p-1{
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 14px;
              color: #FF6700;
              line-height: 20px;
              padding-bottom: 17px;
              .price{
                font-size: 22px;
              }
              .small{
                font-weight: 400;
                font-size: 12px;
                text-decoration: line-through;
              }
            }
            .p-2{
              height: 24px;
              background: #FFE9C0;
              width: 100%;
              text-align: center;
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 12px;
              color: #B67E1E;
              line-height: 24px;
              border-bottom-left-radius: 10px;
              border-bottom-right-radius: 10px;
            }
          }
          .active{
            background: #FEFCF6;
            border: 2px solid #E9BB6A;
            border-bottom: none;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
        .rights{
          width: 100%;
          padding-top: 11px;
          box-sizing: border-box;
          padding-left: 28px;
          .label-word{
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 14px;
            color: #333333;
            line-height: 14px;
            padding-top: 20px;
            padding-bottom: 14px;
            padding-left: 13px;
          }
          .p-bonus{
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: #E6474E;
            line-height: 24px;
            width: calc(100% - 17px);
            margin-bottom: 10px;
            box-sizing: border-box;
            background: #FFF1F1;
            border-radius: 4px;
            height: 24px;
            padding-left: 10px;
          }
          .p-0{
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 14px;
            color: #333333;
            line-height: 20px;
            padding-bottom: 10px;
          }
          .p-t-30{
            padding-top: 15px;
          }
          .right-line{
            width: 100%;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            padding-left: 14px;
          }
          .left-minus{
            width: 26px;
            height: 26px;
            border-radius: 2px;
            border: 1px solid #D5D5D5;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
              width: 14px;
              height: 14px;
              padding: 4px;
              cursor: pointer;
            }
            .disabled{
              cursor: not-allowed !important;
            }
          }
          .num{
            width: 26px;
            height: 26px;
            border-radius: 2px;
            border: 1px solid #D5D5D5;
            margin: 0 6px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 14px;
            color: #333333;
            line-height: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: default;
          }
          .right-plus{
            width: 26px;
            height: 26px;
            border-radius: 2px;
            border: 1px solid #D5D5D5;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
              width: 14px;
              height: 14px;
              padding: 4px;
              cursor: pointer;
            }
            .disabled{
              cursor: not-allowed !important;
            }
            margin-right: 15px;
          }
          .right-intro{
            .p-1{
              font-family: PingFangSC, PingFang SC;
              font-weight: 500;
              font-size: 14px;
              color: #333333;
              line-height: 14px;
              margin-bottom: 6px;
            }
            .p-2{
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 12px;
              color: #999999;
              line-height: 12px;
            }
          }
        }
        .order-detail{
          height: 184px;
          background: #F4F7FC;
          border-radius: 4px;
          margin-top: 16px;
          width: 100%;
          box-sizing: border-box;
          padding-top: 15px;
          padding-left: 13px;
          width: calc(100% - 30px);
          margin-left: 15px;
          .p-0{
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 14px;
            color: #333333;
            line-height: 20px;
            padding-bottom: 5px;
          }
          .detail{
            display: flex;
            width: 100%;
            justify-content: space-between;
            .order-left{
              width: calc(100% - 230px);
              height: 120px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              box-sizing: border-box;
              margin-top: 7px;
              padding-left: 14px;
              .p-1{
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 12px;
                color: #666666;
                line-height: 24px;
                width: 100%;
                span{
                  color: #333333;
                }
              }
            }
            .order-right{
              width: 220px;
              .p-1{
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 14px;
                color: #333333;
                line-height: 20px;
                .big{
                  font-size: 22px;
                  color: #FF6700;
                }
                .color{
                  color: #FF6700;
                }
              }
              .p-2{
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 12px;
                color: #FF6700;
                line-height: 17px;
                text-decoration: line-through;
              }
              .price{
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                padding-bottom: 14px;
                width: 124px;
                white-space: nowrap;
              }
              .confirm-button{
                width: 122px;
                height: 28px;
                background: linear-gradient( 90deg, #FF6A58 0%, #FF303A 100%);
                border-radius: 2px;
                cursor: pointer;
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 14px;
                color: #FFFFFF;
                line-height: 28px;
                display: flex;
                justify-content: center;
                margin-bottom: 10px;
              }
              .check-button{
                display: flex;
                align-items: center;
                img{
                  width: 12px;
                  height: 12px;
                  cursor: pointer;
                  margin-right: 4px;
                }
                p{
                  font-family: PingFangSC, PingFang SC;
                  font-weight: 400;
                  font-size: 12px;
                  color: $--color-theme;
                  line-height: 12px;
                  span{
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }
        .back-color-FFFCF8{
          background-color: #FFFCF8 !important;
        }
      }
      .intro-back-0{
        background-image: url('../../../assets/images/Tan/pop/vip/back-0.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      .intro-back-1{
        background-image: url('../../../assets/images/Tan/pop/vip/back-1.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
    }
    .order-window{
      width: 100%;
      box-sizing: border-box;
      padding: 32px 28px 28px;
      .top{
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-bottom: 11px;
        .p-0{
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 18px;
          color: #333333;
          line-height: 24px;
        }
        .cancel-button{
          width: 74px;
          height: 26px;
          background: #DCE5F0;
          border-radius: 2px;
          border: none;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #486EA1;
          cursor: pointer;
        }
      }
      .order-detail{
        box-sizing: border-box;
        width: 100%;
        padding:  18px 22px 28px;
        display: flex;
        justify-content: space-between;
        background: #FFFFFF;
        border-radius: 4px;
        margin-bottom: 14px;
        .column-0{
          width: 240px;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          .part{
            width: 100%;
            .p-0{
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 14px;
              color: #666666;
              line-height: 22px;
            }
            .p-1{
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 14px;
              color: #333333;
              line-height: 22px;
              width: 100%;
            }
          }
        }
        .column-1{
          width: 210px;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          .part{
            width: 100%;
            .p-0{
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 14px;
              color: #666666;
              line-height: 22px;
            }
            .p-1{
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 14px;
              color: #333333;
              line-height: 22px;
              width: 100%;
            }
          }
        }
        .column-2{
          width: 180px;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          .part{
            width: 100%;
            .p-0{
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 14px;
              color: #666666;
              line-height: 22px;
            }
            .p-1{
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 14px;
              color: #333333;
              line-height: 22px;
              width: 100%;
            }
          }
        }
        .column-3{
          width: 70px;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          .part{
            width: 100%;
            .p-0{
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 14px;
              color: #666666;
              line-height: 22px;
            }
            .p-1{
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 14px;
              color: #333333;
              line-height: 22px;
              width: 100%;
            }
          }
        }
      }
      .pay-detail{
        width: 100%;
        height: 229px;
        background: #FFFFFF;
        border-radius: 4px;
        box-sizing: border-box;
        padding-top: 23px;
        padding-left: 23px;
        display: flex;
        justify-content: space-between;
        .pay-options{
          width: 252px;
          .pay-option-0{
            width: 185px;
            height: 38px;
            border-radius: 4px;
            border: 1px solid #00C800;
            box-sizing: border-box;
            padding-left: 13px;
            cursor: pointer;
            position: relative;
            display: flex;
            align-items: center;
            margin-bottom: 12px;
            .wechat{
              width: 28px;
              height: 24px;
              margin-right: 11px;
            }
            p{
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 14px;
              color: #00C800;
              line-height: 20px;
            }
          }
          .active-0{
            &::before{
              content: '';
              position: absolute;
              right: 0;
              bottom: 0;
              width: 12px;
              height: 12px;
              background-image: url('../../../assets/images/Tan/pop/vip/correct.png');
              background-size: 100% 100%;
              background-repeat: no-repeat;
              z-index: 20;
            }
            &::after{
              content: '';
              position: absolute;
              z-index: 10;
              right: 0;
              bottom: 0;
              width: 0;
              height: 0;
              border-top: 11px solid transparent;
              border-right: 11px solid #00C800;
              border-left: 11px solid transparent;
              border-bottom: 11px solid #00C800;
            }
          }
          .active-1{
            &::before{
              content: '';
              position: absolute;
              right: 0;
              bottom: 0;
              width: 12px;
              height: 12px;
              background-image: url('../../../assets/images/Tan/pop/vip/correct.png');
              background-size: 100% 100%;
              background-repeat: no-repeat;
              z-index: 20;
            }
            &::after{
              content: '';
              position: absolute;
              z-index: 10;
              right: 0;
              bottom: 0;
              width: 0;
              height: 0;
              border-top: 11px solid transparent;
              border-right: 11px solid #009FE8;
              border-left: 11px solid transparent;
              border-bottom: 11px solid #009FE8;
            }
          }
          .pay-option-1{
            width: 185px;
            height: 38px;
            border-radius: 4px;
            border: 1px solid #009FE8;
            box-sizing: border-box;
            padding-left: 15px;
            cursor: pointer;
            position: relative;
            display: flex;
            align-items: center;
            margin-bottom: 12px;
            .ali-pay{
              width: 24px;
              height: 24px;
              margin-right: 13px;
            }
            p{
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 14px;
              color: #009FE8;
              line-height: 20px;
            }
          }
          .pay-option-2{
            width: 185px;
            height: 38px;
            border-radius: 4px;
            border: 1px solid $--color-theme;
            box-sizing: border-box;
            padding-left: 13px;
            cursor: pointer;
            position: relative;
            display: flex;
            align-items: center;
            margin-bottom: 9px;
            .transfer{
              width: 28px;
              height: 28px;
              margin-right: 10px;
            }
            p{
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 14px;
              color: $--color-theme;
              line-height: 20px;
            }
          }
          .tip{
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: #999999;
            line-height: 16px;
            width: 100%;
          }
        }
        .pay-code-info{
          padding-top: 10px;
          width: 450px;
          padding-right: 25px;
          display: flex;
          justify-content: space-between;
          .code{
            width: 110px;
            height: 110px;
            margin-top: 5px;
          }
          .info{
            width: 320px;
            .p-0{
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 14px;
              color: #333333;
              line-height: 20px;
              padding-bottom: 9px;
              .big{
                font-size: 22px;
                color: #FF6700;
              }
              .price{
                color: #FF6700;
              }
            }
            .ticket{
              width: 153px;
              height: 23px;
              background: linear-gradient( 90deg, #FF6A58 0%, #FF303A 100%);
              border-radius: 2px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              margin-bottom: 13px;
              .icon{
                width: 16px;
                height: 16px;
                margin-right: 3px;
              }
              .p-1{
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 12px;
                color: #FFFFFF;
                line-height: 17px;
              }
            }
            .time{
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 14px;
              color: #333333;
              line-height: 14px;
              padding-bottom: 6px;
              span{
                color: #FF6354;
              }
            }
            .tip{
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 12px;
              color: #999999;
              line-height: 14px;
            }
          }
        }
      }
    }
    .transfer-window{
      width: 100%;
      box-sizing: border-box;
      padding: 20px 28px 38px;
      .p-0{
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 18px;
        color: #333333;
        line-height: 24px;
        padding-bottom: 18px;
      }
      .wrap{
        width: 100%;
        background: #FFFFFF;
        border-radius: 4px;
        box-sizing: border-box;
        padding: 30px 0;
        padding-right: 60px;
        padding-left: 23px;
        display: flex;
        align-items: center;
        margin-bottom: 14px;
        .left{
          width: 185px;
          margin-right: 70px;
          height: 76px;
          border-right: 1px solid #F1F1F1;
          box-sizing: border-box;
          padding-right: 30px;
          .p-1{
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 16px;
            color: #333333;
            line-height: 24px;
            padding-bottom: 4px;
            text-align: center;
          }
          .p-2{
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: #666666;
            line-height: 12px;
            text-align: center;
            white-space: nowrap;
          }
        }
        .middle{
          height: 118px;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          width: 300px;
          .p-3{
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #666666;
            line-height: 14px;
            span{
              color: #111111;
            }
            width: 100%;
          }
        }
        .price-line{
          margin-left: 60px;
          .p-4{
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #666666;
            line-height: 20px;
            padding-bottom: 12px;
            white-space: nowrap;
            .big{
              color: #FF6700;
              font-size: 22px;
            }
            .price{
              color: #FF6700;
            }
          }
          .copy-button{
            width: 116px;
            height: 28px;
            background: $--color-theme;
            border-radius: 2px;
            margin-left: 14px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #FFFFFF;
            cursor: pointer;
            border: none;
          }
        }
        .transfer-form{
          .upload-item{
            margin-bottom: 0 !important;
          }
          .form-item{
            display: flex;
            align-items: center;
            margin-bottom: 14px;
            .label{
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 14px;
              color: #111111;
              line-height: 14px;
              span{
                color: #EA0D0D;
              }
            }
            .upload{
              width: 62px;
              height: 62px;
              border-radius: 10px;
              overflow: hidden;
              position: relative;
              .result{
                width: 62px;
                height: 62px;
              }
              .delete{
                position: absolute;
                right: 0px;
                top: 0px;
                width: 16px;
                height: 16px;
                cursor: pointer;
                background-color: #999999ca;
                padding: 3px;
                border-top-right-radius: 10px;
              }
              .upload-container{
                width: 62px;
                height: 62px;
                background: #F4F7FD;
                border-radius: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                img{
                  width: 23px;
                  height: 19px;
                }
              }
            }
            .tip{
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 12px;
              color: #999999;
              line-height: 14px;
              padding-left: 10px;
            }
          }
        }
      }
      .cancel{
        background-color: #999999 !important;
      }
      .submit-button{
        padding-top: 20px;
        width: 100%;
        display: flex;
        justify-content: center;
        button{
          margin: 0 10px;
          width: 150px;
          height: 28px;
          background: $--color-theme;
          border-radius: 2px;
          border: none;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 14px;
          color: #FFFFFF;
          cursor: pointer;
        }
      }
    }
  }
}
.vague{
  filter: blur(10px);
  img{
    width: 100%;
    height: 100%;
  }
}
.success-window{
  width: 100%;
  background: #ffffff;
  position: relative;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 28px;
  .close{
    width: 14px;
    height: 14px;
    position: absolute;
    right: 22px;
    top: 22px;
    cursor: pointer;
  }
  .success-top{
    padding-top: 39px;
    display: flex;
    align-items: center;
    padding-bottom: 24px;
    .success{
      width: 20px;
      height: 20px;
      margin-right: 7px;
    }
    .success-title{
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 22px;
      color: #333333;
      line-height: 22px;
    }
  }
  .success-word{
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
    line-height: 20px;
    padding-bottom: 15px;
  }
  .center{
    width: 290px;
    height: 134px;
    margin-bottom: 14px;
  }
  .success-tip{
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #999999;
    line-height: 20px;
    margin-bottom: 20px;
    span{
      color: $--color-theme;
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .success-button{
    width: 110px;
    height: 28px;
    background: $--color-theme;
    border-radius: 4px;
    cursor: pointer;
    border: none;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
  }
}
</style>