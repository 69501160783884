import { PLWhitePage } from "@/utils/consts";
import { ElMessage } from "element-plus";
import { getPLLocalInfo } from "./login";
import { PostLineSite } from "@/router/index";

export const PLPermission = (to,from,next) => {
  if(!PostLineSite) return // 非PL页面
  else if(getPLLocalInfo()) return  // 已经登录
  else if(PLWhitePage.includes(to.path)) return // PL 白名单
  next({
    path: '/post-line-login',
    query:{
      redirect: window.location.href
    }
  })
}

export const PLChangeVisibility = (route,value,router) => {
  if(value) return
  const path = route.path
  const query = route.query
  if(path === '/post-line-login' && getPLLocalInfo()){
    if(query.redirect){
      window.location.href = this.$route.query.redirect
    }
    else{
      router.push({
        path: '/post-line'
      })
    }
  }
  else if(!PLWhitePage.includes(path) && !getPLLocalInfo()){
    ElMessage.warning('您尚未登录!')
    router.push({
      path: '/post-line-login',
      query:{
        redirect: window.location.href
      }
    })
  }
}