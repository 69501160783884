import { requestVersion } from "@/utils/consts";
import { login } from '@/api/user'


export const PLInfoKey = 'userInfos'

export const getPLLocalInfo = () => {
  return localStorage.getItem(PLInfoKey) ? JSON.parse(localStorage.getItem(PLInfoKey)).memberInfo : null
}

export const setPLLocalInfo = (info) => {
  localStorage.setItem(PLInfoKey,JSON.stringify(info))
}

export const removePLLocalInfo = () => {
  localStorage.removeItem(PLInfoKey)
}
export const getPLId = () => {
  return getPLLocalInfo()?.memberInfo?.id
}

export const getPLToken = () => {
  return getPLLocalInfo()?.accessToken
}

export const getPLRefreshToken = () => {
  return getPLLocalInfo()?.refreshToken
}

export const setPLNewToken = (token) => {
  if(!getPLLocalInfo()) return
  const info = getPLLocalInfo()
  info.accessToken = token
  setPLLocalInfo(info)
}

export const PLLogin = (info)=>{
  const params = {
    machineCode: info.machineCode || '',
    version: requestVersion,
    canal: 1,
    type: 13,
    password: info.code,
    phone: info.phone,
  }
  return new Promise((resolve, reject) => {
    login(params).then(res => {
      setPLLocalInfo(res.data)
      resolve()
    }).catch(err => {
      reject(err)
    })
  })
}