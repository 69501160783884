import { G60WhitePage } from "@/utils/consts";
import { ElMessage } from "element-plus";
import { getG60LocalInfo } from "./login";

export const G60Permission = (to,from,next) => {
  if(getG60LocalInfo()) return  // 已经登录
  else if(G60WhitePage.includes(to.path)) return // G60 白名单
  next({
    path: '/login',
    query:{
      redirect: window.location.href
    }
  })
}

export const G60ChangeVisibility = (route,value,router) => {
  if(value) return
  const path = route.path
  const query = route.query
  if(path === '/login' && getG60LocalInfo()){
    if(query.redirect){
      window.location.href = this.$route.query.redirect
    }
    else{
      router.push({
        path: '/'
      })
    }
  }
  else if(!G60WhitePage.includes(path) && !getG60LocalInfo()){
    ElMessage.warning('您尚未登录!')
    router.push({
      path: '/login',
      query:{
        redirect: window.location.href
      }
    })
  }
}