<template>
    <div class="container">
        <!-- 创建身份的弹窗 -->
        <div class="header">
            <el-dialog v-model="showCreate" :show-close="false" title="创建身份" :width="'600px'">
                <!-- 用v-if来重置内容 -->
                <TypeSelection @jumpTo="jumpTo" :window="true" v-if="showCreate"></TypeSelection>
            </el-dialog>
        </div>
        <!-- 个人资料编辑的弹窗 -->
        <div class="header header-top">
            <el-dialog v-model="showEditPerson" :show-close="false" title="个人资料编辑" :width="'900px'" :top="'40px'" :close-on-click-modal="false">
                <!-- 用v-if来重置内容 -->
                <PersonalEdit @editInfo="editInfo" v-if="showEditPerson"></PersonalEdit>
            </el-dialog>
        </div>
        <!-- 数字科创编辑弹窗 -->
        <div class="header header-top">
            <el-dialog v-model="showEditOrg" :show-close="false" :title="orgEditTitle + '信息编辑'" :width="'900px'" :top="'40px'" :close-on-click-modal="false">
                <!-- 用v-if来重置内容 -->
                <OrganizationEdit @editInfo="editInfo" v-if="showEditOrg" @changeOrgEditTitle="changeOrgEditTitle"></OrganizationEdit>
            </el-dialog>
        </div>
        <!-- 关联申请弹窗 -->
        <div class="header">
            <el-dialog v-model="showApply" :show-close="false" title="关联申请" :width="'480px'">
                <div class="apply-text">系统已检测到来自东华大学的XXX向本中心发起关联申请</div>
                <div class="buttons">
                    <button class="refuse">拒绝</button>
                    <button class="confirm">同意</button>
                </div>
            </el-dialog>
        </div>
        <!-- 关联申请询问弹窗 -->
        <div class="header">
            <el-dialog v-model="showAsk" :show-close="false" title="关联申请" :width="'480px'">
                <div class="apply-text">系统检测到您所属单位“东华大学”的产学研中心，是否申请将您的空间与其关联，关联之后您的空间内容将同步到东华大学产学研中心，将极大增加您所发布的动态和内容的曝光量。</div>
                <div class="buttons">
                    <button class="delay">暂不</button>
                    <button class="confirm">关联</button>
                </div>
            </el-dialog>
        </div>
        <!-- 关联申请询问弹窗 -->
        <div class="no-header">
            <el-dialog v-model="showConfirm" :show-close="false" :width="'480px'">
                <div class="applied">
                    <img src="../../assets/images/square-avatar.png" alt="">
                    <div class="apply-text">已申请，请等待该中心管理员审核</div>
                </div>
                <div class="buttons" style="justify-content: flex-end;">
                    <button class="back">返回我的空间</button>
                </div>
            </el-dialog>
        </div>
        <!-- 关联成功弹窗  -->
        <div class="no-header">
            <el-dialog v-model="showApplied" :show-close="false" :width="'480px'">
                <div class="applied">
                    <img src="../../assets/images/square-avatar.png" alt="">
                    <div class="apply-text">恭喜您,已成功关联东华大学数字产学研中心！</div>
                </div>
                <div class="buttons" style="justify-content: flex-end;">
                    <button class="back">进入中心查看</button>
                </div>
            </el-dialog>
        </div>
        <!-- 申请失败弹窗 -->
        <div class="no-header">
            <el-dialog v-model="showRefuse" :show-close="false" :width="'480px'">
                <div class="top">
                    <img src="../../assets/images/square-avatar.png" alt="">
                    <div class="refuse-text">非常遗憾，你发送的与东华大学数字产学研中心的关联申请被该中心管理员拒绝。</div>
                </div>
                <div class="buttons">
                    <button class="confirm">知道了</button>
                    <button class="again">再次申请</button>
                </div>
            </el-dialog>
        </div>
        <!-- 高校信息弹窗 -->
        <div class="header">
            <el-dialog v-model="showHighSchool" :show-close="false" title="高校院所信息" :width="'612px'">
                <div class="input-item">
                    <div class="title">学院名称：</div>
                    <el-input v-model="schoolName" placeholder="请输入学院名称"></el-input>
                </div>
                <div class="input-item">
                    <div class="title">邮&nbsp;&nbsp;箱：</div>
                    <el-input v-model="schoolEmail" placeholder="请输入学院邮箱"></el-input>
                </div>
                <div class="address-item">
                    <div class="title">地&nbsp;&nbsp;址：</div>
                    <el-cascader ref="cascaderAddr" :props="props"
                      style="width: 378px;background: #FFFFFF!important;"
                      :placeholder="'请选择学院地址'"
                      v-model="schoolAddress"></el-cascader>
                </div>
                <div class="upload-item">
                    <div class="title">学校证书：</div>
                    <el-upload
                        style="width:378px;height:148px !important;"
                        ref="upload"
                        action="#"
                        :limit="1"
                        :accept="'image/*'"
                        list-type="picture-card"
                        :on-exceed="handleExceed"
                        :http-request="handleUpload"
                        :on-change="loadJsonFromFile"
                        :before-upload="beforeUpload"
                    >
                        <template #default>
                            <i class="el-icon-plus"></i>
                            <div class="upload-text">
                                <p>请上传单位执照或加盖公章</p>
                                <p>的入驻申请书扫描件</p>
                            </div>
                        </template>
                        <template #file="{file}">
                            <el-progress v-if="file.percentage != 100" type="circle" :percentage="file.percentage"></el-progress>
                            <div v-if="file.percentage == 100" style="position: relative;width: 100%; height: 100%">
                                <img v-if="file.raw.type[0]=='i'" class="el-upload-list__item-thumbnail" :src="file.url" alt="" @click="showDiaFile(file)" style="cursor: pointer;"/>
                                <div @click="deleteImg(file)" class="icon-delete">
                                    <i class="el-icon-delete" style="fontSize: 16px;color: #fff;"></i>
                                </div>
                            </div>
                        </template>
                    </el-upload>
                    <!-- 大图弹窗 -->
                    <div class="no-header">
                        <el-dialog v-model="dialogVisible">
                            <div class="big-img">
                                <img :src="dialogImageUrl" alt="" class="max-img-width">
                            </div>
                        </el-dialog>
                    </div>
                </div>
                <!-- 按钮 -->
                <div class="high-school-buttons">
                    <button class="cancel">取消</button>
                    <button class="send">发送</button>
                </div>
            </el-dialog>
        </div>
        <!-- 专家学者信息弹窗 -->
        <div class="header">
            <el-dialog v-model="showTeacher" :show-close="false" title="专家学者信息" :width="'612px'">
                <div class="input-item">
                    <div class="title">姓&nbsp;&nbsp;名：</div>
                    <el-input v-model="teacherName" placeholder="请输入姓名"></el-input>
                </div>
                <div class="input-item">
                    <div class="title">所属高校：</div>
                    <el-input v-model="teacherBelong" placeholder="请输入所属高校"></el-input>
                </div>
                <div class="input-item">
                    <div class="title">所属院系：</div>
                    <el-input v-model="teacherCollege" placeholder="请输入所属院系"></el-input>
                </div>
                <div class="input-item">
                    <div class="title">研究方向：</div>
                    <el-input v-model="teacherLabel" placeholder="请输入研究方向"></el-input>
                </div>
                <div class="input-item">
                    <div class="title">邮&nbsp;&nbsp;箱：</div>
                    <el-input v-model="teacherEmail" placeholder="请输入邮箱"></el-input>
                </div>
                <div class="address-item">
                    <div class="title">地&nbsp;&nbsp;区：</div>
                    <el-cascader ref="cascaderAddr" :props="props"
                      style="width: 378px;background: #FFFFFF!important;"
                      :placeholder="'请选择地区'"
                      v-model="teacherAddress"></el-cascader>
                </div>
                <!-- 按钮 -->
                <div class="high-school-buttons">
                    <button class="cancel">取消</button>
                    <button class="send">发送</button>
                </div>
            </el-dialog>
        </div>
        <!-- 帐号类型弹窗 -->
        <div class="header title-center">
            <el-dialog v-model="showAccountType" :show-close="false" title="请选择账号类型" :width="'612px'">
                <div class="choose-window">
                    <!-- 个人帐号 -->
                    <div class="list">
                        <div class="title">个人帐号</div>
                        <div class="item">我是专家学者</div>
                        <div class="item">我是专家学者</div>
                    </div>
                    <!-- 机构帐号 -->
                    <div class="list">
                        <div class="title">机构帐号</div>
                        <div class="item">公司企业</div>
                        <div class="item">公司企业</div>
                        <div class="item">公司企业</div>
                    </div>
                </div>
                <!-- 按钮 -->
                <div class="choose-type-buttons">
                    <button class="cancel">取消</button>
                    <button class="send">发送</button>
                </div>
            </el-dialog>
        </div>
        <!-- 发送邮件弹窗 -->
        <div class="header" v-if="showSendEmail">
            <el-dialog v-model="showSendEmail" :show-close="false" title="邮件发送" :width="'900px'" :close-on-press-escape="false" :close-on-click-modal="false">
                <!-- 暂定每天3次，不进行显示 -->
                <div class="left-time">
                    (邮件将由系统推送给对方)
                </div>
                <div class="send-container">
                    <div class="receive">
                        <div class="left fs-14 fc-333">
                            收件人：
                        </div>
                        <div class="middle">
                            <span class="name" v-if="params?.name" v-html="params.name"></span>
                        </div>
                    </div>
                    <div class="receive" style="padding-top: 15px;">
                        <div class="left fs-14 fc-999">
                            主题：
                        </div>
                        <div class="middle">
                            <input v-model="emailTheme" class="input-line" type="text" maxlength="100">
                        </div>
                    </div>
                    <!-- 不上传图片 v-if 重置上传文件 -->
                    <!-- <div class="upload-img">
                        <div class="title">图&nbsp;片：</div>
                        <el-upload
                            v-if="showSendEmail"
                            style="width:400px;"
                            ref="upload"
                            action="#"
                            :limit="9"
                            :accept="'image/*'"
                            :on-exceed="handleExceed"
                            :http-request="handleUpload"
                            :on-change="loadJsonFromFile"
                            :before-upload="beforeUpload">
                            <div class="upload-button">点击上传</div>
                            <template #file="{file}">
                                <div class="file-container">
                                    <img src="../../assets/images/link-icon.png" alt="" class="left-img">
                                    <el-progress  :percentage="file.percentage" :text-inside="true" :stroke-width="1" :color="'#409FFF'"></el-progress>
                                    <div style="position: relative;width: 100%; height: 100%" class="finish-file" @click="showDiaFile(file)">
                                        <div class="file-name">{{file.name}}<span v-if="file.percentage != 100">(上传中)</span></div>
                                        <div class="delete-button" v-if="file.percentage == 100" @click.stop="deleteImg(file)">删除</div>
                                    </div>
                                </div>
                            </template>
                        </el-upload>
                        <div class="no-header">
                            <el-dialog v-model="dialogVisible">
                                <div class="big-img">
                                    <img :src="dialogImageUrl" alt="" class="max-img-width">
                                </div>
                            </el-dialog>
                        </div>
                    </div> -->
                    <div class="remarks" :class="{'marginT20': uploadImg.length === 0}">
                        <V5wangEditorSmall :placeHolder="'您可以按照以下模板编辑内容\n公司介绍：\n产品介绍：\n需要解决的技术难题：\n合作方式：'" :value="emailRemark" @updateContent="updateContent" :scroll="true" :minusTop="-5" :minHeight="263"></V5wangEditorSmall>
                    </div>
                </div>
                <!-- 按钮 -->
                <div class="high-school-buttons send-email-button">
                    <button class="cancel" style="margin-right: 40px;" @click="cancelDialog(5)">取消</button>
                    <button class="send" @click="sendDialog(5)">发送</button>
                </div>
            </el-dialog>
        </div>
        <!-- 已发送邮件弹窗 -->
        <div class="no-header">
            <el-dialog v-model="showWait" :show-close="false" :width="'480px'">
                <div class="sended">
                    <img src="../../assets/images/success-icon.png" alt="">
                    <div class="apply-text">已发送</div>
                </div>
                <div class="sended-word">信息已发送成功，请等待该企业回复。</div>
                <div class="buttons" style="justify-content: flex-end;">
                    <button class="back">进入中心查看</button>
                </div>
            </el-dialog>
        </div>
        <!-- 删除成员弹窗 -->
        <div class="header">
            <el-dialog v-model="showDeleteMember" :show-close="false" title="删除团队成员" :width="'612px'">
                <div class="member-container">
                    <div v-for="(item,index) in params.members" :key="item" class="item" :class="{'marginR24': (item - 1) % 7 !== 6}" @click="selectDelete(index)">
                        <Image :src="item.headImg" class="avatar" :isHeadImg="true"></Image>
                        <div class="name">{{item.name}}</div>
                        <img src="../../assets/images/user/delete-selection.png" alt="" class="option" v-if="selections[index]">
                        <div class="option-not" v-else></div>
                    </div>
                </div>
                <!-- 按钮 -->
                <div class="high-school-buttons">
                    <button class="cancel" @click="showDeleteMember = false">取消</button>
                    <button class="delete" @click="deleteMember">删除</button>
                </div>
            </el-dialog>
        </div>
        <!-- 所有成员弹窗 -->
        <div class="header">
            <el-dialog v-model="showAllMember" :show-close="false" title="所有团队成员" :width="'612px'">
                <div class="member-container">
                    <div v-for="(item) in params.members" :key="item" class="item" :class="{'marginR24': (item - 1) % 7 !== 6}" @click="startConversation(item)">
                        <Image :src="item.headImage" class="avatar" :isHeadImg="true"></Image>
                        <div class="name">{{item.name}}</div>
                    </div>
                </div>
                <!-- 按钮 -->
                <div class="high-school-buttons">
                    <button class="cancel" @click="showAllMember = false">关闭</button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import { staticPath } from "@/utils/consts";
import { delHtmlTag,routerWindow } from "@/utils/tool.js";
import { removeAssistant,removeMember } from "@/api/user"
import { sendCompanyEmail } from "@/api/organization"
import { getprovince, getcity, getarea } from "@/api/demand";
import { uploadTypeImage,cosByUploadType } from "@/api/upload";
import { ElMessage, ElMessageBox } from 'element-plus';
import { bucketMap } from "@/utils/consts";
import TypeSelection from "../TypeSelection/TypeSelection"
import PersonalEdit from "./components/PersonalEdit.vue";
import OrganizationEdit from "./components/OrganizationEdit.vue";
import {randomFilename} from "@/utils/time";
import { mapGetters } from "vuex";
import V5wangEditorSmall from '../../components/rich-text/V5wangEditorSmall.vue';

export default {
    data(){
        return{
            orgEditTitle: '',
            emailTheme: '', // 邮件主题
            showEditOrg: false, // 数字科创信息修改弹窗
            showEditPerson: false,  // 个人信息修改弹窗
            showCreate: false,   // 创建身份弹窗
            showApply: false,   // 关联申请
            showRefuse: false,   // 申请失败弹窗
            showHighSchool: false,  // 高校院所信息
            showTeacher: false,  // 专家学者信息
            showAccountType: false,  // 选择帐号类型
            showSendEmail: false,    // 发送邮件弹窗
            showDeleteMember: false,    // 删除团队成员
            showAllMember: false,   // 展示所有团队成员
            showAsk: false, // 关联请求弹窗
            showConfirm: false,  // 已申请关联弹窗
            showWait: false, // 已发送邮件弹窗
            showApplied: false, // 关联成功弹窗
            schoolName: '', // 学校名称
            schoolEmail: '', // 学校邮箱
            schoolAddress: [], // 学校地区
            btnDisabled: false, // 上传按钮判断
            teacherName: '',    // 老师姓名
            teacherBelong:  '', // 所属高校
            teacherCollege: ''  , // 所属院系
            teacherLabel: '',   // 研究方向
            teacherEmail: '',   // 老师邮箱
            teacherAddress:[],  // 老师地区
            selections: [], // 选项
            emailRemark: '',    // 邮件备注
            params: undefined,  // 弹窗所需要的参数
            props:{
                lazy: true,
                lazyLoad(node, resolve) {
                    setTimeout(() => {
                        if (node.level == 0) {
                            getprovince().then(response => {
                                const cities = []
                                for (let item of response.data) {
                                cities.push({
                                    value: item.provinceCode,
                                    label: item.provinceName,
                                    leaf: false
                                })
                                }
                                resolve(cities);
                            });
                        }
                        if (node.level == 1) {
                            getcity(node.value).then(response => {
                                const areas = [];
                                for (let item of response.data) {
                                areas.push({
                                    value: item.cityCode,
                                    label: item.cityName,
                                    leaf: false
                                })
                                }
                                resolve(areas);
                            });
                        } else if (node.level == 2) {
                            getarea(node.value).then(response => {
                                const areas = []
                                for (let item of response.data) {
                                areas.push({
                                    value: item.areaCode,
                                    label: item.areaName,
                                    leaf: true
                                })
                                }
                                resolve(areas);
                            });
                        }
                    }, 100);
                }
            },
            uploadImg: [],  // 上传的图片
            cosImgArray: undefined, // 上传cos的图片数组
            dialogImageUrl: '', // 查看大图
            dialogVisible: false,   // 大图弹窗
        }
    },
    components:{
        V5wangEditorSmall,
        PersonalEdit,
        OrganizationEdit,
        TypeSelection
    },
    computed:{
        ...mapGetters(['popupStatus','popupType','popupParameter'])
    },
    watch:{
        showSendEmail(val){ // 监听发送邮件关闭，关闭后清空数据
            if(!val){
                this.clearParams()
            }
        },
        popupStatus(){  // 监听状态打开弹窗
            switch (this.popupType) {
                // 0:关联申请
                // 1:申请失败弹窗
                // 2:高校院所信息
                // 3:专家学者信息
                // 4:选择帐号类型
                // 5:发送邮件弹窗
                // 6:删除团队成员
                // 7:关联请求弹窗
                // 8:已申请关联弹窗
                // 9:已发送邮件弹窗
                // 10:关联成功弹窗
                // 11: 创建身份弹窗
                // 12: 修改个人信息弹窗
                // 13: 修改数字科创中心信息弹窗
                case 0:
                    this.showApply = true
                    break;
                case 1:
                    this.showRefuse = true
                    break;
                case 2:
                    this.showHighSchool = true
                    break;
                case 3:
                    this.showTeacher = true
                    break;
                case 4:
                    this.showAccountType = true
                    break;
                case 5:
                    this.showSendEmail = true
                    break;
                case 6:
                    this.showDeleteMember = true
                    break;
                case 7:
                    this.showAsk = true
                    break;
                case 8:
                    this.showConfirm = true
                    break;
                case 9:
                    this.showWait = true
                    break;
                case 10:
                    this.showApplied = true
                    break;
                case 11:
                    this.showCreate = true
                    break;
                case 12:
                    this.showEditPerson = true
                    break;
                case 13:
                    this.showEditOrg = true
                    break;
                case 14:
                    this.showAllMember = true
                    break;
                case -1:
                    this.showEditPerson = false
                    this.showEditOrg = false
                    break;
                default:
                    break;
            }
            if(this.popupParameter){ // 如果有参数，进行获取
                this.params = this.popupParameter
                if(this.popupType === 6){
                    // 删除成员
                    this.selections = this.popupParameter.members.map(()=>false)
                }
            }
        }
    },
    methods:{
        changeOrgEditTitle(val){
            if(val) this.orgEditTitle = val.labTypeName
        },
        editInfo(val){
            // 修改信息
            if(val.type === '0'){
                // 修改个人信息
                this.showEditPerson = false
            }
            else {
                this.showEditOrg = false
                if(this.$route.path === '/selection'){
                    // 在入驻页面 去数字科创中心
                    this.$router.push('/user-center')
                }
            }
            this.$store.commit('user/SET_RELOAD_USER')
        },
        updateContent(val){
            // 更新结果
            this.emailRemark = val;
        },
        deleteMember(){
            // 删除成员
            let flag = false
            this.selections.find((item)=>{
                if(item){
                    flag = true
                    return true
                }
            })
            if(!flag){
                ElMessage.warning('请先选择要删除的成员!')
                return
            }
            ElMessageBox.confirm("是否确认删除成员?", "", { // 主播则进行弹窗判断
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                lockScroll: false,  // 防止页面振动
                type: "warning",
            }).then(async () => {
                for (let i = 0; i < this.selections.length; i++) {
                    if(this.selections[i]){
                        try {
                            if(this.popupParameter.type === 0){
                                await removeAssistant(this.params.members[i].id)
                            }
                            else{
                                await removeMember(this.params.members[i].id)
                            }
                        } catch (error) {
                            console.error('delete member error:',error);
                            continue
                        }
                    }
                }
                this.showDeleteMember = false
                this.$store.dispatch("popup/setPopup", {
                    type: -1, // 删除后重新更新
                    parameter: {},
                });
            })
        },
        startConversation(item){
            if(!item.id) return
            this.$store.dispatch("message/startCommunicationNew",item.id)
            this.showAllMember = false
            this.$store.dispatch("popup/setPopup", {
                type: -1, // 删除后重新更新
                parameter: {},
            });
        },
        selectDelete(index){
            // 选择删除对象
            this.selections[index] = !this.selections[index]
        },
        jumpTo(direction){
            // 跳转页面
            this.showCreate = false
            direction.query.point = 0
            routerWindow(this.$router,direction.path,direction.query)
            this.clearParams()
        },
        async sendDialog(type){   // 确认、发送弹窗判断
            switch (type) {
                case 5:
                    if(this.btnDisabled){   // 说明还在上传图片中
                        ElMessage.warning('正在上传图片，请稍等!')
                        return
                    }
                    else{
                        try {
                            // 发送邮件接口
                            if(this.emailTheme.length === 0){
                                ElMessage.warning('邮件主题不能为空!')
                                return
                            }
                            if(this.emailRemark.length === 0 || this.emailRemark === '<p><br></p>'){
                                ElMessage.warning('备注信息不能为空!')
                                return
                            }
                            let type = 1
                            let id = this.params.companyId
                            if(!this.params.companyId){
                                // 给老师发邮件
                                type = 0
                                id= this.params.id
                            }
                            let images = ''
                            if(this.cosImgArray?.length > 0) images = this.cosImgArray.join(',')
                            let res = await sendCompanyEmail({recipientName:delHtmlTag(this.params.name),id: id,images: images,content: this.emailRemark,theme: this.emailTheme},type)
                            if(res.code === "Sx200"){
                                ElMessage.success('信息已发送成功，请等待回复!')
                                this.showSendEmail = false
                                this.emailTheme = ''
                                this.emailRemark = ''
                            }
                        } catch (error) {
                            // 失败进行原因判断
                            if(error.message === 'LxAPI-000581'){
                                this.showSendEmail = false
                            }
                            else{   // 其他错误
                                console.error('send email error:',error);
                            }
                        }
                    }
                    break;
                default:
                    break;
            }
        },
        cancelDialog(type){ // 关闭、取消弹窗判断
            switch (type) {
                case 5:
                    if(this.btnDisabled){   // 说明还在上传图片中
                        ElMessageBox.confirm('正在上传图片，是否取消？','确认', {   // 取消监听
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            lockScroll: false,  // 防止页面振动
                            type: 'warning'
                        }).then(()=>{
                            this.emailTheme = ''
                            this.showSendEmail = false
                            return
                        }).catch(()=>{
                            return
                        })
                        return
                    }
                    else{
                        this.emailTheme = ''
                        this.showSendEmail = false
                    }
                    break;
                default:
                    break;
            }
        },
        clearParams(){  // 初始化参数
            this.emailRemark = ''
            this.uploadImg = []
            this.btnDisabled = false
            this.cosImgArray = undefined
            this.params = undefined
        },
        handleExceed () { //文件超出个数限制时的钩子
            ElMessage.warning('最多只能上传9张图片～')
        },
        async handleUpload (val) { //处理下载
            let result = undefined
            if (val.file.type.split('/')[0] === 'image') { // 上传的是图片
                result = await this.directUpload(val.file)
                this.btnDisabled = false
                let temp = result.Location.slice(result.Location.indexOf("/"))
                if (!this.cosImgArray) {
                    this.cosImgArray = [temp]
                } else {
                    this.cosImgArray.push(...this.cosImgArray, temp)
                    this.cosImgArray = Array.from(new Set(this.cosImgArray))    // 去重
                }
            }
        },
        directUpload (file) { // 直接上传腾讯云
            let self = this
            let cosMap = {
                image: cosByUploadType(uploadTypeImage),
            }
            let cos = cosMap.image
            let bucket = bucketMap.image
            return new Promise((resolve, reject) => {
                cos.putObject(
                    {
                        Bucket: bucket, /* 必须 */
                        Region: "ap-shanghai",     /* 存储桶所在地域，必须字段 */
                        Key: randomFilename(),              /* 必须 */
                        StorageClass: 'STANDARD',
                        Body: file, // 上传文件对象
                        onProgress: function (progressData) {
                            self.btnDisabled = true
                            self.$refs['upload'].uploadFiles.map((item) => {
                                if (item.raw.uid === file.uid) {
                                    item.percentage = parseInt(progressData.percent * 100) // 设定进度
                                }
                            })
                        }
                    },
                    function (err, data) {
                        if (err) {
                            reject(err)
                        } else {
                            resolve(data)
                        }
                    }
                );
            })
        },
        loadJsonFromFile(file, fileList) {  // 加载文件
            this.uploadImg = fileList
        },
        beforeUpload(file){ //上传前的判断
            console.log("before upload file:",file);
        },
        deleteImg(file) { // 删除图片
            if(!this.cosImgArray) return  // 空判断
            let arr = this.$refs['upload'].uploadFiles
            let index = arr.indexOf(file)
            this.$refs['upload'].uploadFiles.splice(index, 1);
            this.cosImgArray = this.cosImgArray.filter((item,i) => { return i !== index }) // 也要删除腾讯云直传的名称🌟
            let that = this
            this.btnDisabled = true
            setTimeout(function(){
                that.btnDisabled = false
            },1000)
        },
        showDiaFile(file){  // 展示上传图片
            if(file.percentage != 100){ // 上传判断
                ElMessage.warning('还在上传中，请等待!')
                return
            }
            this.dialogImageUrl = file.url
            this.dialogVisible = true
            if(!file.url){  // 如果没有url，从上传列表中找
                this.uploadImg.find((item,index)=>{
                    if(item.uid === file.uid){
                        this.dialogImageUrl = staticPath + this.cosImgArray[index]
                        return true
                    }
                })
            }
        },
    }
}
</script>

<style lang="scss" scoped>
:deep() .el-overlay{
    overflow-x: hidden;
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    z-index: 2004 !important;   // 在聊天页面上方
}
:deep() .el-overlay::-webkit-scrollbar {
    display: none; /* Chrome Safari */
}
.no-header{
    :deep() .el-dialog__header{
        display: none;
    }
    :deep() .el-dialog__body{
        padding: 25px 16px 16px 25px;
        max-height: 70vh;
        box-sizing: border-box;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .applied{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        img{
            width: 26px;
            height: 26px;
            border-radius: 50%;
            margin-right: 12px;
        }
        .apply-text{
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 24px;
        }
    }
    .sended{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        img{
            width: 26px;
            height: 26px;
            border-radius: 50%;
            margin-right: 12px;
        }
        .apply-text{
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #6DD400;
        }
    }
    .sended-word{
        margin-left: 38px;
        margin-top: 15px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 24px;
    }
    .top{
        display: flex;
        align-items: flex-start;
        img{
            width: 28px;
            height: 28px;
        }
        .refuse-text{
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 24px;
            widows: 400px;
            margin-left: 11px;
        }
    }
    .buttons{
        width: 100%;
        margin-top: 24px;
        display: flex;
        justify-content: center;
        .again{
            width: 94px;
            height: 32px;
            background: $--color-theme;
            border-radius: 2px;
        }
        .confirm{
            width: 94px;
            height: 32px;
            background: #999999;
            border-radius: 2px;
            margin-right: 10px;
        }
        .back{
            width: 121px;
            height: 32px;
            background: $--color-theme;
            border-radius: 2px;
            font-size: 14px !important;
        }
        button{
            border: none;
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 22px;
            cursor: pointer;
        }
    }
}
.title-center{
    :deep() .el-dialog__header{
        text-align: center;
    }
    :deep() .el-dialog__body{
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .choose-window{
        width: 500px;
        border-radius: 4px;
        border: 1px solid #999999;
        display: flex;
        .list{
            width: 50%;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            flex-direction: column;
            padding: 24px 0 0 24px;
            .title{
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                line-height: 20px;
                margin-bottom: 24px;
            }
            .item{
                width: 144px;
                height: 44px;
                background: #F6F6F6;
                box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.5000);
                border-radius: 4px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 44px;
                text-align: center;
                cursor: pointer;
                margin-bottom: 24px;
            }
            .item:active{
                background: $--color-theme;
                color: #FFFFFF;
            }
        }
    }
    .choose-type-buttons{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 24px;
        button{
            width: 65px;
            height: 32px;
            background: #999999;
            border-radius: 2px;
            cursor: pointer;
            border: none;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 32px;
        }
        .cancel{
            background: #999999;
            margin-right: 40px;
        }
        .send{
            background: $--color-theme;
        }
    }
}
.header-top{
    :deep() .el-dialog__header{
        border-top: 6px solid $--color-theme;
    }
}
.header{
    :deep() .el-dialog__header{
        border-bottom: #D8D8D8 1px solid !important;
        padding: 16px 24px;
        .el-dialog__title{
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 600;
            color: #333333;
            line-height: 24px;
        }
    }
    :deep() .el-dialog__body{
        padding: 24px 16px 16px 24px;
    }
    .member-container{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        .item{
            display: flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;
            .avatar{
                width: 60px;
                height: 60px;
                margin-bottom: 11px;
                border-radius: 50%;
            }
            .name{
                max-width: 60px;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 22px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                word-break: break-all;
                margin-bottom: 15px;
            }
            .option{
                width: 20px;
                height: 20px;
                margin-bottom: 20px;
                border-radius: 50%;
                box-sizing: border-box;
            }
            .option-not{
                width: 20px;
                height: 20px;
                border: 1px solid #999999;
                box-sizing: border-box;
                margin-bottom: 20px;
                border-radius: 50%;
            }
        }
        .marginR24{
            margin-right: 24px;
        }
    }
    .left-time{
        position: absolute;
        left: 100px;
        top: 15px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 24px;
    }
    .apply-text{
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 22px;
    }
    .buttons{
        width: 100%;
        margin-top: 24px;
        display: flex;
        justify-content: flex-end;
        .delay{
            width: 65px;
            height: 32px;
            background: #999999;
            border-radius: 2px;
            margin-right: 10px;
        }
        .refuse{
            width: 65px;
            height: 32px;
            background: #FF4443;
            border-radius: 2px;
            margin-right: 10px;
        }
        .confirm{
            width: 65px;
            height: 32px;
            background: #177DDC;
            border-radius: 2px;
        }
        button{
            border: none;
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 22px;
            cursor: pointer;
        }
    }
    .address-item{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
        .title{
            width: 70px;
            height: 20px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
            margin-right: 15px;
        }
    }
    .upload-item{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        margin-bottom: 15px;
        :deep() .el-upload{
            position: relative;
        }
        :deep() .el-icon-plus{
            color: #000000;
        }
        .upload-text{
            position: absolute;
            left: 0;
            top: 98px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 14px;
            text-align: center;
        }
        .title{
            width: 70px;
            height: 20px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
            margin-right: 15px;
        }
        .icon-delete {
            background-color: rgba(0,0,0,0.2);
            padding: 3px 10px 0 10px;
            top: 0;
            right: 0;
            position: absolute;
            cursor: pointer;
        }
    }
    .send-container{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        margin-top: -10px;
        .receive{
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 24px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            padding-bottom: 15px;
            border-bottom: 1px solid #D8D8D8;
            .input-line{
                border: none;
                width: 100%;
            }
            .left{
                display: flex;
                align-items: center;
                min-width: 56px;
            }
            .middle{
                width: 100%;
            }
            .name{
                color: #333333 !important;
                max-width: 300px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                height: 20px;
                background: #DDF2FF;
                border-radius: 10px;
                line-height: 16px;
                padding: 2px 10px;
                :deep() font{
                    color: #333333 !important;
                }
            }
            .tip{
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
            }
            .time{
                color: #999999;
                width: 120px;
            }
        }
        .upload-img{
            position: relative;
            margin-top: 15px;
            :deep() .el-upload-list__item {
                transition: none ;
            }
            .title{
                position:absolute;
                left: 0;
                top: 0;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 20px;
            }
            .upload-button{
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: $--color-theme;
                line-height: 20px;
                margin-left: 56px;
            }
            :deep() .el-upload-list {
                max-height: 100px;
                overflow-y: auto;
            }
            :deep() .file-container{
                margin-left: 20px;
                margin-bottom: 20px;
                position:relative;
                .left-img{
                    width: 15px;
                    height: 15px;
                    position: absolute;
                    left: -20px;
                    bottom: 2px;
                }
                .finish-file{
                    display: flex;
                    justify-content: space-between;
                    cursor: pointer;
                    .file-name{
                        max-width: calc(100% - 30px);
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #999999;
                        line-height: 20px;
                    }
                    .delete-button{
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #FF4443;
                        line-height: 20px;
                        cursor: pointer;
                    }
                }
            }
        }
        .marginT20{
            margin-top: 10px;
        }
        .remarks{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding-top: 6px;
            .title{
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 20px;
                margin-bottom: 10px;
            }
            :deep() .el-textarea{
                width: 564px !important;
            }
        }
    }
    .send-email-button{
        justify-content: center !important;
    }
    .high-school-buttons{
        margin-top: 24px;
        display: flex;
        justify-content: flex-end;
        button{
            width: 65px;
            height: 32px;
            border-radius: 2px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 32px;
            text-align: center;
            cursor: pointer;
            border: none;
        }
        .cancel{
            background: #999999;
            margin-right: 8px;
        }
        .send{
            background: $--color-theme;
        }
        .delete{
            background: #FF4443;
        }
    }
    .input-item{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
        .title{
            width: 70px;
            height: 20px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
            margin-right: 15px;
        }
        :deep() .el-input{
            width: 378px !important;
        }

    }
}
.big-img{
    width: 100%;
    border:2px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    .max-img-width{
        max-width: 100%;
    }
}
</style>