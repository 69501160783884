import { post,del } from "@/utils/request";
import { getG60Id } from "@/utils/G60/login";
import { requestVersion } from "@/utils/consts.js"

const pageSize = 20

// 获取专家列表
export function labExpertList(params){
  const param = {
      "city": params.city || '',
      "college": params.college || '',
      "id": getG60Id() || -1,
      "limit": params.pageSize || pageSize,
      "offset": params.offset || 1,
      "school": params.school || '',
      "title": params.title || ''
  }
  return post('/lab/findExpertList',param)
}

export function searchExpert (params){ // 找专家
  const param = {
    "analyzerType": 1,   // 颗粒度 params.analyzerType ? params.analyzerType : 2
    "city": params.city || '',
    "province": params.province || '',
    "college": params.college || '',
    "keyword": params.keyword,
    "limit": params.limit,
    "memberId": getG60Id() || -1,
    "offset": params.offset,
    "school": params.school || '',
    "title": params.title || '',
    "searchByName": params.searchByName ? true : false,
    "type": 7
  }
  return post('/search/searchEpert', param)
}

export function getExpertFilters(){
  return post('/admin/treeScreenCondition')
}

// 动态+需求的列表
export function getlistHomepage(currentPage, pageSize, channelId) {
	return post('/homepage/listHomepage', {
		"limit": pageSize || 10,
		"memberId": getG60Id() || '-1',
		"offset": currentPage || 1,
		state: true,
		channelId
	})
}

export function getAllCommercial(channelId,locationId='',type=1) {
  let param = {
    "memberId": getG60Id() || '',  // 未登录传入空
    type,
    channelId: channelId ? channelId : 146330213220353  // 没有则传推荐ID 146330213220353
  }
  if(locationId.length > 0){  // 官网首页banner位
    param.channelId = ''
    param.type = 2
    param.locationId = locationId
  }
  return post('/commercial/getAllCommercial', param)
}

// 获取留言未读数量
export function getUnreadLeaveMessageNum(){
  const param = {
    "memberId": getG60Id() + '',
  }
  return post('/message/getNotReadCount',param)
}

// 获取用户定制权益
export function getUserPermissions(id){
  const param = {
    "memberId": getG60Id(),
    "organizationId": id
  }
  return post("/customizeMemberPermissions/getInfo",param)
}


// 分页获取关注列表(包含专家)
export function getUserSubscribedDb(page, pageSize) {
  return post('/member/subscribe/getFollowExpertList', {
    subscribeTypes: [0,3,4],       // 0-会员,1-组织机构 2实验室 3导入专家 4在线专家
    offset: page,
    memberId: getG60Id(),
    limit: pageSize
  })
}

export function publishDemand(param){
  return post('/g60/demand/publish',param)
}

export function publishProject(param){
  return post('/g60/achievement/publish',param)
}

export function addG60Expert(param){
  return post('/g60/expert/add',param)
}

export function getG60ExpertSchools(){
  return post('/g60/expert/allSchool')
}

export function getG60ExpertList(param){
  return post('/g60/expert/list',param)
}

export function getG60ProjectList(param){
  return post('/g60/achievement/list',param)
}

export function myselfG60AchievementList(param){
  return post('/g60/achievement/myList',{
    id: getG60Id(),
    limit: param.limit,
    offset: param.offset,
  })
}

// 根据类型获取企业动态
export function getOrgPublishesById(id, type, page, limit) {
  return post('/dynamic/getOrganizationDynamicArticle', {
    "articleId": type,
    "limit": limit || 16,
    "memberId": getG60Id(),
    "offset": page,
    "organizationId": id
  })
}

export function myselfG60DemandList(param){
  return post('/g60/demand/myList',{
    id: getG60Id(),
    limit: param.limit,
    offset: param.offset,
  })
}

export function getG60DemandList(param){
  return post('/g60/demand/list',param)
}

export function getG60AchieveDetail(param){
  return post('/g60/achievement/info',param)
}

export function getG60DemandDetail(param){
  return post('/g60/demand/info',param)
}

export function getG60ExpertDetail(param){
  return post('/g60/expert/info',param)
}

export function submitG60Window(param){
  return post('/g60/controller/add',param)
}

export function addG60Question(param){
  return post('/g60/problem/add',param)
}

export function getG60Questions(param){
  return post('/g60/problem/list',param)
}

export function getG60QuestionDetail(param){
  return post('/g60/problem/info',param)
}

export function allProducts() {
  return post('/product/getProduct', {
      limit: 64,
      memberId: getG60Id(),
      offset: 1
  })
}

export function getPaymentParams(orderNo, productName, type) {
  return post('/pay/invoke', {
      "memberId": getG60Id(),
      "orderNo": orderNo,
      "productName": productName,
      "type": type
  })
}

export function createOrder(productId,num) {
  return post('/order/orderCreateOneProduct', {
      "memberId": getG60Id(),
      "productSkuId": productId,
      "quantity": num
  })
}

// 获取组织机构类型
export function getOrganizationTypes(type) {
  let params = {
      "memberId": getG60Id(),
      "type": type
  }
  return post('/organization/type', params)
}

export function checkG60ExpertExist(){
  return post('/g60/expert/exist',{"memberId": getG60Id()})
}

// 获取所有我的动态
export function getMyDynamic(data,pageSize,currentPage) {
	return post('/dynamic/getMyDynamic', {
		"limit": pageSize || 10,
		"memberId": getG60Id(),
		"offset": currentPage || 1
	})
}

export function getUserPublishesById(id, type, page, limit) {
  if(id == getG60Id()){
    return post('/dynamic/getMyDynamicArticle', {
      "articleId": type,
      "limit": limit || 16,
      "memberId": getG60Id(),
      "offset": page
    })
  }
  return post('/dynamic/getMyDynamicArticle', {
    "articleId": type,
    "limit": limit || 16,
    "memberId": getG60Id(),
    "offset": page,
    "sourceId": id
  })
}

// 获取推荐的动态
export function searchRecommendDynamics(params){
  const param = {
      articlesType: "",
      cooperationType: "",
      industryId: "",
      orIndustryIds: params.ids,
      keyword: params.labels,
      limit: params.limit ? params.limit : 4,
      memberId: getG60Id(),
      offset: params.offset,
      technologyMaturity: "",
      orderByCreateTime: true,    // 是否按时间排序
      type: 4
  }
  return post('/search/searchTechnology',param)
}
// 搜索动态(找项目)
export function searchTechnology(params){
  let param = params.achievementType ? {
      achievementType: params.achievementType,
      cooperationType: params.cooperationType,
      industryIds: params.industryIds,
      keyword: params.keyword,
      limit: params.limit,
      memberId: getG60Id(),
      offset: params.offset,
      technologyMaturity: params.technologyMaturity,
      type: 4
  } : {
      cooperationType: params.cooperationType,
      industryIds: params.industryIds,
      keyword: params.keyword,
      limit: params.limit,
      memberId: getG60Id(),
      offset: params.offset,
      technologyMaturity: params.technologyMaturity,
      type: 4
  }
  if(params.areaId){
      // 八大领域
      param.areaId = params.areaId
  }
  return post('/search/searchTechnology',param)
}

export function getG60DynamicList(param){
  if(!param.memberId){
    param.memberId = '-1'
  }
  return post('/search/searchTechnology',param)
}

export function getG60Broadcast(){
  return post('/g60/common/broadcastList')
}

// 检查权限1.11.3 新增
export function checkUserDiction(param,isSaveCountRecord = false,id){
  const params = !isSaveCountRecord ? {
    "channel": "pc",
    "id": id ? id : '',
    "memberId": getG60Id(),
    "isSaveCountRecord": false,
    "uniqueLab": param.uniqueLab,
    "version": requestVersion
  }:{
    "channel": "pc",
    "memberId": getG60Id(),
    "id":id,
    "uniqueLab": param.uniqueLab,
    "isSaveCountRecord": isSaveCountRecord,
    "version": requestVersion
  }
  return post('/jurisdiction/checkJurisdiction',params)
}

// 删除我的动态
export function deleteDynamicById({id}) {
	return del('/dynamic/deleteDynamicById', {
    "dynamic": id,
    "memberId": getG60Id()
	})
}