const state = {
  postType: '',
  postData: [],
  postMedias: [],
  cover: []
}
const mutations = {
  SET_POST_TYPE:(state,type)=>{
    state.postType = type
  },
  SET_DRAFT_POST_DATA:(state,data)=>{
    state.postData = data
  },
  SET_DRAFT_POST_DATA_NULL:(state)=>{
    state.postData.length = 0
  },
  SET_POST_MEDIAS:(state,data)=>{
    state.postMedias = data
  },
  SET_COVER:(state,data)=>{
    state.cover = data
  }
}
const actions = {
  //视频封面
  setCover({commit},data){
    commit('SET_COVER',data)
  },
  //视频图片存草稿
  setPostMedias({commit},data){
    commit('SET_POST_MEDIAS',data)
  },
  // 修改需求类型
  changePostType({commit},type){
    commit('SET_POST_TYPE',type)
  },
  // 存草稿
  setdraft({commit},data){
    commit('SET_DRAFT_POST_DATA',data)
  },

  // 清草稿
  cleardraft({commit}){
    commit('SET_DRAFT_POST_DATA_NULL')
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}