import {createRouter, createWebHistory} from 'vue-router'

const key = "技术转移，技术成果转化，技术服务，技术创新"
const description = "uwww是专注于技术转移、技术成果转化、技术服务、技术创新和科技项目孵化的一站式数字科创服务平台，提供技术转移、技术成果转化、技术需求、企业政策、科技论坛中心等全流程科技服务。"
export const noScrollTop = [ '/searchDynamic', '/enterpriseDynamic' ,'/search-company' ,'/search-demand','/search-center','/search-patent','/search-industry','/business-vip','/experts','/achieves','/demands','/live-list','/c-center'] // * 不用返回顶部的路由
export const TanNoScrollTop = ['/resource-center','/project-search']
const noSavePosition = [ '/postdynamic']  // 不用保留滚动位置的路由

export const constantRoutes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('@/views/index/newIndex'),
    meta:{
      breadTitle: '首页',
      title: 'uwww-一站式数字科创服务平台',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/roadshow',
    name: '路演管理',
    component: () => import('@/views/roadshow/index'),
    meta:{
      breadTitle: '路演管理',
      title: '路演管理',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/announce',
    name: '数字科创中心产品宣传',
    component: () => import('@/views/announce/announce'),
    meta:{
      breadTitle: '数字科创中心产品宣传',
      title: '数字科创中心产品宣传',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/talent-project-login',
    name: 'talent-project-system-login',
    component: () => import('@/views/talent-project-system/login'),
    meta:{
      breadTitle: '人才项目库登录',
      title: 'uwww-人才项目库-登录',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/talent-project-temp',
    name: 'talent-project-system-temp',
    component: () => import('@/views/talent-project-system/temp'),
    meta:{
      breadTitle: '人才项目库登录临时页面',
      title: 'uwww-人才项目库-登录',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/talent-project',
    name: 'talent-project-system',
    component: () => import('@/views/talent-project-system/home'),
    meta:{
      breadTitle: '人才项目库',
      title: 'uwww-人才项目库-首页',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/talent-project-talents',
    name: 'talent-project-system-experts',
    component: () => import('@/views/talent-project-system/experts'),
    meta:{
      breadTitle: '专家列表页',
      title: 'uwww-人才项目库-专家列表页',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/talent-project-projects',
    name: 'talent-project-system-projects',
    component: () => import('@/views/talent-project-system/projects'),
    meta:{
      breadTitle: '项目列表页',
      title: 'uwww-人才项目库-专家列表页',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/talent-project-detail',
    name: 'talent-project-system-detail',
    component: () => import('@/views/talent-project-system/detail'),
    meta:{
      breadTitle: '详情页',
      title: 'uwww-人才项目库-详情页',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/talent-project-user',
    name: 'talent-project-system-user',
    component: () => import('@/views/talent-project-system/user'),
    meta:{
      breadTitle: '用户中心页',
      title: 'uwww-人才项目库-用户中心页',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/ability-workbench',
    name: 'ability-workbench',
    component: () => import('@/views/activity/ability-workbench'),
    meta:{
      breadTitle: '人才工作站',
      title: 'uwww-一站式数字科创服务平台',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/SEI-register',
    name: 'SEI-register',
    component: () => import('@/views/SEI-register/index'),
    meta:{
      breadTitle: '服务体系',
      title: 'uwww-一站式数字科创服务平台',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/patent-detail',
    name: 'PatentDetail',
    component: () => import('@/views/patent-detail/patent-detail'),
    meta:{
      breadTitle: '服务体系',
      title: 'uwww-一站式数字科创服务平台',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/show-json',
    name: 'show-json',
    component: () => import('@/views/system-daily/show-json'),
    meta:{
      breadTitle: 'show-json',
      title: 'uwww-一站式数字科创服务平台',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/r-i',
    name: 'r-i',
    component: () => import('@/views/system-daily/r-i'),
    meta:{
      breadTitle: 'r-i',
      title: 'uwww-一站式数字科创服务平台',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/site-update-daily',
    name: 'sitemap每日更新',
    component: () => import('@/views/system-daily/site-update-daily'),
    meta:{
      breadTitle: 'sitemap更新',
      title: 'uwww-一站式数字科创服务平台',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/system-daily',
    name: 'SystemDaily',
    component: () => import('@/views/system-daily/system-daily'),
    meta:{
      breadTitle: '服务体系',
      title: 'uwww-一站式数字科创服务平台',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/search-patent',
    name: 'search_patent',
    component: () => import('@/views/search-patent/search-patent'),
    meta:{
      breadTitle: '服务体系',
      title: 'uwww-一站式数字科创服务平台',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/search-industry',
    name: 'SearchIndustry',
    component: () => import('@/views/search-industry/search-industry'),
    meta:{
      breadTitle: '查产业',
      title: 'uwww-一站式数字科创服务平台',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/business-vip',
    name: 'BusinessVip',
    component: () => import('@/views/business-vip/business-vip'),
    meta:{
      breadTitle: '商学院VIP',
      title: 'uwww-一站式数字科创服务平台',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/industry-detail',
    name: 'IndustryDetail',
    component: () => import('@/views/search-industry/industry-detail'),
    meta:{
      breadTitle: '产业详情',
      title: 'uwww-一站式数字科创服务平台',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/incubator',
    name: 'Incubator',
    component: () => import('@/views/incubator/incubator'),
    meta:{
      breadTitle: '服务体系',
      title: '蓝珊瑚BHTI-硬科技孵化加速器_uwww',
      metaInfo : {
        keywords: '科技孵化,科技支持,产业支持,创业设施,技术服务,产业载体,科技融资,科技项目,合作园区',
        description: '蓝珊瑚隶属于科企岛数字科创平台，是专注于科技创业企业和创业团队发展的线上线下一体化的服务载体。它的主要宗旨是以数字化、数据化服务科技创新创业，促进科技成果转化，优化创新创业生态环境。'
      }
    }
  },
  {
    path: '/incubator-new',
    name: 'IncubatorNew',
    component: () => import('@/views/incubator/incubator-new'),
    meta:{
      breadTitle: '服务体系',
      title: '蓝珊瑚硬科技孵化加速器_uwww',
      metaInfo : {
        keywords: '科技孵化,科技支持,产业支持,创业设施,技术服务,产业载体,科技融资,科技项目,合作园区',
        description: '蓝珊瑚隶属于科企岛数字科创平台，是专注于科技创业企业和创业团队发展的线上线下一体化的服务载体。它的主要宗旨是以数字化、数据化服务科技创新创业，促进科技成果转化，优化创新创业生态环境。'
      }
    }
  },
  {
    path: '/incubator-vip',
    name: 'IncubatorVip',
    component: () => import('@/views/incubator/incubator-vip'),
    meta:{
      breadTitle: '服务体系',
      title: '蓝珊瑚创投通',
      metaInfo : {
        keywords: '科技孵化,科技支持,产业支持,创业设施,技术服务,产业载体,科技融资,科技项目,合作园区',
        description: '蓝珊瑚隶属于科企岛数字科创平台，是专注于科技创业企业和创业团队发展的线上线下一体化的服务载体。它的主要宗旨是以数字化、数据化服务科技创新创业，促进科技成果转化，优化创新创业生态环境。'
      }
    }
  },
  // {
  //   path: '/new-index',
  //   name: 'NewIndex',
  //   component: () => import('@/views/index/index'),
  //   meta:{
  //     breadTitle: '首页new',
  //     title: 'uwww-一站式数字科创服务平台',
  //     metaInfo : {
  //       keywords: key,
  //       description: description
  //     }
  //   }
  // },
  {
    path: '/sections', // /:type/:section
    name: 'sections',
    component: () => import('@/views/sections/sections'),
    meta:{
      breadTitle: '服务体系',
      title: 'uwww-一站式数字科创服务平台',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/AI',
    name: '人工智能',
    component: () => import('@/views/sections/technology/AI'),
    meta:{
      breadTitle: '服务体系',
      title: '人工智能资讯_机器学习_AI软件_科技综合频道_科企岛',
      metaInfo : {
        keywords: '人工智能,机器学习,AI软件,科技动态,行业应用',
        description: '科企岛为您提供最新、最全面的人工智能和机器学习资讯，包含AI软件、科技动态、技术创新、行业应用等全方位科技资讯。了解最新科技动态，尽在科企岛。'
      }
    }
  },
  {
    path: '/electronic-information',
    name: '电子信息',
    component: () => import('@/views/sections/technology/electronic-information'),
    meta:{
      breadTitle: '服务体系',
      title: '电子信息资讯_电子产品_电子行业_科技综合频道_科企岛',
      metaInfo : {
        keywords: '电子信息,电子产品,电子行业,产品测评',
        description: '科企岛为您提供最新、最全面的电子产品和电子行业资讯，涵盖电子信息科技创新、行业发展趋势、产品评测等全方位科技资讯。了解最新科技动态，尽在科企岛。'
      }
    }
  },
  {
    path: '/new-energy',
    name: '新能源',
    component: () => import('@/views/sections/technology/new-energy'),
    meta:{
      breadTitle: '服务体系',
      title: '新能源资讯_新能源汽车_自动驾驶_科技综合频道_科企岛',
      metaInfo : {
        keywords: '新能源,新能源汽车,自动驾驶,电动汽车,新能源技术',
        description: '科企岛为您提供最新、最全面的新能源资讯，包括新能源技术创新、电动汽车行业发展、自动驾驶技术等全方位科技资讯，助您了解新能源行业的前沿动态。了解最新科技动态，尽在科企岛。'
      }
    }
  },
  {
    path: '/physics',
    name: '航天科技',
    component: () => import('@/views/sections/technology/physics'),
    meta:{
      breadTitle: '服务体系',
      title: '航天科技资讯_空间站_载人航天_科技综合频道_科企岛',
      metaInfo : {
        keywords: '航天科技,空间站,载人航天,航天技术,发射动态,太空探索',
        description: '科企岛为您提供最新、最全面的航天科技、空间站和载人航天资讯，涵盖航天技术创新、发射动态、太空探索等全方位科技资讯，让您及时了解航天领域的前沿发展。了解最新科技动态，尽在科企岛。'
      }
    }
  },
  {
    path: '/new-material',
    name: '新材料',
    component: () => import('@/views/sections/technology/new-material'),
    meta:{
      breadTitle: '服务体系',
      title: '新材料资讯_材料科学_纳米材料_科技综合频道_科企岛',
      metaInfo : {
        keywords: '新材料,材料科学,纳米材料,新材料研发',
        description: '科企岛为您提供最新、最全面的材料科学相关资讯，包括新材料研发动态、性能应用、技术创新等全方位科技资讯，助您了解新材料领域的前沿趋势。了解最新科技动态，尽在科企岛。'
      }
    }
  },
  {
    path: '/biology',
    name: '生物医药',
    component: () => import('@/views/sections/technology/biology'),
    meta:{
      breadTitle: '服务体系',
      title: '生物医药资讯_药物制剂_生物技术_科技综合频道_科企岛',
      metaInfo : {
        keywords: '生物医药,药物制剂,生物技术,医药研发',
        description: '科企岛为您提供最新、最全面的生物医药相关资讯，涵盖医药研发动态、生物技术、技术创新等全方位科技资讯，助您了解生物医药领域的前沿进展。了解最新科技动态，尽在科企岛。'
      }
    }
  },
  {
    path: '/chip',
    name: '电子芯片',
    component: () => import('@/views/sections/technology/chip'),
    meta:{
      breadTitle: '电子芯片',
      title: '光电芯片资讯_半导体_光刻机_科技综合频道_科企岛',
      metaInfo : {
        keywords: '光电芯片,半导体,光刻机,芯片技术,半导体制程',
        description: '科企岛为您提供最新、最全面的光电芯片资讯，包括芯片技术创新、半导体制程、光刻机设备等全方位科技资讯，助您了解光电芯片领域的前沿技术和行业动态。了解最新科技动态，尽在科企岛。'
      }
    }
  },
  {
    path: '/manufacture',
    name: '智能制造',
    component: () => import('@/views/sections/technology/manufacture'),
    meta:{
      breadTitle: '智能制造',
      title: '智能制造资讯_机器人_智能工厂_科技综合频道_科企岛',
      metaInfo : {
        keywords: '智能制造,机器人,智能工厂,自动化技术,智能生产线,工业机器人',
        description: '科企岛为您提供最新、最全面的智能制造资讯，涵盖自动化技术创新、智能生产线、工业机器人等全方位科技资讯，助您了解智能制造领域的发展趋势和前沿技术。了解最新科技动态，尽在科企岛。'
      }
    }
  },
  {
    path: '/environment',
    name: '环保/碳中和',
    component: () => import('@/views/sections/technology/environment'),
    meta:{
      breadTitle: '环保/碳中和',
      title: '环保碳中和资讯_环境保护_清洁能源_科技综合频道_科企岛',
      metaInfo : {
        keywords: '环保碳中和,环境保护,清洁能源,碳中和政策,环保技术',
        description: '科企岛为您提供最新、最全面的环保碳中和资讯，包括碳中和政策、环保技术创新、清洁能源应用等全方位科技资讯，助您了解环保碳中和领域的前沿动态和行业发展趋势。了解最新科技动态，尽在科企岛。'
      }
    }
  },
  {
    path: '/climate',
    name: '气候生态',
    component: () => import('@/views/sections/technology/climate'),
    meta:{
      breadTitle: '气候生态',
      title: '气候生态资讯_生态环境_可持续发展_科技综合频道_科企岛',
      metaInfo : {
        keywords: '气候生态,生态环境,可持续发展,气候科学研究,生态保护',
        description: '科企岛为您提供最新、最全面的气候生态资讯，涵盖气候科学研究、生态保护、可持续发展等全方位科技资讯，助您了解气候生态领域的前沿动态和环境保护的最新进展。了解最新科技动态，尽在科企岛。'
      }
    }
  },
  {
    path: '/agriculture',
    name: '智慧农业',
    component: () => import('@/views/sections/technology/agriculture'),
    meta:{
      breadTitle: '智慧农业',
      title: '智慧农业资讯_农业信息化_精准农业_科技综合频道_科企岛',
      metaInfo : {
        keywords: '智慧农业,农业信息化,精准农业技术,农业科技创新,农业智能化',
        description: '科企岛为您提供最新、最全面的智慧农业资讯，涵盖农业科技创新、农业智能化、精准农业技术等全方位科技资讯，助您了解智慧农业领域的发展趋势和前沿技术。了解最新科技动态，尽在科企岛。'
      }
    }
  },
  {
    path: '/clothing',
    name: '外贸服装',
    component: () => import('@/views/sections/technology/clothing'),
    meta:{
      breadTitle: '外贸服装',
      title: '外贸服装资讯_服装品牌_纺织工程_科技综合频道_科企岛',
      metaInfo : {
        keywords: '外贸服装,服装品牌,纺织工程,服装设计,面料科技,生产工艺',
        description: '科企岛为您提供最新、最全面的外贸服装资讯，涵盖服装设计、面料科技、生产工艺等全方位科技资讯，助您了解外贸服装领域的前沿动态和纺织工程的技术创新。了解最新科技动态，尽在科企岛。'
      }
    }
  },
  {
    path: '/glance',
    name: '一缆之事',
    component: () => import('@/views/sections/technology/glance'),
    meta:{
      breadTitle: '一缆之事',
      title: '一缆之事资讯_电线电缆_光纤光缆_科技综合频道_科企岛',
      metaInfo : {
        keywords: '一缆之事,电线电缆,光纤光缆,线缆技术',
        description: '一缆之事,电线电缆,光纤光缆,线缆技术'
      }
    }
  },
  {
    path: '/electrochemistry',
    name: '电化学',
    component: () => import('@/views/sections/technology/electrochemistry'),
    meta:{
      breadTitle: '电化学',
      title: '电化学资讯_化学工程_锂电池_科技综合频道_科企岛',
      metaInfo : {
        keywords: '电化学,化学工程,锂电池,电池技术,化学反应工程',
        description: '科企岛为您提供最新、最全面的电化学资讯，涵盖电池技术创新、化学反应工程等全方位科技资讯，助您了解电化学领域的前沿研究和行业动态。了解最新科技动态，尽在科企岛。'
      }
    }
  },
  {
    path: '/industry-outlook',
    name: '产业前瞻',
    component: () => import('@/views/sections/information/industry-outlook'),
    meta:{
      breadTitle: '服务体系',
      title: '产业前瞻资讯_科技产业_产业发展_产业政策频道_科企岛',
      metaInfo : {
        keywords: '产业前瞻,科技产业,产业发展,产业趋势,技术创新',
        description: '科企岛为您提供最新、最全面的产业政策资讯，包括前沿产业趋势、技术创新、政策解读等全方位科技资讯，助您了解产业前瞻领域的发展动态和未来趋势。了解最新产业政策动态，尽在科企岛。'
      }
    }
  },
  {
    path: '/policy',
    name: '政策导向',
    component: () => import('@/views/sections/information/policy'),
    meta:{
      breadTitle: '服务体系',
      title: '政策导向资讯_政策解读_政策扶持_产业政策频道_科企岛',
      metaInfo : {
        keywords: '政策导向,政策解读,政策扶持,政府政策分析',
        description: '科企岛为您提供最新、最全面的政策导向资讯，涵盖政府政策分析、优惠政策解读、产业扶持措施等全方位政策资讯，助您了解政策导向和产业发展的最新动态。了解最新产业政策动态，尽在科企岛。'
      }
    }
  },
  {
    path: '/frontier',
    name: '科技前沿',
    component: () => import('@/views/sections/industry/frontier'),
    meta:{
      breadTitle: '服务体系',
      title: '科技前沿资讯_人工智能_区块链_科技全景频道_科企岛',
      metaInfo : {
        keywords: '科技前沿,人工智能,区块链,科技创新',
        description: '科企岛为您提供最新、最全面的科技领域资讯，涵盖人工智能、区块链等前沿技术动态，以及科技创新、行业应用等全方位科技资讯，助您把握科技前沿趋势和行业动态。了解最新科技动态，尽在科企岛。'
      }
    }
  },
  {
    path: '/fashion',
    name: '科技潮品',
    component: () => import('@/views/sections/industry/fashion'),
    meta:{
      breadTitle: '科技潮品',
      title: '科技潮品资讯_智能家居_可穿戴设备_科技全景频道_科企岛',
      metaInfo : {
        keywords: '科技潮品,智能家居,可穿戴设备,黑科技,未来出行',
        description: '科企岛为您带来最新、最炫酷的科技潮品和黑科技资讯，涵盖智能家居、可穿戴设备、未来出行等全方位科技潮品动态，让您尽情领略科技潮流的魅力。了解最新科技动态，尽在科企岛。'
      }
    }
  },
  {
    path: '/forum',
    name: '科技论坛',
    component: () => import('@/views/sections/industry/forum'),
    meta:{
      breadTitle: '科技论坛',
      title: '科技论坛资讯_互联网大会_发展论坛_科技全景频道_科企岛',
      metaInfo : {
        keywords: '科技论坛,互联网大会,发展论坛,科技会议,行业峰会,专家讲座',
        description: '科企岛为您提供最新、最全面的科技论坛资讯，涵盖各类科技会议、行业峰会、专家讲座等全方位科技资讯，助您了解科技领域的最新动态和前沿趋势。了解最新科技动态，尽在科企岛。'
      }
    }
  },
  {
    path: '/facility',
    name: '科技设施',
    component: () => import('@/views/sections/industry/facility'),
    meta:{
      breadTitle: '科技设施',
      title: '科技设施资讯_基础设施_实验设备_科技全景频道_科企岛',
      metaInfo : {
        keywords: '科技设施,基础设施,实验设备,实验室建设,科研仪器',
        description: '科企岛为您提供最新、最全面的科技设施资讯，包括实验室建设、科研仪器、技术创新等全方位科技资讯，助您了解科技设施领域的最新发展和行业趋势。了解最新科技动态，尽在科企岛。'
      }
    }
  },
  {
    path: '/park',
    name: '产业园区',
    component: () => import('@/views/sections/industry/park'),
    meta:{
      breadTitle: '产业园区',
      title: '产业园区资讯_科技园_招商引资_科技全景频道_科企岛',
      metaInfo : {
        keywords: '产业园区,科技园,工业园,园区规划,招商引资',
        description: '科企岛为您提供最新、最全面的产业园区资讯，涵盖园区规划、基础设施建设、招商引资等全方位科技资讯，助您了解产业园区的最新动态和发展趋势。了解最新科技动态，尽在科企岛。'
      }
    }
  },
  {
    path: '/equipment',
    name: '科技设备',
    component: () => import('@/views/sections/industry/equipment'),
    meta:{
      breadTitle: '科技设备',
      title: '科技设备资讯_高科技产品_无人机_科技全景频道_科企岛',
      metaInfo : {
        keywords: '科技设备,高科技产品,无人机,飞行汽车',
        description: '科企岛为您提供最新、最全面的科技设备资讯，涵盖高科技设备、无人机、飞行汽车等前沿科技设备动态，让您及时了解科技领域的最新创新和发展趋势。了解最新科技动态，尽在科企岛。'
      }
    }
  },
  {
    path: '/character',
    name: '科技人物',
    component: () => import('@/views/sections/industry/character'),
    meta:{
      breadTitle: '科技人物',
      title: '科技人物资讯_马斯克_科学家_科技全景频道_科企岛',
      metaInfo : {
        keywords: '科技人物,马斯克,科学家,企业家,商业领袖',
        description: '科企岛为您提供最新、最全面的科技领域人物资讯，包括埃隆·马斯克等顶级企业家、科学家和行业领袖人物的动态和观点，以及他们在科技领域的探索和创新实践，助您了解科技人物的影响力和行业发展趋势。了解最新科技动态，尽在科企岛。'
      }
    }
  },
  {
    path: '/AR',
    name: '数字AR',
    component: () => import('@/views/sections/industry/AR'),
    meta:{
      breadTitle: '数字AR',
      title: '数字AR资讯_科技视频_科普视频_科技全景频道_科企岛',
      metaInfo : {
        keywords: '数字AR,科技视频,科普视频,未来趋势,应用场景,数字技术创新',
        description: '科企岛为您提供最新、最全面的数字科技资讯，涵盖数字技术创新、应用场景、未来趋势等全方位科技资讯，通过视频形式展现科技领域的精彩内容和前沿动态。了解最新科技动态，尽在科企岛。'
      }
    }
  },
  {
    path: '/finance-capital',
    name: '融资创投',
    component: () => import('@/views/sections/finance/finance-capital'),
    meta:{
      breadTitle: '服务体系',
      title: '融资创投资讯_企业融资_创业投资_融资创投频道_科企岛',
      metaInfo : {
        keywords: '融资创投,企业融资,创业投资,投资动态,融资案例,创业故事',
        description: '科企岛为您提供最新、最全面的投融资资讯，涵盖投资动态、融资案例、创业故事等全方位融资创投信息，助您了解融资创投领域的趋势和发展，为企业的资本运作提供有力支持。了解最新投融资动态，尽在科企岛。'
      }
    }
  },
  {
    path: '/commerce',
    name: '商业视线',
    component: () => import('@/views/sections/finance/commerce'),
    meta:{
      breadTitle: '服务体系',
      title: '商业视线资讯_投资策略_商业秘密_融资创投频道_科企岛',
      metaInfo : {
        keywords: '商业视线,投资策略,商业视角,商业秘密,商业资讯,市场分析',
        description: '科企岛为您提供最新、最全面的商业视线资讯，以独特的商业视角为您揭示商业秘密，提供最新的商业资讯，涵盖市场分析、商业模式、投资策略等全方位资讯，助您把握商业趋势，洞察市场机遇。了解最新投融资动态，尽在科企岛。'
      }
    }
  },
  {
    path: '/case',
    name: '商业案例',
    component: () => import('@/views/sections/deep/case'),
    meta:{
      breadTitle: '服务体系',
      title: '商业案例资讯_商业计划_战略规划_商业智慧频道_科企岛',
      metaInfo : {
        keywords: '商业案例,商业模式,商业机会,商业计划,市场机遇,战略规划',
        description: '科企岛为您提供最新、最全面的商业案例资讯，通过深入分析经典商业案例，探讨商业模式、市场机遇和战略规划，助您汲取商业智慧，为企业的发展提供有益的启示。了解最新商业动态，尽在科企岛。'
      }
    }
  },
  {
    path: '/focus',
    name: '焦点访谈',
    component: () => import('@/views/sections/deep/focus'),
    meta:{
      breadTitle: '服务体系',
      title: '焦点访谈资讯_人物专访_高端访谈_商业智慧频道_科企岛',
      metaInfo : {
        keywords: '焦点访谈,人物专访,高端访谈,管理哲学,成功经验',
        description: '科企岛为您提供最新、最深入的焦点人物专访资讯，通过与商业领袖、行业专家等进行面对面交流，揭示他们的商业智慧、管理哲学和成功经验，助您了解行业动态，汲取商业智慧。了解最新商业动态，尽在科企岛。'
      }
    }
  },
  {
    path: '/insight',
    name: '洞见',
    component: () => import('@/views/sections/deep/insight'),
    meta:{
      breadTitle: '服务体系',
      title: '洞见资讯_商业模式_商业研究_商业智慧频道_科企岛',
      metaInfo : {
        keywords: '洞见,商业模式,商业研究,市场趋势,模式创新',
        description: '科企岛为您提供最新、最具洞察力的商业模式和商业研究资讯，通过深入剖析商业案例、探讨市场趋势和商业模式创新，助您获取商业智慧，为企业的发展提供有力的决策支持。了解最新商业动态，尽在科企岛。'
      }
    }
  },
  {
    path: '/newLogin',
    name: 'newLogin',
    component: () => import('@/views/login/newLogin'),
    meta:{
      breadTitle: '首页',
      title: 'uwww-一站式数字科创服务平台',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login/login.vue'),
    meta:{
      title: 'uwww-登录页面',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/home',
    name: 'newIndex',
    component: () => import('@/views/index/newIndex'),
    meta:{
      breadTitle: '首页',
      title: 'uwww-一站式数字科创服务平台',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/profundity',
    name: 'profundity',
    component: () => import('@/views/secondaryPage/profundity'),
    meta:{
      breadTitle: '深度',
      title: 'uwww-一站式数字科创服务平台',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/capital',
    name: 'capital',
    component: () => import('@/views/secondaryPage/capital'),
    meta:{
      breadTitle: '资本',
      title: 'uwww-一站式数字科创服务平台',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/other-third',
    name: 'other-third',
    component: () => import('@/views/tertiaryPage/other/thirdPage'),
    meta:{
      breadTitle: '三级页面',
      title: 'uwww-一站式数字科创服务平台',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/industry-third',
    name: 'industry-third',
    component: () => import('@/views/tertiaryPage/industry/thirdPage'),
    meta:{
      breadTitle: '产业列表',
      title: 'uwww-一站式数字科创服务平台',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/industry',
    name: 'industry',
    component: () => import('@/views/secondaryPage/industry'),
    meta:{
      breadTitle: '产业',
      title: 'uwww-一站式数字科创服务平台',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/technology-third',
    name: 'technology-third',
    component: () => import('@/views/tertiaryPage/technology/thirdPage'),
    meta:{
      breadTitle: '资讯列表',
      title: 'uwww-一站式数字科创服务平台',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/technology',
    name: 'technology',
    component: () => import('@/views/secondaryPage/technology'),
    meta:{
      breadTitle: '科技',
      title: 'uwww-一站式数字科创服务平台',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/information-third',
    name: 'information-third',
    component: () => import('@/views/tertiaryPage/information/thirdPage'),
    meta:{
      breadTitle: '资讯列表',
      title: 'uwww-一站式数字科创服务平台',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/information',
    name: 'information',
    component: () => import('@/views/secondaryPage/information'),
    meta:{
      breadTitle: '资讯',
      title: 'uwww-一站式数字科创服务平台',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/search-center',
    name: 'search-center',
    component: () => import('@/views/search-center/search-center'),
    meta:{
      breadTitle: '搜索中心',
      title: 'uwww-一站式数字科创服务平台',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/detail',
    name: 'new-detail',
    component: () => import('@/views/detailInfo/detailInfo'),
    meta:{
      breadTitle: '详情页面',
      title: 'uwww-一站式数字科创服务平台',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/company-detail',
    name: 'companyDetail',
    component: () => import('@/views/company/companyDetail'),
    meta:{
      title: 'uwww-企业详情',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/p-center',
    name: 'NewUserCenter',
    component: () => import('@/views/user-center/newUserCenter'),
    meta:{
      breadTitle: '探角数字科创中心',
      title: 'uwww-探角数字科创中心',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/c-center',
    name: 'NewCustomUserCenter',
    component: () => import('@/views/user-center/newCustomUserCenter'),
    meta:{
      breadTitle: '新个人中心',
      title: 'uwww-个人中心',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/user-center',
    name: 'userCenter',
    component: () => import('@/views/user-center/userCenter'),
    meta:{
      breadTitle: '用户中心',
      title: 'uwww-用户中心',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/capital-island',
    name: '/capital-island',
    component: () => import('@/views/capitalIsland/capitalIsland'),
    meta:{
      breadTitle: '资本岛',
      title: 'uwww-资本岛',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/settled-experts',
    name: '/settled-experts',
    component: () => import('@/views/settled-experts/settledExperts'),
    meta:{
      breadTitle: '在线专家',
      title: 'uwww-在线专家列表',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/organization-experts',
    name: '/organization-experts',
    component: () => import('@/views/organization-experts/organizationExperts'),
    meta:{
      breadTitle: '专家名录',
      title: 'uwww-专家名录',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/search-capital',
    name: 'searchCapital',
    component: () => import('@/views/company/searchCapital'),
    meta:{
      breadTitle: '搜资本',
      title: 'uwww-搜资本',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/search-company',
    name: 'searchCompany',
    component: () => import('@/views/company/searchCompany'),
    meta:{
      breadTitle: '搜企业',
      title: 'uwww-搜企业',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/enterpriseUser',
    name: 'enterpriseUser',
    component: () => import('@/views/enterpriseUser/enterpriseUser'),
    meta:{
      title: 'uwww-数字科创中心',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/spread',
    name: 'spread',
    component: () => import('@/views/spread/spread'),
    meta:{
      title: 'uwww-推广',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/project',
    name: 'project',
    component: () => import('@/views/project/tri-project'),
    meta:{
      title: 'uwww-解决方案',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/company',
    name: 'company',
    component: () => import('@/views/project/company'),
    meta:{
      title: 'uwww-企业解决方案',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/government',
    name: 'government',
    component: () => import('@/views/project/government'),
    meta:{
      title: 'uwww-政府解决方案',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/searchDynamic',
    name: 'searchDynamic',
    component: () => import('@/views/searchDetail/searchDynamic'),
    meta:{
      breadTitle: '创新赛道',
      title: 'uwww-创新赛道',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/enterpriseDynamic',
    name: 'enterpriseDynamic',
    component: () => import('@/views/news/enterpriseDynamic'),
    meta:{
      breadTitle: '科企资讯',
      title: 'uwww-科企资讯',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/newsDetail',
    name: 'newsDetail',
    component: () => import('@/views/news/newsDetail'),
    meta:{
      breadTitle: '资讯详情',
      title: 'uwww-资讯详情',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/industrial-garden',
    name: 'industrialGarden',
    component: () => import('@/views/project/industrial-garden'),
    meta:{
      title: 'uwww-园区解决方案',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/live-room',
    name: 'LiveRoom',
    component: () => import('@/views/live-room/LiveRoom'),
    meta:{
      title: 'uwww-路演回放',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/huiyi',
    name: 'HuiYi',
    component: () => import('@/views/huiyi/HuiYi.vue'),
    meta:{
      title: 'uwww-会议列表',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/regional-partner',
    component: () => import('@/views/activity/partner.vue'),
    meta:{
      title: '区域合伙人',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/G60-assembly',
    component: () => import('@/views/activity/G60-assembly.vue'),
    meta:{
      title: '科技招商',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/business-page',
    component: () => import('@/views/activity/business-page.vue'),
    meta:{
      title: '科技招商',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/business-page2',
    component: () => import('@/views/activity/business-page2.vue'),
    meta:{
      title: '科技招商2',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/G60-page',
    component: () => import('@/views/activity/G60-page.vue'),
    meta:{
      title: 'G60Page',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/enterpriseSolution',
    component: () => import('@/views/activity/enterpriseSolution.vue'),
    meta:{
      title: 'uwww-企业解决方案',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/live',
    name: 'Live',
    component: () => import('@/views/live-room/CreateLive.vue'),
    meta:{
      title: 'uwww-路演间',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/live-list',
    name: 'LiveList',
    component: () => import('@/views/liveList/index.vue'),
    meta:{
      title: 'uwww一路演活动',
      metaInfo : {
        keywords: "live list: 线上路演列表",
        description: "live description"
      }
    }
  },
  {
    path: '/live-list',
    name: 'LiveList',
    component: () => import('@/views/liveList/newLiveList.vue'),
    meta:{
      title: 'uwww一路演活动',
      metaInfo : {
        keywords: "live list: 线上路演列表",
        description: "live description"
      }
    }
  },
  {
    path: '/conference',
    name: 'Conference',
    component: () => import('@/views/huiyi/Conference.vue'),
    meta:{
      title: 'uwww-会议',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/personal-center',
    name: 'PersonalCenter',
    component: () => import('@/views/personal-center/index'),
    meta:{
      title: 'uwww-公司介绍',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/lab',
    name: 'Lab',
    component: () => import('@/views/lab/Lab'),
    meta:{
      title: 'uwww-组织空间',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/my-lab',
    name: 'MyLab',
    component: () => import('@/views/my-lab/MyLab'),
    meta:{
      title: 'uwww-专家空间',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/my-lab-doctor-old',
    name: 'MyLabDoctor',
    component: () => import('@/views/my-lab/MyLabDoctor'),
    meta:{
      breadTitle: '专家详情',
      title: 'uwww-专家空间',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/my-lab-doctor',
    name: 'MyLabDoctorNew',
    component: () => import('@/views/my-lab/MyLabDoctorNew'),
    meta:{
      breadTitle: '专家详情',
      title: 'uwww-专家空间',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/searchdetail',
    name: 'searchdetail',
    component: () => import('@/views/searchDetail/searchDetail'),
    meta:{
      breadTitle: '技术详情',
      title: 'uwww-详情展示',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/demand-detail',
    name: 'demand-detail',
    component: () => import('@/views/search-demand/demandDetail'),
    meta:{
      breadTitle: '需求详情',
      title: 'uwww-详情展示',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/profile-edit',
    name: 'ProfileEdit',
    component: () => import('@/views/profile-edit/ProfileEdit'),
    meta:{
      title: 'uwww-个人信息编辑',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/orgnization-edit',
    name: 'OrgnizatioEdit',
    component: () => import('@/views/orgnization-edit/OrgnizationEdit'),
    meta:{
      title: 'uwww-组织信息编辑',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/vip',
    name: 'Vip',
    component: () => import('@/views/vip/newVip'),
    meta:{
      title: 'uwww-会员',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/my-order',
    name: 'MyOrder',
    component: () => import('@/views/my-order/MyOrder'),
    meta:{
      title: 'uwww-我的订单',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/tempLogin',
    name: 'tempLogin',
    component: () => import('../views/login/tempLogin.vue'),
    meta:{
      title: 'uwww-登录页面',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/industry-setup',
    name: 'IndustrySetup',
    component: () => import('@/views/login/Industry'),
    meta:{
      title: 'uwww-行业选择',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/not-found',
    name: '404',
    component: () => import('../views/error-page/404.vue'),
    meta:{
      title: 'uwww-错误页面',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/no-permission',
    name: '401',
    component: () => import('../views/error-page/401.vue'),
    meta:{
      title: 'uwww-无权限页面',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/mobile-menu',
    name: 'menu',
    component: () => import('../views/mobile/menu.vue'),
    meta: {title: '菜单栏展开'}
  },
  {
    path: '/search-demand',
    name: 'search-demand',
    component: () => import('@/views/search-demand/search-demand.vue'),
    meta:{
      breadTitle: '需求库',
      title: 'uwww-需求库',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('@/views/search/search'),
    meta:{
      breadTitle: '专家通',
      title: 'uwww-专家通',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/search-test',
    name: 'searchTest',
    component: () => import('@/views/search/searchTest'),
    meta:{
      breadTitle: '专家通',
      title: 'uwww-专家通',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/contactus',
    name: 'contactus',
    component: () => import('@/views/contact-us/ContactUs'),
    meta:{
      title: 'uwww-联系我们',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/postdemand',
    name: 'postdemand',
    component: () => import('@/views/post-demand/postdemand'),
    meta:{
      title: 'uwww-发布需求',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/postTech',
    name: 'postTech',
    component: () => import('@/views/post-dynamic/postTech'),
    meta:{
      title: 'uwww-发布成果',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/postdynamic',
    name: 'postdynamic',
    component: () => import('@/views/post-dynamic/postdynamic'),
    meta:{
      title: 'uwww-发布动态',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/post-dynamic-local',
    name: 'postdynamiclocal',
    component: () => import('@/views/post-dynamic/postDynamicLocal'),
    meta:{
      title: 'uwww-发布动态-科企岛',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/yszc',
    name: 'privacyagreement',
    component: () => import('@/views/static-page/privacy-agreement'),
    meta:{
      title: 'uwww-隐私政策',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/selection',
    name: 'selection',
    component: () => import('@/views/before-workbench/selection'),
    meta:{
      title: 'uwww-定制数字科创中心',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/vipxy',
    name: 'vipagreement',
    component: () => import('@/views/static-page/vip-agreement'),
    meta:{
      title: 'uwww-会员协议',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/nrsm',
    name: 'contentstatement',
    component: () => import('@/views/static-page/content-statement'),
    meta:{
      title: 'uwww-内容声明',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/yhxy',
    name: 'userstatement',
    component: () => import('@/views/static-page/user-statement'),
    meta:{
      title: 'uwww-用户协议',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/:catchAll(.*)',
    redirect: to => { // 路径判断，如果含有'.'则进入相应的网页 否则进入404页面
      console.log(to,'hehe');
      if(to.params.catchAll.indexOf('.') !== -1 && !to.params.catchAll.startsWith('https://') && !to.params.catchAll.startsWith('http://')){
        window.location = 'https://' + to.params.catchAll
      }
      else return '/not-found'
    },
    meta:{
      title: 'uwww-数字化科创服务平台',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  }
]

export const G60constantRoutes = [
  {
    path: '/',
    name: 'G60首页',
    component: () => import('@/views/G60/home'),
    meta:{
      breadTitle: 'G60首页',
      title: 'uwww-G60专区首页',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/news',
    name: 'G60资讯中心',
    component: () => import('@/views/G60/news'),
    meta:{
      breadTitle: 'G60资讯中心',
      title: 'uwww-G60资讯中心',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/questions',
    name: 'G60问答中心',
    component: () => import('@/views/G60/questions'),
    meta:{
      breadTitle: 'G60问答中心',
      title: 'uwww-G60问答中心',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/policies',
    name: 'G60产业政策',
    component: () => import('@/views/G60/policies'),
    meta:{
      breadTitle: 'G60产业政策',
      title: 'uwww-G60产业政策',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/login',
    name: 'G60登录',
    component: () => import('@/views/G60/login'),
    meta:{
      breadTitle: 'G60登录',
      title: 'uwww-G60登录',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/user',
    name: 'G60工作中心',
    component: () => import('@/views/G60/user'),
    meta:{
      breadTitle: 'G60工作中心',
      title: 'uwww-G60工作中心',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/search',
    name: 'G60搜索中心',
    component: () => import('@/views/G60/search'),
    meta:{
      breadTitle: 'G60搜索中心',
      title: 'uwww-G60搜索中心',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/publish-demand',
    name: 'G60发布需求',
    component: () => import('@/views/G60/publishDemand'),
    meta:{
      breadTitle: 'G60发布需求',
      title: 'uwww-G60发布需求',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/announce',
    name: 'G60-数字科创中心产品宣传',
    component: () => import('@/views/G60/announce'),
    meta:{
      breadTitle: 'G60数字科创中心产品宣传',
      title: 'uwww-G60数字科创中心产品宣传',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/out-demand',
    name: 'G60-发布需求',
    component: () => import('@/views/G60/outLink/demand'),
    meta:{
      breadTitle: 'G60外部发布需求',
      title: 'uwww-G60外部发布需求',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/publish-dynamic',
    name: 'G60发布文章',
    component: () => import('@/views/G60/publishDynamic'),
    meta:{
      breadTitle: 'G60发布文章',
      title: 'uwww-G60发布文章',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/publish-project',
    name: 'G60发布成果',
    component: () => import('@/views/G60/publishProject'),
    meta:{
      breadTitle: 'G60发布成果',
      title: 'uwww-G60发布成果',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/out-project',
    name: 'G60-发布成果',
    component: () => import('@/views/G60/outLink/project'),
    meta:{
      breadTitle: 'G60外部发布成果',
      title: 'uwww-G60外部发布成果',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/expert-settle',
    name: 'G60专家入驻',
    component: () => import('@/views/G60/expertSettle'),
    meta:{
      breadTitle: 'G60专家入驻',
      title: 'uwww-G60专家入驻',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/out-expert',
    name: 'G60-专家入驻',
    component: () => import('@/views/G60/outLink/expert'),
    meta:{
      breadTitle: 'G60外部专家入驻',
      title: 'uwww-G60外部专家入驻',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/selection',
    name: 'G60数字科创中心',
    component: () => import('@/views/G60/selection'),
    meta:{
      breadTitle: 'G60数字科创中心',
      title: 'uwww-G60数字科创中心',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/space',
    name: 'G60产学研空间',
    component: () => import('@/views/G60/labSpace'),
    meta:{
      breadTitle: 'G60产学研空间',
      title: 'uwww-G60产学研空间',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/vip',
    name: 'G60VIP',
    component: () => import('@/views/G60/vip'),
    meta:{
      breadTitle: 'G60VIP',
      title: 'uwww-G60VIP',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/detail',
    name: 'G60详情',
    component: () => import('@/views/G60/detail'),
    meta:{
      breadTitle: 'G60详情',
      title: 'uwww-G60详情',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/demands',
    name: 'G60企业需求',
    component: () => import('@/views/G60/demands'),
    meta:{
      breadTitle: 'G60企业需求',
      title: 'uwww-G60企业需求',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/experts',
    name: 'G60技术专家',
    component: () => import('@/views/G60/experts'),
    meta:{
      breadTitle: 'G60技术专家',
      title: 'uwww-G60技术专家',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/achieves',
    name: 'G60技术成果',
    component: () => import('@/views/G60/achieves'),
    meta:{
      breadTitle: 'G60技术成果',
      title: 'uwww-G60技术成果',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/not-found',
    name: '404',
    component: () => import('../views/error-page/404.vue'),
    meta:{
      title: 'uwww-错误页面',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/:catchAll(.*)',
    redirect: to => { // 路径判断，如果含有'.'则进入相应的网页 否则进入404页面
      console.log(to,'hehe');
      if(to.params.catchAll.indexOf('.') !== -1 && !to.params.catchAll.startsWith('https://') && !to.params.catchAll.startsWith('http://')){
        window.location = 'https://' + to.params.catchAll
      }
      else return '/not-found'
    },
    meta:{
      title: 'uwww-数字化科创服务平台',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  }
]

const tanKey = "探角数字科创中心, 科创大数据, SaaS平台, 科研专家, 技术成果, 创新科研项目, 科创解决方案, 企业科技创新, 数字化基础设施, 科企岛"
const tanDescription = "探角数字科创中心，科企岛倾力打造的科创大数据SaaS产品，汇聚50万顶尖科研专家、百万技术成果与海量创新硬科技项目，通过智能算法推荐，精准匹配企业科创需求，提供全方位、个性化的科创解决方案，加速企业科技创新步伐。立即体验，打造企业科技创新的数字化基础设施！"

export const TanConstantRoutes = [
  {
    path: '/',
    name: '探角首页',
    component: () => import('@/views/TanSite/home'),
    meta:{
      breadTitle: '探角首页',
      title: 'uwww - 科创大数据SaaS平台，企业数字化科创解决方案专家',
      metaInfo : {
        keywords: tanKey,
        description: tanDescription
      }
    }
  },
  {
    path: '/identity',
    name: '探角身份创建',
    component: () => import('@/views/TanSite/identity'),
    meta:{
      breadTitle: '探角身份创建',
      title: 'uwww-探角身份创建',
      metaInfo : {
        keywords: tanKey,
        description: tanDescription
      }
    }
  },
  {
    path: '/tan-invite',
    name: '探角邀请加入',
    component: () => import('@/views/TanSite/invite'),
    meta:{
      breadTitle: '探角邀请加入',
      title: 'uwww-探角邀请加入',
      metaInfo : {
        keywords: tanKey,
        description: tanDescription
      }
    }
  },
  {
    path: '/vip',
    name: '探角VIP',
    component: () => import('@/views/TanSite/vip'),
    meta:{
      breadTitle: '探角VIP',
      title: 'uwww-探角VIP',
      metaInfo : {
        keywords: tanKey,
        description: tanDescription
      }
    }
  },
  {
    path: '/p-center',
    name: 'TanjiaoUserCenter',
    component: () => import('@/views/TanSite/center'),
    meta:{
      breadTitle: '探角数字科创中心',
      title: 'uwww - 科创大数据SaaS平台，企业数字化科创解决方案专家',
      metaName: '首页',
      metaInfo : {
        keywords: tanKey,
        description: tanDescription
      }
    }
  },
  {
    path: '/expert-management',
    name: '探角专家管理',
    component: () => import('@/views/TanSite/expert'),
    meta:{
      breadTitle: '探角专家管理',
      title: 'uwww-探角专家管理',
      metaName: '专家管理',
      metaInfo : {
        keywords: tanKey,
        description: tanDescription
      }
    }
  },
  {
    path: '/expert-detail',
    name: '探角专家详情',
    component: () => import('@/views/TanSite/expert-detail'),
    meta:{
      breadTitle: '探角专家详情',
      title: 'uwww-探角专家详情',
      metaName: '专家管理',
      metaInfo : {
        keywords: tanKey,
        description: tanDescription
      }
    }
  },
  {
    path: '/expert-search',
    name: '探角专家搜索',
    component: () => import('@/views/TanSite/expert-search'),
    meta:{
      breadTitle: '探角专家搜索',
      title: 'uwww-探角专家搜索',
      metaName: '找专家',
      metaInfo : {
        keywords: tanKey,
        description: tanDescription
      }
    }
  },
  {
    path: '/project-search',
    name: '探角找项目',
    component: () => import('@/views/TanSite/project-search'),
    meta:{
      breadTitle: '探角找项目',
      title: 'uwww-探角找项目',
      metaName: '找项目',
      metaInfo : {
        keywords: tanKey,
        description: tanDescription
      }
    }
  },
  {
    path: '/project-detail',
    name: '探角项目详情',
    component: () => import('@/views/TanSite/project-detail'),
    meta:{
      breadTitle: '探角项目详情',
      title: 'uwww-探角项目详情',
      metaName: '找项目',
      metaInfo : {
        keywords: tanKey,
        description: tanDescription
      }
    }
  },
  {
    path: '/team-management',
    name: '探角团队管理',
    component: () => import('@/views/TanSite/list'),
    meta:{
      breadTitle: '探角团队管理',
      title: 'uwww-探角团队管理',
      metaName: '团队管理',
      metaInfo : {
        keywords: tanKey,
        description: tanDescription
      }
    }
  },
  {
    path: '/order',
    name: '探角订单管理',
    component: () => import('@/views/TanSite/order'),
    meta:{
      breadTitle: '探角订单管理',
      title: 'uwww-探角订单管理',
      metaName: '订单管理',
      metaInfo : {
        keywords: tanKey,
        description: tanDescription
      }
    }
  },
  {
    path: '/resource-center',
    name: '探角订阅中心',
    component: () => import('@/views/TanSite/resource-center'),
    meta:{
      breadTitle: '探角订阅中心',
      title: 'uwww-探角订阅中心',
      metaName: '订阅中心',
      metaInfo : {
        keywords: tanKey,
        description: tanDescription
      }
    }
  },
  {
    path: '/dynamic-center',
    name: '探角科研动态',
    component: () => import('@/views/TanSite/dynamic'),
    meta:{
      breadTitle: '探角科研动态',
      title: 'uwww-探角科研动态',
      metaName: '科研动态',
      metaInfo : {
        keywords: tanKey,
        description: tanDescription
      }
    }
  },
  {
    path: '/demand-publish',
    name: '探角发布需求',
    component: () => import('@/views/TanSite/demand-publish'),
    meta:{
      breadTitle: '探角发布需求',
      title: 'uwww-探角发布需求',
      metaName: '发布需求',
      metaInfo : {
        keywords: tanKey,
        description: tanDescription
      }
    }
  },
  {
    path: '/demand-management',
    name: '探角需求管理',
    component: () => import('@/views/TanSite/demand'),
    meta:{
      breadTitle: '探角需求管理',
      title: 'uwww-探角需求管理',
      metaName: '需求管理',
      metaInfo : {
        keywords: tanKey,
        description: tanDescription
      }
    }
  },
  {
    path: '/AI',
    name: '探角科创助手',
    component: () => import('@/views/TanSite/AI'),
    meta:{
      breadTitle: '探角科创助手',
      title: 'uwww-探角科创助手',
      metaName: 'AI科创助手',
      metaInfo : {
        keywords: tanKey,
        description: tanDescription
      }
    }
  },
  {
    path: '/c-center',
    name: 'NewCustomCenter',
    component: () => import('@/views/user-center/newCustomCenter'),
    meta:{
      breadTitle: '新个人中心',
      title: 'uwww-个人中心',
      metaInfo : {
        keywords: tanKey,
        description: tanDescription
      }
    }
  },
  {
    path: '/not-found',
    name: '404',
    component: () => import('../views/error-page/404.vue'),
    meta:{
      title: 'uwww-错误页面',
      metaInfo : {
        keywords: tanKey,
        description: tanDescription
      }
    }
  },
  {
    path: '/:catchAll(.*)',
    redirect: to => { // 路径判断，如果含有'.'则进入相应的网页 否则进入404页面
      console.log(to,'hehe');
      if(to.params.catchAll.indexOf('.') !== -1 && !to.params.catchAll.startsWith('https://') && !to.params.catchAll.startsWith('http://')){
        window.location = 'https://' + to.params.catchAll
      }
      else return '/not-found'
    },
    meta:{
      title: 'uwww-数字化科创服务平台',
      metaInfo : {
        keywords: tanKey,
        description: tanDescription
      }
    }
  }
]

export const AIConstantRoutes = [
  {
    path: '/',
    name: 'XiaoKe',
    component: () => import('@/views/AI/home'),
    meta:{
      breadTitle: '科创助手',
      title: 'uwww-一站式数字科创服务平台',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/share',
    name: 'XiaoKeShare',
    component: () => import('@/views/AI/share'),
    meta:{
      breadTitle: '科创助手分享',
      title: 'uwww-一站式数字科创服务平台',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
]

export const asyncRoutes = [
  {
    path: '/vip',
    name: 'Vip',
    component: () => import('../views/vip/vip.vue'),
    meta: {
      title: '会员页面',
      roles: ['vip']
    }
  },

]

export const PostLineConstantRoutes =[
  {
    path: '/',
    name: '科企投条',
    component: () => import('@/views/post-line/home'),
    meta:{
      breadTitle: '科企投条',
      title: 'uwww-科企投条',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/info',
    name: '科企投条-资讯中心',
    component: () => import('@/views/post-line/info'),
    meta:{
      breadTitle: '科企投条-资讯中心',
      title: 'uwww-科企投条-资讯中心',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/not-found',
    name: '404',
    component: () => import('../views/error-page/404.vue'),
    meta:{
      title: 'uwww-错误页面',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  },
  {
    path: '/:catchAll(.*)',
    redirect: to => { // 路径判断，如果含有'.'则进入相应的网页 否则进入404页面
      console.log(to,'hehe');
      if(to.params.catchAll.indexOf('.') !== -1 && !to.params.catchAll.startsWith('https://') && !to.params.catchAll.startsWith('http://')){
        window.location = 'https://' + to.params.catchAll
      }
      else return '/not-found'
    },
    meta:{
      title: 'uwww-数字化科创服务平台',
      metaInfo : {
        keywords: key,
        description: description
      }
    }
  }
]

export const G60site = window.location.href.includes('g60.keqidao.com')

export const TanSite = window.location.href.includes('tanjiao.keqidao.com') // true

export const AISite = window.location.href.includes('aichat.keqidao.com')

export const PostLineSite = false // window.location.href.includes('post.keqidao.com')

let routes = constantRoutes
if(G60site) routes = G60constantRoutes
else if(TanSite) routes = TanConstantRoutes
else if(PostLineSite) routes = PostLineConstantRoutes
else if(AISite) routes = AIConstantRoutes

const router = createRouter({
  base: './',
  mode: 'history', //更改路由方式
  history: createWebHistory(process.env.BASE_URL),
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    //see https://next.router.vuejs.org/guide/advanced/scroll-behavior.html#delaying-the-scroll
    if (savedPosition && !noSavePosition.includes(to.path)) {
      return savedPosition
    } else {
      // let timer = null
      // let step = document.documentElement.scrollTop/30   ; //requestAnimationFrame每秒60帧，我们动画执行0.5s
      // cancelAnimationFrame(timer);
      // timer = requestAnimationFrame(function fn(){
      //     var oTop = document.body.scrollTop || document.documentElement.scrollTop;
      //     if(oTop > 0){
      //         document.body.scrollTop = document.documentElement.scrollTop = oTop - step;    //可以调整数字明确放慢速度20->50,为0时为正常速度
      //         timer = requestAnimationFrame(fn);
      //     }else{
      //         cancelAnimationFrame(timer);
      //     }
      // });
      if(noScrollTop.includes(to.path)) return // 不用回到顶部
      else if(TanNoScrollTop.includes(to.path) && !to.query.resourceKey) return // 不用回到顶部
      return {top: 0}
    }

  },
})


export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

router.onError((error) => { // 路由错误监听
  console.error('router error:', error);
  const pattern = "Unexpected token '<'"
  const isSyntaxError = error.message.indexOf(pattern) !== -1
  if (isSyntaxError) {
    location.reload()
    return
  }
  const pattern1 = /Loading chunk chunk-(.*)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern1);
  if (isChunkLoadFailed) {
    location.reload();
  }
})

export default router
