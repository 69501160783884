<template>
  <el-dialog v-model="show" :show-close="false" :close-on-press-escape="false" :width="showForm ?  '630px' : '900px'" :custom-class="'dialog-no-title'" :close-on-click-modal="false">
    <div class="wrap">
      <div class="achieve-content hide-scroll-bar">
        <p class="title text-ol-ell">{{ typeName }}服务</p>
        <p class="time text-ol-ell">{{ tipWord }}</p>
        <div class="form">
          <div class="left">
            <div class="input-line">
              <p class="p-title">您的姓名：</p>
              <input v-model="form.name" class="input" maxlength="30" />
            </div>
            <div class="input-line">
              <p class="p-title"><span>*</span>联系方式：</p>
              <input v-model="form.contactWay" class="input" maxlength="30" />
            </div>
            <div class="input-line">
              <p class="p-title"><span>*</span>留言内容：</p>
              <textarea v-model="form.context" class="textarea" maxlength="1000" ></textarea>
            </div>
          </div>
          <div class="right">
            <div class="phone-line">
              <img src="@/assets/images/G60/pop/host-icon.png" class="icon" />
              <p class="tip">
                客服电话
              </p>
              <p class="word">400-6265-298</p>
            </div>
          </div>
        </div>
        <div class="button-line">
          <button class="button" @click="submit">提交</button>
        </div>
      </div>
      <img src="@/assets/images/G60/pop/close-button.png" class="close-icon" @click="close" />
    </div>
  </el-dialog>
</template>

<script>
import { ElMessage } from "element-plus";
import { mapGetters } from "vuex";
import { submitG60Window } from "@/api/G60";
import { getG60Id } from "@/utils/G60/login";

export default{
  data(){
    return{
      cur: 0,
      show: true,
      showForm: false,
      form:{
        name: '',
        contactWay: '',
        context: '',
      },
    }
  },
  computed:{
    typeName(){
      return this.askStatus.typeName || '产学研合作'
    },
    tipWord(){
      switch (this.typeName) {
        case '产学研合作':
          return '如果有任何疑问或者合作意愿，请填写一下您的信息，我们会尽快与您取得联系。谢谢！期待与您的合作'
        case '政策申报咨询':
          return '如果您在政策申报过程中遇到任何困惑 或挑战，或是有明确的申报意向，我们诚挚的邀请您填写一下信息，我们会尽快与您取得联系，谢谢！'
        case '知识产权咨询':
          return '如果您在知识产权或商业化过程中遇到任何困惑或挑战，或是有明确的知识产权咨询需求，我们诚挚的邀请您填写一下信息， 我们会尽快与您取得联系，谢谢！'
        case '科技招商咨询':
          return '如果您在科技招商或产业创新过程中遇到难题，或是有明确的科技招商咨询需求，我们诚挚的邀请您填写一下信息， 我们会尽快与您取得联系，谢谢！'
        case '投融资咨询服务':
          return '如果您在投融资决策过程中感到迷茫或需要专业的建议，我们诚挚的邀请您填写一下信息， 我们会尽快与您取得联系，谢谢！'
        default:
          return ''
      }
    },
    ...mapGetters([
      "askStatus",
    ]),
  },
  watch:{
    showForm(val){
      if(!val) this.clearForm()
    },
  },
  methods:{
    submit(){
      if(!this.form.contactWay){
        ElMessage.warning('请输入联系方式')
        return
      }
      else if(!this.form.context){
        ElMessage.warning('请输入留言内容')
        return
      }
      let type = 5
      switch (this.typeName) {
        case '产学研合作':
          type = 5
          break;
        case '政策申报咨询':
          type = 8
          break;
        case '知识产权咨询':
          type = 9
          break;
        case '科技招商咨询':
          type = 6
          break;
        case '投融资咨询服务':
          type = 7
          break
        default:
          break
      }
      const param = {
        "customJson": JSON.stringify({
          "留言内容": this.form.context,
          "咨询类型": this.typeName,
        }),
        "memberId": getG60Id(),
        "name": this.form.name || this.form.contactWay,
        "phone": this.form.contactWay,
        "sourceId": '-1',
        "type": type
      }
      submitG60Window(param).then((res)=>{
        if(res.code === "Sx200"){
          // let name = '产学研合作专家'
          // if(this.typeName === '政策申报咨询') name = '政策申报咨询专家团队'
          // else if(this.typeName === '知识产权咨询') name = '知识产权咨询专家团队'
          this.$store.dispatch('G60/closeAsk')
          this.$store.dispatch('G60/openSuccess',`感谢您对我们平台的关注与支持。_我们的行业专家将在最短时间内与您取得联系，为您提供全方位专业指导和一对一贴心服务。请保持电话畅通，谢谢！`)
        }
      })
    },
    clearForm(){
      this.form = {
        name: '',
        contactWay: '',
        context: '',
      }
    },
    close(){
      this.$store.dispatch('G60/closeAsk')
    },
  }
}
</script>


<style lang="scss" scoped>
.wrap{
  position: relative;
  .close-icon{
    width: 28px;
    height: 28px;
    position: absolute;
    right: 20px;
    top: 30px;
    cursor: pointer;
  }
}
.achieve-content{
  width: 100%;
  max-height: 70vh;
  overflow-x: auto;
  box-sizing: border-box;
  padding: 60px 50px;
  .title{
    font-size: 22px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: #111111;
    line-height: 22px;
    width: 100%;
    margin-bottom: 13px;
  }
  .time{
    font-size: 14px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 14px;
    width: 100%;
    margin-bottom: 34px;
  }
  .form{
    width: 100%;
    padding-bottom: 28px;
    display: flex;
    justify-content: space-between;
    .left{
      .input-line{
        display: flex;
        margin-bottom: 16px;
        .p-title{
          width: 78px;
          text-align: right;
          font-size: 14px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #111111;
          line-height: 38px;
          span{
            color: #F22222;
          }
        }
        .input{
          width: 319px;
          height: 38px;
          border-radius: 4px;
          border: 1px solid #E2E8EF;
          box-sizing: border-box;
          padding: 0 12px;
          font-size: 14px;
          color: #111111;
        }
        .textarea{
          width: 319px;
          height: 119px;
          border-radius: 4px;
          border: 1px solid #E2E8EF;
          box-sizing: border-box;
          padding: 12px;
          font-size: 14px;
          line-height: 20px;
          color: #111111;
          resize: none;
        }
      }
    }
    .right{
      width: 342px;
      height: 228px;
      position: relative;
      background-image: url('https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2024/00/08/170467933956833');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      .phone-line{
        display: flex;
        align-items: center;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 287px;
        height: 47px;
        background: $--G60-theme;
        box-sizing: border-box;
        padding-left: 17px;
        .icon{
          width: 24px;
          height: 24px;
          margin-right: 10px;
        }
        .tip{
          width: 24px;
          height: 28px;
          font-size: 12px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 14px;
          padding-right: 10px;
          position: relative;
          margin-right: 10px;
          &::after{
            content: '';
            width: 1px;
            height: 20px;
            background: #FFFFFF;
            position: absolute;
            right: 0;
            top: 50%;
            margin-top: -10px;
          }
        }
        .word{
          font-size: 20px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 20px;
        }
      }
    }
  }
  .button-line{
    width: 100%;
    display: flex;
    justify-content: center;
    button{
      width: 157px;
      height: 38px;
      background: $--G60-theme;
      border-radius: 4px;
      cursor: pointer;
      border: none;
      font-size: 14px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 14px;
    }
  }
}
</style>
