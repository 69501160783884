import { post } from "@/utils/request";
import { getTanId } from "@/utils/Tan/login";

// 创建单产品订单
export function createTanOrder(param) {
  return post('/order/orderCreateOneProduct', {
    ...param,
    memberId: getTanId(),
    quantity: 1,
    source: "PC-探角",
  })
}

// 获取第三方支付唤醒参数 /pay/invoke
export function getTanPayment(param) {
  return post('/pay/invoke', {
      "memberId": getTanId(),
      ...param,
  })
}

// 修改未支付订单为已关闭
export function closeTanOrder(id) {
  return post('/order/updateStatusClosed', {id})
}

// 根据会员ID分页查询订单 /order/getOrderByMemberId
export function getTanOrderList(param) {
  return post('/order/getOrderByMemberId', {
      "memberId": getTanId(),
      "orderType": 1,
      ...param,
  })
}

// 获取订单支付状态
export function getTanPaymentStatus(orderNo) {
  return post('/order/isOrderDone', {orderNo})
}