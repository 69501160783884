<template>
    <el-dialog v-model="showWindow" :show-close="false" :close-on-press-escape="false" :width="'876px'" :custom-class="'dialog-no-title dialog-transparent'">
      <div class="tan-login-window" v-loading="loading">
        <div class="left">
        </div>
        <div class="right">
          <img src="@/assets/images/login/Tan/title.png" style="width: 100px;height: 58px;" />
          <p class="tip">欢迎使用探角！</p>
          <div class="position-r">
            <input v-model="phone" oninput="value=value.replace(/[^\d]/g,'')" maxlength="11" class="phone" placeholder="请输入手机号"/>
            <img src="@/assets/images/login/Tan/clear.png" style="width: 14px;height: 14px;" class="clear-icon" v-if="phone" @click="phone=''" />
          </div>
          <div class="position-r">
            <input v-model="code" oninput="value=value.replace(/[^\d]/g,'')" maxlength="6" class="code" placeholder="请输入验证码" @keydown.enter="commitLogin()"/>
            <button @click="getCode()" class="code-button" :disabled="!showCode">
              <span v-if="showCode && !sendFlag">验证码</span>
              <span v-else-if="!showCode">{{ count }}秒重新发送</span>
              <span v-else-if="showCode">重新发送</span>
            </button>
          </div>
          <div class="protocols">
            登录即表示已阅读并同意
            <a class="protocol-name" target="_blank" href="https://www.keqidao.com/yhxy">《科企岛用户协议》</a>
            、
            <a class="protocol-name" target="_blank" href="https://www.keqidao.com/yszc">《科企岛隐私政策》</a>
            及
            <span>
              <a class="protocol-name" target="_blank" href="https://www.keqidao.com/nrsm">《科企岛内容声明》</a>。未注册的手机号或第三方帐号验证后将自动创建新帐号
            </span>
          </div>
          <button @click="commitLogin()" class="login-button">登录/注册</button>
        </div>
      </div>
    </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { TanLogin } from "@/utils/Tan/login";
import { ElMessage } from "element-plus";
import { getPhoneCode } from '@/api/user'
import { validatePhone } from '@/utils/common/validate';
import { setCookie } from "@/utils/Cookies/index";
import { getTanId,getTanToken,getTanRefreshToken } from "@/utils/Tan/login";
import { getTanMemberInfo } from "@/api/Tan/member";

export default{
  data(){
    return{
      machineCode: '',
      code: '',
      phone: '',
      showWindow: false,
      sendFlag: false,
      showCode: true,
      count: 60,
      timer: null,
      loading: false,
    }
  },
  computed:{
    ...mapGetters([
      "loginStatus",
    ]),
    show(){
      return this.loginStatus.show
    },
  },
  watch:{
    show(val){
      if(val) this.showWindow = val
    },
    showWindow(val){
      if(!val){
        this.$store.dispatch('Tan/setLoginShow',false)
        this.phone = ''
        this.code = ''
        if(this.timer){
          this.showCode = true;
          clearInterval(this.timer);
          this.timer = null;
        }
      }
    },
  },
  methods:{
    commitLogin(){
      if(!this.phone){
        ElMessage.warning('请输入手机号')
        return
      }
      else if(!this.code){
        ElMessage.warning('请输入验证码')
        return
      }
      this.loading = true
      TanLogin({
        machineCode: this.machineCode,
        phone: this.phone,
        code: this.code,
      }).then(()=>{
        setCookie('KqdInfo',{
          status: '1',
          accessToken: getTanToken(),
          memberInfo: {
            id: getTanId(),
          },
          refreshToken: getTanRefreshToken(),
        })
        ElMessage.success('登陆成功')
        this.showWindow = false
        this.$store.dispatch('Tan/setLoginChange','login')
        getTanMemberInfo(getTanId()).then(res=>{
          if(!res.data?.tjCompanyId){
            // 未创建探角
            this.$router.push('/identity')
          }
          else{
            this.$router.push('/p-center')
          }
        })
      }).finally(()=>{
        this.loading = false
      })
    },
    getCode() { // 验证码倒计时60s
      if(!this.showCode) return
      if(!this.phone){
        ElMessage.warning('请输入手机号')
        return
      }
      else if(!validatePhone(this.phone)){
        ElMessage.warning('请输入正确格式的手机号')
        return
      }
      const params = {
        phone: this.phone
      }
      if(!this.sendFlag) this.sendFlag = true
      const TIME_COUNT = 60
      getPhoneCode(params).then((res) => {
        if (res.code === 'Sx200') {
          ElMessage.success('验证码已发送!')
          if (!this.timer) {
            this.count = TIME_COUNT;
            this.showCode = false;
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count--;
              } else {
                this.showCode = true;
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000);
          }
        }
      });
    },
  },
}
</script>

<style lang="scss" scoped>
.tan-login-window{
  display: flex;
  align-items: center;
  height: 478px;
  background-color: #ffffff;
  border-radius: 10px;
  .left{
    width: 395px;
    height: 100%;
    box-sizing: border-box;
    background-image: url('https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2024/07/15/1723704305570876');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding-top: 85px;
    padding-left: 40px;
    .tip{
      margin-left: 11px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #999999;
      line-height: 14px;
      margin-top: 14px;
      margin-bottom: 44px;
    }
  }
  .right{
    width: 481px;
    box-sizing: border-box;
    padding: 33px 53px 0;
    height: 100%;
    .tip{
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 24px;
      color: #111111;
      line-height: 33px;
      margin-top: 31px;
      margin-bottom: 31px;
    }
    .phone{
      width: 377px;
      height: 40px;
      border-radius: 4px;
      border: 1px solid #DDE2E9;
      line-height: 40px;
      box-sizing: border-box;
      padding-right: 45px;
      box-sizing: border-box;
      padding-left: 11px;
      margin-bottom: 22px;
    }
    .clear-icon{
      position: absolute;
      right: 14px;
      top: 50%;
      margin-top: -17px;
      cursor: pointer;
    }
    .code-button{
      border: none;
      position: absolute;
      right: 22px;
      height: 38px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      top: 1px;
      color: #167CFF;
      cursor: pointer;
      background-color: #ffffff;
    }
    .code{
      width: 377px;
      height: 40px;
      border-radius: 4px;
      border: 1px solid #DDE2E9;
      line-height: 40px;
      box-sizing: border-box;
      padding-left: 11px;
      margin-bottom: 22px;
    }
    .login-button{
      margin-top: 30px;
      width: 378px;
      height: 40px;
      background: #167CFF;
      border-radius: 4px;
      border: none;
      cursor: pointer;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #FFFFFF;
      line-height: 14px;
    }
    .protocols {
      box-sizing: border-box;
      width: 377px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      line-height: 18px;
      font-size: 12px;
      font-family: Source Han Sans SC;
      font-weight: 400;
      color: #B0B5C0;
      float:left;
      text-align: justify;
      word-break: break-all;
      a{
        color: $--color-theme;
        &:visited{
          color: $--color-theme !important;
        }
      }
    }
    .protocol-name {
      color: #B0B5C0;
      word-break: break-all;
    }
  }
}
</style>