<template>
  <div v-loading="loading" class="group">
    <div v-if="!isSecond">
      <div class="members">
        <div v-for="member in showMemberList" :key="member.userID" class="member">
          <img :src="member.avatar && !member.avatar.includes('null') ? member.avatar : defaultAvatar" class="avatar" @click="toConversation(member.userID)" />
          <p class="name text-ol-ell">{{ member.nick }}</p>
        </div>
        <div v-if="isOwner" class="member">
          <img src="@/assets/images/im/group/add.png" class="avatar" @click="isAdd = true" />
          <p class="name text-ol-ell" style="width: 1px;"></p>
        </div>
        <div v-if="isOwner" class="member">
          <img src="@/assets/images/im/group/remove.png" class="avatar" @click="isRemove = true" />
          <p class="name text-ol-ell" style="width: 1px;"></p>
        </div>
      </div>
      <div v-if="notAllMember">
        <p class="all-button" @click="showAll = true" v-if="!showAll">查看全部</p>
        <p class="all-button" @click="hideAllGroup" v-else>收起</p>
      </div>
      <div v-if="showBusinessWindow">
        <BusinessWindow @changeWindow="changeWindow"></BusinessWindow>
      </div>
      <div class="options">
        <div class="option">
          <p class="name">群名称</p>
          <p class="value text-ol-ell" :class="{'forbid': !isOwner}" @click="editName = true">{{ groupInfo.name }}</p>
        </div>
        <div class="option">
          <p class="name">群头像</p>
          <Image :src="groupInfo.avatar || 'http://test-1304545655.cos.ap-shanghai.myqcloud.com/defaultlogo.png'" class="value-avatar" :imgId="gId" :class="{'forbid': !isOwner}" @click="editImg = true" />
        </div>
        <div class="option">
          <p class="name">群公告</p>
          <p class="value text-ol-ell" :class="{'forbid': !isOwner}" @click="editNotification = true">{{ groupInfo.notification || '未设置' }}</p>
        </div>
        <div class="option">
          <p class="name">投诉</p>
          <div class="value text-ol-ell">
            <el-dropdown @command="handleComplainCommand">
              <span class="el-dropdown-link">
                原因<el-icon class="el-icon-arrow-down"></el-icon>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item :command="index" class="reason-item" v-for="(reason,index) in reasonTypeList" :key="reason.id">{{ reason.val }}</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </div>
        <el-dialog v-model="editImg" title="修改群头像" width="30%">
          <EditGroupAvatar :default="groupInfo.avatar" ref="EditGroupAvatar" @uploadGroupImage="uploadGroupImage"></EditGroupAvatar>
          <div class="dialog-footer">
            <el-button @click="editImg = false">取消</el-button>
            <el-button type="primary" @click="ownerEditImg">确认</el-button>
          </div>
        </el-dialog>
        <el-dialog v-model="editNotification" title="修改群公告" width="30%">
          <input v-model="newName" maxlength="300" class="dialog-input"/>
          <div class="dialog-footer">
            <el-button @click="editNotification = false">取消</el-button>
            <el-button type="primary" @click="ownerEditNotification">
              确认
            </el-button>
          </div>
        </el-dialog>
        <el-dialog v-model="editName" title="修改群名称" width="30%">
          <input v-model="newName" maxlength="50" class="dialog-input"/>
          <div class="dialog-footer">
            <el-button @click="editName = false">取消</el-button>
            <el-button type="primary" @click="ownerEditName">
              确认
            </el-button>
          </div>
        </el-dialog>
      </div>
      <div class="exit">
        <button @click="exitGroup">退出群聊</button>
      </div>
    </div>
    <div v-else style="height: 100%;">
      <div class="back" v-if="!chooseMember">
        <el-icon @click="isAdd = false;isRemove = false" class="el-icon-arrow-left icon"></el-icon>
        <p class="confirm" @click="confirm()">确定</p>
      </div>
      <div class="all-list" v-if="!chooseMember">
        <div class="list-member" v-for="(member,index) in vipMemberList" :key="member.memberId" @click="clickMember(member,index)">
          <Image :src="member.memberImg" :imgId="member.memberId" class="avatar"></Image>
          <p class="name text-ol-ell">{{ member.memberName }}</p>
          <div class="check">
            <el-icon class="el-icon-check" v-if="member.check"></el-icon>
          </div>
        </div>
      </div>
      <div class="all-list" v-else>
        <div class="list-member" v-for="(member,index) in memberList" :key="member.memberId" @click="clickMember(member,index)">
          <Image :src="member.avatar && !member.avatar.includes('null') ? member.avatar : defaultAvatar" :imgId="member.memberId" class="avatar"></Image>
          <p class="name text-ol-ell">{{ member.nick }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { tim } from '@/config/WebTIMConfig'
import { getLocalUserId } from "@/utils/tool";
import { staticPath } from "@/utils/consts";
import { businessVipList } from "@/api/search";
import { ElMessage,ElMessageBox } from "element-plus"
import EditGroupAvatar from '@/components/im/components/EditGroupAvatar'
import { getOptionsByType,reasonType } from "@/api/config";
import { complainUser,judgeBusinessVip } from "@/api/user";
import BusinessWindow from './BusinessWindow';

export default{
  props: ['gId','chooseMember'],
  emits: ['closeGroupTool','insertAtMemberName'],
  components:{
    EditGroupAvatar,
    BusinessWindow
  },
  data(){
    return{
      newName: '',
      newNotification: '',
      editNotification: false,
      editName: false,
      editImg: false,
      infoLoading: false,
      memberLoading: false,
      editLoading: false,
      showBusinessWindow: false,
      isOwner: false,
      offset: 0,
      showAll: false,
      isAdd: false,
      isRemove: false,
      groupInfo: {},
      memberList: [],
      vipMemberList: [],
      reasonTypeList: [],
    }
  },
  computed:{
    notAllMember(){
      if(this.isOwner){
        return this.groupInfo.memberCount > 16
      }
      else return this.groupInfo.memberCount > 18
    },
    showMemberList(){
      return this.showAll ? this.memberList : this.isOwner ? this.memberList.slice(0,16) : this.memberList.slice(0,18)
    },
    isSecond(){
      return this.isAdd || this.isRemove
    },
    defaultAvatar(){
      return 'http://test-1304545655.cos.ap-shanghai.myqcloud.com/defaultlogo.png'
    },
    loading(){
      return this.infoLoading || this.memberLoading || this.editLoading
    }
  },
  watch:{
    isSecond(val){
      if(!val){
        this.getGroupMembers()
        this.showAll = false
      }
      else this.getBusinessList()
    }
  },
  mounted(){
    if(this.chooseMember) {
      // 是否选择群租消息
      this.isSecond = true
      this.isRemove = true
    }
    this.getGroupInfo()
    this.getReasonType()
  },
  methods:{
    clickMember(member,index){
      // 选择点击事件
      if(this.chooseMember){
        this.$emit('insertAtMemberName',{
          name: member.nick,
          id: member.userID,
        })
      }
      else{
        this.vipMemberList[index].check = !this.vipMemberList[index].check
      }
    },
    sortMemberList(){
      this.memberList.sort((a,b)=>a.joinTime - b.joinTime)
      console.log(this.memberList,'hehe22222222');
    },
    changeWindow(val){
      this.showBusinessWindow = val
    },
    getReasonType() {
      // 获取投诉原因列
      getOptionsByType(reasonType).then(res=>{
        this.reasonTypeList = res.data.result
      })
    },
    handleComplainCommand(val){
      ElMessageBox.confirm(
        `是否因${this.reasonTypeList[val].val}投诉此群`,'确认窗口',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          lockScroll: false,  // 防止页面振动
          type: 'warning',
        }
      ).then(()=>{
        this.editLoading = true
        complainUser({
          "complaintMemberId": this.gId + '',
          "memberId": getLocalUserId() + '',
          "reasonId": this.reasonTypeList[val].id
        }).then((res)=>{
          if(res.code === 'Sx200'){
            ElMessage.success({
              message: '投诉成功',
              type: 'success',
            });
          }
        }).catch(err=>{
          console.log('投诉失败：',err.message);
        }).finally(()=>{
          this.editLoading = false
        })
      })
    },
    uploadGroupImage(url){
      this.editLoading = true
      tim.updateGroupProfile({
        groupID: this.gId,
        avatar: staticPath + url, // 修改群公告
      }).then(()=>{
        ElMessage.success('修改成功!')
      }).catch(err=>{
        ElMessage.error(err.message)
      }).finally(()=>{
        this.groupInfo.avatar = staticPath + url
        this.editImg = false
        this.editLoading = false
      })
    },
    ownerEditImg(){
      if(!this.$refs.EditGroupAvatar) return
      this.$refs.EditGroupAvatar.getImage()
    },
    exitGroup(){
      if(this.isOwner){
        ElMessage.error('您是群主，不可退出该群!')
        return
      }
      else if(!this.gId) return
      ElMessageBox.confirm(
        '是否确认退出此群','确认窗口',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          lockScroll: false,  // 防止页面振动
          type: 'warning',
        }
      ).then(() => {
        this.editLoading = true
        tim.quitGroup(this.gId).then(()=>{
          ElMessage.success('退出成功')
        }).catch(err=>{
          console.log('退出群组错误:',err);
        }).finally(()=>{
          this.editLoading = false
        })
      })
    },
    ownerEditNotification(){
      if(!this.newNotification){
        ElMessage.warning('请输入新的群公告!')
        return
      }
      else if(!this.gId) return
      this.editLoading = true
      tim.updateGroupProfile({
        groupID: this.gId,
        notification: this.newNotification, // 修改群公告
      }).then(()=>{
        ElMessage.success('修改成功!')
      }).catch(err=>{
        ElMessage.error(err.message)
      }).finally(()=>{
        this.groupInfo.notification = this.newNotification
        this.newNotification = ''
        this.editNotification = false
        this.editLoading = false
      })
    },
    ownerEditName(){
      if(!this.newName){
        ElMessage.warning('请输入新的群名称!')
        return
      }
      else if(!this.gId) return
      this.editLoading = true
      tim.updateGroupProfile({
        groupID: this.gId,
        name: this.newName, // 修改群名称
      }).then(()=>{
        ElMessage.success('修改成功!')
      }).catch(err=>{
        ElMessage.error(err.message)
      }).finally(()=>{
        this.groupInfo.name = this.newName
        this.newName = ''
        this.editName = false
        this.editLoading = false
      })
    },
    confirm(){
      const num = this.vipMemberList.filter(item=>item.check).length
      if(num === 0){
        ElMessage.warning(`请选择${this.isAdd ? '要邀请': '要删除'}的用户！`)
        return
      }
      else if(!this.gId) return
      if(this.isAdd){
        let arr = []
        this.vipMemberList.filter(item=>item.check).forEach((item)=>{
          arr.push(item.memberId + '')
        })
        this.editLoading = true
        tim.addGroupMember({
          groupID: this.gId,
          userIDList: arr,
        }).then(()=>{
          ElMessage.success('邀请成功!')
        }).catch(err=>{
          console.log('添加群用户错误：',err);
        }).finally(()=>{
          this.editLoading = false
          this.getGroupMembers(1)
        })
      }
      else{
        let arr = []
        this.vipMemberList.filter(item=>item.check).forEach((item)=>{
          arr.push(item.memberId + '')
        })
        this.editLoading = true
        tim.deleteGroupMember({groupID: this.gId, userIDList: arr,}).then(()=>{
          ElMessage.success('删除成功!')
        }).catch(err=>{
          console.log('删除群用户错误：',err);
        }).finally(()=>{
          this.editLoading = false
          this.getGroupMembers(1)
        })
      }
    },
    getBusinessList(){
      this.memberLoading = true
      this.vipMemberList = []
      businessVipList({
        memberId: getLocalUserId() + '',
        limit: 10000,
        offset: 1,
        vipType: 'businessSchoolTime',
      }).then(res=>{
        res.data.result.forEach((item)=>{
          let arr = []
          if(this.isAdd){
            arr = item.memberList.filter(member=> !this.memberList.find(jtem=>jtem.userID === member.memberId + ''))
          }
          else{
            arr = item.memberList.filter(member=> this.memberList.find(jtem=>jtem.userID === member.memberId + ''))

          }
          this.vipMemberList = [...this.vipMemberList,...arr].map((item)=>{
            item.check = false
            return item
          })
        })
      }).finally(()=>{
        this.memberLoading = false
      })
    },
    toConversation(id){
      if(!id) return
      else if(id + '' === getLocalUserId() + ''){
        ElMessage.warning('您不能与自己交流')
        return
      }
      this.editLoading = true
      judgeBusinessVip({
        memberId: getLocalUserId() + ''
      }).then(res=>{
        if(!res.data){
          this.showBusinessWindow = true
        }
        else{
          this.$emit('closeGroupTool')
          this.$store.dispatch("message/startCommunication", {id: id,serverId:'',title:'',quickChat:false})  // 在线联系不需要需求id、标题及承接需求quickChat
        }
      }).catch(err=>{
        console.log('获取商学院身份错误：',err.message);
      }).finally(()=>{
        this.editLoading = false
      })
    },
    hideAllGroup(){
      this.showAll = false
    },
    getAllGroup(type=-1){
      if(!this.gId) return
      this.memberLoading = true
      tim.getGroupMemberList({ groupID: this.gId, count: 100, offset: 0 }).then((res)=>{
        this.memberList = res.data.memberList
        if(this.groupInfo?.memberCount > 100){
          tim.getGroupMemberList({ groupID: this.gId, count: 100, offset: 0 }).then((res)=>{
            this.memberList = [...this.memberList,...res.data.memberList]
            this.sortMemberList()
            this.memberLoading = false
          }).catch((err) => {
            console.log('获取群成员错误：',err);
            this.memberLoading = false
          })
        }
        else {
          this.sortMemberList()
          this.memberLoading = false
        }
      }).catch((err) => {
        console.log('获取群成员错误：',err);
        this.memberLoading = false
      }).finally(()=>{
        this.memberLoading = false
        if(type === 1) this.getBusinessList()
      })
    },
    getGroupMembers(type=-1){
      if(!this.gId) return
      this.memberLoading = true
      this.getAllGroup(type)
    },
    getGroupInfo(){
      if(!this.gId) return
      this.infoLoading = true
      tim.getGroupProfile({ groupID: this.gId, }).then((res) => {
        this.isOwner = getLocalUserId() + '' === res?.data?.group?.ownerID
        this.groupInfo = res?.data?.group
      }).catch((err) => {
        console.log('获取群信息错误：',err);
      }).finally(()=>{
        this.infoLoading = false
        this.getGroupMembers()
      })
    }
  }
}
</script>


<style lang="scss" scoped>
.group{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding-top: 20px;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  .back{
    font-size: 18px;
    color: #666666;
    width: 300px;
    box-sizing: border-box;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    .confirm{
      font-size: 18px;
      line-height: 18px;
      cursor: pointer;
      color: #333333;
      &:hover{
        color: $--color-theme;
      }
    }
    .icon{
      cursor: pointer;
      &:hover{
        color: $--color-theme;
      }
    }
  }
  .all-list{
    height: calc(100% - 38px);
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
  }
  .list-member{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    &:hover{
      .name{
        color: $--color-theme;
      }
    }
    .avatar{
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 20px;
    }
    .name{
      width: 180px;
      margin-right: 20px;
      font-size: 16px;
      color: #111111;
      line-height: 20px;
    }
    .check{
      width: 20px;
      height: 20px;
      font-size: 20px;
      line-height: 20px;
      color: $--color-theme;
    }
  }
  .all-button{
    width: 100%;
    padding: 10px;
    text-align: center;
    box-sizing: border-box;
    cursor: pointer;
    color: #666666;
    font-size: 16px;
    &:hover{
      color: $--color-theme;
    }
  }
  .options{
    width: 100%;
    box-sizing: border-box;
    padding: 0 10px;
    .option{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 0;
      .name{
        font-size: 16px;
        color: #111111;
      }
      .forbid{
        cursor: not-allowed;
        &:hover{
          color: #666666 !important;
        }
      }
      .value-avatar{
        height: 20px;
        width: 20px;
        border-radius: 50%;
        overflow: hidden;
        cursor: pointer;
      }
      .value{
        width: 200px;
        font-size: 16px;
        color: #666666;
        cursor: pointer;
        text-align: right;
        &:hover{
          color: $--color-theme;
        }
      }
    }
  }
  .exit{
    width: 100%;
    margin-top: 20px;
    button{
      width: 100%;
      cursor: pointer;
      border: none;
      background-color: #ffffff;
      color: #ff4443;
      font-size: 16px;
    }
  }
  .members{
    width: 300px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    .member{
      padding: 5px;
      width: 65px;
      .avatar{
        width: 65px;
        height: 65px;
        border-radius: 50%;
        overflow: hidden;
        margin-bottom: 10px;
        cursor: pointer;
      }
      .name{
        height: 14px;
        font-size: 14px;
        line-height: 14px;
        color: #111111;
        text-align: center;
        width: 100%;
      }
    }
  }
}
.dialog-input{
  width: 100%;
  height: 30px;
  font-size: 20px;
  color: #111111;
  margin-bottom: 20px;
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
}
.dialog-footer button:first-child {
  margin-right: 10px;
  text-align: right;
}
.dialog-footer{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.reason-item{
  cursor: pointer;
  padding: 5px 20px;
  text-align: center;
}
</style>