<template>
  <div class="seize">
  </div>
  <div class="navbar">
    <div class="center">
      <div class="left">
        <a href="/post-line" target="_blank">
          <img class="logo-0" src="@/assets/images/post-line/Navbar/post-logo.png" alt="科企投条" title="科企投条" />
        </a>
        <a href="/post-line" target="_blank" title="首页" class="a" :class="{'a-active': routePath === '/post-line'}">首页</a>
        <a href="/" target="_blank" title="科企岛官网" class="a" :class="{'a-active': routePath === '/'}">科企岛官网</a>
        <a href="/post-line-about" target="_blank" title="关于科企投条" class="a" :class="{'a-active': routePath === '/post-line-about'}">关于科企投条</a>
        <a href="/post-line-opinion" target="_blank" title="意见反馈" class="a" :class="{'a-active': routePath === '/post-line-opinion'}">意见反馈</a>
      </div>
      <div class="right">
        <div class="icon-part">
          <img class="icon-1" src="@/assets/images/post-line/Navbar/post.png" alt="投稿发布" title="投稿发布" />
          <p class="p">投稿发布</p>
        </div>
        <div class="icon-part">
          <img class="icon-2" src="@/assets/images/post-line/Navbar/bell.png" alt="消息" title="消息" />
          <p class="p">消息</p>
        </div>
        <button v-if="!loginStatus" class="login-button">登录/注册</button>
      </div>
    </div>
  </div>
</template>

<script>
import { getG60LocalInfo } from "@/utils/G60/login";
import { mapGetters } from "vuex";

export default{
  data(){
    return{
      info: {},
    }
  },
  watch:{
    visibility(val){
      if(val) this.setInfo()
    },
    reloadUser(){
      this.setInfo()
    },
    $route(){
      this.setInfo()
    },
  },
  computed:{
    routePath(){
      return this.$route.path
    },
    loginStatus(){
      return this.info?.id
    },
    ...mapGetters([
      "visibility",
      "reloadUser",
    ]),
  },
  mounted(){
    this.setInfo()
  },
  methods:{
    setInfo(){
      this.info = getG60LocalInfo()?.memberInfo || {}
    },
  },
}
</script>

<style lang="scss" scoped>
.seize {
  width: 100%;
  height: 60px;
}

.navbar {
  height: 60px;
  width: 100%;
  min-width: 1200px;
  background: #FFFFFF;
  position: fixed;
  top: 0;
  z-index: 2001;
  display: flex;
  justify-content: center;
  align-items: center;
  .center{
    width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left{
      display: flex;
      align-items: center;
      .logo-0{
        width: 135px;
        height: 35px;
        margin-right: 8px;
      }
      .logo-1{
        width: 119px;
        height: 38px;
        margin-right: 34px;
      }
      .a{
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #111111;
        line-height: 20px;
        margin: 0 20px;
        &:hover{
          color: $--color-theme !important;
        }
        &:visited{
          color: #111111;
        }
      }
      .a-active{
        color: $--color-theme !important;
        &:visited{
          color: $--color-theme;
        }
      }
    }
    .right{
      display: flex;
      align-items: center;
      .icon-part{
        display: flex;
        align-items: center;
        cursor: pointer;
        .p:hover{
          color: $--color-theme;
        }
        .icon-1{
          width: 18px;
          height: 18px;
          margin-right: 1px;
        }
        .icon-2{
          width: 16px;
          height: 18px;
          margin-right: 3px;
        }
      }
      .p{
        margin-right: 30px;
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #111111;
        line-height: 14px;
      }
      .login-button{
        width: 84px;
        height: 28px;
        background: $--color-theme;
        border-radius: 4px;
        border: none;
        cursor: pointer;
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 14px;
        margin-left: 10px;
        text-align: center;
      }
      .center{
        width: 101px;
        height: 28px;
        border-radius: 4px;
        border: 1px solid #111111;
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #111111;
        line-height: 14px;
        cursor: pointer;
        margin-left: 10px;
        background-color: #ffffff;
        text-align: center;
        box-sizing: border-box;
        padding-left: 8px;
        &:hover{
          color: $--color-theme;
          border: 1px solid $--color-theme;
        }
      }
    }
  }
}
</style>