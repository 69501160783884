<template>
    <!-- :class="{'default-back': isHeadImg}" -->
    <img class="image-container" :src="(imgFinishLoad ? imgSrcSplit(isExpert ? 'https://images.weserv.nl/?url=' + src : src,randomDefault) : randomDefault) + (src?.indexOf('http') === -1 || !src ? `?imageMogr2/thumbnail/!${proportion}p` : '')" :alt="alt" srcset="" :onerror="imgError" :onload="imgLoad" v-if="!noSkeleton && !isError" />
    <img class="image-container" :src="imgSrcSplit(isExpert ? 'https://images.weserv.nl/?url=' + src : src,randomDefault) + (src?.indexOf('http') === -1 || !src ? `?imageMogr2/thumbnail/!${proportion}p` : '')" :alt="alt" srcset="" :onerror="imgError" v-else-if="!isError" />
    <img class="image-container" :src="(randomDefault || errorImg) + `?imageMogr2/thumbnail/!${proportion}p`" v-else />
</template>

<script>
import { imgSrcSplit } from "@/utils/tool"
export default {
    props:{
        proportion:{    // * 缩放百分比 默认 100%
            default: 100,
        },
        imgId: {
            default: '0',
        },
        src:{    // 展现的图片
            default: undefined
        },
        alt:{   // alt文字
            default: ""
        },
        noSkeleton:{    // 无需加载占位
            default: false,
        },
        isHeadImg:{
            default: false,
        },
        isExpert:{  // 是否专家
            default: false
        },
        defaultImg:{    // 默认图片
            default: require('@/assets/images/square-avatar.png')
        },
        errorImg:{      // 错误展示图片
            default: require('@/assets/images/square-avatar.png')
        },
    },
    computed:{
        randomDefault(){
            // imgId
            if(!this.imgId || this.isHeadImg) return this.defaultImg
            const lastChar = (this.imgId + '').slice(-1)
            switch (lastChar) {
                case '0':
                    return 'https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2023/06/25/1690263171118518'
                case '1':
                    return 'https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2023/06/25/1690263171751177'
                case '2':
                    return 'https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2023/06/25/1690263172582903'
                case '3':
                    return 'https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2023/06/25/1690263172612130'
                case '4':
                    return 'https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2023/06/25/1690263173900455'
                case '5':
                    return 'https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2023/06/25/1690263173685312'
                case '6':
                    return 'https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2023/06/25/1690263173951075'
                case '7':
                    return 'https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2023/06/25/1690263174212418'
                case '8':
                    return 'https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2023/06/25/1690263174172672'
                case '9':
                    return 'https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2023/06/25/1690263174890804'
                default:
                    return 'https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2023/06/25/1690263172582903'
            }
        }
    },
    methods:{
        imgSrcSplit,
        imgError(){ // 加载错误
            this.isError = true
        },
        imgLoad(){ // 加载成功
            this.imgFinishLoad = true
        },
    },
    data(){
        return{
            isError: false,
            imgFinishLoad: false,   // 是否加载完成
        }
    },
    watch:{
        src(val){
            if(val){
                this.isError = false
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.default-back{
    background-image: url('https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/defaultlogo.png');
    background-size: 100% 100%;
}
.image-container{
    width: 100%;
    height: 100%;
    object-fit: cover !important;
}
</style>