<template>
  <div class="wrapDialog">
    <div class="iconChatBox">
      <div class="img flex-dc-rc">
        <div class="vip-window" @click.stop>
          <img src="@/assets/images/pop/vip/vip-new-button.png" @click="goVip"/>
        </div>
        <img class="close-btn" src="@/assets/images/pop/publish/publish-pop-close.png" @click="closeWindow"/>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
    closeWindow(){
      this.$store.dispatch('G60/closeVip')
    },
    goVip () {
      this.$router.push({
        path: '/vip',
        query:{
        }
      })
    }
  }
}
</script>


<style scoped lang="scss">
.wrapDialog {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background: rgba(0,0,0,0.5);
  height: 100vh;
  z-index: 3000;
}
.iconChatBox {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  z-index: 999;
  position: absolute;
  top: 0%;
  overflow: auto;
  .img {
    padding-top: 100px;
    .business-window{
      width: 292px;
      height: 322px;
      background-image: url('../../../assets/images/business-vip/business-top.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      position: relative;
      .right-top-close{
        width: 30px;
        height: 28px;
        position: absolute;
        top: 10px;
        right: 12px;
        cursor: pointer;
      }
      .bottom-go-vip{
        width: 250px;
        height: 48px;
        position: absolute;
        bottom: 25px;
        left: 50%;
        margin-left: -125px;
        cursor: pointer;
      }
    }
    .vip-window{
      width: 304px;
      height: 313px;
      background-image: url('../../../assets/images/pop/vip/vip-new.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      position: relative;
      img{
        position: absolute;
        bottom: 20px;
        width: 198px;
        height: 44px;
        cursor: pointer;
        left: 50%;
        margin-left: -99px;
      }
    }
    .close-btn{
      color: #ffffff;
      font-size: 50px;
      cursor: pointer;
      display: grid;
      text-align: center;
      margin-top: 36px;
      width: 31px;
      height: 31px;
    }
  }
}
</style>