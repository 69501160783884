<template>
  <div class="video-container" v-if="_if">
    <video width='100%' :controls="controls" :autoplay="autoplay" :title="title" :poster="poster" class="video" :style="_style">
      <source :src='videoSourceSplit(src)'>
    </video>
  </div>
</template>

<script>
import { videoSourceSplit } from "@/utils/tool";
export default {
  props: {
    _if: {
      default: false,
    },
    autoplay: {
      default: false,
    },
    controls: {
      default: false
    },
    title:{
      default:'',
    },
    src:{
      default: '',
    },
    poster:{
      default: ''
    },
    objectFit:{
      default: 'cover',
    },
    backColor:{
      default: '#000000',
    },
  },
  methods: {
    videoSourceSplit,
  },
  computed:{
    _style(){
      return this._objectFit + this._backColor
    },
    _objectFit(){
      return `object-fit: ${this.objectFit};`
    },
    _backColor(){
      return `background-color: ${this.backColor};`
    },
  }
}
</script>

<style lang="scss" scoped>
.video-container{
  width: 100%;
  height: 100%;
  .video{
    width: 100%;
    height: 100%;
  }
}
</style>