import { requestVersion } from "@/utils/consts";
import { login } from '@/api/user'


export const TPSInfoKey = 'TPSInfo'

export const getLocalInfo = () => {
  return localStorage.getItem(TPSInfoKey) ? JSON.parse(localStorage.getItem(TPSInfoKey)) : null
}

export const setLocalInfo = (info) => {
  localStorage.setItem(TPSInfoKey,JSON.stringify(info))
}

export const removeLocalInfo = () => {
  localStorage.removeItem(TPSInfoKey)
}
export const getTPSId = () => {
  return getLocalInfo()?.memberInfo?.id
}

export const getTPSToken = () => {
  return getLocalInfo()?.accessToken
}

export const getRefreshToken = () => {
  return getLocalInfo()?.refreshToken
}

export const setNewToken = (token) => {
  if(!getLocalInfo()) return
  const info = getLocalInfo()
  info.accessToken = token
  setLocalInfo(info)
}

export const TPSLogin = (info)=>{
  const params = {
    machineCode: info.machineCode || '',
    version: requestVersion,
    canal: 1,
    type: 13,
    password: info.code,
    phone: info.phone,
  }
  return new Promise((resolve, reject) => {
    login(params).then(res => {
      setLocalInfo(res.data)
      resolve()
    }).catch(err => {
      reject(err)
    })
  })
}