import { getG60LocalInfo } from "@/utils/G60/login";
import router from '@/router'
import { ElMessage } from "element-plus";


const state = {
  achieveStatus:{
    show: false,
    id: '',
  },
  demandStatus:{
    show: false,
    id: '',
  },
  corStatus:{
    show: false,
    id: '',
  },
  askStatus:{
    show: false,
    typeName: '',
  },
  successStatus:{
    show: false,
    word: '',
  },
  expertStatus:{
    show: false,
    id: '',
  },
  questionStatus:{
    show: false,
  },
  vipStatus:{
    show: false,
  },
  vipFormStatus:{
    show: false,
  },
  tableStatus:{
    show: false,
    type: 0,
  }
}

const mutations = {
  SET_ACHIEVE: (state, status) => {
    state.achieveStatus = {
      show: status.show,
      id: status.id
    }
  },
  SET_DEMAND: (state, status) => {
    state.demandStatus = {
      show: status.show,
      id: status.id
    }
  },
  SET_COR: (state, status) => {
    state.corStatus = {
      show: status.show,
      id: status.id
    }
  },
  SET_ASK:(state,status)=>{
    state.askStatus = {
      show: status.show,
      typeName: status.typeName,
    }
  },
  SET_SUCCESS:(state,status)=>{
    state.successStatus = {
      show: status.show,
      word: status.word
    }
  },
  SET_QUESTION:(state,status)=>{
    state.questionStatus = {
      show: status.show,
    }
  },
  SET_EXPERT:(state,status)=>{
    state.expertStatus = {
      show: status.show,
      id: status.id
    }
  },
  SET_VIP:(state,status)=>{
    state.vipStatus = {
      show: status.show,
    }
  },
  SET_VIP_FORM:(state,status)=>{
    state.vipFormStatus = {
      show: status.show,
    }
  },
  SET_TABLE:(state,status)=>{
    state.tableStatus = {
      show: status.show,
      type: status.type,
    }
  }
}
const actions = {
  openAchieve({commit}, id) {
    if(!checkLogin()) return
    commit('SET_ACHIEVE', {
      show: true,
      id
    })
  },
  closeAchieve({commit},) {
    commit('SET_ACHIEVE', {
      show: false,
      id: ''
    })
  },
  openDemand({commit}, id) {
    if(!checkLogin()) return
    commit('SET_DEMAND', {
      show: true,
      id
    })
  },
  openCor({commit},id){
    if(!checkLogin()) return
    commit('SET_COR', {
      show: true,
      id
    })
  },
  openAsk({commit},typeName='产学研'){
    if(!checkLogin()) return
    commit('SET_ASK', {
      show: true,
      typeName
    })
  },
  openSuccess({commit},word){
    commit('SET_SUCCESS', {
      show: true,
      word
    })
  },
  openExpert({commit},id){
    if(!checkLogin()) return
    commit('SET_EXPERT', {
      show: true,
      id
    })
  },
  openQuestion({commit}){
    if(!checkLogin()) return
    commit('SET_QUESTION',{
      show: true,
    })
  },
  openVip({commit}){
    commit('SET_VIP',{
      show: true,
    })
  },
  openTable({commit},type){
    commit('SET_TABLE',{
      show: true,
      type: type,
    })
  },
  openVipForm({commit}){
    commit('SET_VIP_FORM',{
      show: true,
    })
  },
  closeVipForm({commit}){
    commit('SET_VIP_FORM',{
      show: false,
    })
  },
  closeTable({commit}){
    commit('SET_TABLE',{
      show: false,
      type: 0,
    })
  },
  closeVip({commit}){
    commit('SET_VIP',{
      show: false,
    })
  },
  closeQuestion({commit}){
    commit('SET_QUESTION',{
      show: false,
    })
  },
  closeExpert({commit}) {
    commit('SET_EXPERT', {
      show: false,
      id: '',
    })
  },
  closeDemand({commit},) {
    commit('SET_DEMAND', {
      show: false,
      id: ''
    })
  },
  closeCor({commit},) {
    commit('SET_COR', {
      show: false,
      id: ''
    })
  },
  closeAsk({commit}){
    commit('SET_ASK', {
      show: false,
      typeName: '',
    })
  },
  closeSuccess({commit}){
    commit('SET_SUCCESS', {
      show: false,
      word: '',
    })
  },
}

const checkLogin = ()=>{
  if(!getG60LocalInfo()){
    ElMessage.warning('您尚未登录!')
    router.push({
      path: '/login',
      query:{
        redirect: window.location.href
      }
    })
    return false
  }
  return true
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}