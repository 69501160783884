<template>
  <el-dialog v-model="showWindow" :show-close="false" :close-on-press-escape="false" :width="'881px'" :custom-class="'dialog-no-title dialog-transparent'">
    <div class="invite-window">
      <p class="p-0">邀请成员加入探角</p>
      <p class="p-1">将邀请链接分享给团队成员，点击链接并申请</p>
      <p class="p-2" v-loading="loading">{{name}} 邀请您加入探角数字科创中心！点击链接立即申请加入，享受海量科创资源与智能科创服务的强大支持：<br/>{{ link }}</p>
      <div class="copy-button">
        <button @click="copy">复制</button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { getTanInviteLink } from "@/api/Tan/member";
import { mapGetters } from "vuex";
import { copyToClipboard } from "@/utils/tool.js"
import { ElMessage } from 'element-plus';
import { getTanLocalInfo } from "@/utils/Tan/login";
import { environment } from "@/utils/consts";

export default{
  data(){
    return{
      showWindow: false,
      name: '',
      loading: false,
      link: environment === 'uat' ? 'https://utanjiao.keqidao.com/tan-invite?link=' : 'https://tanjiao.keqidao.com/tan-invite?link='
    }
  },
  computed:{
    ...mapGetters([
      "TanInvitePopShow",
    ]),
  },
  watch:{
    TanInvitePopShow(val){
      this.showWindow = val
    },
    showWindow(val){
      if(!val){
        this.$store.dispatch('Tan/setInviteShow',false)
        this.link = environment === 'uat' ? 'https://utanjiao.keqidao.com/tan-invite?link=' : 'https://tanjiao.keqidao.com/tan-invite?link='
      }
      else{
        this.name = getTanLocalInfo().tjInfo.name || '-'
        this.getLink()
      }
    },
  },

  methods:{
    getLink(){
      const id = getTanLocalInfo()?.memberInfo?.id
      if(!id) return
      this.loading = true
      getTanInviteLink(id).then(res=>{
        this.link += encodeURIComponent(res.data)
      }).finally(()=>{
        this.loading = false
      })
    },
    copy(){
      copyToClipboard(`${this.name}邀请您加入探角数字科创中心！点击链接立即申请加入，享受海量科创资源与智能科创服务的强大支持：\n` +this.link)
      ElMessage.success('复制成功!')
    },
  }
}
</script>

<style lang="scss" scoped>
.invite-window{
  box-sizing: border-box;
  width: 100%;
  padding: 53px 69px 41px 57px;
  background: linear-gradient( 180deg, #CEDFFF 0%, #FEFFFF 21%, #FFFFFF 100%);
  box-shadow: 0px 2px 8px 8px rgba(3,11,52,0.01);
  border-radius: 10px;
  .p-0{
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 24px;
    color: #333333;
    line-height: 33px;
    padding-bottom: 46px;
  }
  .p-1{
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
    line-height: 25px;
    padding-bottom: 17px;
  }
  .p-2{
    background: #F5F5F5;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 14px 20px;
    width: 100%;
    box-sizing: border-box;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
    line-height: 26px;
    margin-bottom: 46px;
  }
  .copy-button{
    width: 100%;
    display: flex;
    justify-content: center;
    button{
      width: 172px;
      height: 32px;
      background: $--color-theme;
      border-radius: 3px;
      cursor: pointer;
      border: none;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 14px;
      color: #FFFFFF;
    }
  }
}
</style>