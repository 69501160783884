import Cookies from 'js-cookie';
import { environment } from "@/utils/consts"
import { initTime } from "@/utils/time"

export function getCookie(name){
  if(window.location.href.includes('localhost')){
    return
  }
  if(environment === 'uat'){
    name = 'uat' + name
  }
  if(!name){
    return Cookies.get()
  }
  else{
    return Cookies.get(name)
  }
}

export function setCookie(name,value,expires = 365){
  if(window.location.href.includes('localhost')){
    return
  }
  if(!name || !value){
    console.log('设置cookie错误');
    return false
  }
  if(environment === 'uat'){
    name = 'uat' + name
  }
  let temp = value
  temp.time = initTime()
  Cookies.set(name, JSON.stringify(temp) ,{ expires: expires,domain: 'keqidao.com' });
}

export function removeCookie(name){
  if(environment === 'uat'){
    name = 'uat' + name
  }
  Cookies.remove(name);
}