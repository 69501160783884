import { post } from "@/utils/request";
import { getTanId } from "@/utils/Tan/login";

// 获取产品列表
export function getTanProducts() {
  return post('/product/getProduct', {
    limit: 20,
    memberId: getTanId(),
    offset: 1,
    vipTypeList: [12,13,14]
  })
}