<template>
  <div v-if="labTipVisible" @click.stop>
    <div class="iconChatBox">
      <div class="box-container">
        <div class="box-header">
          <div>{{isDemandDetail ? '提示' : '新客福利'}}</div>
          <i class="el-icon-close" style="cursor: pointer;" @click="closeBox"></i>
        </div>
        <div class="box-content">
          <p v-if="isDemandDetail">系统检测还未定制"探角"，助力企业技术创新、数字化升级，还可获得免费联系专家的机会！</p>
          <p v-else>定制“探角”，即可获得免费联系专家的机会！</p>
          <div class="button-line">
            <button class="create-button" @click="goToCreate">
              定制数字科创中心
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  data () {
    return {
    }
  },
  computed: {
    isDemandDetail(){ // 是否需求详情页
      return this.$route.path === '/demand-detail'
    },
    ...mapGetters(["labTipVisible"])
  },
  methods: {
    goToCreate(){ // 前往定制数字科创中心
      // routerWindow(this.$router,'/selection',{type:2})
      this.closeBox() // 前往后关闭
    },
    closeBox(){ // 关闭弹窗
      this.$store.commit("message/SET_LAB_TIP_VISIBLE",false)
    }
  }
}
</script>


<style scoped lang="scss">
.iconChatBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 999;
  position: absolute;
  top: 0%;
  overflow: auto;
  .box-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 480px;
    background: #FFFFFF;
    .box-header{
      display: flex;
      box-sizing: border-box;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 16px 24px;
      border-bottom: 1px solid #D8D8D8;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
    .box-content{
      width: 100%;
      box-sizing: border-box;
      padding: 24px 24px 16px 24px;
      flex-direction: column;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      display: flex;
      justify-content: flex-start;
      .button-line{
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        margin-top: 24px;
      }
    }
    .create-button{
      border-radius: 2px;
      border: none;
      width: 148px;
      height: 32px;
      background: $--color-theme;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 32px;
      text-align: center;
      cursor: pointer;
    }
  }
}
</style>