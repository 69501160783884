<template>
  <el-dialog title="我要提问" v-model="show" :show-close="false" :close-on-press-escape="false" :width="showForm ?  '630px' : '900px'" :custom-class="'dialog-no-title'" :close-on-click-modal="false">
    <div class="wrap" v-if="!showForm" v-loading="loading">
      <div class="achieve-content hide-scroll-bar">
        <p class="title text-ol-ell">{{ detail.title }}</p>
        <p class="time text-ol-ell">发布时间：{{ detail.publishTime }}</p>
        <div class="form">
          <div class="line flex-cc">
            <p class="dark flex-cc">持有方</p>
            <div class="light">
              <p class="flex-cc text-ol-ell">上海人工智能与先进计算研究所</p>
            </div>
            <p class="dark flex-cc">应用产业</p>
            <div class="light">
              <p class="flex-cc text-ol-ell">{{ detail.domain }}</p>
            </div>
          </div>
          <div class="line flex-cc">
            <p class="dark flex-cc">合作方式</p>
            <div class="light">
              <p class="flex-cc text-ol-ell">{{ detail.cooperationModeList?.join('、') }}</p>
            </div>
            <p class="dark flex-cc">成果形式</p>
            <div class="light">
              <p class="flex-cc text-ol-ell">{{ detail.form }}</p>
            </div>
          </div>
          <div class="line flex-cc">
            <p class="dark flex-cc">技术领域</p>
            <div class="light">
              <p class="flex-cc text-ol-ell">{{ detail.applicationIndustry }}</p>
            </div>
            <p class="dark flex-cc">所属地区</p>
            <div class="light">
              <p class="flex-cc text-ol-ell">{{ showAddress(detail) }}</p>
            </div>
          </div>
          <div class="line last flex-cc">
            <p class="dark flex-cc">成果类别</p>
            <div class="light">
              <p class="flex-cc text-ol-ell">{{ detail.typeList?.join('、') }}</p>
            </div>
            <p class="dark flex-cc">技术成熟度</p>
            <div class="light">
              <p class="flex-cc text-ol-ell">{{ detail.stage }}</p>
            </div>
          </div>
        </div>
        <div class="options">
          <div class="option" :class="{'active': cur === 0}" @click="cur = 0">
            成果描述
          </div>
          <div class="option" :class="{'active': cur === 1}" @click="cur = 1" v-if="detail?.achievementList?.length > 0">
            相似成果
          </div>
        </div>
        <div v-if="cur === 0" class="detail">
          <div class="d-title">
            <img src="@/assets/images/G60/pop/icon-0.png" class="icon-0" />
            <p class="p">成果简述：</p>
          </div>
          <div class="context">
            {{ detail.sketch }}
          </div>
          <div class="d-title">
            <img src="@/assets/images/G60/pop/icon-2.png" class="icon-2" />
            <p class="p">市场前景：</p>
          </div>
          <p class="num">{{ detail.marketOutlook }}</p>
          <div class="button">
            <button @click="showForm = true">成果合作</button>
          </div>
        </div>
        <div v-else class="similar">
          <div class="dynamic" v-for="item in detail.achievementList" :key="item.id">
            <div class="thumb" @click="changeAchieve(item.id)">
              <Image :src="item.img"></Image>
            </div>
            <div class="infos">
              <a class="text-ol-ell a-title" @click="changeAchieve(item.id)">{{ item.title }}</a>
              <div class="flex">
                <p class="key text-ol-ell">{{ item.applicationIndustry }}</p>
              </div>
              <div class="flex">
                <div class="publisher">
                  <img src="@/assets/images/G60/labSpace/build.png" class="icon-0" />
                  <a class="text-ol-ell a-publisher">{{ item.position }}</a>
                  <img src="@/assets/images/G60/labSpace/right-arrow.png" class="icon-1" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img src="@/assets/images/G60/pop/close-button.png" class="close-icon" @click="close" />
    </div>
    <div class="editing" v-else>
      <p class="stop" @click="close()">X</p>
      <p class="title">我要提问</p>
      <div class="form-item">
        <p class="item"><span>*</span>问题描述：</p>
        <input v-model="form.describe" class="input" maxlength="30" />
      </div>
      <div class="form-item">
        <p class="item">补充说明：</p>
        <textarea v-model="form.add" class="textarea" maxlength="1000"></textarea>
      </div>
      <div class="form-item">
        <p class="item"><span>*</span>问题分类：</p>
        <el-select v-model="form.type" placeholder="请选择问题分类" :style="filterStyle">
          <el-option v-for="(jtem,index) in typeList" :key="index" :label="jtem" :value="jtem">
          </el-option>
        </el-select>
      </div>
      <div class="button">
        <button @click="submit">提交合作申请</button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { addG60Question } from "@/api/G60";
import { ElMessage } from "element-plus";
import { getG60Id } from "@/utils/G60/login";


export default{
  data(){
    return{
      cur: 0,
      show: true,
      showForm: true,
      detail: {},
      typeList: [],
      filterStyle: 'width: 319px;height: 38px;',
      loading: false,
      form:{
        describe: '',
        add: '',
        type: '',
      },
    }
  },
  computed:{
    ...mapGetters([
      "questionStatus",
    ]),
  },
  watch:{
    showForm(val){
      if(!val) this.clearForm()
    },
  },
  mounted(){
    this.getSelection()
  },
  methods:{
    getSelection(){
      this.typeList = [
        '产学研合作','知识产权','政策申报','科技招商','投融资','其他'
      ]
    },
    submit(){
      if(!this.form.describe){
        ElMessage.warning('请输入问题描述')
        return
      }
      else if(!this.form.type){
        ElMessage.warning('请选择问题类型')
        return
      }
      const param = {
        "memberId": getG60Id(),
        "problemDescription" : this.form.describe,
        "problemType": this.form.type,
        "supplementaryExplanation": this.form.add,
      }
      addG60Question(param).then((res)=>{
        if(res.code === "Sx200"){
          this.$store.dispatch('G60/closeQuestion')
          this.$store.dispatch('G60/openSuccess',`感谢您对我们平台的关注与支持。_我们的行业专家将在最短时间内与您取得联系，为您提供全方位专业指导和一对一贴心服务。请保持电话畅通，谢谢！`)
        }
      })
    },
    changeAchieve(id){
      if(!id) return
      this.$store.dispatch('G60/closeQuestion',id)
      this.cur = 0
    },
    showAddress(detail){
      let result = ''
      if(detail.province){
        result += detail.province
      }
      if(detail.city){
        result += '-' + detail.province
      }
      if(detail.area){
        result += '-' + detail.province
      }
      return result
    },
    clearForm(){
      this.form = {
        name: '',
        contactor: '',
        way: '',
        corWay: '',
        cor: '',
      }
    },
    close(){
      this.$store.dispatch('G60/closeQuestion')
    },
  }
}
</script>


<style lang="scss" scoped>
.wrap{
  position: relative;
  .close-icon{
    width: 28px;
    height: 28px;
    position: absolute;
    right: 20px;
    top: 30px;
    cursor: pointer;
  }
}
.achieve-content{
  width: 100%;
  max-height: 70vh;
  overflow-x: auto;
  box-sizing: border-box;
  padding: 60px 50px;
  .title{
    font-size: 22px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: #111111;
    line-height: 22px;
    width: 100%;
    margin-bottom: 13px;
  }
  .time{
    font-size: 14px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 14px;
    width: 100%;
    margin-bottom: 34px;
  }
  .form{
    width: 100%;
    padding-bottom: 30px;
    .line{
      border-left: 1px solid #E2E8EF;
      align-items: stretch !important;
      p{
        width: 100%;
        display: block;
        min-height: 22px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #111111;
        line-height: 22px;
        border-bottom: none;
      }
      .light{
        display: flex;
        align-items: center;
        border: 1px solid #E2E8EF;
        border-left: none;
        border-bottom: none;
        width: 259px;
        box-sizing: border-box;
        padding: 15px;
        .member{
          color: $--color-theme;
          cursor: pointer;
          .split{
            color: #111111 !important;
            cursor: text !important;
          }
        }
      }
      .country{
        width: 344px;
        box-sizing: border-box;
        padding: 15px;
        min-height: 43px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #111111;
        border: 1px solid #E2E8EF;
        border-left: none;
        line-height: 22px;
        border-bottom: none;
        display: flex;
        align-items: center;
        img{
          width: 32px;
          height: 18px;
          margin-right: 4px;
        }
      }
      .dark{
        border: 1px solid #E2E8EF;
        border-left: none;
        border-bottom: none;
        box-sizing: border-box;
        padding: 15px;
        width: 139px !important;
        background: #F3F9FD;
        color: #666666 !important;
      }
    }
    .last{
      border-bottom: 1px solid #E2E8EF;
    }
  }
  .options{
    width: 100%;
    border-bottom: 1px solid #ebebeb;
    margin-bottom: 20px;
    display: flex;
    .option{
      padding-bottom: 7px;
      font-size: 16px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #111111;
      line-height: 16px;
      margin-right: 40px;
      cursor: pointer;
    }
    .active{
      position: relative;
      color: $--G60-theme !important;
      &::after{
        content: '';
        position: absolute;
        bottom: -1px;
        left: 50%;
        margin-left: -32px;
        width: 64px;
        height: 2px;
        background: $--G60-theme;
      }
    }
  }
  .detail{
    width: 100%;
    padding-bottom: 50px;
    .d-title{
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      .icon-0{
        width: 18px;
        height: 16px;
        margin-right: 5px;
      }
      .icon-1{
        width: 18px;
        height: 15px;
        margin-right: 5px;
      }
      .icon-2{
        width: 16x;
        height: 16px;
        margin-right: 5px;
      }
      .p{
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: bold;
        color: #111111;
        line-height: 16px;
      }
    }
    .context{
      width: 100%;
      font-size: 14px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #111111;
      line-height: 28px;
      letter-spacing: 1px;
      text-align: justify;
      padding-bottom: 14px;
    }
    .num{
      font-size: 14px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #111111;
      line-height: 14px;
      margin-bottom: 14px;
      width: 100%;
    }
    .button{
      width: 100%;
      display: flex;
      padding-top: 40px;
      justify-content: center;
      button{
        width: 216px;
        height: 45px;
        background: $--G60-theme;
        cursor: pointer;
        font-size: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        border: none;
      }
    }
  }
  .similar{
    width: 100%;
    .dynamic{
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      .thumb{
        width: 160px;
        height: 103px;
        cursor: pointer;
      }
      .infos{
        width: 620px;
        padding-top: 3px;
        .a-title{
          display: block;
          width: 100%;
          font-size: 14px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          color: #111111;
          line-height: 14px;
          cursor: pointer;
          &:hover{
            color: $--G60-theme !important;
          }
          &:visited{
            color: #111111;
          }
        }
        .key{
          height: 22px;
          background: #F6F6F6;
          border: 1px solid #EBEBEB;
          box-sizing: border-box;
          line-height: 20px;
          padding: 0 7px;
          font-size: 12px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #666666;
          max-width: 500px;
          margin-top: 30px;
          margin-bottom: 10px;
        }
        .publisher{
          display: flex;
          max-width: 300px;
          align-items: center;
          height: 22px;
          background: #F5F9FF;
          border-radius: 4px;
          box-sizing: border-box;
          padding: 4px 6px;
          .icon-0{
            width: 14px;
            height: 14px;
            margin-right: 4px;
          }
          .a-publisher{
            font-size: 12px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #2280FF;
            line-height: 18px;
            max-width: 188px;
            cursor: pointer;
            &:hover{
              // color: $--G60-theme !important;
            }
            &:visited{
              color: #2280FF;
            }
          }
          .icon-1{
            width: 5px;
            height: 8px;
            margin-left: 6px;
          }
        }
      }
    }
  }
}
.editing{
  position: relative;
  width: 100%;
  padding-bottom: 40px;
  .stop{
    width: 13px;
    height: 13px;
    color: #000000;
    font-size: 13px;
    line-height: 13px;
    position: absolute;
    right: 20px;
    top: 24px;
    cursor: pointer;
  }
  .title{
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 18px;
    font-family: PingFangSC, PingFang SC;
    font-weight: bold;
    color: #111111;
    line-height: 22px;
    border-bottom: 1px solid #EbEBeb;
    text-align: center;
    margin-bottom: 30px;
  }
  .form-item{
    display: flex;
    width: 100%;
    padding-bottom: 18px;
    .item{
      width: 193px;
      text-align: right;
      font-size: 14px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #111111;
      line-height: 14px;
      padding-top: 12px;
      span{
        color: #F22222;
      }
    }
    .input{
      width: 319px;
      height: 38px;
      border-radius: 4px;
      border: 1px solid #E2E8EF;
      background-color: #ffffff;
      font-size: 14px;
      color: #111111;
      box-sizing: border-box;
      padding: 0 15px;
      line-height: 38px;
    }
    .textarea{
      width: 319px;
      height: 119px;
      border-radius: 4px;
      border: 1px solid #E2E8EF;
      background-color: #ffffff;
      font-size: 14px;
      color: #111111;
      box-sizing: border-box;
      padding: 15px;
      line-height: 20px;
      resize: none;
    }
  }
  .button{
    margin-top: 17px;
    width: 100%;
    display: flex;
    justify-content: center;
    button{
      border: none;
      cursor: pointer;
      width: 157px;
      height: 38px;
      background: $--G60-theme;
      border-radius: 4px;
      font-size: 14px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
    }
  }
}
</style>
