<template>
  <el-dialog title="科技成果" v-model="show" :show-close="false" :close-on-press-escape="false" :width="showForm ?  '630px' : '900px'" :custom-class="'dialog-no-title'" :close-on-click-modal="false">
    <div class="wrap" v-if="!showForm" v-loading="loading">
      <div class="achieve-content hide-scroll-bar">
        <div class="expert">
          <div class="avatar">
            <Image :src="detail.img" />
          </div>
          <div class="name-line">
            <p class="name text-ol-ell">{{ detail.name }}</p>
            <p class="education text-ol-ell">{{ detail.rank}}</p>
            <p class="position text-ol-ell">{{ detail.education}}</p>
          </div>
          <p class="belong text-ol-ell"><span>工作单位：</span>{{detail.belong }}</p>
          <p class="direction text-otl-ell"><span>研究方向：</span>{{ detail.direction}}</p>
        </div>
        <div class="options">
          <div class="option" :class="{'active': cur === 0}" @click="cur = 0">
            个人简介
          </div>
          <div class="option" :class="{'active': cur === 1}" @click="cur = 1">
            成果荣誉
          </div>
        </div>
        <div v-if="cur === 0" class="detail">
          <div class="d-title">
            <img src="@/assets/images/G60/pop/icon-0.png" class="icon-0" />
            <p class="p">个人简介：</p>
          </div>
          <div class="context">
            {{ detail.introduction }}
          </div>
        </div>
        <div v-else class="detail">
          <div class="d-title">
            <img src="@/assets/images/G60/pop/icon-0.png" class="icon-0" />
            <p class="p">成果荣誉：</p>
          </div>
          <div class="context">
            {{ detail.honor }}
          </div>
        </div>
        <div class="button">
          <button @click="checkForm()">申请合作</button>
        </div>
        <div class="similar" v-if="detail?.expertList?.length > 0">
          <p class="tip">相关专家</p>
          <div class="other" v-for="item in detail.expertList" :key="item.id" :class="{'m-t-25': item === 1}">
            <div class="head" @click="changeAchieve(item.id)">
              <Image :src="item.img"></Image>
            </div>
            <div class="info" @click="changeAchieve(item.id)">
              <div class="info-name">
                <p class="name text-ol-ell">{{ item.name }}</p>
                <p class="education text-ol-ell">{{ item.position }}</p>
                <p class="position text-ol-ell">{{ item.education }}</p>
              </div>
              <p class="belong text-ol-ell"><span>工作单位：</span>{{ item.belong }}</p>
              <p class="direction text-ol-ell"><span>研究方向：</span>{{ item.direction }}</p>
            </div>
            <div class="contact-button">
              <button @click="changeAchieve(item.id)">联系专家</button>
            </div>
          </div>
        </div>
      </div>
      <img src="@/assets/images/G60/pop/close-button.png" class="close-icon" @click="close()" />
    </div>
    <div class="editing" v-else>
      <p class="stop" @click="showForm = false">X</p>
      <p class="title">申请合作</p>
      <div class="form-item">
        <p class="item"><span>*</span>企业名称：</p>
        <input v-model="form.name" class="input" maxlength="30" />
      </div>
      <div class="form-item">
        <p class="item"><span>*</span>联系人：</p>
        <input v-model="form.contactor" class="input" maxlength="30" />
      </div>
      <div class="form-item">
        <p class="item"><span>*</span>联系方式：</p>
        <input v-model="form.way" class="input" maxlength="30" />
      </div>
      <div class="form-item">
        <p class="item"><span>*</span>合作方式：</p>
        <el-cascader placeholder=" " :style="filterStyle"
          :props="{ checkStrictly: true, expandTrigger: 'hover' }" :popper-class="'el-popDropDownPanelArea'"
          :show-all-levels="false" v-model="form.corWay" clearable :options="cooperationList" @visible-change="visibleChange" @change="visibleChange" @expand-change="visibleChange">
        </el-cascader>
      </div>
      <div class="form-item">
        <p class="item"><span>*</span>合作意向：</p>
        <textarea v-model="form.cor" class="textarea" maxlength="1000"></textarea>
      </div>
      <div class="button">
        <button class="publish" @click="publish">提交合作申请</button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { getSelectFatherSonByType } from "@/api/config";
import { submitG60Window,getG60ExpertDetail } from "@/api/G60";
import { ElMessage } from "element-plus";
import { getG60Id } from "@/utils/G60/login";

export default{
  data(){
    return{
      cur: 0,
      show: true,
      showForm: false,
      loading: false,
      detail: {},
      filterStyle: 'width: 319px;height: 38px;',
      form:{
        name: '',
        contactor: '',
        way: '',
        corWay: '',
        cor: '',
      },
      cooperationList: [],
    }
  },
  computed:{
    corId(){
      return this.corStatus.id
    },
    ...mapGetters([
      "corStatus",
    ]),
  },
  watch:{
    showForm(val){
      if(!val) this.clearForm()
    },
    corId(){
      this.getDetail()
    },
  },
  mounted(){
    this.getSelection()
    this.getDetail()
  },
  methods:{
    checkForm(){
      if(this.detail.memberId === getG60Id()){
        ElMessage.warning('您不能与自己合作')
        return
      }
      this.showForm = true
    },
    publish(){
      if(!this.form.name){
        ElMessage.warning('请输入企业名称')
        return
      }
      else if(!this.form.contactor){
        ElMessage.warning('请输入联系人')
        return
      }
      else if(!this.form.way){
        ElMessage.warning('请输入联系方式')
        return
      }
      else if(!this.form.corWay){
        ElMessage.warning('请选择合作方式')
        return
      }
      else if(!this.form.cor){
        ElMessage.warning('请输入合作意向')
        return
      }
      const param = {
        "customJson": JSON.stringify({
          "企业名称": this.form.name,
          "合作方式": this.form.corWay,
          "合作意向": this.form.cor,
        }),
        "memberId": getG60Id(),
        "name": this.form.contactor,
        "phone": this.form.way,
        "sourceId": this.corId,
        "type": 4
      }
      submitG60Window(param).then(res=>{
        if(res.code === "Sx200"){
          this.$store.dispatch('G60/closeCor')
          this.$store.dispatch('G60/openSuccess','您的申请合作已提交审核_产学研经理将在1-3个工作日内与您联系！')
        }
      })
    },
    visibleChange(){
      this.$nextTick(() => {
        const $el = document.querySelectorAll('.el-cascader-panel .el-cascader-node[aria-owns]');
        Array.from($el).map((item) => item.removeAttribute('aria-owns'));
      });
    },
    getSelection(){
      getSelectFatherSonByType(106).then(res=>{
        let remarks = []
        res.data.forEach((item)=>{
          if(!remarks.includes(item.remark)) remarks.push(item.remark)
        })
        this.cooperationList = remarks.map((item)=>{
          return {
            label: item,
            value: item,
            children: res.data.filter((jtem)=>jtem.remark === item).map((ktem)=>{
              return{
                label: ktem.val,
                value: ktem.val,
              }
            })
          }
        })
        this.cooperationList.push({
          label: '其他合作',
          value: '其他合作',
          children:[
            {
              label: '其他',
              value: '其他',
            },
          ]
        })
      })
    },
    changeAchieve(id){
      if(!id) return
      this.$store.dispatch('G60/openCor',id)
      this.cur = 0
    },
    getDetail(){
      if(!this.corId){
        this.$store.dispatch('G60/closeDemand')
        return
      }
      this.loading = true
      getG60ExpertDetail({id: this.corId}).then((res)=>{
        this.detail = res.data
      }).finally(()=>{
        this.loading = false
      })
    },
    clearForm(){
      this.form = {
        name: '',
        contactor: '',
        way: '',
        corWay: '',
        cor: '',
      }
    },
    close(){
      this.$store.dispatch('G60/closeCor')
    },
  }
}
</script>


<style lang="scss" scoped>
.wrap{
  position: relative;
  .close-icon{
    width: 28px;
    height: 28px;
    position: absolute;
    right: 20px;
    top: 30px;
    cursor: pointer;
  }
}
.achieve-content{
  width: 100%;
  max-height: 70vh;
  overflow-x: auto;
  box-sizing: border-box;
  padding: 60px 50px;
  .expert{
    width: calc(100% + 100px);
    margin-left: -50px;
    box-sizing: border-box;
    padding: 0px 61px 30px 53px;
    background-image: url('https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2023/11/27/1703655932770523');
    background-position: bottom left;
    background-repeat: no-repeat;
    background-size: 100% 222px;
    margin-bottom: 20px;
    .avatar{
      width: 118px;
      height: 118px;
      margin-bottom: 30px;
    }
    .name-line{
      display: flex;
      align-items: flex-end;
      margin-bottom: 14px;
      .name{
        font-size: 20px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #111111;
        margin-right: 8px;
        max-width: 400px;
      }
      .education{
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #2280FF;
        line-height: 14px;
        margin-right: 8px;
        max-width: 100px;
      }
      .position{
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #44B46F;
        line-height: 14px;
        margin-right: 8px;
        max-width: 100px;
      }
    }
    .belong{
      width: 100%;
      font-size: 16px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 16px;
      margin-bottom: 9px;
      span{
        color: #999999;
      }
    }
    .direction{
      width: 100%;
      font-size: 16px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 26px;
      span{
        color: #999999;
      }
    }
  }
  .title{
    font-size: 22px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: #111111;
    line-height: 22px;
    width: 100%;
    margin-bottom: 13px;
  }
  .time{
    font-size: 14px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 14px;
    width: 100%;
    margin-bottom: 34px;
  }
  .form{
    width: 100%;
    padding-bottom: 30px;
    .line{
      border-left: 1px solid #E2E8EF;
      align-items: stretch !important;
      p{
        width: 100%;
        display: block;
        min-height: 22px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #111111;
        line-height: 22px;
        border-bottom: none;
      }
      .light{
        display: flex;
        align-items: center;
        border: 1px solid #E2E8EF;
        border-left: none;
        border-bottom: none;
        width: 259px;
        box-sizing: border-box;
        padding: 15px;
        .member{
          color: $--color-theme;
          cursor: pointer;
          .split{
            color: #111111 !important;
            cursor: text !important;
          }
        }
      }
      .country{
        width: 344px;
        box-sizing: border-box;
        padding: 15px;
        min-height: 43px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #111111;
        border: 1px solid #E2E8EF;
        border-left: none;
        line-height: 22px;
        border-bottom: none;
        display: flex;
        align-items: center;
        img{
          width: 32px;
          height: 18px;
          margin-right: 4px;
        }
      }
      .dark{
        border: 1px solid #E2E8EF;
        border-left: none;
        border-bottom: none;
        box-sizing: border-box;
        padding: 15px;
        width: 139px !important;
        background: #F3F9FD;
        color: #666666 !important;
      }
    }
    .last{
      border-bottom: 1px solid #E2E8EF;
    }
  }
  .options{
    width: 100%;
    border-bottom: 1px solid #ebebeb;
    margin-bottom: 20px;
    display: flex;
    .option{
      padding-bottom: 7px;
      font-size: 16px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #111111;
      line-height: 16px;
      margin-right: 40px;
      cursor: pointer;
    }
    .active{
      position: relative;
      color: $--G60-theme !important;
      &::after{
        content: '';
        position: absolute;
        bottom: -1px;
        left: 50%;
        margin-left: -32px;
        width: 64px;
        height: 2px;
        background: $--G60-theme;
      }
    }
  }
  .detail{
    width: 100%;
    padding-bottom: 50px;
    .d-title{
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      .icon-0{
        width: 18px;
        height: 16px;
        margin-right: 5px;
      }
      .icon-1{
        width: 18px;
        height: 15px;
        margin-right: 5px;
      }
      .icon-2{
        width: 16x;
        height: 16px;
        margin-right: 5px;
      }
      .p{
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: bold;
        color: #111111;
        line-height: 16px;
      }
    }
    .context{
      width: 100%;
      font-size: 14px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #111111;
      line-height: 28px;
      letter-spacing: 1px;
      text-align: justify;
      padding-bottom: 14px;
    }
    .num{
      font-size: 14px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #111111;
      line-height: 14px;
      margin-bottom: 14px;
      width: 100%;
    }
  }
  .button{
    width: 100%;
    display: flex;
    padding-top: 40px;
    justify-content: center;
    margin-bottom: 50px;
    button{
      width: 216px;
      height: 45px;
      background: $--G60-theme;
      cursor: pointer;
      font-size: 16px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      border: none;
    }
  }
  .similar{
    width: 100%;
    .tip{
      padding-bottom: 23px;
      width: 100%;
      border-bottom: 1px solid #EBEBEB;
      font-size: 16px;
      color: #111111;
    }
    .m-t-25{
      margin-top: 25px !important;
    }
    .other{
      width: 100%;
      display: flex;
      align-items: center;
      margin-top: 36px;
      cursor: pointer;
      &:hover{
        .name{
          color: $--G60-theme;
        }
      }
      .head{
        width: 90px;
        height: 90px;
        border-radius: 6px;
        margin-right: 12px;
        overflow: hidden;
        cursor: pointer;
      }
      .info{
        width: 510px;
        margin-right: 67px;
        .info-name{
          margin-bottom: 14px;
          padding-top: 6px;
          display: flex;
          width: 100%;
          align-items: flex-end;
          .name{
            font-size: 20px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            color: #111111;
            cursor: pointer;
            max-width: 300px;
            &:hover{
              color: $--G60-theme;
            }
          }
          .education{
            margin-left: 8px;
            font-size: 14px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #2280FF;
            line-height: 14px;
            max-width: 80px;
          }
          .position{
            margin-left: 8px;
            font-size: 14px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #44B46F;
            line-height: 14px;
            max-width: 80px;
          }
        }
        .belong{
          width: 100%;
          font-size: 16px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 16px;
          margin-bottom: 9px;
          span{
            color: #999999;
          }
        }
        .direction{
          width: 100%;
          font-size: 16px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 16px;
          span{
            color: #999999;
          }
        }
      }
      .contact-button{
        width: 80px;
        button{
          border: none;
          width: 80px;
          height: 32px;
          background: #2280FF;
          border-radius: 4px;
          cursor: pointer;
          font-size: 14px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 20px;
        }
      }
    }
  }
}
.editing{
  position: relative;
  width: 100%;
  padding-bottom: 40px;
  .stop{
    width: 13px;
    height: 13px;
    color: #000000;
    font-size: 13px;
    line-height: 13px;
    position: absolute;
    right: 20px;
    top: 24px;
    cursor: pointer;
  }
  .title{
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 18px;
    font-family: PingFangSC, PingFang SC;
    font-weight: bold;
    color: #111111;
    line-height: 22px;
    border-bottom: 1px solid #EbEBeb;
    text-align: center;
    margin-bottom: 30px;
  }
  .form-item{
    display: flex;
    width: 100%;
    padding-bottom: 18px;
    .item{
      width: 193px;
      text-align: right;
      font-size: 14px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #111111;
      line-height: 14px;
      padding-top: 12px;
      span{
        color: #F22222;
      }
    }
    .input{
      width: 319px;
      height: 38px;
      border-radius: 4px;
      border: 1px solid #E2E8EF;
      background-color: #ffffff;
      font-size: 14px;
      color: #111111;
      box-sizing: border-box;
      padding: 0 15px;
      line-height: 38px;
    }
    .textarea{
      width: 319px;
      height: 119px;
      border-radius: 4px;
      border: 1px solid #E2E8EF;
      background-color: #ffffff;
      font-size: 14px;
      color: #111111;
      box-sizing: border-box;
      padding: 15px;
      line-height: 20px;
      resize: none;
    }
  }
  .button{
    margin-top: 17px;
    width: 100%;
    display: flex;
    justify-content: center;
    button{
      border: none;
      cursor: pointer;
      width: 157px;
      height: 38px;
      background: $--G60-theme;
      border-radius: 4px;
      font-size: 14px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
    }
  }
}
</style>
