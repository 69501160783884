<template>
  <footer class="animate__animated animate__fadeIn footer" :class="{'login-footer': isLogin}" v-if="!loading && !notShow">
    <div class="footer-line-2">
      <p>
        copyrigt ©️ 2021科企岛(上海)网络技术有限公司 |
        <a class="protocol-name" target="_blank" href="https://www.keqidao.com/sitemap.xml">&nbsp;网站地图</a>
        &nbsp;|
        <a class="protocol-name" target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index"
          rel="nofollow">&nbsp;沪ICP备2021003328号-1 </a>
        &nbsp;|
        沪网公安备案 <a class="protocol-name" target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo"
          rel="nofollow">&nbsp;3101150200888号&nbsp;</a>
      </p>
    </div>
  </footer>
</template>


<script>
import { TanNotShowFooter } from '@/utils/consts';

export default{
  data(){
    return{
      loading: false,
    }
  },
  computed:{
    notShow(){
      return TanNotShowFooter.includes(this.$route.path)
    },
    isLogin(){
      return this.$route.path === '/login'
    }
  },
  mounted(){
    this.loading = true
    setTimeout(()=>{
      this.loading = false
    },1000)
  },
}
</script>

<style lang="scss" scoped>
.login-footer{
  position: absolute;
  bottom: 35px;
  .footer-line-2{
    background-color: transparent !important;
    color: #ffffff !important;
    border-top: none !important;
    a{
      color: #ffffff !important;
    }
    a:hover{
      color: $--color-theme !important;
    }
    a:visited{
      color: #ffffff !important;
    }
  }
}
.footer {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 24px;
  .footer-line-2{
    background-color: #f4f7fc;
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 24px;
    border-top: 1px solid #e6e6e6;
    a{
      color: #333333;
    }
    a:hover{
      color: $--color-theme !important;
    }
    a:visited{
      color: #333333;
    }
  }
}
</style>