<template>
  <div class="window-container" v-loading="types.length === 0">
    <div class="title">
      选择身份
    </div>
    <div class="tip">
      <p>您还未创建任何身份，创建身份解锁{{window ? '更多功能' : ''}}</p>
      <p v-if="!window">更多功能</p>
    </div>
    <div :class="{'type-selection-window':window}">
      <div class="type" style="padding-top: 20px;" :class="{'last': index === types.length - 1}" v-for="(type,index) in types" :key="type.id">
        <Image :imgId="type.id" :src="type.spaceBackgroudImg" :noSkeleton="true" class="type-img" @click="jumpTo(type)"></Image>
      </div>
    </div>

  </div>
</template>

<script>
import { personalTypeId } from "../../utils/consts.js";
import { G60site } from "@/router/index";
import { getIdentityTypes } from "../../utils/tool.js";
export default {
  emits: ['jumpTo'],
  props: ['window'],
  data(){
    return{
      types: [],  // 身份类型
      personalTypeId
    }
  },
  async created(){
    let res = await getIdentityTypes()
    this.types = res
  },
  methods:{
    jumpTo(type){
      // 跳转页面
      if(this.$route.path !== '/user-center'){
        // TODO_Track: 表单提交页进入情况 e_create_organization_select_type
      }
      let path = '/selection'
      if(G60site) path = '/selection'
      if(type.isLab){
        let query = {
          type:1
        }
        if(type.id + '' === '310577956192257') query.addType = '310577956192257'
        this.$emit('jumpTo',{path: path,query})
      }
      else if(type.name.indexOf('资本') !== -1){
        this.$emit('jumpTo',{path: path,query:{ type:3,addType: type.id}})
      }
      else {
        this.$emit('jumpTo',{path: path,query:{ type:2,addType: type.id}})
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.window-container{
  width: 100%;
  box-sizing: border-box;
  .title{
    padding-left: 8px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 25px;
    margin-bottom: 8px;
  }
  .tip{
    padding-left: 8px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
  }
  .last{
    border: none !important;
    padding-bottom: 10px !important;
  }
  .type-selection-window{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    .type{
      width: 260px !important;
      border: none !important;
    }
    .last{
      padding-bottom: 20px !important;
    }
  }
  .type{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: 1px solid #EDEDED;
    .type-img{
      width: 100%;
      height: 40px;
      cursor: pointer;
    }
    .icon{
      width: 39px;
      height: 39px;
      border-radius: 50%;
      margin-right: 15p;
    }
    .type-name{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 132px;
      .name{
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #161616;
        line-height: 20px;
      }
      .type-word{
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #999999;
        line-height: 17px;
      }
    }
  }
  .create-button{
    width: 60px;
    height: 22px;
    border-radius: 11px;
    border: 1px solid #666666;
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #666666;
    line-height: 22px;
    text-align: center;
    cursor: pointer;
  }
}
</style>