<template>
  <div id="editor—wrapper" :class="{'visible': !scroll}" v-loading="loadingJs">
    <div id="toolbar-container" :style="`${minusTop ? 'top:'+ minusTop + 'px;': '' }`"><!-- 工具栏 --></div>
    <div id="editor-container" @click="editorFocus" :style="`${minHeight ? 'min-height:' + minHeight + 'px;' : 'min-height: 500px;'}`" :class="{'max-height': scroll}"><!-- 编辑器 --></div>
  </div>
</template>

<script>
import { ElMessage } from "element-plus";
import { bucketMap } from "@/utils/consts";
import { randomFilename } from "@/utils/time";
import { cosByUploadType } from "@/api/upload";

var _this;
export default {
  props: ["placeHolder", "value","scroll","minusTop","minHeight"],
  emits: ["updateContent"],
  watch: {
    value(val) {
      // 捕捉内容的值，赋值给内部文字
      if (val.length === 0 || !val) this.editor.clear(); // 清空文本框
      else if(this.editor.getHtml() === '<p><br></p>'){
        // 如果有内容但编辑器为空，进行赋值
        this.editor.setHtml(val)
      }
    },
  },
  created() {
    _this = this; // 初始化全局this
    if (!window.wangEditor) {
      // 如果未定义，则局部引入js
      const s = document.createElement("script");
      s.type = "text/javascript";
      s.src = "https://unpkg.com/@wangeditor/editor@latest/dist/index.js";
      document.body.appendChild(s);
    }
    this.$nextTick(() => {
      // 增加一步判断
      if(!window.wangEditor){
        this.insertJs()
        return
      }
      _this.createEditor = window.wangEditor.createEditor;
      _this.createToolbar = window.wangEditor.createToolbar;
      _this.initEditor();
    });
  },
  data() {
    return {
      loadingJs: false, // 加载JS
      createEditor: undefined, // 创建编辑器方法
      createToolbar: undefined, // 创建工具栏方法
      editor: undefined, // 编辑器实例
      toolbar: undefined, // 工具栏实例
    };
  },
  beforeUnmount() {
    // 销毁编辑器
    this.editorDestroy()
  },
  methods: {
    insertJs(){
      // 按需引入js
      this.loadingJs = true
      const s = document.createElement("script");
      s.type = "text/javascript";
      s.src = "https://unpkg.com/@wangeditor/editor@latest/dist/index.js";
      document.body.appendChild(s);
      setTimeout(()=>{
        _this.loadingJs = false
        _this.createEditor = window.wangEditor.createEditor;
        _this.createToolbar = window.wangEditor.createToolbar;
        _this.initEditor();
      },1000)
    },
    editorDestroy(){
      // 编辑器销毁
      const editor = this.editor;
      if (editor == null) return;
      editor.destroy(); // 组件销毁时，及时销毁编辑器
      this.editor = null;
    },
    editorFocus(){
      // 编辑器获取焦点
      const editor = this.editor;
      if (editor == null) return;
      editor.focus()
    },
    escape2Html(str) {
      // html 字符转义
      const arrEntities = { lt: "<", gt: ">", nbsp: " ", amp: "&", quot: '"' };
      return str.replace(/&(lt|gt|nbsp|amp|quot);/gi, function (all, t) {
        return arrEntities[t];
      });
    },
    getSummary(html,editor) {
      // 根据富文本内容 给出summary
      let summary = "";
      const start = html.indexOf('<img src="');
      if (start !== -1) {
        // 首先判断有无图片
        const end = html.indexOf('"', start + 10);
        summary = "**kqd-img**" + html.slice(start + 10, end);
      } else {
        summary = this.escape2Html(this.editor ? this.editor.getText() : editor.getText()).trim().slice(0, 200); // 过滤两边空格换行
        const firstFullStop = summary.indexOf("。");
        if (firstFullStop !== -1) {
          // 根据句号截取长度
          summary = "**kqd-str**" + summary.slice(0, firstFullStop + 1);
        } else summary = "**kqd-str**" + summary;
      }
      if (summary.length >= 300) {
        // 长度限制
        summary = summary.slice(0, 290);
      }
      return summary;
    },
    async initEditor() {
      // 初始化编辑器
      const editorConfig = {
        placeholder: this.placeHolder.replace(/\n/g,'<br>'),  // 替换换行符号
        MENU_CONF: {},
        scroll: this.scroll ? this.scroll : false,  // 自适应高度
        onChange(editor) {
          // 变化事件
          let newHtml = editor.getHtml();
          console.log('onChange rich text:',editor.getHtml());
          let flag = false
          let p1 = -1
          let p2 = -1
          if(newHtml.indexOf('src="file') !== -1){
            // 说明将本地文件复制入内
            let start = newHtml.indexOf('src="file')
            for(let i = start;i >= 0; i--){
              if(newHtml.charAt(i) === '<'){
                p1 = i
                break
              }
            }
            p2 = newHtml.indexOf('>',start)
            if(p1 !== -1 && p2 !== -1){
              flag = true
            }
          }
          if (newHtml.startsWith("<p><br/></p>")) {
            newHtml = newHtml.slice(12); // 空格处理在赋值前去掉一行空行
          } else if (newHtml.startsWith("<p></p>")) newHtml = newHtml.slice(7);
          else if (newHtml.startsWith("<br/>")) newHtml = newHtml.slice(5);
          _this.newValue = newHtml;
          _this.$emit(
            "updateContent",
            newHtml,
            _this.getSummary(_this.newValue,editor)
          ); // 更改后进行修改
          // 也可以同步到 <textarea>
          if(flag){
            ElMessage.warning('加载图片失败，请通过其他方式上传!')
            newHtml = newHtml.slice(0,p1) + newHtml.slice(p2 + 1)
            editor.setHtml(newHtml)
          }
        },
      };
      // 自定义提示
      editorConfig.customAlert = (s, t) => {
        switch (t) {
          case "success":
            ElMessage.success(s);
            break;
          case "info":
            ElMessage.info(s);
            break;
          case "warning":
            ElMessage.warning(s);
            break;
          case "error":
            ElMessage.error(s);
            break;
          default:
            ElMessage.info(s);
            break;
        }
      };
      // 配置字号
      editorConfig.MENU_CONF["fontSize"] = {
        fontSizeList: [
          // 元素支持两种形式
          //   1. 字符串；
          //   2. { name: 'xxx', value: 'xxx' }
          "12px",
          "13px",
          "14px",
          "15px",
          "16px",
          "17px",
          "18px",
          "19px",
          "20px",
          "21px",
          "22px",
          "23px",
          "24px",
        ],
      };
      // 文字颜色
      editorConfig.MENU_CONF["color"] = {
        colors: [
          "#222222",
          "#ffffff", // 白色前置防止视觉效果不好
          "#707070",
          "#FD3940",
          "#FF6D20",
          "#00B04E",
          "#008EEC",
          "#8126FF",
          "#f3f3f3", // 增加白色的选项用于背景色的调整
        ],
      };
      // 背景色
      editorConfig.MENU_CONF["bgColor"] = {
        colors: [
          "#222222",
          "#ffffff", // 白色前置防止视觉效果不好
          "#707070",
          "#FD3940",
          "#FF6D20",
          "#00B04E",
          "#008EEC",
          "#8126FF",
          "#f3f3f3", // 增加白色的选项用于背景色的调整
        ],
      };
      // 图片配置
      editorConfig.MENU_CONF["uploadImage"] = {
        // 单个文件的最大体积限制，默认为 2M
        maxFileSize: 15 * 1024 * 1024, // 15M
        // 最多可上传几个文件，默认为 100
        maxNumberOfFiles: 30,
        // 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
        allowedFileTypes: [".jpg", ".jpeg", ".png", ".gif", ".bmp", ".webp", ".jfif"],
        // 超时时间，默认为 10 秒
        timeout: 5 * 1000, // 5 秒
        // 自定义上传
        async customUpload(file, insertFn) {
          // file 即选中的文件
          // 自己实现上传，并得到图片 url alt href
          // 最后插入图片
          _this.editor.disable(); // 上传中禁用编辑器
          let res = await _this.directUploadRich(file, 0);
          insertFn("https://" + res.Location, "", "");
        },
      };
      // 鼠标悬浮窗
      editorConfig.hoverbarKeys = {
        image: {
          // 重写 image 元素的 hoverbar
          menuKeys: [
            "imageWidth30",
            "imageWidth50",
            "imageWidth100",
            "deleteImage",
          ],
        },
      };
      editorConfig.MENU_CONF["fontFamily"] = {
        fontFamilyList: [
          // 元素支持两种形式
          //   1. 字符串；
          //   2. { name: 'xxx', value: 'xxx' }
          "黑体",
          "仿宋",
          "楷体",
          "标楷体",
          "华文细黑",
          "华文仿宋",
          "华文楷体",
          "宋体",
          "微软雅黑",
          "Arial",
          "Tahoma",
          "Verdana",
          "Times New Roman",
          "Courier New",
        ],
      };
      const editor = this.createEditor({
        selector: "#editor-container",
        html: "<p><br></p>",
        config: editorConfig,
        mode: "default", // or 'simple'
      });

      const toolbarConfig = {
        excludeKeys: [
          // 删除功能
          "todo", // 待办
          "emotion", // 表情
          "codeBlock", // 代码块
          "insertTable", // 表格
          "group-video", // 视频
          "insertImage",
          "fullScreen",
        ],
      };

      const toolbar = this.createToolbar({
        editor,
        selector: "#toolbar-container",
        config: toolbarConfig,
        mode: "default", // or 'simple'
      });
      this.editor = editor;
      this.toolbar = toolbar;
      if(this.value?.length > 0){
        // 插入草稿内容
        editor.setHtml(this.value)
      }
      editor.blur() // 失去焦点
    },
    directUploadRich(file, uploadType) {
      // 上传方法
      let cosMap = {
        image: cosByUploadType(0),
        video: cosByUploadType(2),
      };
      let cos = uploadType === 0 ? cosMap.image : cosMap.video;
      let bucket = uploadType === 0 ? bucketMap.image : bucketMap.video;
      return new Promise((resolve, reject) => {
        cos.putObject(
          {
            Bucket: bucket /* 必须 */,
            Region: "ap-shanghai" /* 存储桶所在地域，必须字段 */,
            Key: "PC-" + randomFilename() /* 必须 */,
            StorageClass: "STANDARD",
            Body: file, // 上传文件对象
            onProgress: function (progressData) {
              let percentage = parseInt(progressData.percent * 100);
              if (percentage === 100) {
                ElMessage.success("插入成功!");
                _this.editor.enable(); // 上传结束开放编辑
                _this.editor.showProgressBar(100)
              }
              else{
                _this.editor.showProgressBar(percentage) // progress 为 0-100 的数字
              }
            },
          },
          function (err, data) {
            if (err) {
              reject(err);
            } else {
              resolve(data);
            }
          }
        );
      });
    },
  },
};
</script>

<style scoped lang="scss">
.max-height{
  // 不自适应高度
  max-height: 400px !important;
  overflow: auto;
}
#editor-container{
  // 高度自适应
  height: auto !important;
  :deep() div[data-slate-node="element"]{
    // 防止左侧溢出
    margin-left: 0 !important;
  }
}
#editor—wrapper{
  :deep() .w-e-modal{
    padding: 10px 15px 0 15px !important;
    .button-container{
      button{
        line-height: 100% !important;
      }
    }
  }
}
#editor—wrapper{
  border-top: none !important;
}
#toolbar-container{
  position: sticky;
  top: 96px;
  border-top: 1px solid #ccc;
  z-index: 2000;
  :deep() .w-e-bar-item{
    button{
      font-size: 16px !important;
    }
  }
  :deep() .w-e-bar{
    padding: 0 !important;
  }
  :deep() .w-e-bar-divider{
    display: none;
  }
}
.visible{
  :deep() .w-e-text-container{
    overflow: visible !important;
  }
}
</style>