import {post} from "@/utils/request";
import { getTanLocalInfo,setTanLocalInfo,getTanId } from "@/utils/Tan/login";

// 探角企业创建
export function createTanCompany(param) {
  return post('/tj/company/add', param)
}

// 探角企业id是否存在 true存在 false不存在
export function getTanCompanyExist(tjCompanyId) {
  return post('/tj/company/companyIdIsExist', {tjCompanyId})
}

// 探角企业申请加入
export function joinTanByApply(params) {
  return post('/tj/company/applyForJoin', params)
}

// 探角企业详情
function TanCompanyInfo(memberId) {
  return post('/tj/company/infoByMemberId', {memberId})
}

export const getTanCompanyInfo = (memberId)=>{
  return new Promise((resolve, reject) => {
    TanCompanyInfo(memberId).then(res => {
      let info = getTanLocalInfo()
      info.tjInfo.tjCompanyName = res.data.companyName
      info.tjCompanyInfo = res.data
      setTanLocalInfo(info)
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

// 探角企业编辑
export function editTanCompanyInfo(param) {
  return post('/tj/company/edit', param)
}

// 探角企业邀请加入
export function joinTanByLink(params) {
  return post('/tj/company/applyForInviteJoin', params)
}

// 探角企业成员列表
export function getTanCompanyMemberList(params) {
  return post('/tj/company/memberList', {
    ...params,
    "memberId": getTanId()
  })
}

// 探角企业申请加入列表
export function getTanCompanyApplyList(params) {
  return post('/tj/company/applyForList', {
    ...params,
    "memberId": getTanId()
  })
}

// 探角企业加入申请拒绝
export function refuseTanApplyJoin(params) {
  return post('/tj/company/applyForRefuse', {
    ...params,
    "memberId": getTanId()
  })
}

// 探角企业加入申请通过
export function passTanApplyJoin(params) {
  return post('/tj/company/applyForAdopt', {
    ...params,
    "memberId": getTanId()
  })
}

// 探角企业转让管理员
export function transferTanHostManager(params) {
  return post('/tj/company/transferenceAdmin', {
    ...params,
    "memberId": getTanId()
  })
}

// 探角企业修改职务
export function editTanCompanyDuties(params) {
  return post('/tj/company/updateDuties', {
    ...params,
    "memberId": getTanId()
  })
}

// 探角企业专家管理权限数量
export function getTanCompanyAuthorNum() {
  return post('/tj/company/expertAuthCount', {
    "tjCompanyId": getTanLocalInfo()?.tjCompanyInfo?.tjCompanyId,
    "memberId": getTanId()
  })
}

// 探角企业专家管理授权
// "authMemberId" 授权会员id
// "authType"  1授权 2取消授权
export function giveTanCompanyExpertAuthor(param) {
  return post('/tj/company/authExpert', {
    ...param,
    "tjCompanyId": getTanLocalInfo()?.tjCompanyInfo?.tjCompanyId,
    "memberId": getTanId()
  })
}
// 探角企业专家管理权限申请
export function applyTanCompanyExpertAuthor(param) {
  return post('/tj/company/applyForExpertAuth', {
    ...param,
    "tjCompanyId": getTanLocalInfo()?.tjCompanyInfo?.tjCompanyId,
    "memberId": getTanId()
  })
}

// 探角退出-移除企业
export function quitTanCompany(param) {
  return post('/tj/company/quit', {
    ...param,
    "tjCompanyId": getTanLocalInfo()?.tjCompanyInfo?.tjCompanyId,
    "memberId": getTanId()
  })
}