<template>
  <!-- 留言窗口 -->
  <div class="im-container" v-if="leaveMessageVisible" id="imContainer" v-drag>
    <div class="im-left-bar">
      <!-- 我的信息 -->
      <div class="im-self-info" id="selfInfo">
        <img class="img-avatar" :src="staticPath + avatar" />
        <div class="im-my-name">{{ name }}</div>
        <img v-if="isVip" class="vipIcon" src="@/assets/images/icon-vip.png" />
      </div>
      <div class="friend-list" id="friendList">
        <div style="padding: 10px" v-if="updating">
          <el-skeleton :row="5" animated />
        </div>
        <div v-else>
          <div v-for="(friend,index) in messageFriends" :key="index">
            <!-- 留言列表 -->
            <div :class="{'friend-card-s': friend.id === newCurrentFriendId}" class="friend-card">
              <div class="friend-avatar">
                <Image :src="friend.headImage" :isHeadImg="true" :isExpert="true"></Image>
                <el-badge v-if="friend.unread_num > 0" class="badgePosition" :value="friend.unread_num" :max="99" type="danger"></el-badge>
              </div>
              <div class="friend-text">
                <div class="friend-info">
                  <div class="friend-name">{{friend.name}}</div>
                </div>
                <!-- 最后一条留言 -->
                <div class="latest-message">
                  <!-- 消息数据 -->
                  <div v-if="newCurrentFriend?.lastMsg">
                    <div class="latest-message-content">{{newCurrentFriend.lastMsg}}</div>
                    <!-- 图片消息 -->
                    <!-- <div v-if="friend.lastMessage.type === 'TIMImageElem'" class="latest-message-content">[图片]</div> -->
                  </div>
                  <!-- 时间 -->
                  <div class="latest-message-time" v-if="newCurrentFriend?.lastContactTime">
                    {{ judgeShowLastMessageTime(newCurrentFriend.lastContactTime) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="im-right-bar">
      <!-- 名字 -->
      <div class="title-bar" id="titleBar">
        <!-- 留言窗口 -->
        <div class="current-title">
          <div class="current-friend-avatar" v-if="newCurrentFriendId">
            <Image :src="newCurrentFriend?.headImage" :isHeadImg="true" :isExpert="true"></Image>
          </div>
          <div class="current-friend-name">{{ newCurrentFriend ? newCurrentFriend.name : "" }}</div>
        </div>
        <el-icon @click="hideWindow()" class="el-icon-circle-close close-button" style="color: #666666"></el-icon>
      </div>
      <!-- 留言内容 -->
      <div @dragover="fileDragover" @drop="fileDrop" ref="messageBlock" class="message-block" v-if="newCurrentFriendId" v-loading="updating" element-loading-background="rgba(255, 255, 255, 1)">
        <!-- 上拉加载 -->
        <div @click="seeMore" class="shangla" v-if="newCurrentFriend?.messages.length !== newCurrentFriend?.total">
          <img src="@/assets/images/shangla.png" alt="">
          <span>查看更多</span>
        </div>
        <div>
          <!-- 提示内容 -->
          <div v-if="showTip">
            <div class="message-line">
              <!-- 消息时间 -->
              <!-- <div class="message-time-line">
                {{formatFullTime((message.time)*1000)}}
                {{ tipTime }}
              </div> -->
              <!-- 提示语句 -->
              <div class="message-tip-line fs-14 fc-333">
                <p class="title">系统消息提示</p>
                <p>您可以按照以下模版发送留言：</p>
                <p>1.公司介绍 2.产品介绍 3.需要解决的技术难题 4.合作方式 5.联系电话 6.联系人等等</p>
              </div>
            </div>
          </div>
          <!-- 聊天列表 -->
          <div v-if="newCurrentFriend?.messages.length > 0">
            <div class="message-line" v-for="(message, i) in newCurrentFriend.messages" :key="i"
                :class="{'others-message': message.toMemberId + '' === myId + '', 'my-message': message.toMemberId + '' !== myId + ''}">
              <!-- 消息时间 -->
              <div class="message-time-line" v-if="showTime(i)">
                {{ formatTime(message.msgTime) }}
              </div>
              <div class="message-avatar"  :class="{'margin-time': showTime(i)}">
                <Image v-if="message.toMemberId + '' === myId + ''" :src="message.headImage" />
                <!-- 当前用户的头像是直接获取的无需操作 -->
                <img v-else :src="staticPath + avatar" />
              </div>
              <div class="message-container" :class="{'margin-time': showTime(i)}">
                <div class="message-text" >
                  <!-- 文字信息 -->
                  <div class="text" v-if="message.msgType == 0">
                      {{message.chatMsg}}
                  </div>
                  <!-- 图片信息 -->
                  <div v-else-if="message.msgType == 1" @click="showLargeImg(message.chatMsg)">
                    <img :src="message.chatMsg" style="max-width: 350px"/>
                  </div>
                  <!-- 地址位置信息 -->
                  <div class="text" v-else-if="message.msgType == 2" @click="jumpToPage(message)">
                      {{message.chatMsg}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 查看图片消息大图 -->
        <el-dialog v-model="imageMessage" :append-to-body="false">
          <div style="display:flex;align-items: center;justify-content: center;">
            <img :src="imageMsgURL" style="max-width: 100%;"/>
          </div>
        </el-dialog>
      </div>
      <!-- 留言框 -->
      <div class="input-block" v-if="newCurrentFriendId">
        <div class="function-bar">
          <div style="display:flex">
            <div v-loading="uploading" class="function-item" @click="startLeaveImage">
               <span class="tupian"></span>
               <input type="file"
                  id="imageLeavePicker" ref="imageLeavePicker"
                  accept="image/gif,image/jpeg,image/jpg,image/png,image/svg"
                  v-show="false"
                  @change="leavePicture" />
              <span class="type-text">发送图片</span>
            </div>
          </div>
          <!-- 加载条 -->
          <el-progress :percentage="imgPercentage" v-if="imgPercentage > 0 && imgPercentage < 100" style="width: 200px;"></el-progress>
        </div>
        <!-- 输入文字 -->
        <div class="input-area">
          <el-input
            ref="leaveInput"
            v-model="messageText"
            type="textarea"
            :disabled="btnDisabled"
            @keypress="textareaKeyPress"
            :rows="3"
            resize="none"
            class="resizeNone"
          ></el-input>
        </div>
        <div class="send-line">
          <el-button :disabled="btnDisabled || uploading" size="mini" style="height: 30px" @click="leaveText();" :title="sendButtonTitle">发送</el-button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters } from 'vuex';
import { staticPath,bucketMap } from "@/utils/consts";
import { ElMessage } from "element-plus";
import { getLocalTime, dateFormatToHour , dateFormatToComplete , formatFullTime } from "@/utils/time.js"
import { uploadTypeImage, cosByUploadType} from "@/api/upload"
import { randomFilename } from "@/utils/time";
import to from "await-to-js";
import { getLeaveMessageInfo,sendLeaveMessage,setLeaveMessageRead,memberDetails } from "@/api/user";
import { routerWindow } from "@/utils/tool";
import { tanVipJudge } from "@/utils/auth";


export default {
  name: "InstantMessage",
  computed: {
    sendButtonTitle(){
      // 发送按钮的标题
      if(this.uploading) return '上传图片中请稍后'
      else if(this.btnDisabled) return '发送消息中请稍后'
      return '发送'
    },
    ...mapGetters(['id', 'avatar', 'name','closeServiceWindow','leaveMessageVisible','visibility','leaveMessageClickId','leaveMessageClickInfo','imWindowVisible']),
    tipTime(){
      // 提示时间
      return formatFullTime(new Date().getTime())
    },
    expertHeadImage(){
      // 专家头像
      return this.leaveMessageClickInfo.headImage || this.resExpertInfo.headImg
    },
    expertHeadName(){
      // 专家头像
      return this.leaveMessageClickInfo.name || this.resExpertInfo.name
    },
  },
  data() {
    return {
      resExpertInfo: {  // 接口获取的专家信息
        name: '',
        headImg: ''
      },
      messageOffset: 1, // 消息记录分页
      imgPercentage: 0, // 图片上传进度
      showTip: false, // 是否展示提示
      messageText: '',  // 留言框输入
      newCurrentFriend: null, // 留言好友信息
      newCurrentFriendId: null, // 留言好友ID
      messageFriends: [], // 留言列表
      total: 0, // 系统消息总数
      offset: 1, // 消息分页
      myId: '',
      imageMessage: false,  // 图片信息放大
      imageMsgURL: '',      // 图片大图URL
      btnDisabled: false, // 发送按钮禁用
      staticPath,
      uploading: false,
      updating: false,  // 加载留言列表中
      isVip: false,
    }
  },
  directives:{
    drag(el){
      // 拉拽
      el.onmousedown = function(e){
        if(!['selfInfo','titleBar'].includes(e.target.id)) return
        let disx = e.clientX - el.offsetLeft;
        let disy = e.clientY - el.offsetTop;
        document.onmousemove = function (e){
            el.style.left = e.clientX - disx < 0 ? 0 + 'px' : e.clientX - disx +'px';
            el.style.top = e.clientY - disy < 0 ? 0 + 'px' : e.clientY - disy + 'px';
        }
        document.onmouseup = function(){
            document.onmousemove = document.onmouseup = null;
        }
      }
    },
  },
  watch: {
    imWindowVisible(val){
      // 打开聊天窗口则关闭留言窗口
      if(!val) return
      this.hideWindow()
    },
    visibility(val){
      // 返回页面的事件
      if (val) {
        // 切换到该页面时执行
        if(!localStorage.getItem("userInfo")) return  // 空判断
      }
    },
    closeServiceWindow(){
      // 退出登录关闭聊天
      this.hideWindow()
    },
    async $route() {  // 路由变化时，获取系统消息
      let userInfo = localStorage.getItem("userInfos");
      if(!userInfo) return  // 空判断
      userInfo = JSON.parse(localStorage.getItem("userInfos")).memberInfo
    },
    imageMessage(val){  // 清空IMG
      if(!val) {
        this.imageMsgURL = ''
      }
    },
    leaveMessageVisible(val){ // 聊天窗口是否打开
      if(val){  // 打开窗口
        if(this.leaveMessageClickId.length === 0) return
        this.setHeight()
        this.initPage()
      }
      else {
        // 刷新聊天列表
        this.$store.dispatch('message/updateLeaveMessage')
      }
    },
    leaveMessageClickId(){ // Id变化
      if(!this.leaveMessageVisible) return
      this.setHeight()
      this.initPage()
    },
  },
  mounted() {
    this.setHeight()
    setTimeout(()=>{
      // 加定时是因为组件在App.vue中一上来就拿数据，这时缓存中的数据不是最新的
      const userInfo = JSON.parse(localStorage.getItem('userInfo'))
      this.isVip = userInfo.isVip
      this.myId = userInfo.id
    }, 1500)

    window.onresize = (()=>{  // 重置高度
      if(this.leaveMessageVisible){
        this.setHeight()
      }
    })
  },
  methods: {
    judgeShowLastMessageTime(time){
      // 判断显示最后一条消息的格式
      const d = new Date()
      const year = d.getFullYear()
      const month = (d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1)
      const day = (d.getDate()) < 10 ? '0' + (d.getDate()) : (d.getDate())
      return (year + '-' + month + '-' + day === time.slice(0,time.indexOf(' '))) ? time.slice(time.indexOf(' ') + 1) : time.slice(0,time.indexOf(' '))
    },
    async initPage(){
      // 初始化页面
      if(this.updating) return
      this.messageOffset = 1  // 每次打开回到第一页
      this.messageFriends = [] // 清空专家数据
      this.newCurrentFriend = null
      this.newCurrentFriendId = null
      this.showTip = false
      this.updating = true
      await this.getExpertInfo()
      await this.getMessageInfo()
    },
    jumpToPage(message){
      // 跳转页面方法
      if(message.pathApp && message.pathApp.indexOf('liveRoom?roomId=') !== -1){  //  路演跳转
        let start = message.pathApp.indexOf('liveRoom?roomId=')
        let end = message.pathApp.indexOf('&',start)
        let roomId = message.pathApp.slice(start + 16,end)
        this.$router.push('/')
        let that = this
        setTimeout(()=>{
          that.$router.push({path:'/live',query:{
            "roomId" : roomId
          }})
          that.hideWindow()
        },100)
      }
      else if(message.pathApp && message.pathApp.indexOf('dyId') !== -1){ // 动态跳转
        let start = message.pathApp.indexOf('dyId')
        let end = message.pathApp.indexOf('&subscribeId',start)
        let dynamicId = message.pathApp.slice(start + 5,end)
        setTimeout(()=>{
          routerWindow(this.$router,'/detail',{dynamicId: dynamicId})
        },100)
      }
      else if(message.pathPc){  // 其他PC路径
        setTimeout(()=>{
          routerWindow(this.$router,message.pathPc,{})
        },100)
      }
      else if(message.pathApp === '/pages/service/addReq/addReq?type=1'){ // 发布需求
        setTimeout(()=>{
          routerWindow(this.$router,'/postdemand',{})
        },100)
      }
      else if(message.pathApp === '/pages/my/company/details'){ // 修改、创建身份
        let userInfo = localStorage.getItem("userInfo");
        if(!userInfo) return
        userInfo = JSON.parse(userInfo);
        memberDetails(userInfo.id).then(res=>{
          if (!userInfo.organizationId || (res.data.organizationName.startsWith(res.data.phone) && res.data.organizationName.length === (res.data.phone + '').length)) {
            // 包括普通用户
            // this.$store.dispatch("popup/setPopup", {
            //   type: 11, // 创建身份弹窗
            //   parameter: {},
            // });
            tanVipJudge({
              pRes: res,
            })
            return;
          }
          this.$store.dispatch("popup/setPopup", {
            type: 13, // 修改数字科创
            parameter: {},
          });
        })
      }
      else if(message.pathApp === '/pages/my/emil/emil'){ // 我的邮件列表
        let that = this
        setTimeout(()=>{
          that.$router.push({
            path: '/user-center',
            query:{
              keyPath: JSON.stringify(['9']),
              page: 1
            }
          })
          that.hideWindow()
        },100)
      }
      else if(message.pathApp === '/pages/my/emil/info/info?id=xxx'){ // 邮件详情
        let that = this
        let id = message.pathApp.slice(message.pathApp.indexOf('=') + 1)
        setTimeout(()=>{
          that.$router.push({
            path: '/user-center',
            query:{
              keyPath: JSON.stringify(['9']),
              page: 1,
              detailId: id
            }
          })
          that.hideWindow()
        },100)
      }
    },
    async getExpertInfo(){
      // 获取专家详情
      this.messageFriends.push({
        id: this.leaveMessageClickId,
        name: this.expertHeadName,
        headImage: this.expertHeadImage,
      })
    },
    async getMessageInfo(){
      // 获取聊天详情
      const [err,res] = await to(getLeaveMessageInfo({
        id: this.id + '/' + this.leaveMessageClickId,
        offset: this.messageOffset,
      }))
      if(err){
        console.error('获取留言详情错误:',err)
        return
      }
      // 如果有ID切换到ID
      this.switchToLeaveFriend(this.leaveMessageClickId,res.data)
      if(this.newCurrentFriendId?.length > 0){
        // 返回底部
        setTimeout(()=>{
          this.scrollMessageBlockToBottom()
          this.updating = false
        },500)
      }
    },
    async seeMore(){
      // 获取更多历史消息
      this.messageOffset += 1
      const [err,res] = await to(getLeaveMessageInfo({
        id: this.id + '/' + this.leaveMessageClickId,
        offset: this.messageOffset,
      }))
      if(err){
        console.error('获取留言详情错误:',err)
        return
      }
      this.newCurrentFriend.messages.unshift(...res.data.records.reverse())
      this.showTip = this.newCurrentFriend.messages.length === this.newCurrentFriend.total  // 显示tip
    },
    showMessageTime(){
      // 是否展示消息时间
      return true
    },
    formatTime(timeArray){
      // 时间处理
      return timeArray.map((item)=>{
        return item < 10 ? '0' + item : '' + item
      }).slice(0,3).join('-')  + ' ' + timeArray.map((item)=>{
        return item < 10 ? '0' + item : '' + item
      }).slice(3).join(':')
    },
    async switchToLeaveFriend(id,infos){
      // 切换留言好友
      this.newCurrentFriendId = id
      this.newCurrentFriend = null // 清空后赋值
      // 获取留言消息列表
      const [err] = await to(setLeaveMessageRead({chatId: this.id + '/' + this.leaveMessageClickId}))
      let lastMsg = ''
      if(infos.total !== 0){
        lastMsg = infos.records[0].msgType == 1 ? '[图片]' : infos.records[0].chatMsg
      }
      this.resExpertInfo.name = infos.name
      this.resExpertInfo.headImg = infos.headImage
      this.newCurrentFriend = {
        headImage: this.expertHeadImage,
        total: infos.total,
        name: this.expertHeadName,
        messages: infos.records.reverse(),
        lastContactTime: infos.records.length > 0 ? this.formatTime(infos.records[0].msgTime) : '',
        lastMsg: lastMsg,
      }
      this.showTip = this.newCurrentFriend.messages.length === this.newCurrentFriend.total  // 显示tip
      if(err){
        console.error('设定留言已读错误',err)
        return
      }
    },
    formatFullTime,
    showTime(index){  // 判断是否间隔 5分钟
      if(!this.newCurrentFriend?.messages[index]) return false
      else if(index === 0) return true
      let dateStr = this.formatTime(this.newCurrentFriend?.messages[index].msgTime).replace(/-/g,'/')
      let dateStr1 = this.formatTime(this.newCurrentFriend?.messages[index - 1].msgTime).replace(/-/g,'/')
      if(new Date(dateStr).getTime() - new Date(dateStr1).getTime() > 300000){
        return true
      }
      return false
    },
    setHeight(){
      // 设定高度
      this.$nextTick(()=>{
        let container = document.getElementById('imContainer')
        if(!container) return
        const height = document.documentElement.offsetHeight * 0.8 >= 300 ? document.documentElement.offsetHeight * 0.8 : 300
        container.style.height = height  +'px'
        let friendList = document.getElementById('friendList')
        friendList.style.height = height - 70  +'px'
      })
    },
    strToObj (str) {
      // 要对字符串进行判断
      try {
        return JSON.parse(str) // 如果不是json字符串就会抛异常
      } catch(e) {
          return '[图片]' // 旧的消息非json只有图片
      }
    },
    dateFormatToComplete,
    dateFormatToHour,
    getLocalTime,
    textareaKeyPress(e) {
       // 回车发送留言
      if (e.keyCode === 13) {
        this.leaveText()
        e.preventDefault()
        return false;
      }
    },
    async leaveText(){
      // 发送留言
      if(this.messageText.length === 0 ){
        ElMessage.warning('请输入留言！')
        return false;
      }
      else if (this.messageText.trim().length === 0 ) {
        ElMessage.warning('请勿只输入空格或换行！')
        return false;
      }
      this.btnDisabled = true
      const [err,res] = await to(sendLeaveMessage({
        "chatMsg": this.messageText,
        "chatType": '0',
        "fromName": this.name,
        "msgType": '0',
        "headImage": this.newCurrentFriend.headImage,
        "name": this.newCurrentFriend.name,
        "id": this.newCurrentFriendId
      }))
      this.btnDisabled = false
      if(err){
        console.error('发送文字消息错误:',err)
        return
      }
      if(res.code === "Sx200"){
        // 发送成功 将这条消息插入聊天框
        this.newCurrentFriend.messages.push({
          chatId: this.id + '/' + this.leaveMessageClickId,
          chatMsg: this.messageText,
          chatType: "0",
          msgTime: [
            new Date().getFullYear(),
            new Date().getMonth() + 1,
            new Date().getDate(),
            new Date().getHours(),
            new Date().getMinutes(),
            new Date().getSeconds(),
          ],
          msgType: "0",
          toHeadImg: this.expertHeadImage,
          toIm: this.expertHeadName
        })
        this.newCurrentFriend.lastMsg = this.messageText
        this.newCurrentFriend.lastContactTime = this.formatTime([
          new Date().getFullYear(),
          new Date().getMonth() + 1,
          new Date().getDate(),
          new Date().getHours(),
          new Date().getMinutes(),
          new Date().getSeconds(),
        ])
        this.newCurrentFriend.total += 1  // 多加一条
        this.messageText = '' // 清空输入
      }
      // 发送消息 插入消息框
      this.scrollMessageBlockToBottom()
    },
    uploadImg (file) {
      // 上传图片
      var self = this
      let cos = cosByUploadType(uploadTypeImage)
      let bucket = bucketMap.image
      return new Promise((resolve, reject) => {
        cos.putObject(
          {
            Bucket: bucket, /* 必须 */
            Region: "ap-shanghai",     /* 存储桶所在地域，必须字段 */
            Key: 'pc_message_' + randomFilename(),              /* 必须 */
            StorageClass: 'STANDARD',
            Body: file, // 上传文件对象
            onProgress: function (progressData) {
              self.imgPercentage = parseInt(progressData.percent * 100)
            }
          },
          function (err, data) {
            if (err) {
              reject(err)
            } else {
              resolve(data)
            }
          }
        );
      })
    },
    async leavePicture(){
      // 留言图片
      let pic = document.getElementById('imageLeavePicker').files
      if(pic?.length === 0 || !pic) return
      let picFormat = [ "image/gif","image/jpeg","image/jpg","image/png","image/svg"]
      if(!picFormat.includes(pic[0].type)){
        ElMessage.error('请检查图片格式，仅允许发送jpg png jpeg gif bmp格式的图片')
        return
      }
      else if(pic[0].type === "image/jpeg" && pic[0].name.slice(-4)==='jfif'){
        ElMessage.error('请检查图片格式，仅允许发送jpg png jpeg gif bmp格式的图片')
        return
      }
      this.uploading = true
      // 发送图片 插入消息框
      let res1 = await this.uploadImg(pic[0])
      const [err,res] = await to(sendLeaveMessage({
        "chatMsg": 'https://'+ res1.Location,
        "chatType": '0',
        "fromName": this.name,
        "msgType": '1',
        "headImage": this.newCurrentFriend.headImage,
        "name": this.newCurrentFriend.name,
        "id": this.newCurrentFriendId
      }))
      if(err){
        console.error('发送图片消息错误:',err)
        return
      }
      if(res.code === "Sx200"){
        // 发送成功 将这条图片消息插入聊天框
        this.newCurrentFriend.messages.push({
          chatId: this.id + '/' + this.leaveMessageClickId,
          chatMsg: 'https://'+ res1.Location,
          chatType: "0",
          msgTime: [
            new Date().getFullYear(),
            new Date().getMonth() + 1,
            new Date().getDate(),
            new Date().getHours(),
            new Date().getMinutes(),
            new Date().getSeconds(),
          ],
          msgType: "1",
          toHeadImg: this.expertHeadImage,
          toIm: this.expertHeadName
        })
        this.newCurrentFriend.total += 1  // 多加一条
      }
      this.newCurrentFriend.lastMsg = '图片'
      this.newCurrentFriend.lastContactTime = this.formatTime([
        new Date().getFullYear(),
        new Date().getMonth() + 1,
        new Date().getDate(),
        new Date().getHours(),
        new Date().getMinutes(),
        new Date().getSeconds(),
      ])
      this.uploading = false
      setTimeout(()=>{
        this.scrollMessageBlockToBottom()
      },500)
    },
    startLeaveImage() {
      // 从接口获取聊天许可
      document.getElementById('imageLeavePicker').value = ''  // 清空之前的值，触发onchange
      this.$refs.imageLeavePicker.click()
    },
    hideWindow() {
      // 关闭留言窗口
      this.$store.dispatch('message/setLeaveMessageInfos',{show:false})
    },
    scrollMessageBlockToBottom() { // 自动保持在底部
      let a = this.$refs.messageBlock
      let that = this
      setTimeout(function () {
        if(a){
          a.scrollTo(0, 1 << 20)
          if(that.messageFriends?.length <= 1){
            return
          }
        }
      }, 10)
    },
    showLargeImg(val){ // 图片大图
      this.imageMessage = true
      this.imageMsgURL = val
    },
  },
}
</script>

<style scoped lang="scss">

.shangla {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  img {
    width: 30px;
    height: 30px;
    margin-top: 5px;
  }
  span {
    font-size: 12px;
    color: #666;
  }
}
.badgePosition {
  position: absolute;
  top: -4px;
  right: -7px;
}
.im-container {
  z-index: 2003;
  position: fixed;
  box-shadow: 0 10px 10px rgba(107, 107, 107, 0.22);
  bottom: 10px;
  right: 10px;
  display: flex;
  width: 930px;
  // height: 900px;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  .im-left-bar {
    width: 303px;
    height: 100%;
    background: #FBFBFB;
    .im-self-info {
      width: 100%;
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $--color-theme;
      .img-avatar {
        width: 52px;
        height: 52px;
        border-radius: 50%;
      }
      .im-my-name {
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        margin-left: 15px;
      }
      .vipIcon {
        margin-left: 7px;
        width: 24px;
        height: 23px;
      }
    }
    .im-self-info:active{
      cursor: move;
    }
    .friend-list {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
      // height: 590px;
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;
      .friend-card {
        width: 100%;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        .friend-labels span {
          border: 1px solid #999;
          border-radius: 4px;
          padding: 0 3px;
          font-size: 12px;
          margin-left: 4px;
          color: #999;
          max-width: 60px;
          height: 18px;
          overflow: hidden;
        }
        .friend-avatar {
          position: relative;
          margin-left: 9px;
          border-radius: 50%;
          img {
            width: 45px;
            height: 45px;
            border-radius: 50%;
          }
        }
      }
      .system-back{
        background: #D5E6F7;
      }
      .friend-card:hover {
        background: #d0d0d0;
        color: white;
      }
      .friend-card:hover .friend-labels span {
        border: white 1px solid;
        color: white;
      }
      .friend-card-s {
        background: #E6E9ED;
      }
      .friend-card-s * {
        color: #0F1C34;
      }
      .friend-card-s .friend-labels span {
        border: white 1px solid !important;
        color: #fff;
      }
    }
    .friend-list::-webkit-scrollbar {
      width: 0
    }
  }
  .im-right-bar {
    width: 627px;
    height: 100%;
    background: #fff;
    display: flex;
    flex-direction: column;
    .title-bar {
      height: 60px;
      flex-shrink: 0;
      border-bottom: 1px rgba(107, 107, 107, 0.22) solid;
      font-size: 22px;
      color: #333333;
      display: flex;
      padding: 0 15px 0 30px;
      align-items: center;
      justify-content: space-between;
      .current-title{
        display: flex;
        align-items: center;
        .current-friend-avatar{
          display: flex;
          align-items: center;
          margin-right: 10px;
          img{
            width: 30px;
            height: 30px;
            border-radius: 50%;
          }
        }
        .current-friend-name{
          max-width: 300px;
          padding-right: 10px;
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
        }
        .gotoOrgSpace{
          font-size: 18px;
          padding: 5px 10px;
          border-radius: 10px;
          margin-left: 10px;
          cursor: pointer;
          background: $--color-theme;
          color: white;
          text-align: center;
        }
      }
    }
    .title-bar:active{
      cursor: move;
    }
  }
}

.resizeNone {
  .el-textarea__inner {
    resize: none;
  }
}

.el-badge-friend {
  overflow: hidden;
  height: 20px;
  width: 64px;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: PingFang SC;
  font-weight: normal;
}

.friend-text {
  margin-left: 10px;
  height: 100%;
  flex-grow: 1;
  box-sizing: border-box;
  padding: 11px 10px 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: #666666;
}

.friend-info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.friend-name {
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
  max-width: 120px;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}

.friend-labels {
  display: flex;
}

.latest-message-time {
  font-size: 14px;
  padding-top: 2px;
  float: right;
  margin-top: -60px;
}

.latest-message {
  width: 100%;
  font-size: 12px;
  line-height: 12px;
  color: #8991A0 !important;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.latest-message-content {
  color: #8991A0 !important;
  display: inline-block;
  width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.close-button {
  cursor: pointer;
}

.message-block {
  overflow-y: scroll;
  flex-grow: 1;
  .message-line {
    padding: 8px 20px;
    display: flex;
    position: relative;
    .message-tip-line{
      width: 100%;
      text-align: center;
      margin: 22px 0;
      .title{
        margin-bottom: 10px;
      }
    }
    .message-time-line{
      font-family: PingFang SC;
      font-weight: 400;
      color: #B0B5C0;
      display: flex;
      justify-content: center;
      position: absolute;
      top: 5px;
      width: 100%;
      left: 0;
    }
  }
  .my-message {
    flex-direction: row-reverse;
  }
  .margin-time{
    margin-top: 20px;
  }
  .message-avatar {
    img {
      cursor: pointer;
      width: 38px;
      height: 38px;
      border-radius: 50%;
    }
  }
  .message-text {
    padding: 8px;
    margin-bottom: 5px;
    flex-grow: 0;
    max-width: 500px;
    border-radius: 8px;
    word-wrap: break-word;
    .system-title{
      line-height: 24px;
      font-size: 16px;
      font-weight: bold;
      padding-bottom: 5px;
    }
    .text{
      max-width: 350px;
      font-size: 14px;
      font-weight: 400;
    }
  }
}

.input-block {
  background: white;
  height: 210px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  border-top: 1px solid rgba(107, 107, 107, 0.22);
}

.function-bar {
  width: 100%;
  height: 56px;
  padding: 5px 0;
  display: flex;
  align-items: center;
  .tupian {
    display: inline-block;
    width: 27px;
    height: 25px;
    background: url('../../assets/images/tupian.png');
    background-size: 100% 100%;
  }
  .type-text{
    font-family: PingFang;
    font-weight: 400;
    padding: 5px 0;
  }
  .file {
    display: inline-block;
    width: 26px;
    height: 24px;
    background: url('../../assets/images/file.png');
    background-size: 100% 100%;
  }
}


.function-item {
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #333333;
  cursor: pointer;
  font-size: 15px;
}

.function-item:hover {
  color: $--color-theme;
  .file {
    display: inline-block;
    width: 26px;
    height: 24px;
    background: url('../../assets/images/file-2.png');
    background-size: 100% 100%;
  }
  .tupian {
    background: url('../../assets/images/tupian-2.png');
    display: inline-block;
    width: 27px;
    height: 25px;
    background-size: 100% 100%;
  }
}

.function-item div:last-child {
  font-size: 12px;
}


.input-area {
  flex-grow: 1;
  padding: 0 10px;
}

.send-line {
  height: 70px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 40px;
  .el-button{
    background: $--color-theme;
    width: 80px;
    font-family: PingFang SC;
    font-weight: 450;
    color: #FFFFFF;
    font-size: 16px;
    border-radius: 4px;
  }
}
.others-message .message-text::after{
  content: '';
  position: absolute;
  left: -5px;
  top: 5px;
  border-top: 5px solid transparent;
  border-right: 10px solid #f3f3f3;
  border-bottom: 5px solid transparent;
}
.others-message .message-text {
  line-height: 20px;
  margin-left: 10px;
  background: #F3F3F3;
  color: #333333;
  position: relative;
}
.my-message .message-text::after{
  content: '';
  position: absolute;
  right: -5px;
  top: 5px;
  border-top: 5px solid transparent;
  border-left: 10px solid $--color-theme;
  border-bottom: 5px solid transparent;
}
.my-message .message-text {
  margin-right: 10px;
  line-height: 20px;
  background: $--color-theme;
  color: white;
  position: relative;
}
.message-container{
  display: flex;
  position: relative;
}
::v-deep .el-dialog{
  background-color: rgba(255,255,255,0);
}
::v-deep .el-dialog__header{
  background: #FFFFFF;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
}
:deep() .el-dialog__body{
  background: #FFFFFF !important;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  max-height:  60vh;
  overflow-y: auto;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}
:deep() .el-dialog__body::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.el-badge__content.is-fixed.is-dot {
  right: 9px;
  top: 5px;
}
</style>
