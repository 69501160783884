import { requestVersion } from "@/utils/consts";
import { login } from '@/api/user'


export const G60InfoKey = 'G60Info'

export const getG60LocalInfo = () => {
  return localStorage.getItem(G60InfoKey) ? JSON.parse(localStorage.getItem(G60InfoKey)) : null
}

export const setLocalInfo = (info) => {
  localStorage.setItem(G60InfoKey,JSON.stringify(info))
}

export const removeG60LocalInfo = () => {
  localStorage.removeItem(G60InfoKey)
}
export const getG60Id = () => {
  return getG60LocalInfo()?.memberInfo?.id
}

export const getG60Token = () => {
  return getG60LocalInfo()?.accessToken
}

export const getG60RefreshToken = () => {
  return getG60LocalInfo()?.refreshToken
}

export const setG60NewToken = (token) => {
  if(!getG60LocalInfo()) return
  const info = getG60LocalInfo()
  info.accessToken = token
  setLocalInfo(info)
}

export const G60Login = (info)=>{
  const params = {
    machineCode: info.machineCode || '',
    version: requestVersion,
    canal: 1,
    type: 0,
    code: info.code,
    phone: info.phone,
  }
  return new Promise((resolve, reject) => {
    login(params).then(res => {
      setLocalInfo(res.data)
      resolve()
    }).catch(err => {
      reject(err)
    })
  })
}