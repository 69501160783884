import { requestVersion } from "@/utils/consts";
import { login } from '@/api/user'


export const AIInfoKey = 'AIInfo'

export const getAILocalInfo = () => {
  return localStorage.getItem(AIInfoKey) ? JSON.parse(localStorage.getItem(AIInfoKey)) : null
}

export const setAILocalInfo = (info) => {
  localStorage.setItem(AIInfoKey,JSON.stringify(info))
}

export const removeAILocalInfo = () => {
  localStorage.removeItem(AIInfoKey)
}
export const getAIId = () => {
  return getAILocalInfo()?.memberInfo?.id
}

export const getAIToken = () => {
  return getAILocalInfo()?.accessToken
}

export const getAIRefreshToken = () => {
  return getAILocalInfo()?.refreshToken
}

export const setAINewToken = (token) => {
  if(!getAILocalInfo()) return
  const info = getAILocalInfo()
  info.accessToken = token
  setAILocalInfo(info)
}

export const AILogin = (info)=>{
  const params = {
    machineCode: info.machineCode || '',
    version: requestVersion,
    canal: 1,
    type: 0,
    code: info.code,
    phone: info.phone,
  }
  return new Promise((resolve, reject) => {
    login(params).then(res => {
      setAILocalInfo(res.data)
      resolve()
    }).catch(err => {
      reject(err)
    })
  })
}