const state = {
  invitePopShow: false,
  vipPopShow: -1,
  vipPopOrderInfo: {},
  vipInfo: [],
  quotationPopShow: false,
  purchasePopShow: false,
  companyEditPopShow: false,
  personalEditPopShow: false,
  teamManagementApply: false,
  loginStatus: {
    show: false,
    change: false,
    from: '',
  }
}
const mutations = {
  SET_VIP_INFO:(state,val)=>{
    state.vipInfo = val
  },
  SET_TEAM_MANAGEMENT_APPLY:(state,val)=>{
    state.teamManagementApply = val
  },
  SET_LOGIN_STATUS_SHOW:(state,show)=>{
    state.loginStatus.show = show
  },
  SET_LOGIN_STATUS_CHANGE:(state,from)=>{
    state.loginStatus.change = !state.loginStatus.change
    state.loginStatus.from = from
  },
  SET_VIP_POP_SHOW:(state,val)=>{
    state.vipPopShow = val
  },
  SET_VIP_POP_ORDER_INFO:(state,val)=>{
    state.vipPopOrderInfo = val
  },
  SET_PURCHASE_POP_SHOW:(state,val)=>{
    state.purchasePopShow = val
  },
  SET_QUOTATION_POP_SHOW:(state,val)=>{
    state.quotationPopShow = val
  },
  SET_COMPANY_EDIT_POP_SHOW:(state,val)=>{
    state.companyEditPopShow = val
  },
  SET_PERSONAL_EDIT_POP_SHOW:(state,val)=>{
    state.personalEditPopShow = val
  },
  SET_INVITE_POP_SHOW:(state,val)=>{
    state.invitePopShow = val
  }
}
const actions = {
  setTeamApply({commit},val){
    commit('SET_TEAM_MANAGEMENT_APPLY',val)
  },
  setLoginShow({commit},show){
    commit('SET_LOGIN_STATUS_SHOW',show)
  },
  setLoginChange({commit},from=''){
    commit('SET_LOGIN_STATUS_CHANGE',from)
  },
  setInviteShow({commit},val){
    commit('SET_INVITE_POP_SHOW',val)
  },
  setVipShow({commit},val){
    commit('SET_VIP_POP_SHOW',val)
  },
  setVipInfo({commit},val){
    commit('SET_VIP_INFO',val)
  },
  setVipOrderInfo({commit},val){
    commit('SET_VIP_POP_ORDER_INFO',val)
  },
  setQuotationPopShow({commit},val){
    commit('SET_QUOTATION_POP_SHOW',val)
  },
  setPurchasePopShow({commit},val){
    commit('SET_PURCHASE_POP_SHOW',val)
  },
  setCompanyEditShow({commit},val){
    commit('SET_COMPANY_EDIT_POP_SHOW',val)
  },
  setPersonalEditShow({commit},val){
    commit('SET_PERSONAL_EDIT_POP_SHOW',val)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}