const state = {
    sdkReady: false,
}
const mutations = {
    SET_SDK_READY: (state, status) => {
        state.sdkReady = status
    },
}
const actions = {
    // 清草稿
    setSDK({ commit },status) {
        commit('SET_SDK_READY',status)
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}