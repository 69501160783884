<template>
  <div v-if="createWindowVisible">
    <div class="iconChatBox">
      <div class="img flex-dc-rc">
        <!--  查看专家按钮提示 -->
        <img src="../../assets/images/pop/vip/vip-new-window-up.png"  style="width: 304px;height: 243px;" @click="jumpToVip(0)"/>
        <img src="../../assets/images/pop/vip/vip-new-window-down.png" style="width: 304px;height: 258px;object-fit: cover;" @click="jumpToVip(2)"/>
        <img class="close-btn" src="../../assets/images/pop/publish/publish-pop-close.png" />
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import { LaTrack, routerWindow } from '../../utils/tool'

export default {
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters(['createWindowVisible'])
  },
  methods: {
    jumpToVip (type) {
      // 打开VIP页面
      LaTrack('e_create_organization_from_expert')
      this.$store.commit("message/SET_CREATE_WINDOW_VISIBLE",false)
      routerWindow(this.$router,'/vip',{setType:type})
    }
  }
}
</script>


<style scoped lang="scss">
.iconChatBox {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  z-index: 999;
  position: absolute;
  top: 0%;
  overflow: auto;
  .img {
    padding-top: 100px;
    position: relative;
    .event{
      position: absolute;
      width: 440px;
      height: 78px;
      top: 583px;
      left: 78px;
      box-sizing: border-box;
      cursor: pointer;
    }
    img {
      cursor: pointer;
      height: 50vh;
    }
    .pop-window{
    }
    .close-btn{
      color: #ffffff;
      font-size: 50px;
      cursor: pointer;
      display: grid;
      text-align: center;
      margin-top: 36px;
      width: 31px;
      height: 31px;
    }
  }
}
</style>