import {post} from "@/utils/request";
import { getTanLocalInfo,setTanLocalInfo } from "@/utils/Tan/login";

// 探角会员信息
function TanMemberInfo(memberId) {
  return post('/tj/member/login', {memberId})
}

export const getTanMemberInfo = (memberId)=>{
  return new Promise((resolve, reject) => {
    TanMemberInfo(memberId).then(res => {
      setTanLocalInfo({
        ...getTanLocalInfo(),
        tjInfo:{
          ...res.data
        }
      })
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

// 探角会员注销
export function TanCancelAccount(memberId) {
  return post('/tj/member/cancel', {memberId})
}

// 探角会员信息编辑
export function editTanMemberInfo(param) {
  return post('/tj/member/edit', param)
}

// 探角邀请标识获取邀请人名称(支持未登录)
export function getTanInviteInfo(inviteTag) {
  return post('/tj/member/invite/decrypt', {inviteTag})
}

// 探角会员获取邀请标识
export function getTanInviteLink(memberId) {
  return post('/tj/member/invite', {memberId})
}