import { post } from "@/utils/request";
import { getTanId } from "@/utils/Tan/login";

// 12 : 探角企业版 精英版
// 13 : 探角旗舰版 至尊版
// 14 : 探角创新赛道

// 探角已购买vip信息列表
export function getTanBuyVipInfoList() {
  return post('/tj/vip/infoList', {
    memberId: getTanId(),
  })
}

// 探角会员vip信息
export function getTanVipInfo(vipType) {
  return post('/vip/vipInfo', {
    memberId: getTanId(),
    vipType,
  })
}

// vip信息列表
export function getTanVipInfoList(vipTypeList) {
  return post('/vip/vipInfoList', {
    memberId: getTanId(),
    vipTypeList,
  })
}