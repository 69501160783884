<template>
  <div class="container" v-show="showServiceWindow" :style="`${isOutside || notKePage ||
    (isNewUserCenter ) ? 'border: none;':''}`">
    <div v-if="!retracted">
      <div class="box bottom-line wider" @click="toAI">
        <img src="@/assets/images/AI/xiaoKeBig.png" style="width: 40px;"/>
        <div style="margin-top: 5px;">AI助手</div>
      </div>
      <!-- 小科 -->
      <!-- <div class="box bottom-line wider" @click="chatKqd" v-if="showKqd && hasTim && !isOutside && !notKePage && !isNewUserCenter">
        <img :src="kqdAvatar?.length > 0 ? kqdAvatar : require('../assets/images/user/kqd-icon.png')" style="width: 40px;"/>
        <div>小科</div>
      </div> -->
      <!-- 客服 -->
      <!-- <div class="box bottom-line wider" :class="cur==1?'top':''" @click="showWindow" @mouseenter="hoverColor('1')" @mouseleave="hoverExit('0')">
        <img src="../assets/images/manual-now.png" v-if="cur==1" style="background:#32C5FF" alt="" />
        <img src="../assets/images/manual-future.png" v-else  alt="" />
      </div> -->
      <!-- 微信 -->
      <el-popover placement="left" :width="220" trigger="hover" content="" v-if="!isOutside && !notKePage && !isNewUserCenter">
        <template #reference>
          <div class="box bottom-line" :class="cur==2?'top':''"  @mouseenter="hoverColor('2')" @mouseleave="hoverExit('0')">
            <img src="../assets/images/wx-now.png" v-if="cur==2" style="background:#32C5FF" alt="" />
            <img src="../assets/images/wx-future.png" v-else  alt="" />

          </div>
        </template>
        <div class="code" style="justify-content: center;">
          <div class="code-flex">
            <img src="@/assets/images/manualService/wechat-service.jpg" alt="" />
            <span>微信</span>
          </div>
        </div>
      </el-popover>
      <!-- app二维码 -->
      <!-- <el-popover placement="left" :width="220" trigger="hover" content="">
        <template #reference>
          <div class="box bottom-line" :class="cur==3?'top':''" @mouseenter="hoverColor('3')" @mouseleave="hoverExit('0')">
            <img src="../assets/images/app-now.png" v-if="cur==3" style="background:#32C5FF"  alt="" />
            <img src="../assets/images/app-future.png" v-else  alt="" />
          </div>
        </template>
        <div class="code">
          <div class="code-flex">
            <img src="@/assets/images/footer/ios.png" alt="" />
            <span>IOS</span>
          </div>
          <div class="code-flex">
            <img src="@/assets/images/footer/android.png" alt="" />
            <span>安卓</span>
          </div>
        </div>
      </el-popover> -->
      <!-- 400电话 -->
      <!-- <el-popover placement="left" trigger="hover" content="">
        <template #reference>
          <div class="box bottom-line wider"  :class="cur==4?'top':''" @mouseenter="hoverColor('4')" @mouseleave="hoverExit('0')">
            <img src="../assets/images/phone-now.png" v-if="cur==4" style="background:#32C5FF" alt="" />
            <img src="../assets/images/phone-future.png" v-else  alt="" />
          </div>
        </template>
        <div class="code">400-626-5298</div>
      </el-popover> -->
      <!-- 回到顶部 -->
      <div class="box bottom-line" :class="cur==5?'top':''" @click="backToTop"  @mouseenter="hoverColor('5')" @mouseleave="hoverExit('0')" v-if="!isOutside && !notKePage && !isNewUserCenter">
        <img src="../assets/images/top-now.png" v-if="cur==5" style="background:#32C5FF"  alt="" />
        <img src="../assets/images/top-future.png" v-else  alt="" />
      </div>
      <!-- 收起按钮 -->
      <div class="retract" @click="retracted = true" v-if="!isOutside && !notKePage && !isNewUserCenter">
        收起
      </div>
      <!-- 客服弹窗 可拖拽 -->
      <div v-if="showService" class="service-window" v-loading="sdkLoading" v-drag>
        <div class="title" id="serviceWindow">
          <img :src="'https://test-1304545655.cos.ap-shanghai.myqcloud.com/2022/03/20/1650437954305509'" alt="" />
          <div class="name">科企岛客服</div>
          <el-icon @click="showService = false" class="el-icon-circle-close close-button" style="color: #666666"></el-icon>
        </div>
        <div class="chat" ref="messageBlock">
          <div>
            <div class="message-line" v-for="(message,i) in chatMessages" :key="message.ID" :class="{'others-message': message.from == serviceId, 'my-message': message.from != serviceId, 'none': false}">
              <!-- 消息时间 -->
              <div class="message-time-line" v-if="showTime(i)">
                {{formatFullTime((message.time)*1000)}}
              </div>
              <!-- 头像 -->
              <div class="message-avatar" v-if="message.type || (message.type === 'TIMCustomElem' && toString(message.payload.data)?.type + '' !== '10000')">
                <img v-if="message.from == serviceId" :src="'https://test-1304545655.cos.ap-shanghai.myqcloud.com/2022/03/20/1650437954305509'" :class="{'margin-time': showTime(i)}"/>
                <img v-else :src="notKePage ? staticPath + TpsAvatar : staticPath + avatar" />
              </div>
              <div class="message-text" v-if="message.type && message.type !== 'TIMCustomElem'" :class="{'margin-time': showTime(i)}">
                <!-- 文字消息 -->
                <div v-if="message.type==='TIMTextElem'" class="text">
                  {{message.payload.text}}
                </div>
                <!-- 图片 @click="showLargeImg(message)" -->
                <div v-if="message.type==='TIMImageElem'" v-html="newImage(message)" ></div>
                <!-- 文件 -->
                <div v-if="message.type==='TIMFileElem'">
                  <div class="file" @click="openFile(message.payload.fileUrl, message.payload.fileName)">
                    <div class="l">
                      <span class="title">{{message.payload.fileName}}</span>
                      <span class="size">{{converFileSize(message.payload.fileSize)}}</span>
                    </div>
                    <div class="r">
                      <span v-if="getFileType(message.payload.fileName) == 'pdf'" class="pdf"></span>
                      <span v-else-if="getFileType(message.payload.fileName) == 'ppt' || getFileType(message.payload.fileName) == 'pptx'" class="ppt"></span>
                      <span v-else-if="getFileType(message.payload.fileName) == 'txt'" class="txt"></span>
                      <span v-else-if="getFileType(message.payload.fileName) == 'doc' || getFileType(message.payload.fileName) == 'docx'" class="doc"></span>
                      <span v-else-if="getFileType(message.payload.fileName) == 'xls' || getFileType(message.payload.fileName) == 'xlsx'" class="excel"></span>
                      <span v-else class="unknow"></span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 10000消息 -->
              <div class="custom-wrap" v-if="message.type === 'TIMCustomElem' && toString(message.payload.data)?.type + '' === '10000' " :class="{'margin-time': showTime(i)}">
                <div class="custom">{{ toString(message.payload.data)?.des}}</div>
              </div>
              <!-- 10001 消息 -->
              <div class="message-container" v-else-if="message.type === 'TIMCustomElem' && toString(message.payload.data)?.type + '' === '10001'" :class="{'margin-time': showTime(i)}">
                <div class="message-text">
                  <div v-if="message.type==='TIMCustomElem'" class="text">
                    {{toString(message.payload.data).des}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="input">
          <div class="send-options">
            <div
              v-loading="uploading"
              class="function-item"
              @click="startSelectImage"
            >
              <span class="tupian"></span>
              <input
                type="file"
                id="imagePicker"
                ref="imagePicker"
                accept="image/gif,image/jpeg,image/jpg,image/png,image/svg"
                v-show="false"
                @change="sendPicture"
              />
              <span class="type-text">发送图片</span>
            </div>
            <div
              v-loading="fileuploading"
              class="function-item"
              @click="startSelectFile"
            >
              <span class="file"></span>
              <input
                type="file"
                id="filePicker"
                ref="filePicker"
                v-show="false"
                @change="sendFile"
              />
              <span class="type-text">发送文件</span>
            </div>
          </div>
          <el-input
            v-model.trim="inputText"
            type="textarea"
            @keypress="textareaKeyPress"
            :rows="4"
            resize="none"
            class="input-area"
            v-loading="textLoading"
          ></el-input>
          <div class="send-btn" @click="sendText()" :class="{'disabled': btnDisabled}">发送</div>
        </div>
      </div>
    </div>
    <div v-else-if="!isOutside && !notKePage" @click="retracted = false" class="retracted">
      <p>展开</p>
    </div>
  </div>
  <div v-if="isOutside" class="outside-tool">
    <img src="@/assets/images/service/service.png" style="width: 68px;height: 90px;cursor: pointer;" @click="showWindow"/>
    <img src="@/assets/images/service/back-to-top.png" style="width: 68px;height: 74px;cursor: pointer;" @click="backToTop"/>
  </div>
  <div v-if="isNewUserCenter && showNewPop" class="n-container">
    <img src="https://ol-image-1304545655.cos.ap-shanghai.myqcloud.com/PC-2024/00/25/1706152748995783" class="avatar" @click="customerService()"/>
    <p class="title">欢迎咨询</p>
    <p class="tip">客服在线，点击咨询</p>
    <button class="button-line" @click="customerService()">点击交谈</button>
  </div>
</template>

<script>
import { environment,staticPath,serviceId,kqdId,routeHasTim,otherTim,routeNewBarTim,outsidePage,talentProjectSystemPage } from "@/utils/consts";
import { ElMessage } from "element-plus";
import TIM, { tim,updateProfile } from "@/config/WebTIMConfig";
import { canUpload } from "@/api/upload";
import { mapGetters } from 'vuex'
import { checkCommunicate } from "@/api/demand"
import { everydayGreet } from "@/api/user"
import { formatFullTime } from "@/utils/time.js"
import { getTPSId,getLocalInfo } from "@/utils/talent-project-system/login";
import { G60site,TanSite,PostLineSite,AISite } from "@/router/index";
import { getTanId } from "@/utils/Tan/login";
import { getAIId } from "@/utils/AI/login";

export default {
  data() {
    return {
      retracted: false, // 是否被收起
      userId: -1, // 用户ID
      cur:0,
      kqdAvatar: '',  // 小科头像
      serviceId, // 客服ID
      sdkLoading: true,
      uploading: false,
      fileuploading: false,
      showService: false, //是否展示弹窗
      staticPath,
      chatMessages: [], // 聊天记录
      isCompleted: false, // 是否完毕
      nextReqMessageID: '', // 续拉ID
      inputText: "",
      btnDisabled: false,
      textLoading: false, // 发送文字加载
    };
  },
  directives:{
    drag(el){
      el.onmousedown = function(e){
        if(!['serviceWindow'].includes(e.target.id)) return
        let disx = e.clientX - el.offsetLeft;
        let disy = e.clientY - el.offsetTop;
        document.onmousemove = function (e){
            el.style.left = e.clientX - disx < 0 ? 0 + 'px' : e.clientX - disx +'px';
            el.style.top = e.clientY - disy < 70 ? 70 + 'px' : e.clientY - disy + 'px';
        }
        document.onmouseup = function(){
            document.onmousemove = document.onmouseup = null;
        }
      }
    },
  },
  watch:{
    organizationType(val){
      // 已创建身份
      if((val + '').length > 0){
        this.getKqdAvatar()
      }
    },
    chatMessages(){ // 监听改变loading状态
      if(this.sdkLoading === true){
        this.sdkLoading = false
      }
    },
    closeServiceWindow(){
      // 关闭客服窗口
      this.showService = false
    },
    openServiceWindow(){
      this.showWindow()
      if(this.demandInfo.id.length > 0){  // 有承接需求
        this.sendDemandMessage()  // 发送自定义消息
      }
    },
    imWindowVisible(val){ //  关闭聊天窗口
      if(val){
        this.showService = false
      }
    },
    $route(){
      this.$nextTick(()=>{
        if(this.showService === true ) return
        if(location.href.split('#')[0].indexOf('openService=true') !== -1 && location.href.split('#')[0].indexOf('redirect') === -1){
          // this.showWindow() // 打开客服窗口
        }
      })
    },
  },
  computed: {
    showNewPop(){
      return !this.$route.query.publish && (this.$route.query.active === '0' || !this.$route.query.active)
    },
    isNewUserCenter(){
      return window.location.href.includes('/p-center') && !this.isTan
    },
    TpsAvatar(){
      return getLocalInfo()?.memberInfo.headImage
    },
    notKePage(){
      return this.isG60 || this.isTps || this.isPL || this.isTan || this.isAI
    },
    isG60(){
      return G60site || window.location.href.includes('/announce')
    },
    isTan(){
      return TanSite
    },
    isAI(){
      return AISite
    },
    isPL(){
      return PostLineSite
    },
    isTps(){
      return talentProjectSystemPage.includes(this.$route.path)
    },
    isOutside(){
      return outsidePage.includes(this.$route.path)
    },
    hasTim(){
      // 是否有聊天
      return (routeHasTim.includes(this.$route.path) || otherTim.includes(this.$route.path) || routeNewBarTim.includes(this.$route.path)) && this.$route.path !== '/business-vip'
    },
    ...mapGetters(['avatar','imWindowVisible','showServiceWindow','openServiceWindow','closeServiceWindow','demandInfo',"otherInfo","organizationType"]),
    showKqd(){
      // 是否展示小科
      if((this.organizationType || this.organizationType + '' === '0') && (this.organizationType + '').length > 0){
        // 存在组织
        return true
      }
      return false
    },
  },
  created(){
    tim.on(TIM.EVENT.MESSAGE_RECEIVED, this.msgReceived);
    tim.on(TIM.EVENT.SDK_READY, this.sdkReady);
  },
  beforeUnmount(){
    tim.off(TIM.EVENT.SDK_READY, this.sdkReady);
  },
  mounted(){
    this.$nextTick(()=>{
      if(this.showService === true ) return
      if(location.href.split('#')[0].indexOf('openService=true') !== -1 && location.href.split('#')[0].indexOf('redirect') === -1){
        // this.showWindow() // 打开客服窗口
      }
    })
  },
  methods: {
    toAI(){
      window.open(environment === 'uat' ? 'https://uaichat.keqidao.com' : 'https://aichat.keqidao.com')
    },
    customerService() {
      // 联系客服
      this.$store.commit("message/SET_OPEN_SERVICE_WINDOW");
      // 客服ID uat: 185580801622017 线上: 180517873975297
    },
    getKqdAvatar(){
      // 获取小科头像
      if(this.isTan || this.isAI) return
      let that = this
      tim.getUserProfile({
        userIDList: [ kqdId + ''] // 请注意：即使只拉取一个用户的资料，也需要用数组类型，例如：userIDList: ['user1']
      }).then((res)=>{
        // 获取小科的信息
        that.kqdAvatar = res.data.avatar
      })
    },
    chatKqd(){
      // 小科聊天
      let userInfo = localStorage.getItem('userInfo')
      if(!userInfo){
        ElMessage.warning('您尚未登录!')
        this.$router.push({path:'/login'})
        return
      }
      this.$store.dispatch("message/kqdCommunication")
    },
    toString(obj){  // 对象转字符串
      return JSON.parse(obj)
    },
    async sendOtherMessage(){
      // 动态或新闻消息
      this.btnDisabled = true
      let message = tim.createTextMessage({
        to: this.serviceId + '', //消息接收方的userID,字符串类型
        conversationType: TIM.TYPES.CONV_C2C, //类型：端到端会话
        payload: {
          text: `我对“${this.otherInfo.title}”感兴趣，请问方便沟通吗？`
        }
      })
      tim.sendMessage(message).then((res) => {
        this.chatMessages.push(res.data.message)
        this.btnDisabled = false
        this.scrollMessageBlockToBottom()
        this.textLoading = false
      }).catch((error)=>{
        console.error('send other custom message error:',error);
        this.textLoading = false
      })
      updateProfile()
      this.$store.commit('message/SET_OTHER_INFO',{id: '', title: ''})
    },
    async sendDemandMessage(){  // 承接需求消息
      const isFirst = await checkCommunicate(this.demandInfo.id, serviceId)
      if(!isFirst.data.firstSend) return
      let customElem = {
        "des": this.demandInfo.title,
        "id": this.demandInfo.id, // 需求id
        "source": 'pc',
        "type": 10000
      }
      let message = await tim.createCustomMessage({
        to: ''+ serviceId,
        conversationType: TIM.TYPES.CONV_C2C,
        payload: {
          data: JSON.stringify(customElem),
          description: this.demandInfo.title,
          extension: '10000'  // 用于标识‘需求服务’的类型
        }
      })
      this.btnDisabled = true
      this.textLoading = true
      tim.sendMessage(message).then(res => {  // 等待发送10000消息后再执行之后语句
        this.chatMessages.push(res.data.message)
        let cuntomElem = {
          "des": isFirst.data.text,
          "id": this.demandInfo.id,
          "source": 'pc',
          "type": 10001
        }
        let message1 = tim.createCustomMessage({
          to: '' + serviceId,
          conversationType: TIM.TYPES.CONV_C2C,
          payload: {
            data: JSON.stringify(cuntomElem),
            extension: '10001' // 用于标识‘一键沟通’的类型
          }
        })
        tim.sendMessage(message1).then(res => {
          this.chatMessages.push(res.data.message)
          this.btnDisabled = false
          this.textLoading = false
          this.scrollMessageBlockToBottom()
          this.$store.commit('message/SET_DEMAND_INFO',{id: '', title: ''})
          updateProfile()
        }).catch((err)=>{
          console.error('send demand custom message error:',err);
          this.btnDisabled = false
          this.textLoading = false
        })
      }).catch((err)=>{
        console.error('send demand custom message error:',err);
        this.btnDisabled = false
        this.textLoading = false
      })
    },
    formatFullTime,
    showTime(index){  // 判断是否间隔 5分钟
      if(!this.chatMessages[index]) return false
      if(index === 0 || this.chatMessages[index].time - this.chatMessages[index - 1].time > 3000){
        return true
      }
      return false
    },
    msgReceived(event){ // 收到消息监听
      if(event.data[0].from != this.serviceId){
        return
      }
      this.chatMessages.push(event.data[0])
      if(this.showService){
        this.$nextTick(()=>{
          this.scrollMessageBlockToBottom()
        })
      }
    },
    converFileSize (limit) {
      var size = "";
      if( limit < 0.1 * 1024 ){ //如果小于0.1KB转化成B
          size = limit.toFixed(2) + "B";
      }else if(limit < 0.1 * 1024 * 1024 ){//如果小于0.1MB转化成KB
          size = (limit / 1024).toFixed(2) + "KB";
      }else if(limit < 0.1 * 1024 * 1024 * 1024){ //如果小于0.1GB转化成MB
          size = (limit / (1024 * 1024)).toFixed(2) + "MB";
      }else{ //其他转化成GB
          size = (limit / (1024 * 1024 * 1024)).toFixed(2) + "GB";
      }
      var sizestr = size + ""
      var len = sizestr.indexOf(".");
      var dec = sizestr.substr(len + 1, 2);
      if(dec == "00"){//当小数点后为00时 去掉小数部分
          return sizestr.substring(0,len) + sizestr.substr(len + 3,2);
      }
      return sizestr;
    },
    getFileType(fileName) {
      var index= fileName.lastIndexOf(".");//获取最后一个.的位置
      var ext = fileName.substr(index+1);//获取后缀
      return ext
    },
    openFile (url, name) {
      const ext = this.getFileType(name)
      if (ext == 'doc' || ext == 'docx' || ext=='ppt' || ext=='pptx' || ext=='xls' || ext=='xlsx') {
        // embed 只显示内容区
        window.open('https://view.officeapps.live.com/op/embed.aspx?src=' + url)
      } else if (ext == 'txt'){
        // 解决txt预览乱码问题
        const req = new XMLHttpRequest();
        req.open("get", url, true);
        req.responseType = "blob";
        req.onload = function () {
          // 在预览txt时需要转换成utf-8
          // const data = new Blob([req.response], {type: 'application/json;charset=utf-8'});
          const reader = new FileReader()
          reader.onload = function () {
            let data = ''
            if(reader.result.indexOf('�') !== -1) data = new Blob([req.response], {type: 'charset=utf-8'});
            else data = new Blob([req.response], {type: 'application/json;charset=utf-8'});
            let blobUrl = window.URL.createObjectURL(data);
            window.open(blobUrl)
          }
          reader.readAsText(this.response);
        }
        req.send()
      } else if (ext == 'pdf') {
          window.open(url)
      }else {
        ElMessage.error('暂不支持预览此类文件')
      }
    },
    newImage(val,big = 0) { // 图片初始化 big=1则将图片放大
      if(!val) return // 无图则返回
      let w = val.payload.imageInfoArray[0].width
      let h = val.payload.imageInfoArray[0].height
      // 图片长宽为0，信息以JSON格式存在url中
      if(w === 0 || h === 0){
        let imgInfo = JSON.parse(val.payload.imageInfoArray[0].url)
        w = imgInfo[0].Width
        h = imgInfo[0].Height
        if(w>230){ h = h/(w/230);w = 230}
        if(big === 1){
          h*=1.5
          w*=1.5
        }
        return `<img style='width:${w}px;height:${h}px;' src='${imgInfo[0].URL}'/>`
      }
      if(w>230){ h = h/(w/230);w = 230}
      if(big === 1){
        h*=1.5
        w*=1.5
      }
        return `<img style='width:${w}px;height:${h}px;' src='${val.payload.imageInfoArray[0].imageUrl}'/>`
    },
    sendText() {
      if (this.btnDisabled) return
      let that = this
      setTimeout(() => {
        that.btnDisabled = false
      },1000)
      this.btnDisabled = true
      if (!this.inputText) {
        ElMessage.warning('请勿只输入空格或换行！')
        return false;
      }
      this.inputText = this.inputText.trim()
      // if (this.isVip === false && !this.messageUnlimited) {
      //   this.$store.dispatch("message/hideIMWindow")
      //   this.$store.dispatch("message/startQuickChat")
      //   return
      // }
      this.textLoading = true
      let message = tim.createTextMessage({
        to: this.serviceId + '', //消息接收方的userID,字符串类型
        conversationType: TIM.TYPES.CONV_C2C, //类型：端到端会话
        payload: {
          text: this.inputText
        }
      })
      tim.sendMessage(message).then((res) => {
        this.chatMessages.push(res.data.message)
        this.btnDisabled = false
        this.inputText = ''
        this.scrollMessageBlockToBottom()
        this.textLoading = false
      }).catch((error)=>{
        console.error('send message error:',error);
        this.textLoading = false
      })
      updateProfile()
    },
    scrollMessageBlockToBottom() { // 自动保持在底部
      let a = this.$refs.messageBlock
      setTimeout(function () {
        if(a){
          a.scrollTo(0, 1 << 20)
        }
      }, 10)
    },
    textareaKeyPress(e) { // 回车发送 阻止浏览器默认换行工作
      if (e.keyCode === 13) {
        this.sendText()
        e.preventDefault()
        return false;
      }
    },
    sdkReady(){ // SDK加载后获取客服聊天记录
      this.sdkLoading = false
      this.getKqdAvatar()
      let that = this
      // 打开某个会话时，第一次拉取消息列表
      tim.getMessageList({conversationID: 'C2C' + this.serviceId, count: 15})
      .then(function(imResponse) {
        that.nextReqMessageID = imResponse.data.nextReqMessageID; // 用于续拉，分页续拉时需传入该字段。
        that.isCompleted = imResponse.data.isCompleted; // 表示是否已经拉完所有消息。
        that.chatMessages = imResponse.data.messageList
        that.scrollMessageBlockToBottom()
      });
    },
    startSelectFile() {
      // let {level, organizationVipEndTime, organizationType} = getUserInfo()
      // if(!((organizationType === 1 && organizationVipEndTime) || level > 0)){
      //     return
      // }
      this.$refs.filePicker.click();
    },
    startSelectImage() {
      // let {level, organizationVipEndTime, organizationType} = getUserInfo()
      // if(!((organizationType === 1 && organizationVipEndTime) || level > 0)){
      //     return
      // }
      this.$refs.imagePicker.click();
    },
    async sendFile () {
      let file = document.getElementById('filePicker').files
      if (file) {
        //判断文件格式
        var index= file[0].name.lastIndexOf(".");//获取最后一个.的位置
        var ext = file[0].name.substr(index+1);//获取后缀
        // var pattern = /^(doc|docx|xls|xlsx|txt|html|htm|zip|rar|pdf)$/;
        // if(!pattern.test(ext)) {
        //   ElMessage.warning('文件格式不支持！')
        //   return
        // }
        // var filename = file['name'];
        try {
          let res = await canUpload(file[0].name)
          if (res.data) {
            this.fileuploading = true;
            let message = tim.createFileMessage({
              to: this.serviceId + '',
              conversationType: TIM.TYPES.CONV_C2C,
              payload: {
                file: document.getElementById('filePicker')
              },
              onProgress: (event) => {
                console.log('file uploading:', event)
              }
            })
            message.payload.uuid = message.payload.uuid +'.'+ ext
            tim.sendMessage(message).then((res) => {
              this.chatMessages.push(res.data.message)
              this.fileuploading = false
              this.scrollMessageBlockToBottom()
            }).catch(err => {
              this.fileuploading = false  // 上传文件按钮停止加载
              ElMessage.error(err)
            })
          } else {
            ElMessage.error('暂不支持此类型上传')
          }
        } catch (err) {
          console.error('send file error:',err)
        }
      }
    },
    async sendPicture() {
      //   if (this.isVip === false) {
      //     this.$store.dispatch("message/hideIMWindow");
      //     this.$store.dispatch("message/startQuickChat");
      //     return;
      //   }
      let pic = document.getElementById("imagePicker").files;
      let picFormat = [
        "image/gif",
        "image/jpeg",
        "image/jpg",
        "image/png",
        "image/svg",
      ];
      if (!picFormat.includes(pic[0].type)) {
        ElMessage.error(
          "请检查图片格式，仅允许发送jpg png jpeg gif bmp格式的图片"
        );
        return;
      } else if (
        pic[0].type === "image/jpeg" &&
        pic[0].name.slice(-4) === "jfif"
      ) {
        ElMessage.error(
          "请检查图片格式，仅允许发送jpg png jpeg gif bmp格式的图片"
        );
        return;
      }
      this.uploading = true;
      let message = tim.createImageMessage({
        to: this.serviceId + "",
        conversationType: TIM.TYPES.CONV_C2C,
        payload: {
          file: document.getElementById("imagePicker"),
        },
        onProgress: (event) => {
          console.log("file uploading:", event);
        },
      });
      tim
        .sendMessage(message)
        .then((res) => {
          this.chatMessages.push(res.data.message)
          this.uploading = false;
          this.scrollMessageBlockToBottom()
        })
        .catch((err) => {
          this.uploading = false;
          ElMessage.error(err);
        });
    },
    async showWindow() {  // 展示客服弹窗
      if(this.isTps){
        if(!getTPSId()) return
        this.userId = getTPSId()
      }
      else if(this.isTan){
        if(!getTanId()) return
        this.userId = getTanId()
      }
      else if(this.isAI){
        if(!getAIId()) return
        this.userId = getAIId()
      }
      else{
        let userInfo = localStorage.getItem('userInfo')
        if(userInfo){
          this.userId = JSON.parse(userInfo).id
        }
        else{
          ElMessage.warning('您尚未登录!')
          this.$router.push({path:'/login',query: {'redirect': '/','openService': true}})
          return
        }
      }
      this.showService = !this.showService;
      if(this.showService){
        if(this.imWindowVisible){ // 关闭IM窗口
          this.$store.dispatch('message/hideIMWindow')
        }
        await everydayGreet(this.userId)
        if(this.otherInfo.id.length > 0){ // 发送完每日联系消息后 动态或新闻联系
          this.sendOtherMessage() // 发送文字消息
        }
      }
      this.$nextTick(()=>{
        this.scrollMessageBlockToBottom()
      })
    },
    backToTop() {
      // 回到顶部
      let top = document.scrollingElement.scrollTop;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.scrollingElement.scrollTop = top -= 100;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },
    // 变色
    hoverColor(val){
      this.cur=val
    },
    hoverExit(val){
      this.cur=val
    }
  },
};
</script>

<style lang="scss" scoped>
.outside-tool{
  position: fixed;
  bottom: 10px;
  right: 10px;
  img{
    background-color: #f0f2f5;
    display: block;
  }
}
.n-container{
  position: fixed;
  bottom: 75px;
  right: 10px;
  width: 154px;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px 5px rgba(237,237,237,0.5);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding-top: 24px;
  z-index: 2020;
  .avatar{
    width: 70px;
    height: 70px;
    margin-bottom: 16px;
    cursor: pointer;
  }
  .title{
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: #111111;
    line-height: 22px;
    margin-bottom: 3px;
  }
  .tip{
    width: 100%;
    text-align: center;
    font-size: 12px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 12px;
    margin-bottom: 21px;
  }
  .button-line{
    width: 154px;
    height: 32px;
    background: $--color-theme;
    border-radius: 0px 0px 6px 6px;
    border: none;
    font-size: 14px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    cursor: pointer;
  }
}
.container {
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 50px;
  background: #ffffff;
  border-radius: 4px;
  box-sizing: border-box;
  border: 1px solid #D8D8D8;
  color: black;
  z-index: 2002;
  .service-window {
    position: fixed;
    right: 10px;
    bottom: 10px;
    box-shadow: 1px 3px 20px 0px rgba(15, 28, 52, 0.3);
    border-radius: 16px;
    height: 80vh;
    min-height: 300px;
    width: 930px;
    background: rgba(255, 255, 255);
    .title {
      height: 80px;
      border-bottom: 1px solid #dfe0e5;
      box-sizing: border-box;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      .close-button {
        position: absolute;
        right: 20px;
        cursor: pointer;
      }
      img {
        width: 50px;
        height: 50px;
        margin: 0 10px;
        border-radius: 50%;
      }
      .name {
        font-size: 18px;
        color: black;
      }
    }
    .chat {
      height: calc(80vh - 260px);
      min-height: 110px;
      background: #ffffff;
      overflow: auto;
      .none{
        display: none !important;
      }
      .message-line {
        padding: 8px 20px;
        display: flex;
        position: relative;
        .message-time-line{
          font-family: PingFang SC;
          font-weight: 400;
          color: #B0B5C0;
          display: flex;
          justify-content: center;
          position: absolute;
          top: 5px;
          width: 100%;
          left: 0;
        }
        .margin-time{
          margin-top: 20px;
        }
        .message-avatar {
          img {
            cursor: pointer;
            width: 38px;
            height: 38px;
            border-radius: 50%;
          }
        }
        .message-text {
          padding: 8px;
          margin-bottom: 5px;
          flex-grow: 0;
          max-width: 500px;
          border-radius: 8px;
          word-wrap: break-word;
        }
      }
      .my-message {
        flex-direction: row-reverse;
      }
    }
    .input {
      border-top: 1px solid #dfe0e5;
      height: 180px;
      background: #ffffff;
      border-bottom-right-radius: 16px;
      border-bottom-left-radius: 16px;
      display: flex;
      align-items: center;
      position: relative;
      flex-direction: column;
      .send-options {
        width: 100%;
        display: flex;
        justify-content: flex-start;
      }
      .input-area {
        margin-top: 10px;
        width: 95%;
      }
      .send-btn {
        cursor: pointer;
        border-radius: 4px;
        position: absolute;
        bottom: 30px;
        right: 40px;
        background: $--color-theme;
        padding: 5px 10px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #ffffff;
      }
      .disabled{
        background: #80899A !important;
        color: black !important;
      }
    }
  }
  .wider{
    img{
      // width: 40px !important;
      // height: 56px;
    }
  }
  .retracted{
    position: fixed;
    bottom: 10px;
    right: 10px;
    background: #ffffff;
    width: 50px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #D8D8D8;
    height: 16px;
    text-align: center;
    line-height: 16px;
    cursor: pointer;
  }
  .retract{
    text-align: center;
  }
  .box {
    cursor: pointer;
    padding: 0px 0px;
    width: 50px;
    height: 67px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // img {
    //   width: 32px;
    //   height: 47px;
    // }
  }
  .top {
    background:#32C5FF;
  }
  .bottom-line {
    border-bottom: 1px solid #dfe0e5;
  }
}
.code {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-family: PingFang SC;
  font-weight: 500;
  color: #000;
  font-size: 18px;
  img{
    width: 88px;
    height: 88px;
  }
  .code-flex {
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 88px;
      height: 88px;
    }
    span {
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #0f1c34;
    }
  }
}
.function-item {
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #333333;
  cursor: pointer;
  font-size: 15px;
  margin-top: 5px;
  .tupian {
    display: inline-block;
    width: 27px;
    height: 25px;
    background: url("../assets/images/tupian.png");
    background-size: 100% 100%;
  }
  .file {
    display: inline-block;
    width: 26px;
    height: 24px;
    background: url("../assets/images/file.png");
    background-size: 100% 100%;
  }
  .type-text {
    margin-top: 5px;
  }
}
.function-item:hover {
  color: #f57b15;
  .file {
    display: inline-block;
    width: 26px;
    height: 24px;
    background: url("../assets/images/file-2.png");
    background-size: 100% 100%;
  }
  .tupian {
    background: url("../assets/images/tupian-2.png");
    display: inline-block;
    width: 27px;
    height: 25px;
    background-size: 100% 100%;
  }
}
.others-message .message-text::after{
  content: '';
  position: absolute;
  left: -5px;
  top: 5px;
  border-top: 5px solid transparent;
  border-right: 10px solid #f3f3f3;
  border-bottom: 5px solid transparent;
}
.others-message .message-text {
  line-height: 18px;
  margin-left: 10px;
  background: #F3F3F3;
  color: #333333;
  position: relative;
  .unread-sign::after{
    content: '';
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background-color: red;
    position: absolute;
    right: -10px;
    top: 5px;
  }
  .file {
    overflow: hidden;
    padding: 16px 17px 12px 17px;
    cursor: pointer;
    background: #fff;
    .l {
      float: left;
      span {
        display: block;
      }
      .title {
        color: #333;
        font-size: 16px;
        margin-bottom: 10px;
        width: 140px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .size {
        color: #80899A;
        font-size: 14px;
      }
    }
    .r {
      float: right;
      margin-left: 6px;
      span{
        display: inline-block;
        width: 46px;
        height: 58px;
      }
      .doc {
        background: url('../assets/images/file_icon-doc.png');
        background-size: 100% 100%;
      }
      .ppt {
        background: url('../assets/images/file_icon-ppt.png');
        background-size: 100% 100%;
      }
      .txt {
        background: url('../assets/images/file_icon-txt.png');
        background-size: 100% 100%;
      }
      .pdf {
        background: url('../assets/images/file_icon-pdf.png');
        background-size: 100% 100%;
      }
      .excel {
        background: url('../assets/images/file_icon-excel.png');
        background-size: 100% 100%;
      }
      .unknow {
        background: url('../assets/images/file_icon-unknow.png');
        background-size: 100% 100%;
      }
    }
  }
  .box {
    overflow: hidden;
    padding: 12px 12px 8px 12px;
    cursor: pointer;
    border: 1px dashed #C4DAFF;
    .l {
      float: left;
      span {
        color: #80899A;
        font-size: 14px;
        display: block;
      }
      .title {
        color: #000;
        font-size: 16px;
        margin-bottom: 6px;
      }
      .time {
        color: #333;
        font-size: 14px;
      }
    }
    .r {
      float: right;
      margin-left: 6px;
      span{
        display: inline-block;
        width: 50px;
        height: 50px;
        color: #fff;
        background: #1265F1;
        border-radius: 5px;
        text-align: center;
        line-height: 50px;
      }
    }
  }
  .share-box{
    display: flex;
    align-items: center;
    cursor: pointer;
    background: white;
    border: 2px dashed #FFE8BB;
    border-radius: 4px;
    padding: 6px 10px;
    .l{
      display: flex;
      flex-direction: column;
      max-width: 160px;
      img{
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
      .title{
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 600;
        color: #0F1C34;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .name{
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #8991A0;
        margin-top: 20px;
      }
    }
    .expert{
      display: flex;
      max-width: 270px;
      flex-direction: column;
      img{
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
      .top-info{
        display: flex;
        .name-info{
          display: flex;
          flex-direction: column;
          margin-left: 10px;
          .top{
            display: flex;
            .name{
              max-width: 95px;
              overflow: hidden;
              text-overflow: ellipsis;
              word-break: break-all;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              font-size: 18px;
              font-family: PingFang SC;
              font-weight: 600;
              color: #0F1C34;
            }
            .position{
              margin-left: 10px;
              height: 20px;
              font-size: 14px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #6C4FF6;
              background: #EBE5FF;
              border-radius: 2px;
              padding: 5px 10px;
            }
          }
          .belong{
            max-width: 220px;
            margin-top: 10px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #8991A0;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
            white-space: nowrap;
          }
        }
      }
      .direction{
        margin-top: 15px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #3C4351;
      }
    }
    .r{
      img{
        margin-top: 15px;
        width: 52px;
        height: 52px;
      }
    }
  }
}
.my-message .message-text::after{
  content: '';
  position: absolute;
  right: -5px;
  top: 5px;
  border-top: 5px solid transparent;
  border-left: 10px solid $--color-theme;
  border-bottom: 5px solid transparent;
}
.my-message .message-text {
  margin-right: 10px;
  line-height: 18px;
  background: $--color-theme;
  color: white;
  position: relative;
  .file {
    overflow: hidden;
    padding: 16px 17px 12px 17px;
    cursor: pointer;
    background: #fff;
    .l {
      float: left;
      span {
        display: block;
      }
      .title {
        color: #333;
        font-size: 16px;
        margin-bottom: 10px;
        width: 140px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .size {
        color: #80899A;
        font-size: 14px;
      }
    }
    .r {
      float: right;
      margin-left: 6px;
      span{
        display: inline-block;
        width: 46px;
        height: 58px;
      }
      .doc {
        background: url('../assets/images/file_icon-doc.png');
        background-size: 100% 100%;
      }
      .ppt {
        background: url('../assets/images/file_icon-ppt.png');
        background-size: 100% 100%;
      }
      .txt {
        background: url('../assets/images/file_icon-txt.png');
        background-size: 100% 100%;
      }
      .pdf {
        background: url('../assets/images/file_icon-pdf.png');
        background-size: 100% 100%;
      }
      .excel {
        background: url('../assets/images/file_icon-excel.png');
        background-size: 100% 100%;
      }
      .unknow {
        background: url('../assets/images/file_icon-unknow.png');
        background-size: 100% 100%;
      }
    }
  }
  .box {
    overflow: hidden;
    padding: 12px 12px 8px 12px;
    border: 1px dashed #f7b066;
    cursor: pointer;
    .l {
      float: left;
      span {
        color: #fff;
        font-size: 14px;
        display: block;
      }
      .title {
        color: #fff;
        font-size: 16px;
        margin-bottom: 6px;
      }
      .time {
        color: #fff;
        font-size: 14px;
      }
    }
    .r {
      float: right;
      margin-left: 10px;
      span{
        display: inline-block;
        width: 50px;
        height: 50px;
        color: #fff;
        background: #1265F1;
        border-radius: 5px;
        text-align: center;
        line-height: 50px;
      }
    }
  }
  .share-box{
    display: flex;
    align-items: center;
    cursor: pointer;
    background: white;
    border: 2px dashed #FFE8BB;
    border-radius: 4px;
    .l{
      display: flex;
      flex-direction: column;
      img{
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
    }
    .r{
      img{
        width: 26px;
        height: 26px;
      }
    }
  }
}
.message-container{
  display: flex;
  position: relative;
}
.custom-wrap {
  width: 100%;
  .custom {
    color: #202C3F;
    font-size: 18px;
    padding: 5px 10px;
    text-align: center;
    width: 400px;
    margin: auto;
    box-sizing: border-box;
    background: #f5f5f5;
    border-radius: 8px;
  }
}
</style>