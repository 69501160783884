import {post} from "@/utils/request";
import store from '@/store';
import { TanSite } from "@/router/index";
import { getTanId } from "@/utils/Tan/login";

// 获取所有我路演的列表
export function getAllMyLives(item){
    const param = {
        offset: item.offset,
        limit: item.limit,
        memberId: item.id
    }
    return post('/live/meAll',param)
}

// 获取正在进行的路演
export function getCurrentLive(){
    return post('/live/getCurrentPlayLive',{"memberId": ''})
}

// 查询邀请我的路演列表
export function getLiveInvited({limit,offset}){
    let id = store.getters.id
    if(TanSite) id = getTanId()
    const params = {
        limit:limit,
        offset:offset,
        memberId: id,
    }
    return post('/live/invitationList',params)
}
// 根据id获取回放地址
export function getReplayAddress(id,password){
    const params = !password ? {
        "memberId": store.getters.id,
        "id": id
    } : {
        "memberId": store.getters.id,
        "id": id,
        "password": password,
    }
    return post('/live/describeMediaInfos', params)
}

// 检查敏感词(20长度)
export function sensitiveWord(text){
    const params = {
        "checkText": text
      }
    return post('/live/checkSensitiveWord',params)
}

// 编辑预约路演
export function editLive({description, name, planStartTime, thumb, password,invitationMemberIdList,type},liveID){
    const params = {
        "description": description,
        "liveId": liveID,
        "memberId": store.getters.id,
        "name": name,
        "planStartTime": planStartTime,
        "thumb": thumb ? thumb : '',
        "newPassword": password,
        'invitationMemberIdList':invitationMemberIdList,
        'type':type
      }
    return post('/live/edit',params)
}

// 退出路演日志
export function quitLiveLog(liveId,userId){
    const params = {
        "joinMemberId": userId,
        "liveActivityId": liveId,
        "source": "pc"
      }
    return post('/live/exitLog',params)
}

// 分享、聊天等埋点
export function joinLiveLogType(liveId,userId,type){
    const params = {
        "joinMemberId": userId,
        "liveActivityId": liveId,
        "source": "pc",
        "type": type
      }
    return post('/live/joinLog',params)
}

// 加入路演日志
export function joinLiveLog(liveId,userId){
    const params = {
        "joinMemberId": userId,
        "liveActivityId": liveId,
        "source": "pc"
      }
    return post('/live/joinLog',params)
}

// 预约路演
export function appointLive(liveId,userId){
    const params = {
        "memberId": userId,
        "roomNo": liveId
    }
    return post('/live/appointment', params)
}

// 取消预约路演
export function cancelAppointLive(liveId,userId){
    const params = {
        "memberId": userId,
        "roomNo": liveId
    }
    return post('/live/cancelAppointment', params)
}

// 1.9 获取观看路演的会员列表 /live/getLookLiveMembers
export function getLiveMembers(liveId,memberId){
    const params = {
        "liveId": liveId,
        "memberId": memberId
    }
    return post('/live/getLookLiveMembers', params)
}
// new获取路演状态
export function getStatusLiveInfo(id){
    const params = {
        "id": id,
    }
    return post('/live/liveStatusInfo',params)
}

// 获取路演状态
export function getStatusLive(id){
    const params = {
        "id": id,
    }
    return post('/live/liveStatus',params)
}

// 查询路演是否有密码
export function liveHasPwd(params){
    return post('/live/existsPwd',params)
}

// 查询路演活动详情
export function getLiveDetails(liveId,password){
    let id = 1
    if(store.getters.id !== -1) id = store.getters.id   // @/utils/auth 里未登录时id为 -1
    const params = !password ? {
        "memberId": id,                     //获取token的用户id，做的权限校验，用的谁的token就传谁的id和业务逻辑无关
        "roomNo": liveId,                   // 路演间id或别名
    } : {
        "memberId": id,
        "roomNo": liveId,
        "password": password,
    }
    return post('/live/detail',params)
}

// 获取路演分享链接
export function getLiveLink(liveId){
    const params = {
        "activityId": liveId,                   // 房间号ID
    }
    return post('/live/link',params)
}

// 开始路演
export function startLive(liveId){
    const params = {
        memberId: store.getters.id,
        userId: localStorage.getItem('userId'),
        liveId
    }
    return post('/live/start',params)
}

// 创建立即路演
export function nowLive({ userId, name }){
    const params = {
        memberId: store.getters.id,
        userId,
        name
    }
    return post('/live/now',params)
}

// 获取会员禁言状态
export function getForbidden(params){
    return post('/live/getForbidden',params)
}

// 关闭路演
export function closeLive({id, userId}){
    const params = {
        memberId: store.getters.id,
        userId,
        id
    }
    return post('/live/close',params)
}

// 查看路演列表
export function getLiveList({limit, offset}) {
    let params = {
        limit,
        offset,
        userId: localStorage.getItem('userId'),
        memberId: store.getters.id
    }
    return post('/live/list',params)
}

// 按条件查询路演列表(支持游客)
export function getLiveAfterList({limit, offset, searchType, keyword,roadshowType},schoolFlag) {
    let params = {
        limit,
        offset,
        roadshowType: roadshowType || '',
        keyword,
        memberId: store.getters.id,
        searchType //全部路演列表:1 待路演列表:2 回放列表:3
    }
    if(schoolFlag === 0 || schoolFlag === 1) params.schoolFlag = schoolFlag
    return post('/live/afterList',params)
}
export function getLiveAfterListAll() {
    let params = {
        limit :10,
        offset:1,
        keyword:'',
        memberId: store.getters.id,
        searchType:1 //全部路演列表:1 待路演列表:2 回放列表:3
    }
    return post('/live/afterList',params)
}
// 查询我预约的路演列表
export function getLiveAppointmentList({limit, offset}) {
    let id = store.getters.id
    if(TanSite) id = getTanId()
    let params = {
        limit,
        offset,
        memberId: id,
    }
    return post('/live/appointmentList',params)
}

// 查询我创建的路演列表
export function getLiveCreateList({limit, offset}) {
    let params = {
        limit,
        offset,
        memberId: store.getters.id,
        userId: store.getters.id
    }
    return post('/live/mine',params)
}

// 创建预约路演
export function createforward({description, name, planStartTime, thumb, password,invitationMemberIdList,type,audienceConnection,interactiveChat,roadshowReplay,roadshowType,infoJson}) {
    let params = {
        audienceConnection,
        interactiveChat,
        roadshowReplay,
        roadshowType,
        createType: 2, //0云路演 1trtc
        description,
        name,
        thumb,
        password,
        planStartTime,
        invitationMemberIdList,
        type,
        infoJson,
        memberId: store.getters.id,
        userId: store.getters.id
    }
    return post('/live/forward',params)
}

// 设置路演会员禁言（恢复禁言）
export function setForbidden({forbiddenStatus, liveId, memberId}) {
    let params = {
        forbiddenStatus, //0关闭禁言 1禁言
        liveId,
        memberId, //观看路演会员id
        userId: localStorage.getItem('userId') //操作人员id
    }
    return post('/live/enableForbidden',params)
}

// 教师列表
export function liveLecturer(params) {
    return post('/live/liveLecturerMembers',params)
}

// 讲师禁言单人
export function liveLecturerSingle(params) {
    return post('/live/lecturerMute',params)
}

// 开始合流
export function liveStartMCUMixTranscode(params) {
    return post('/live/liveStartMCUMixTranscode',params)
}
// 分享
export function shareScreenStatus(params) {
    
    return post('/live/shareScreenStatus',params)
}
// 创建路演的时候实名认证
export function checkRealApi(params) {
    let data = {
        memberId:params
    }
    return post('/member/checkReal',data)
}

// 如果没认证则实名
export function realMemberApi(params) {
    return post('/member/realMember',params)
}

// 删除直播
export function deleteSelectLives(params){
    return post('/live/delete',params)
}

// 发布路演
export function releaseRoadshowReplay(params){
    return post('/live/releaseType',params)
}

// 查询可创建直播数量
export function getRoadshowTimes(params){
    return post('/live/getLiveCreateCount',params)
}