import { requestVersion } from "@/utils/consts";
import { login } from '@/api/user'


export const TanInfoKey = 'TanInfo'

export const getTanLocalInfo = () => {
  return localStorage.getItem(TanInfoKey) ? JSON.parse(localStorage.getItem(TanInfoKey)) : null
}

export const setTanLocalInfo = (info) => {
  localStorage.setItem(TanInfoKey,JSON.stringify(info))
}

export const removeTanLocalInfo = () => {
  localStorage.removeItem(TanInfoKey)
}
export const getTanId = () => {
  return getTanLocalInfo()?.memberInfo?.id
}

export const getTanToken = () => {
  return getTanLocalInfo()?.accessToken
}

export const getTanRefreshToken = () => {
  return getTanLocalInfo()?.refreshToken
}

export const setTanNewToken = (token) => {
  if(!getTanLocalInfo()) return
  const info = getTanLocalInfo()
  info.accessToken = token
  setTanLocalInfo(info)
}

export const TanLogin = (info)=>{
  const params = {
    machineCode: info.machineCode || '',
    version: requestVersion,
    canal: 1,
    type: 0,
    code: info.code,
    phone: info.phone,
  }
  return new Promise((resolve, reject) => {
    login(params).then(res => {
      setTanLocalInfo(res.data)
      resolve()
    }).catch(err => {
      reject(err)
    })
  })
}