<template>
  <el-dialog v-model="show" :show-close="false" :close-on-press-escape="false" :width="'660px'" :custom-class="'dialog-no-title'" :close-on-click-modal="false">
    <div class="cor-window">
      <p class="p-0">产品购买咨询</p>
      <img src="@/assets/images/Tan/project/close-button.png" @click="close" class="icon"/>
      <div class="form-item">
        <p class="p-1"><span>*</span>您的称呼：</p>
        <el-input v-model="form.name" maxlength="30" placeholder="请输入" class="input"></el-input>
      </div>
      <div class="form-item">
        <p class="p-1"><span>*</span>联系方式：</p>
        <el-input @input="handlePhoneChange" v-model="form.phone" placeholder="请输入" class="input" maxlength="11" />
      </div>
      <div class="form-item">
        <p class="p-1"><span>*</span>公司名称：</p>
        <el-input v-model="form.companyName" maxlength="30" placeholder="请输入" class="input"></el-input>
      </div>
      <div class="form-item">
        <p class="p-1"><span>*</span>预计使用人数：</p>
        <el-select v-model="form.memberNum" style="width: 376px;" placeholder="请选择" clearable >
          <el-option
            v-for="(item,index) in members"
            :key="index"
            :label="item"
            :value="item"/>
        </el-select>
      </div>
      <div class="form-item">
        <p class="p-1 p-textarea"><span>*</span>您的需求描述：</p>
        <el-input type="textarea" resize="none" v-model="form.des" maxlength="500" placeholder="请输入" class="textarea"></el-input>
      </div>
      <div class="form-item">
        <p class="p-1" style="margin-top: -2px;"><span>*</span>您意向购买的版本：</p>
        <el-checkbox v-model="item.val" :label="item.label" class="checkbox" v-for="(item,index) in form.version" :key="index"></el-checkbox>
      </div>
      <div class="buttons">
        <button @click="submit" class="confirm" v-loading="loading">提交</button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { getTanLocalInfo, } from "@/utils/Tan/login";
import { ElMessage } from "element-plus";
import { getSelectFatherSonByType } from "@/api/config";
import { activityRegister } from '@/api/activity';

export default{
  data(){
    return{
      loading: false,
      show: false,
      form:{
        name: '',
        phone: '',
        companyName: '',
        memberNum: '',
        des: '',
        version: [
          {
            val: false,
            label: '精英版'
          },
          {
            val: false,
            label: '至尊版'
          },
          {
            val: false,
            label: '定制版'
          },
        ],
      },
      members: [],
      TanInfo: {},
    }
  },
  computed:{
    ...mapGetters([
      "TanPurchasePopShow",
    ]),
    defaultPhone(){
      return this.TanInfo.phone || ''
    },
    defaultName(){
      return this.TanInfo.name || ''
    },
    defaultCompanyName(){
      return this.TanInfo.tjCompanyName || ''
    },
  },
  watch:{
    TanPurchasePopShow(val){
      this.show = val
      if(val){
        this.getSelections()
        if(getTanLocalInfo()?.tjInfo?.tjCompanyId){
          this.TanInfo = getTanLocalInfo()?.tjInfo
        }
        else if(getTanLocalInfo()?.memberInfo?.id){
          this.TanInfo = getTanLocalInfo()?.memberInfo
        }
        else{
          this.TanInfo = {}
        }
        this.form.name = this.defaultName
        this.form.phone = this.defaultPhone
        this.form.companyName = this.defaultCompanyName
      }
    },
  },
  methods:{
    handlePhoneChange(e) {  // 手机号处理，只填入数字
      let pattern = new RegExp("^[0-9]|-$")
      this.form.phone = Array.from(e).filter(v => pattern.test(v)).join("")
    },
    getSelections(){
      getSelectFatherSonByType(10035).then(res=>{
        this.members = res.data.map(item=>item.val)
      })
    },
    clearForm(){
      this.form = {
        name: '',
        phone: '',
        companyName: '',
        memberNum: '',
        des: '',
        version: [
          {
            val: false,
            label: '精英版'
          },
          {
            val: false,
            label: '至尊版'
          },
          {
            val: false,
            label: '定制版'
          },
        ],
      }
    },
    submit(){
      if(!this.form.name){
        ElMessage.warning('请输入您的称呼')
        return
      }
      else if(!this.form.phone){
        ElMessage.warning('请输入联系方式')
        return
      }
      else if(!this.form.companyName){
        ElMessage.warning('请输入公司名称')
        return
      }
      else if(!this.form.memberNum){
        ElMessage.warning('请选择预计使用人数')
        return
      }
      else if(!this.form.des){
        ElMessage.warning('请输入定制需求描述')
        return
      }
      else if(!this.form.version.find(item=>item.val)){
        ElMessage.warning('请选择您意向购买的版本')
        return
      }
      const param = {
        activityName: '探角产品购买咨询',
        activityType: '54',
        code: '123',
        isCreateMember: false,
        contactWay: this.form.phone,
        companyName: this.form.companyName,
        name: this.form.name,
        jsonStr: JSON.stringify({
          '预计使用人数': this.form.memberNum,
          '意向购买的版本': this.form.version.filter(item=>item.val).map(item=>item.label),
          '定制需求描述': this.form.des,
        }),
      }
      this.loading = true
      activityRegister(param).then((res) => {
        if (res.code === "Sx200") {
          ElMessage.success('提交成功')
          this.clearForm()
          this.$store.dispatch('Tan/setPurchasePopShow',false)
        }
      }).finally(()=>{
        this.loading = false
      })
    },
    close(){
      this.$store.dispatch('Tan/setPurchasePopShow',false)
    },
  }
}
</script>


<style lang="scss" scoped>
.cor-window{
  width: 100%;
  position: relative;
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 52px;
  .p-0{
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 18px;
    color: #333333;
    line-height: 25px;
    width: 100%;
    box-sizing: border-box;
    padding: 21px 23px 13px;
    border-bottom: 1px solid #F2F2F2;
    margin-bottom: 25px;
  }
  .icon{
    width: 14px;
    height: 14px;
    cursor: pointer;
    position: absolute;
    right: 23px;
    top: 23px;
  }
  .form-item{
    width: 536px;
    display: flex;
    align-items: center;
    padding: 10px 0;
    .p-textarea{
      align-self: flex-start;
      margin-top: 10px;
    }
    .p-1{
      width: 160px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #333333;
      line-height: 14px;
      text-align: right;
      span{
        color: #EA0D0D;
      }
    }
    .name{
      width: 376px;
      height: 32px;
      background: #EFF4F9;
      border-radius: 4px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #333333;
      line-height: 24px;
      box-sizing: border-box;
      padding: 4px 9px;
    }
    .input{
      width: 376px;
    }
    .textarea{
      width: 376px;
      height: 110px;
      font-size: 14px;
      color: 333333;
      :deep(.el-textarea__inner){
        height: 110px !important;
      }
    }
    .right{
      width: 376px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .active{
        border: 1px solid $--color-theme !important;
        color: $--color-theme !important;
      }
      .way{
        cursor: pointer;
        border-radius: 2px;
        border: 1px solid #C7C7C7;
        box-sizing: border-box;
        padding: 8px 9px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        line-height: 14px;
        margin-bottom: 14px;
      }
    }
  }
  .form-item-textarea{
    width: 457px;
    padding: 10px 0;
    .p-1{
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #333333;
      line-height: 14px;
      margin-bottom: 12px;
      span{
        color: #EA0D0D;
      }
    }
  }
  .buttons{
    margin-top: 37px;
    width: 100%;
    display: flex;
    justify-content: center;
    .cancel{
      width: 130px;
      height: 32px;
      background: #C6CAD3;
      border-radius: 4px;
      border: none;
      cursor: pointer;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #FFFFFF;
      margin: 0 15px;
    }
    .confirm{
      margin: 0 15px;
      width: 130px;
      height: 32px;
      background: $--color-theme;
      border-radius: 4px;
      border: none;
      cursor: pointer;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #FFFFFF;
    }
  }
}
</style>
