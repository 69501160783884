<template>
  <div class="seize">
  </div>
  <div class="navbar">
    <div class="center">
      <div class="imgs">
        <div class="line">
          <a href="/talent-project"><img src="@/assets/images/TPS/icon-3.png" class="icon-3"/></a>
        </div>
        <a href="/" target="_blank"><img src="@/assets/images/TPS/icon-4.png" class="icon-4" /></a>
      </div>
      <div v-if="info.id">
        <el-popover placement="bottom" :width="262" trigger="hover" :popper-class="'popper-class-user'" content="keqidao">
          <template #reference>
            <div class="flex-cc">
              <img v-if="info.headImage" class="avatar" :src="staticPath + info.headImage" />
              <img v-else src="@/assets/images/avatar.jpg" class="avatar" />
              <div class="avatar-name text-ol-ell" @click="toUserCenter">{{ info.name }}</div>
            </div>
          </template>
          <div class="self-menu">
            <div class="user-info">
              <img v-if="info.headImage" class="avatar" :src="staticPath + info.headImage" />
              <img v-else src="@/assets/images/avatar.jpg" class="avatar" />
              <div class="user-name">{{ info.name }}</div>
            </div>
            <div class="buttons">
              <div class="button" @click="commitLogout()">
                <div class="flex-cc">
                  <img src="@/assets/images/navbar/logout.png" alt="" class="left-icon" />
                  <div>退出登陆</div>
                </div>
                <div class="flex-cc">
                  <div class="right-icon" style="width:16px; height:16px;"></div>
                </div>
              </div>
            </div>
          </div>
        </el-popover>
      </div>
    </div>
  </div>
</template>

<script>
import { getLocalInfo,removeLocalInfo } from "@/utils/talent-project-system/login";
import { staticPath } from "@/utils/consts";
import { ElMessage, ElMessageBox } from 'element-plus';
import { routerWindow } from "../../../utils/tool";
import { mapGetters } from "vuex";


export default {
  data(){
    return{
      staticPath,
      info: {},
    }
  },
  computed:{
    ...mapGetters([
      "visibility",
      "reloadUser",
    ]),
  },
  watch:{
    visibility(val){
      if(val) this.info = getLocalInfo()?.memberInfo || {}
    },
    reloadUser(){
      this.info = getLocalInfo()?.memberInfo || {}
    },
    $route(){
      this.info = getLocalInfo()?.memberInfo || {}
    },
  },
  mounted(){
    this.info = getLocalInfo()?.memberInfo || {}
  },
  methods:{
    toUserCenter(){
      if(this.$route.path === '/talent-project-user') return
      routerWindow(this.$router,'/talent-project-user',{})
    },
    commitLogout(){
      ElMessageBox.confirm("是否确认退出登录?", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        lockScroll: false,  // 防止页面振动
        type: "warning",
      }).then(() => {
        ElMessage.success('成功退出登录!')
        removeLocalInfo()
        this.$router.push('/talent-project-login')
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.seize {
  width: 100%;
  height: 60px;
}

.navbar {
  height: 60px;
  width: 100%;
  min-width: 1200px;
  background: #F8F8F8;
  position: fixed;
  top: 0;
  z-index: 2001;
  display: flex;
  justify-content: center;
  align-items: center;
  .center{
    width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .imgs{
      display: flex;
      align-items: center;
      .line{
        position: relative;
        &::after{
          position: absolute;
          content: '';
          width: 1px;
          height: 27px;
          background: #D8D8D8;
          right: 0;
          top: 50%;
          margin-top: -13.5px;
        }
      }
      .icon-3{
        margin-left: 2px;
        width: 160px;
        height: 38px;
        margin-right: 26px;
      }
      .icon-4{
        margin-left: 26px;
        width: 104px;
        height: 38px;
      }
    }
  }
}
.avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.avatar-name{
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #111111;
  line-height: 20px;
  max-width: 200px;
  margin-left: 6px;
  cursor: pointer;
  &:hover{
    color: $--tps-theme;
  }
}
.self-menu {
  font-size: 16px;
  color: #666666;
  display: flex;
  flex-direction: column;
  .user-info {
    display: flex;
    width: calc(100% + 2px);
    margin-left: -1px;
    margin-top: -1px;
    padding: 25px 0;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border-radius: 4px 4px 0px 0px;
    background: $--color-theme;
    .avatar {
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
    .user-name {
      margin-top: 8px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
    }
  }
  .vip-services {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    .vip {
      margin-top: 10px;
      width: 47%;
      height: 68px;
      padding: 5px 0;
      background: #f1f5f8;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #0f1c34;
      .left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .top {
          font-size: 18px;
          margin-left: 10px;
          display: flex;
          align-items: center;
          img {
            margin-right: 5px;
          }
          .golden {
            width: 28px;
            height: 38px;
            margin-right: 5px;
          }
          .diamond {
            width: 38px;
            height: 38px;
            margin-right: 5px;
          }
          margin-right: 10px;
        }
      }
      .middle {
        display: flex;
        flex-direction: column;
        margin-left: 5px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #8991a0;
      }
      .right {
        width: 46px;
        height: 32px;
        background: #feebd7;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #864d07;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px 5px;
        margin-right: 10px;
        border-radius: 4px;
        cursor: pointer;
      }
    }
    .full-width {
      width: 100%;
      .left {
        flex-direction: row !important;
        justify-content: flex-start;
        align-items: center;
      }
      .right {
        width: 56px;
        border-radius: 4px;
        margin-right: 10px;
        background: #dfe0e5;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #8991a0;
      }
    }
  }
  .buttons {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding-top: 8px;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px 0px rgba(56,29,0,0.3);
    padding-bottom: 5px;
    .button:hover{
      background: #2282ff14;
      color: $--color-theme;
      .right-icon{
        background-image: url('../../../assets/images/navbar/right-icon-hover.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
    }
    .button {
      padding: 10px 20px;
      width: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      .left-icon {
        margin-top: 2px;
        width: 16px;
        height: 16px;
        margin-right: 13px
      }
      .right-icon{
        background-image: url('../../../assets/images/navbar/right-icon.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
      font-size: 16px;
      line-height: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #0f1c34;
    }
    border-bottom: 2px solid #f1f5f8;
  }
  .exit-login {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 5px;
    .exit {
      display: flex;
      align-items: center;
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #8991a0;
      cursor: pointer;
    }
  }
}

.self-menu-item {
  height: 50px;
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.self-menu-item:hover {
  background: #ec8541;
  color: white;
}
</style>