<template>
  <div class="avatar-inner">
    <div class="default" :class="{'small': small,'middle': middle}" v-if="!src" :style="`background-color: ${color};`">
      <p>{{ word || name }}</p>
    </div>
    <img :src="src" v-else class="image"/>
  </div>
</template>

<script>
import { getTanLocalInfo } from "@/utils/Tan/login";

export default{
  props:{
    small:{
      type: Boolean,
      default: false,
    },
    middle:{
      type: Boolean,
      default: false,
    },
    src:{
      type: String,
      default: '',
    },
    id:{
      type: Number,
      default: 0,
    },
    word:{
      type: String,
      default: '',
    }
  },
  watch:{
    id(){
      this.setBackColor()
    },
  },
  data(){
    return{
      colors: ['#EEBA40','#8177F1','#2ECC9A','#F6955A'],
      color: '',
      name: '',
    }
  },
  mounted(){
    this.setBackColor()
    this.name = getTanLocalInfo()?.tjInfo?.name?.charAt(0) || '-'
  },
  methods:{
    setBackColor(){
      let temp = this.id + ''
      temp = temp.split('')
      temp = Number(temp[temp.length - 1])
      this.color = this.colors[temp % 4]
    },
  }
}
</script>

<style lang="scss" scoped>
.avatar-inner{
  width: 100%;
  height: 100%;
  .default{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #ffffff;
  }
  .middle{
    font-size: 16px !important;
  }
  .small{
    font-size: 14px !important;
  }
  .image{
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}
</style>