import { talentProjectSystemPage } from "@/utils/consts";
import { ElMessage } from "element-plus";
import { getLocalInfo } from "./login";

export const TPSPermission = (to,from,next) => {
  if(!talentProjectSystemPage.includes(to.path)) return
  else if(to.path === '/talent-project-login') return
  else if(getLocalInfo()) return
  next({
    path: '/talent-project-login',
    query:{
      redirect: window.location.href
    }
  })
}

export const TPSChangeVisibility = (route,value,router) => {
  if(value) return
  const path = route.path
  const query = route.query
  if(path === '/talent-project-login' && getLocalInfo()){
    if(query.redirect){
      window.location.href = this.$route.query.redirect
    }
    else{
      router.push({
        path: '/talent-project-talents'
      })
    }
  }
  else if(path !== '/talent-project-login' && !getLocalInfo()){
    ElMessage.warning('您尚未登录!')
    router.push({
      path: '/talent-project-login',
      query:{
        redirect: window.location.href
      }
    })
  }
}