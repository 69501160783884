<template>
  <el-dialog v-model="show" :show-close="false" :close-on-press-escape="false" :width="showForm ?  '630px' : '900px'" :custom-class="'dialog-no-title'" :close-on-click-modal="false">
    <div class="wrap">
      <div class="achieve-content hide-scroll-bar">
        <div class="success-window">
          <img src="@/assets/images/G60/pop/success-icon.png" class="icon" />
          <p class="p-title">提交成功!</p>
          <p class="word">{{ word[0] || '' }}</p>
          <p class="word">{{ word[1] || '' }}</p>
        </div>
        <div class="button-line">
          <button class="button" @click="close">我知道了</button>
        </div>
      </div>
      <img src="@/assets/images/G60/pop/close-button.png" class="close-icon" @click="close" />
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default{
  data(){
    return{
      cur: 0,
      show: true,
      showForm: false,
    }
  },
  computed:{
    word(){
      return this.successStatus.word.split('_') || ['','']
    },
    ...mapGetters([
      "successStatus",
    ]),
  },
  watch:{
  },
  methods:{
    close(){
      this.$store.dispatch('G60/closeSuccess')
    },
  }
}
</script>


<style lang="scss" scoped>
.wrap{
  position: relative;
  .close-icon{
    width: 28px;
    height: 28px;
    position: absolute;
    right: 20px;
    top: 30px;
    cursor: pointer;
  }
}
.achieve-content{
  width: 100%;
  max-height: 70vh;
  overflow-x: auto;
  box-sizing: border-box;
  padding: 60px 50px;
  .title{
    font-size: 22px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: #111111;
    line-height: 22px;
    width: 100%;
    margin-bottom: 13px;
  }
  .time{
    font-size: 14px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 14px;
    width: 100%;
    margin-bottom: 34px;
  }
  .success-window{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .icon{
      width: 64px;
      height: 64px;
      margin-bottom: 20px;
    }
    .p-title{
      font-size: 22px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #111111;
      line-height: 22px;
      margin-bottom: 33px;
    }
    .word{
      font-size: 14px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #111111;
      line-height: 23px;
    }
  }
  .button-line{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 100px;
    button{
      width: 157px;
      height: 38px;
      background: $--G60-theme;
      border-radius: 4px;
      cursor: pointer;
      border: none;
      font-size: 14px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 14px;
    }
  }
}
</style>
