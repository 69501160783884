const state = {
  smallScreen: false,
}

const mutations = {
  SET_SMALL_SCREEN: (state, bool) => {
    state.smallScreen = bool
  },
}
const actions = {
  setSmallScreen({commit}, bool) {   // 传入type及参数parameter
    commit('SET_SMALL_SCREEN', bool)
  },
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}