const state = {
  loginStatus: {
    show: false,
    change: false,
    from: '',
  }
}
const mutations = {
  SET_LOGIN_STATUS_SHOW:(state,show)=>{
    state.loginStatus.show = show
  },
  SET_LOGIN_STATUS_CHANGE:(state,from)=>{
    state.loginStatus.change = !state.loginStatus.change
    state.loginStatus.from = from
  },
}
const actions = {
  setLoginShow({commit},show){
    commit('SET_LOGIN_STATUS_SHOW',show)
  },
  setLoginChange({commit},from=''){
    commit('SET_LOGIN_STATUS_CHANGE',from)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}