<template>
  <el-dialog v-model="show" :show-close="false" :close-on-press-escape="false" :width="'900px'"
    :custom-class="'dialog-no-title'">
    <div v-if="type === 0" class="left">
      <p class="tab-title">数字科创中心和传统方式的综合效益对比</p>
      <div class="tab-line" style="width: 899px;">
        <div class="l has-back p-0" style="border-right: none;">
          对比维度
        </div>
        <div class="m has-back p-0" style="border-right: none;">
          传统方式
        </div>
        <div class="r has-back p-0" style="border-right: none;">
          数字科创中心
        </div>
      </div>
      <div class="tab-line">
        <div class="f dark p-0">
          成本投入
        </div>
      </div>
      <div class="tab-line">
        <div class="l p-0">
          初始建设/人才成本
        </div>
        <div class="m p-1">
          高昂
          <span style="font-weight: 400;">实验室建设平均费用为500万元</span>
          <span style="font-weight: 400;">设备购置200万元</span>
          <span style="font-weight: 400;">人才招聘及培训100万元</span>
        </div>
        <div class="r p-0">
          较低
          <span style="font-weight: 400;">（无需实验室建设，按需支付软件服务费用</span>
          <span style="font-weight: 400;">企业版¥59,800/年 高级定制版¥99,800/年起</span>
        </div>
      </div>
      <div class="tab-line">
        <div class="l p-0 dark">
          运营成本
        </div>
        <div class="m p-1 dark">
          持续且较高
          <span style="font-weight: 400;">设备维护年均50万元</span>
          <span style="font-weight: 400;">人才薪酬年均200万元</span>
          <span style="font-weight: 400;">实验室消耗品年均30万元</span>
        </div>
        <div class="r p-0 dark">
          较低
          <span style="font-weight: 400;">仅需支付年费，无额外维护成本</span>
        </div>
      </div>
      <div class="tab-line">
        <div class="l p-0">
          资金占用
        </div>
        <div class="m p-1">
          长期且大量
          <span style="font-weight: 400;">资金回收期可能长达5年以上</span>
        </div>
        <div class="r p-0">
          短期且较少
          <span style="font-weight: 400;">按年支付，资金占用少</span>
        </div>
      </div>
      <div class="tab-line">
        <div class="f dark p-0">
          效益产出
        </div>
      </div>
      <div class="tab-line">
        <div class="l p-0">
          创新项目数量
        </div>
        <div class="m p-1">
          受限
          <span style="font-weight: 400;">（受限于团队规模、资源、时间）</span>
          <span style="font-weight: 400;">年均完成3个项目</span>
        </div>
        <div class="r p-0">
          高效
          <span style="font-weight: 400;">（海量资源，智能匹配，加速项目进程）</span>
          <span style="font-weight: 400;">年均接触并筛选50+创新项目，成功合作10+</span>
        </div>
      </div>
      <div class="tab-line">
        <div class="l p-0 dark">
          创新质量
        </div>
        <div class="m p-1 dark">
          不稳定
          <span style="font-weight: 400;">（受限于团队能力、经验）</span>
          <span style="font-weight: 400;">依赖于团队经验和技能水平</span>
        </div>
        <div class="r p-0 dark">
          提升
          <span style="font-weight: 400;">（专家资源、先进技术保障）</span>
          <span style="font-weight: 400;">智能匹配确保高质量的合作与解决方案</span>
        </div>
      </div>
      <div class="tab-line">
        <div class="l p-0">
          市场响应速度
        </div>
        <div class="m p-1">
          较慢
          <span style="font-weight: 400;">（需要更多时间进行市场调研、设计研发）</span>
          <span style="font-weight: 400;">市场调研和设计研发周期长达1年</span>
        </div>
        <div class="r p-0">
          快速
          <span style="font-weight: 400;">（实时市场数据，快速对接需求）</span>
          <span style="font-weight: 400;">实时数据支持，快速响应市场变化</span>
        </div>
      </div>
      <div class="tab-line">
        <div class="l p-0 dark">
          竞争优势
        </div>
        <div class="m p-1 dark">
          有限
          <span style="font-weight: 400;">受限于资源、成本、效率</span>
        </div>
        <div class="r p-0 dark">
          增强
          <span style="font-weight: 400;">（降低成本、提升效率、优化创新质量）</span>
          <span style="font-weight: 400;">通过优化创新流程，显著提升竞争力</span>
        </div>
      </div>
      <div class="tab-line">
        <div class="f p-0">
          时间效率
        </div>
      </div>
      <div class="tab-line">
        <div class="l p-0 dark">
          项目启动时间
        </div>
        <div class="m p-1 dark">
          较长
          <span style="font-weight: 400;">（需要时间进行团队建设、实验室建设）</span>
          <span style="font-weight: 400;">从团队建设到项目启动需6个月</span>
        </div>
        <div class="r p-0 dark">
          快速
          <span style="font-weight: 400;">（即插即用，无需等待）</span>
          <span style="font-weight: 400;">即买即用，项目启动时间缩短至1个月</span>
        </div>
      </div>
      <div class="tab-line">
        <div class="l p-0">
          研发周期
        </div>
        <div class="m p-1">
          较长
          <span style="font-weight: 400;">（受限于资源调配、团队协作效率）</span>
          <span style="font-weight: 400;">平均研发周期为18个月</span>
        </div>
        <div class="r p-0">
          缩短
          <span style="font-weight: 400;">（智能匹配资源，优化研发流程）</span>
          <span style="font-weight: 400;">通过智能匹配和协作工具，研发周期缩短至12个月</span>
        </div>
      </div>
      <div class="tab-line">
        <div class="l p-0 dark">
          上市时间
        </div>
        <div class="m p-1 dark">
          延迟
          <span style="font-weight: 400;">（更多时间用于验证、改进产品）</span>
          <span style="font-weight: 400;">由于验证和改进，产品上市时间可能推迟3个月</span>
        </div>
        <div class="r p-0 dark">
          提前
          <span style="font-weight: 400;">（快速迭代，抢占市场先机）</span>
          <span style="font-weight: 400;">高效流程助力产品提前上市</span>
        </div>
      </div>
    </div>
    <div v-else class="right">
      <p class="tab-title">数字科创中心和传统方式的优势提升对比</p>
      <div class="tab-line" style="width: 899px;">
        <div class="l has-back p-0">
          对比维度
        </div>
        <div class="m has-back p-0" style="border-right: none;">
          传统方式
        </div>
        <div class="r has-back p-0" style="border-right: none;">
          数字科创中心
        </div>
        <div class="r1 has-back p-0" style="border-right: none;">
          备注说明
        </div>
      </div>
      <div class="tab-line">
        <div class="f dark p-0">
          成本节约
        </div>
      </div>
      <div class="tab-line">
        <div class="l p-0">
          初始建设/人才成本节约
        </div>
        <div class="m p-1">
          ———
        </div>
        <div class="r p-0">
          可节约高达90%的初始投资
        </div>
        <div class="r1 p-0">
          数字科创中心采用付费订阅制，无需大额初期投入
        </div>
      </div>
      <div class="tab-line">
        <div class="l p-0 dark">
          运营成本节约
        </div>
        <div class="m p-1 dark">
          ———
        </div>
        <div class="r p-0 dark">
          可节约50%-70%的年度运营成本
        </div>
        <div class="r1 p-0 dark">
          无需承担实验室维护、设备更新等持续费用
        </div>
      </div>
      <div class="tab-line">
        <div class="l p-0">
          总体成本节约（5年累计）
        </div>
        <div class="m p-1">
          ———
        </div>
        <div class="r p-0">
          可节约总成本的60%-80%
        </div>
        <div class="r1 p-0">
          综合考虑初始和持续投入，长期成本显著降低
        </div>
      </div>
      <div class="tab-line">
        <div class="f dark p-0">
          效益提升
        </div>
      </div>
      <div class="tab-line">
        <div class="l p-0">
          创新项目数量提升
        </div>
        <div class="m p-1">
          ———
        </div>
        <div class="r p-0">
          项目接触数量提升10倍
        </div>
        <div class="r1 p-0">
          通过平台海量项目资源实现
        </div>
      </div>
      <div class="tab-line">
        <div class="l p-0 dark">
          成功合作项目提升
        </div>
        <div class="m p-1 dark">
          ———
        </div>
        <div class="r p-0 dark">
          成功合作项目数量提升3-5倍
        </div>
        <div class="r1 p-0 dark">
          智能匹配提高合作成功率和质量
        </div>
      </div>
      <div class="tab-line">
        <div class="l p-0">
          市场响应速度提升
        </div>
        <div class="m p-1">
          ———
        </div>
        <div class="r p-0 w">
          市场数据获取速度提升5倍，响应时间缩短70%
        </div>
        <div class="r1 p-0">
          实时数据支持和在线协作工具加速决策和行动
        </div>
      </div>
      <div class="tab-line">
        <div class="l p-0 dark">
          竞争优势提升
        </div>
        <div class="m p-1 dark">
          ———
        </div>
        <div class="r p-0 dark">
          竞争力指标提升2-3倍
        </div>
        <div class="r1 p-0 dark">
          优化创新流程，显著提升企业在行业中的地位
        </div>
      </div>
      <div class="tab-line">
        <div class="f p-0">
          时间缩短
        </div>
      </div>
      <div class="tab-line">
        <div class="l p-0 dark">
          项目启动时间缩短
        </div>
        <div class="m p-1 dark">
          ———
        </div>
        <div class="r p-0 dark">
          项目启动时间缩短50%-70%
        </div>
        <div class="r1 p-0 dark">
          即买即用，无需等待团队建设和设备采购
        </div>
      </div>
      <div class="tab-line">
        <div class="l p-0">
          研发周期缩短
        </div>
        <div class="m p-1">
          ———
        </div>
        <div class="r p-0 w">
          研发周期缩短30%-50%
        </div>
        <div class="r1 p-0">
          智能匹配和协作工具提高研发效率
        </div>
      </div>
      <div class="tab-line">
        <div class="l p-0 dark">
          产品上市时间提前
        </div>
        <div class="m p-1 dark">
          ———
        </div>
        <div class="r p-0 dark">
          产品上市时间提前2-4个月
        </div>
        <div class="r1 p-0 dark">
          高效流程助力产品更快进入市场
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      show: true,
    }
  },
  watch: {
    show(val) {
      if (!val) {
        this.$store.dispatch('G60/closeTable')
      }
    }
  },
  computed: {
    ...mapGetters([
      "tableStatus",
    ]),
    type() {
      return this.tableStatus.type
    }
  }
}
</script>

<style lang="scss" scoped>
.left {
  width: 100%;

  .tab-title {
    width: 100%;
    height: 60px;
    font-size: 16px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: #111111;
    line-height: 60px;
    text-align: center;
    background-image: url('../../../assets/images/G60/announce/t-0.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .tab-line {
    width: 100%;
    display: flex;
    align-items: stretch;
    border-left: 1px solid #EBEBEB;

    .p-0 {
      font-size: 14px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #111111;
    }

    .p-1 {
      font-size: 14px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #666666;
    }

    .l {
      width: 20%;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 40px;
      text-align: center;
      border-right: 1px solid #EBEBEB;
      border-bottom: 1px solid #EBEBEB;
    }

    .m {
      font-weight: bold !important;
      width: 40%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      line-height: 40px;
      text-align: center;
      border-right: 1px solid #EBEBEB;
      border-bottom: 1px solid #EBEBEB;
    }

    .r {
      font-weight: bold !important;
      width: 40%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      line-height: 40px;
      text-align: center;
      border-right: 1px solid #EBEBEB;
      border-bottom: 1px solid #EBEBEB;
    }

    .f {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 40px;
      text-align: center;
      border-right: 1px solid #EBEBEB;
      border-bottom: 1px solid #EBEBEB;
      box-sizing: border-box;
      font-weight: bold;
    }

    .has-back {
      background: linear-gradient(129deg, #F3F7FF 0%, #A4C6FE 100%);
      border: 1px solid #FFFFFF !important;
      box-sizing: border-box;
    }

    .dark {
      background: #F6F6FB;
    }
  }

  .remark {
    font-size: 14px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 14px;
    margin-top: 16px;
  }
}

.right {
  width: 100%;

  .remark {
    font-size: 14px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 14px;
  }

  .tab-title {
    width: 100%;
    height: 60px;
    font-size: 16px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: #111111;
    line-height: 60px;
    text-align: center;
    background-image: url('../../../assets/images/G60/announce/t-1.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .tab-line {
    width: 100%;
    display: flex;
    align-items: stretch;
    border-left: 1px solid #EBEBEB;

    .p-0 {
      font-size: 14px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #111111;
    }

    .p-1 {
      font-size: 14px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #666666;
    }

    .l {
      width: 20%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      line-height: 40px;
      text-align: center;
      border-right: 1px solid #EBEBEB;
      border-bottom: 1px solid #EBEBEB;
    }

    .m {
      width: 10%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      line-height: 40px;
      text-align: center;
      border-right: 1px solid #EBEBEB;
      border-bottom: 1px solid #EBEBEB;
    }

    .r {
      width: 35%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      line-height: 40px;
      text-align: center;
      border-right: 1px solid #EBEBEB;
      border-bottom: 1px solid #EBEBEB;
    }


    .r1 {
      width: 35%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      line-height: 40px;
      text-align: center;
      border-right: 1px solid #EBEBEB;
      border-bottom: 1px solid #EBEBEB;
    }

    .f {
      width: 899px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      line-height: 40px;
      text-align: center;
      border-right: 1px solid #EBEBEB;
      border-bottom: 1px solid #EBEBEB;
      box-sizing: border-box;
      font-weight: bold;
    }

    .has-back {
      background: linear-gradient(140deg, #FFF2D2 0%, #FEDD9E 100%);
      border: 1px solid #FFFFFF !important;
      box-sizing: border-box;
    }

    .dark {
      background: #F6F6FB;
    }
  }
}</style>