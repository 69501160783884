<template>
  <div class="upload-contain" v-loading="editing">
    <el-upload action="#" :accept="'image/*'" :show-file-list="false" :http-request="handleUpload"
      :on-change="loadJsonFromFile" :limit="1">
      <Image :src="image || 'http://test-1304545655.cos.ap-shanghai.myqcloud.com/defaultlogo.png'" class="avatar-center"/>
    </el-upload>
  </div>
</template>

<script>
import { ElMessage } from "element-plus"
import { uploadTypeImage, uploadTypeVideo, cosByUploadType,} from "@/api/upload";
import { bucketMap } from "@/utils/consts";
import { randomFilename } from "@/utils/time";


export default{
  props: ['default'],
  emits: ['uploadGroupImage'],
  data(){
    return{
      editing: false,
      image: '',
      file: '',
    }
  },
  mounted(){
    this.image = this.default
  },
  methods:{
    getImage(){
      this.$emit('uploadGroupImage',this.image)
    },
    async loadJsonFromFile(file) {
      this.file = file;
    },
    async handleUpload(val) {
      let result = await this.directUpload(val.file, uploadTypeImage, true);
      this.image = result.Location.slice(result.Location.indexOf("/"));
      ElMessage.success('上传成功！')
      this.editing = false
    },
    directUpload(file, uploadType) {
      var self = this;
      let cosMap = {
        image: cosByUploadType(uploadTypeImage),
        video: cosByUploadType(uploadTypeVideo),
      };
      let cos = uploadType === uploadTypeImage ? cosMap.image : cosMap.video;
      let bucket =
        uploadType === uploadTypeImage ? bucketMap.image : bucketMap.video;
      return new Promise((resolve, reject) => {
        cos.putObject(
          {
            Bucket: bucket,
            Region: "ap-shanghai",
            Key: randomFilename(),
            StorageClass: "STANDARD",
            Body: file,
            onProgress: function (progressData) {
              self.editing = true
              self.percentage = parseInt(progressData.percent * 100);
            },
          },
          function (err, data) {
            if (err) {
              reject(err);
            } else {
              resolve(data);
            }
          }
        );
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.upload-contain{
  width: 100%;
  display: flex;
  justify-content: center;
  .avatar-center{
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }
}
</style>