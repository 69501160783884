<template>
  <div class="seize" v-if="!isLogin && !isG60OutLink && !isAnnounce">
  </div>
  <header class="navbar" v-if="!isLogin && !isG60OutLink && !isAnnounce">
    <div class="center">
      <div class="left">
        <a href="http://g60.songjiang.gov.cn/WebSite/Default.aspx" target="_blank">
          <img class="logo-0" src="@/assets/images/G60/Navbar/logo0.png" alt="G60" title="G60" />
        </a>
        <a href="/" target="_blank">
          <img class="logo-1" src="@/assets/images/G60/Navbar/logo1.png" alt="科企岛" title="科企岛" />
        </a>
        <a href="/" target="_blank" title="首页" class="a" :class="{'a-active': routePath === '/'}">首页</a>
        <a href="/announce" target="_blank" title="数字科创中心" class="a" :class="{'a-active': routePath === '/announce'}">数字科创中心</a>
        <a href="/space" target="_blank" title="产学研中心" class="a" :class="{'a-active': routePath === '/space'}">产学研中心</a>
        <el-popover placement="bottom" :width="100" trigger="hover" :popper-class="'popper-class-user'">
          <template #reference>
            <div class="flex-cc">
              <p class="p-title">九城科创门户</p>
            </div>
          </template>
          <div class="cities">
            <div class="city wait">上海</div>
            <div class="city wait">苏州</div>
            <div class="city wait">嘉兴</div>
            <div class="city wait">湖州</div>
            <div class="city wait">杭州</div>
            <div class="city wait">金华</div>
            <div class="city wait">宣城</div>
            <div class="city wait">芜湖</div>
            <div class="city wait">合肥</div>
          </div>
        </el-popover>
        <el-popover placement="bottom" :width="100" trigger="hover" :popper-class="'popper-class-user'">
          <template #reference>
            <div class="flex-cc">
              <p class="p-title" @click="toRoute('/news','331415558356993')">新闻动态</p>
            </div>
          </template>
          <div class="news">
            <a class="news-type" target="_blank" href="/news?id=331415558356993">全部新闻</a>
            <a class="news-type" target="_blank" href="/news?id=329066093805569">走廊动态</a>
            <a class="news-type" target="_blank" href="/news?id=329071672229889">产业要闻</a>
            <a class="news-type" target="_blank" href="/news?id=329089112145921">创新前沿</a>
            <a class="news-type" target="_blank" href="/news?id=329089217003521">产学研用</a>
          </div>
        </el-popover>
        <!-- <a href="/user" target="_blank" title="创投空间" class="a" :class="{'a-active': routePath === '/'}">创投空间</a> -->
      </div>
      <div class="right">
        <el-popover placement="bottom" :width="292" trigger="hover" :popper-class="'popper-class-user'">
          <template #reference>
            <div class="flex-cc">
              <p class="p-publish" @click="toPublish(1)">发布平台</p>
            </div>
          </template>
          <div class="entrance-line">
            <div class="entrance-0" @click="toPublish(0)">
              <p class="p-0">官方入口</p>
              <p class="p-tip">G60科创走廊、九城政府、产业园区等政府单位</p>
            </div>
            <div class="entrance-1" @click="toPublish(1)">
              <p class="p-0">行业发布入口</p>
              <p class="p-tip">企业、科研院所、专家教授、投融资等行业机构</p>
            </div>
          </div>
        </el-popover>
        <a v-if="!loginStatus" class="login-button" :title="'登录/注册'" :href="`/login?redirect=${redirectUrl()}`" >登录/注册</a>
        <div v-else>
          <el-popover placement="bottom" :width="262" trigger="hover" :popper-class="'popper-class-user'" content="keqidao">
            <template #reference>
              <div class="flex-cc">
                <img v-if="info.headImage" class="avatar" :src="staticPath + info.headImage" />
                <img v-else src="@/assets/images/avatar.jpg" class="avatar" />
                <div class="avatar-name text-ol-ell" @click="toUserCenter">{{ info.name }}</div>
              </div>
            </template>
            <div class="self-menu">
              <div class="user-info">
                <img v-if="info.headImage" class="avatar" :src="staticPath + info.headImage" />
                <img v-else src="@/assets/images/avatar.jpg" class="avatar" />
                <div class="user-name">{{ info.name }}</div>
              </div>
              <div class="buttons">
                <div class="button" @click="commitLogout()">
                  <div class="flex-cc">
                    <img src="@/assets/images/navbar/logout.png" alt="" class="left-icon" />
                    <div>退出登陆</div>
                  </div>
                  <div class="flex-cc">
                    <div class="right-icon" style="width:16px; height:16px;"></div>
                  </div>
                </div>
              </div>
            </div>
          </el-popover>
        </div>
        <!-- <a class="center" :title="'数字科创中心'" href="/user" target="_blank">数字科创中心</a> -->
      </div>
    </div>
  </header>
  <header class="navbar" v-else-if="isAnnounce" :style="`${backgroundColor ? 'background-color:' + backgroundColor + ';' : ''}`">
    <div class="center">
      <div class="left">
        <img src="@/assets/images/G60/announce/logo.png" style="width: 81px;height: 44px;margin-right: 50px;"/>
        <!-- <a href="/" title="首页" class="a" :class="{'a-active': routePath === '/'}">首页</a> -->
        <a @click="scrollToSmooth('INT')" title="产品功能" class="a-1" :class="{'a-active': routePath === '/'}">产品功能</a>
        <a @click="scrollToSmooth('AD')" title="产品优势与特点" class="a-1" :class="{'a-active': routePath === '/'}">产品优势与特点</a>
        <a @click="scrollToSmooth('VERSION')" title="产品版本与定价" class="a-1" :class="{'a-active': routePath === '/'}">产品版本与定价</a>
        <!-- <a href="/" title="客户案例" class="a" :class="{'a-active': routePath === '/'}">客户案例</a> -->
        <a @click="scrollToSmooth('US')" title="联系我们" class="a-1" :class="{'a-active': routePath === '/'}">联系我们</a>
      </div>
      <div class="right">
        <!-- <a v-if="!loginStatus" class="login-button" :title="'登录/注册'" :href="`/login?redirect=${redirectUrl()}`" >登录/注册</a> -->
      </div>
    </div>
  </header>
  <div class="pops">
    <div class="achieve" v-if="showAchieve">
      <AchievePop></AchievePop>
    </div>
    <div class="demand" v-if="showDemand">
      <DemandPop></DemandPop>
    </div>
    <div class="cor" v-if="showCor">
      <CorPop></CorPop>
    </div>
    <div class="ask" v-if="showAsk">
      <AskPop></AskPop>
    </div>
    <div class="success" v-if="showSuccess">
      <SuccessPop></SuccessPop>
    </div>
    <div class="expert" v-if="showExpert">
      <ExpertPop></ExpertPop>
    </div>
    <div class="question" v-if="showQuestion">
      <QuestionPop></QuestionPop>
    </div>
    <div class="table" v-if="showTable">
      <TablePop></TablePop>
    </div>
    <div class="vip-form" v-if="showVipForm">
      <VipFormPop></VipFormPop>
    </div>
  </div>
  <div class="vip" v-if="showVip">
    <VipPop></VipPop>
  </div>
</template>

<script>
import { getG60LocalInfo,removeG60LocalInfo } from "@/utils/G60/login";
import { mapGetters } from "vuex";
import AchievePop from "./AchievePop.vue";
import { ElMessage, ElMessageBox } from 'element-plus';
import CorPop from "./CorPop.vue";
import DemandPop from "./DemandPop.vue";
import AskPop from "./AskPop.vue";
import SuccessPop from "./SuccessPop.vue";
import ExpertPop from "./ExpertPop.vue";
import QuestionPop from "./QuestionPop.vue";
import VipPop from "./VipPop.vue";
import TablePop from "./TablePop.vue";
import VipFormPop from "./VipFormPop.vue";
import { staticPath,G60WhitePage,G60OutLink } from "@/utils/consts";
import { routerWindow } from "../../../utils/tool";


export default{
  components:{
    AchievePop,
    QuestionPop,
    AskPop,
    SuccessPop,
    DemandPop,
    CorPop,
    ExpertPop,
    TablePop,
    VipFormPop,
    VipPop,
},
  data(){
    return{
      info: {},
      backgroundColor: '',
      staticPath,
      scrollInter: null,
    }
  },
  watch:{
    visibility(val){
      if(val) this.setInfo()
    },
    reloadUser(){
      this.setInfo()
    },
    $route(){
      this.setInfo()
    },
  },
  computed:{
    isAnnounce(){
      return window.location.href.includes('/announce')
    },
    isG60OutLink(){
      return G60OutLink.includes(this.$route.path)
    },
    isLogin(){
      return this.$route.path === '/login'
    },
    showAchieve(){
      return this.achieveStatus.show
    },
    showDemand(){
      return this.demandStatus.show
    },
    showCor(){
      return this.corStatus.show
    },
    showAsk(){
      return this.askStatus.show
    },
    showSuccess(){
      return this.successStatus.show
    },
    showExpert(){
      return this.expertStatus.show
    },
    showQuestion(){
      return this.questionStatus.show
    },
    routePath(){
      return this.$route.path
    },
    loginStatus(){
      return this.info?.id
    },
    showVip(){
      return this.vipStatus.show
    },
    showTable(){
      return this.tableStatus.show
    },
    showVipForm(){
      return this.vipFormStatus.show
    },
    ...mapGetters([
      "visibility",
      "achieveStatus",
      "demandStatus",
      "corStatus",
      "askStatus",
      "successStatus",
      "expertStatus",
      "questionStatus",
      "vipStatus",
      "tableStatus",
      "vipFormStatus",
      "reloadUser",
    ]),
  },
  mounted(){
    this.setInfo()
    this.handleScroll()
    window.addEventListener('scroll', this.handleScroll) // 监听页面滚动
  },
  methods:{
    scrollToSmooth(eId){
      if(!eId || this.scrollInter) return
      const dom = document.getElementById(eId)
      if(!dom) return
      let scrollTop =
      window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if(scrollTop > dom.offsetTop){
         this.scrollInter = setInterval(()=>{
          scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
          if(scrollTop - 50 <= dom.offsetTop){
            dom.scrollIntoView()
            clearInterval(this.scrollInter)
            this.scrollInter = null
          }
          else{
            document.scrollingElement.scrollTop -= 50
          }
        },10)
      }
      else if(scrollTop < dom.offsetTop){
        this.scrollInter = setInterval(()=>{
          scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
          if(scrollTop + 50 >= dom.offsetTop){
            dom.scrollIntoView()
            clearInterval(this.scrollInter)
            this.scrollInter = null
          }
          else{
            document.scrollingElement.scrollTop += 50
          }
        },10)
      }
      // dom.scrollIntoView()
    },
    handleScroll () {
      const scrollTop =
      window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if(scrollTop > 150){
        this.backgroundColor = ''
      }
      else{
        this.backgroundColor = `rgba(255,255,255,${scrollTop / 150})`
      }
    },
    toRoute(path,id){
      if(!path) return
      routerWindow(this.$router,path,{id})
    },
    toUserCenter(){
      routerWindow(this.$router,'/user',{})
    },
    redirectUrl(){
      return window.location.href
    },
    toPublish(type){
      routerWindow(this.$router,'/publish-dynamic',{type})
    },
    setInfo(){
      this.info = getG60LocalInfo()?.memberInfo || {}
    },
    commitLogout(){
      ElMessageBox.confirm("是否确认退出登录?", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        lockScroll: false,  // 防止页面振动
        type: "warning",
      }).then(() => {
        ElMessage.success('成功退出登录!')
        removeG60LocalInfo()
        this.setInfo()
        let path = this.$route.path
        if(!G60WhitePage.includes(path)){
          this.$router.push({
            path: '/login',
            query:{
              redirect: window.location.href
            }
          })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.seize {
  width: 100%;
  height: 60px;
}
.navbar {
  height: 60px;
  width: 100%;
  min-width: 1200px;
  background: #FFFFFF;
  position: fixed;
  top: 0;
  z-index: 2001;
  display: flex;
  justify-content: center;
  align-items: center;
  .center{
    width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left{
      display: flex;
      align-items: center;
      .logo-0{
        width: 172px;
        height: 55px;
        margin-right: 8px;
      }
      .logo-1{
        width: 119px;
        height: 38px;
        margin-right: 34px;
      }
      .p-title{
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #111111;
        line-height: 20px;
        margin: 0 20px;
        cursor: pointer;
        &:hover{
          color: $--G60-theme;
        }
      }
      .a-1{
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #111111;
        line-height: 20px;
        margin: 0 20px;
        cursor: pointer;
        &:hover{
          color: $--color-theme !important;
        }
        &:visited{
          color: #111111;
        }
      }
      .a{
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #111111;
        line-height: 20px;
        margin: 0 20px;
        cursor: pointer;
        &:hover{
          color: $--G60-theme !important;
        }
        &:visited{
          color: #111111;
        }
      }
      .a-active{
        color: $--G60-theme !important;
        &:visited{
          color: $--G60-theme;
        }
      }
    }
    .right{
      display: flex;
      align-items: center;
      .p{
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #111111;
        line-height: 14px;
        cursor: pointer;
        &:hover{
          color: $--G60-theme;
        }
      }
      .login-button{
        width: 84px;
        height: 28px;
        background: $--G60-theme;
        border-radius: 4px;
        border: none;
        cursor: pointer;
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 14px;
        margin-left: 10px;
        text-align: center;
        line-height: 28px;
        &:visited{
          color: #ffffff !important;
        }
      }
      .center{
        width: 101px;
        height: 28px;
        border-radius: 4px;
        border: 1px solid #111111;
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #111111;
        line-height: 28px;
        cursor: pointer;
        margin-left: 10px;
        background-color: #ffffff;
        text-align: center;
        box-sizing: border-box;
        padding-left: 8px;
        &:visited{
          color: #111111;
        }
        &:hover{
          color: $--G60-theme;
          border: 1px solid $--G60-theme;
        }
      }
    }
  }
}

.avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-left: 10px;
}
.avatar-name{
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #111111;
  line-height: 20px;
  max-width: 140px;
  margin-left: 6px;
  cursor: pointer;
  &:hover{
    color: $--G60-theme;
  }
}
.self-menu {
  font-size: 16px;
  color: #666666;
  display: flex;
  flex-direction: column;
  .user-info {
    display: flex;
    width: calc(100% + 2px);
    margin-left: -1px;
    margin-top: -1px;
    padding: 25px 0;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border-radius: 4px 4px 0px 0px;
    background: $--G60-theme;
    .avatar {
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
    .user-name {
      margin-top: 8px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
    }
  }
  .vip-services {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    .vip {
      margin-top: 10px;
      width: 47%;
      height: 68px;
      padding: 5px 0;
      background: #f1f5f8;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #0f1c34;
      .left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .top {
          font-size: 18px;
          margin-left: 10px;
          display: flex;
          align-items: center;
          img {
            margin-right: 5px;
          }
          .golden {
            width: 28px;
            height: 38px;
            margin-right: 5px;
          }
          .diamond {
            width: 38px;
            height: 38px;
            margin-right: 5px;
          }
          margin-right: 10px;
        }
      }
      .middle {
        display: flex;
        flex-direction: column;
        margin-left: 5px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #8991a0;
      }
      .right {
        width: 46px;
        height: 32px;
        background: #feebd7;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #864d07;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px 5px;
        margin-right: 10px;
        border-radius: 4px;
        cursor: pointer;
      }
    }
    .full-width {
      width: 100%;
      .left {
        flex-direction: row !important;
        justify-content: flex-start;
        align-items: center;
      }
      .right {
        width: 56px;
        border-radius: 4px;
        margin-right: 10px;
        background: #dfe0e5;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #8991a0;
      }
    }
  }
  .buttons {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding-top: 8px;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px 0px rgba(56,29,0,0.3);
    padding-bottom: 5px;
    .button:hover{
      background: #00c38220;
      color: $--G60-theme;
      .right-icon{
        background-image: url('../../../assets/images/navbar/right-icon-hover.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
    }
    .button {
      padding: 10px 20px;
      width: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      .left-icon {
        margin-top: 2px;
        width: 16px;
        height: 16px;
        margin-right: 13px
      }
      .right-icon{
        background-image: url('../../../assets/images/navbar/right-icon.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
      font-size: 16px;
      line-height: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #0f1c34;
    }
    border-bottom: 2px solid #f1f5f8;
  }
  .exit-login {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 5px;
    .exit {
      display: flex;
      align-items: center;
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #8991a0;
      cursor: pointer;
    }
  }
}

.self-menu-item {
  height: 50px;
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.self-menu-item:hover {
  background: #ec8541;
  color: white;
}

.p-publish{
  width: 84px;
  height: 28px;
  background: $--G60-theme;
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 28px;
  text-align: center;
  cursor: pointer;
}
.news{
  .news-type{
    display: block;
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #111111;
    line-height: 20px;
    padding: 10px 0;
    cursor: pointer;
    &:hover{
      color: $--G60-theme !important;
    }
    &:visited{
      color: #111111;
    }
  }
}
.cities{
  .city{
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #111111;
    line-height: 20px;
    padding: 10px 0;
    cursor: pointer;
    &:hover{
      color: $--G60-theme;
    }
  }
  .wait{
    position: relative;
    &::after{
      content: '即将上线';
      position: absolute;
      top: 2px;
      right: 5px;
      font-size: 12px;
      color: #ffffff;
      background-color: #ff5e52;
      border-radius: 8px 8px 8px 0;
      padding: 0 2px;
    }
  }
}
.entrance-line{
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  .p-0{
    font-size: 14px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: #111111;
    line-height: 16px;
    margin-bottom: 6px;
  }
  .p-tip{
    font-size: 12px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 16px;
  }
  .entrance-0{
    width: 100%;
    height: 57px;
    background-image: url('../../../assets/images/G60/Navbar/entrance-0.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    box-sizing: border-box;
    padding-top: 12px;
    padding-left: 12px;
    cursor: pointer;
    margin-bottom: 7px;
  }
  .entrance-1{
    width: 100%;
    height: 57px;
    background-image: url('../../../assets/images/G60/Navbar/entrance-1.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    box-sizing: border-box;
    padding-top: 12px;
    padding-left: 12px;
    cursor: pointer;
  }
}

</style>