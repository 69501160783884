import { AIWhitePage } from "@/utils/consts";
import { ElMessage } from "element-plus";
import { getAILocalInfo,setAILocalInfo,removeAILocalInfo,getAIToken,getAIRefreshToken } from "./login";
import { getCookie,setCookie } from "@/utils/Cookies/index";
import { memberDetails } from "@/api/user";
import store from '@/store';


export const AIPermission = (to,from,next) => {
  if(getAILocalInfo()) return  // 已经登录
  else if(AIWhitePage.includes(to.path)) return // AI 白名单
  next({
    path: '/login',
    query:{
      redirect: window.location.href
    }
  })
}

export const judgeAICookie = ()=>{
  if(!getCookie('KqdInfo') && getAILocalInfo()){
    // 本地登陆了却没有cookie
    const info = getAILocalInfo()
    const localNew = {
      status: '1',
      accessToken: getAIToken(),
      memberInfo: {
        id: info.id,
      },
      refreshToken: getAIRefreshToken()
    }
    setCookie('KqdInfo',localNew)
  }
  else if(getCookie('KqdInfo') && !getAILocalInfo()){
    // 有cookie但无本地缓存
    let cookie = JSON.parse(getCookie('KqdInfo'))
    if(cookie.status + '' === '0'){
      // 退出登录状态 退出登录清除缓存
      removeAILocalInfo()
      store.dispatch('AI/setLoginChange','cookie') // 通知获取状态
    }
    else{
      setAILocalInfo(cookie) // 存入 token
      memberDetails(cookie.memberInfo.id).then(res=>{
        if(res.code === "Sx200"){
          cookie.memberInfo = res.data
          setAILocalInfo(cookie) // 存入信息
        }
      })
    }
  }
  else if(getCookie('KqdInfo') && getAILocalInfo()){
    let cookie = JSON.parse(getCookie('KqdInfo'))
    if(cookie.status + '' === '0'){
      // 退出登录状态 退出登录清除缓存
      removeAILocalInfo()
      store.dispatch('AI/setLoginChange','cookie') // 通知获取状态
    }
    else if(cookie.memberInfo.id + '' !== getAILocalInfo().memberInfo.id + ''){
      // 判断是否更换了账号以cookie账号为准
      setAILocalInfo(cookie) // 存入新账号的token
      memberDetails(cookie.memberInfo.id).then(res=>{
        if(res.code === "Sx200"){
          cookie.memberInfo = res.data
          setAILocalInfo(cookie) // 存入信息
          store.dispatch('AI/setLoginChange','cookie') // 通知获取状态
          location.reload() // 刷新页面
        }
      })
    }
  }
}

export const AIChangeVisibility = (route,value,router) => {
  if(value) return
  judgeAICookie()
  const path = route.path
  const query = route.query
  if(path === '/login' && getAILocalInfo()){
    if(query.redirect){
      window.location.href = this.$route.query.redirect
    }
    else{
      router.push({
        path: '/'
      })
    }
  }
  else if(!AIWhitePage.includes(path) && !getAILocalInfo()){
    ElMessage.warning('您尚未登录!')
    router.push({
      path: '/login',
      query:{
        redirect: window.location.href
      }
    })
  }
}