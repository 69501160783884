<template>
  <div v-loading="changing">
    <el-cascader style="width: 100%;" :options="result.length === max ? forBidOther : mySelection" v-model="result" :props="props" :before-filter="beforeFilter(result)" :placeholder="placeholder" :popper-class="'popper-class'" :disabled="loading || disabled" :show-all-levels="false" @visible-change="visibleChange" @change="visibleChangeNew" @expand-change="visibleChange">
    </el-cascader>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
export default {
  /*
  数据格式
  mySelection:[{
    val: type.id,
    label: type.val,
    children: type.child.map((child)=>{
      return {
        val: child.id,
        label: child.val
      }
    })
  }]
  */
  props: ['selections','placeholder','max','value','disabled','changing'],
  emits: ['sendValue'],
  mounted(){
    let that = this
    setTimeout(()=>{
      if((that.value + '')?.length > 0 && that.selections?.length > 0){
        let arr = (that.value + '').split(',')
        let newResult = []
        that.mySelection?.forEach((type)=>{
          type.children?.forEach((item )=>{
            if(arr.includes(item.value + '')){
              newResult.push([type.value,item.value])
            }
          })
        })
        that.loading = true
        setTimeout(()=>{
          that.loading = false
          that.result = newResult // 回显操作
          that.$nextTick(()=>{
            // 防止崩溃
            const $el = document.querySelectorAll('.el-cascader-panel .el-cascader-node[aria-owns]');
            Array.from($el).map((item) => item.removeAttribute('aria-owns'));
          })
        },1000)
      }
    },500)
    setTimeout(()=>{
      that.init = true
    },3000)
  },
  computed:{
    mySelection(){
      // 选项
      if(this.selections.length === 0) return []
      let arr = this.selections.map((type)=>{
        return {
          value: type.id,
          label: type.val,
          children: type.child.map((child)=>{
            return {
              value: child.id,
              label: child.val
            }
          })
        }
      })
      return arr
    },
    forBidOther(){
      // 除了已选禁用其他
      if(this.selections.length === 0) return []
      let resultIds = this.result.map((item)=>item[1])
      let arr = this.selections.map((type)=>{
        return {
          value: type.id,
          label: type.val,
          children: type.child.map((child)=>{
            return resultIds.includes(child.id) ?  {
              value: child.id,
              label: child.val
            }:{
              disabled: 'disabled',
              value: child.id,
              label: child.val
            }
          })
        }
      })
      return arr
    },
  },
  watch:{
  },
  data(){
    return{
      props: {
        // props.
        multiple: true,
      },
      init: false,  // 初始化修改
      loading: false, // 加载结果
      result: [],  // 结果
    }
  },
  methods:{
    visibleChangeNew(){
      this.$emit('sendValue',this.getResult())
      this.$nextTick(() => {
        const $el = document.querySelectorAll('.el-cascader-panel .el-cascader-node[aria-owns]');
        Array.from($el).map((item) => item.removeAttribute('aria-owns'));
      });
    },
    visibleChange(){
      this.$nextTick(() => {
        const $el = document.querySelectorAll('.el-cascader-panel .el-cascader-node[aria-owns]');
        Array.from($el).map((item) => item.removeAttribute('aria-owns'));
      });
    },
    clearResult(){
      // 清空结果
      this.result = []
    },
    getResult(){
      // 获取结果
      return this.result
    },
    beforeFilter(val){
      // 过滤函数调用前欲被调用的钩子函数，该函数接受一个参数。 如果该函数的返回值是 false 或者是一个被拒绝的 Promise，那么接下来的过滤逻辑便不会执行。
      if(val.length === 10 && this.init){
        ElMessage.warning('最多选择10项!')
      }
    },
  }
}
</script>
