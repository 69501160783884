<template>
  <div v-if="firstWindowVisible">
    <div class="iconChatBox">
      <div class="img flex-dc-rc">
        <div class="pop-window flex-dc-rc" @click.stop>
          <img src="../../assets/images/pop/publish/publish-pop-title.png" alt="" style="width: 150px; height: 27px;margin-top: 30px;">
          <img src="../../assets/images/pop/publish/publish-pop-tip.png" alt="" style="width: 224px; height: 21px;margin-top: 13px;margin-bottom: 11px;">
          <div class="flex-cc words">
            <img src="../../assets/images/pop/publish/publish-pop-icon.png" style="width: 16px;height: 16px;margin-right: 5px;"/>
            科企岛数字科创服务平台
          </div>
          <div class="flex-cc words">
            <img src="../../assets/images/pop/publish/publish-pop-icon.png" style="width: 16px;height: 16px;margin-right: 5px;"/>
            提交需求 立即响应
          </div>
          <div class="flex-cc words">
            <img src="../../assets/images/pop/publish/publish-pop-icon.png" style="width: 16px;height: 16px;margin-right: 5px;"/>
            百万级专家教授与您对接
          </div>
          <div class="flex-cc words">
            <img src="../../assets/images/pop/publish/publish-pop-icon.png" style="width: 16px;height: 16px;margin-right: 5px;"/>
            为技术创新提供全要素解决方案
          </div>
          <img src="../../assets/images/pop/publish/publish-pop-img.png" style="width: 258px;height: 133px;margin-top: 4px;" alt="" srcset=""/>
          <img src="../../assets/images/pop/publish/publish-pop-button.png" style="width: 237px;height: 49px;cursor: pointer;" alt="" srcset="" @click="jumpToDemand()"/>
        </div>
        <img class="close-btn" src="../../assets/images/pop/publish/publish-pop-close.png" />
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import { LaTrack,routerWindow } from '../../utils/tool'

export default {
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters(['firstWindowVisible'])
  },
  methods: {
    jumpToDemand(){
      // 发布需求页面
      this.$store.commit("message/SET_FIRST_WINDOW_VISIBLE",false)
      LaTrack('e_create_organization_from_homepage')
      routerWindow(this.$router,'/postdemand')
    },
  }
}
</script>


<style scoped lang="scss">
.iconChatBox {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  z-index: 999;
  position: absolute;
  top: 0%;
  overflow: auto;
  .img {
    padding-top: 100px;
    position: relative;
    .event{
      position: absolute;
      width: 440px;
      height: 78px;
      top: 583px;
      left: 78px;
      box-sizing: border-box;
      cursor: pointer;
    }
    img {
      // cursor: pointer;
      height: 50vh;
    }
    .pop-window{
      width: 304px;
      height: 416px;
      background-image: url('../../assets/images/pop/publish/publish-pop-back.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      .words{
        width: 247px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #0C368D;
        margin: 4px 0;
      }
    }
    .close-btn{
      color: #ffffff;
      font-size: 50px;
      cursor: pointer;
      display: grid;
      text-align: center;
      margin-top: 36px;
      width: 31px;
      height: 31px;
    }
  }
}
</style>